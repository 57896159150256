import React, { Component } from "react";
import PropTypes from "prop-types";

import Canvas from "./canvas/Canvas";

import "./FilterTarget.scss";

export class FilterTarget extends Component {
	render () {
		const { playgroundProps, filter, onCanvasChangeDimensions } = this.props;
		const { dashboard } = playgroundProps;
		const { canvases } = dashboard;
		const { result } = filter;

		if (!result.canvas_id || !result.canvas_version) {
			throw new Error("Missing 'canvas_id' or 'canvas_version' in filter result");
		}

		if (!canvases) {
			throw new Error("Missing 'canvases' data");
		}

		const canvasKey = result.canvas_id;

		if (!canvases[canvasKey]) {
			throw new Error("Missing canvas for: " + canvasKey);
		}

		return (
			<div className="pg-inlet-filter-target">
				<Canvas
					key={canvasKey}
					onChangeDimensions={onCanvasChangeDimensions}
					playgroundProps={playgroundProps}
					canvas={canvases[canvasKey]}
				/>
			</div>
		);
	}
}

FilterTarget.propTypes = {
	playgroundProps: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	filter: PropTypes.object.isRequired,
	onCanvasChangeDimensions: PropTypes.func
};

export default FilterTarget;
