import React, { Component } from "react";
import PropTypes from "prop-types";
import { getIn } from "formik";
import { Draggable } from "react-beautiful-dnd";

import { LogicTypeOptions } from "../../../../../../lib/logic/type";
import { OperatorTypeOptions, operatorValueIsRequired } from "../../../../../../lib/logic/operator";
import { extractErrorMessageFromArrayErrors, extractErrors } from "../../../../../../util/form/helper";
import { RawField } from "../../../../form";

import "./ConditionRule.scss";

const RuleAction = ({ icon, iconClass, onClick }) => {
	if (!iconClass) {
		iconClass = "";
	}

	return (
		<div className="action" onClick={onClick}>
      <span className={"icon " + iconClass}>
        <i className={icon}/>
      </span>
		</div>
	);
};

const RuleActions = ({ doDelete }) => {
	return (
		<div className="rule-actions">
			{doDelete
				? <RuleAction icon="fad fa-trash" iconClass="is-danger" onClick={doDelete}/>
				: ""}
		</div>
	);
};

class ConditionRuleElement extends Component {
	static propTypes = {
		provided: PropTypes.object,
		snapshot: PropTypes.object,
		rule: PropTypes.object.isRequired,
		group: PropTypes.object.isRequired,
		namePath: PropTypes.string.isRequired,
		disableEdit: PropTypes.bool,
		doDelete: PropTypes.func.isRequired,
		setFieldValue: PropTypes.func.isRequired,
		values: PropTypes.object,
		touched: PropTypes.object,
		errors: PropTypes.object
	};

	state = {
		isDragHover: false
	};

	setIsDragHover = (isDragHover) => {
		this.setState(() => ({
			isDragHover: isDragHover
		}));

		return false;
	};

	render () {
		const {
			provided, snapshot, namePath, disableEdit,
			doDelete, values, touched, errors, setFieldValue
		} = this.props;

		const { isDragHover } = this.state;

		const errorData = extractErrors(namePath, errors, touched);
		const errorMessage = extractErrorMessageFromArrayErrors(errorData);

		const operatorOptions = [
			{ value: "" },
			...OperatorTypeOptions
		];

		const operatorEnabled = true;
		const operatorValue = getIn(values, namePath + ".operator");

		let valueEnabled = false;
		if (operatorValue && operatorValueIsRequired(operatorValue)) {
			valueEnabled = true;
		}

		let dragInnerRef;
		let isDragging = false;
		let draggableProps = {};
		let dragHandleProps = {};
		if (!disableEdit && snapshot) {
			isDragging = snapshot.isDragging;
		}
		if (!disableEdit && provided) {
			dragInnerRef = provided.innerRef;
			draggableProps = provided.draggableProps;
			dragHandleProps = provided.dragHandleProps;
		}

		return (
			<div
				className={"condition-rule-container " + ((isDragging) ? "is-dragging " : "")}
				ref={dragInnerRef}
				{...draggableProps}
			>
				<div className={"condition-rule " + ((isDragHover || isDragging) ? "is-drag-hover " : "")}>
					{!disableEdit
						? (
							<div
								className="rule-drag"
								onMouseEnter={() => this.setIsDragHover(true)}
								onMouseLeave={() => this.setIsDragHover(false)}
								onMouseUp={() => this.setIsDragHover(false)}
								{...dragHandleProps}
							>
                <span className="icon">
                  <i className="fal fa-ellipsis-v"/>
                </span>
							</div>
						)
						: ""}
					<div className="rule-content">
						<div className="rule-inner-content">
							<div className="rule-values">
								<div className="type">
									<RawField
										name={`${namePath}.type`} values={values} touched={touched} errors={errors}
										hasError={!!errorData.type} type="select" placeholder="Type"
										options={LogicTypeOptions}
										setFieldValue={setFieldValue} isSmall
									/>
								</div>
								<div className="key">
									<RawField
										name={`${namePath}.key`} values={values} touched={touched} errors={errors}
										hasError={!!errorData.key} type="text" placeholder="Key"
										setFieldValue={setFieldValue} isSmall isFullWidth
									/>
								</div>
								<div className="operator">
									<RawField
										name={`${namePath}.operator`} values={values} touched={touched} errors={errors}
										hasError={!!errorData.operator} type="select" placeholder="Operator"
										options={operatorOptions}
										setFieldValue={setFieldValue} isSmall disabled={!operatorEnabled}
									/>
								</div>
								<div className="value">
									<RawField
										name={`${namePath}.value`} values={values} touched={touched} errors={errors}
										hasError={!!errorData.value} type="text" placeholder="Value"
										setFieldValue={setFieldValue} isSmall isFullWidth disabled={!valueEnabled}
									/>
								</div>
								<div className="enabled">
									<RawField
										name={`${namePath}.enabled`} values={values} touched={touched} errors={errors}
										hasError={!!errorData.enabled} type="bool" placeholder="Enabled"
										setFieldValue={setFieldValue} isSmall
									/>
								</div>
							</div>
							<RuleActions doDelete={doDelete}/>
						</div>
						{errorMessage
							? (
								<div className="rule-errors">
									<p className="help is-danger">{errorMessage}</p>
								</div>
							)
							: ""}
					</div>
				</div>
			</div>
		);
	}
}

export class ConditionRule extends Component {
	static propTypes = {
		rule: PropTypes.object.isRequired,
		group: PropTypes.object.isRequired,
		namePath: PropTypes.string.isRequired,
		disableEdit: PropTypes.bool,
		doDelete: PropTypes.func.isRequired,
		setFieldValue: PropTypes.func.isRequired,
		values: PropTypes.object,
		touched: PropTypes.object,
		errors: PropTypes.object
	};

	render () {
		const { rule, index, disableEdit, ...rest } = this.props;

		if (disableEdit) {
			return (
				<ConditionRuleElement
					disableEdit={disableEdit}
					rule={rule}
					index={index}
					{...rest}
				/>
			);
		}

		return (
			<Draggable
				key={rule._id}
				draggableId={rule._id}
				type="rule"
				index={index}
			>
				{(provided, snapshot) => (
					<ConditionRuleElement
						provided={provided}
						snapshot={snapshot}
						rule={rule}
						index={index}
						{...rest}
					/>
				)}
			</Draggable>
		);
	}
}

export default ConditionRule;
