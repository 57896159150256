import listSagas from "./list";
import newSagas from "./new";
import dashboardSagas from "./dashboard";
import canvasSagas from "./canvas";
import editSagas from "./edit";
import debuggerSagas from "./debugger";
import killSagas from "./kill";
import groupSagas from "./group";

export const playgroundSagas = [
	...listSagas,
	...newSagas,
	...dashboardSagas,
	...canvasSagas,
	...editSagas,
	...debuggerSagas,
	...killSagas,
	...groupSagas
];

export default playgroundSagas;
