import cloneDeep from "lodash/cloneDeep";
import Color from "color";

import { OptFill, OptFillHover, OptIconFill, OptIconFillHover } from "core/config";

export const initStyleProps = (inProps) => {
	const props = cloneDeep({
		[OptFill]: "#FFFFFF",
		[OptIconFill]: "#000000",
		...inProps
	});

	if (!props[OptFillHover]) {
		if (props[OptFill]) {
			props[OptFillHover] = Color(props[OptFill]).lighten("0.12").string();
		}
	}
	if (!props[OptIconFillHover]) {
		if (props[OptIconFill]) {
			props[OptIconFillHover] = props[OptIconFill];
		}
	}

	return props;
};
