import React, {useContext, useEffect} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";

import NotFound from "core/containers/error/NotFound.container";
import ScrollToTop from "core/components/layout/ScrollToTop";
import PrivateRoute from "core/components/layout/route/PrivateRoute";

import Dashboard from "../../sections/dashboard/Dashboard.container";

import PlaygroundCanvasRoutes from "canvas/routes";
import TemplateEditorRoutes from "../../sections/editors/routes";
import PrivateRouteAuth from "../../core/containers/auth/private/PrivateRouteAuth.container";
import Logout from "../navigation/Logout";
import AccountSettingsSection from "../../sections/account/AccountSettings";
import SettingsHome from "../../core/containers/settings/Home.container";
import {ScreenContext} from "../../state/ScreenContextProvider";
import {PrivateRouteModeLogin, PrivateRouteModeRegister} from "../../core/actions/auth";
import NumbersSection from "../../sections/numbers/NumbersSection";
import {PrototypeFrame, PrototypesSection} from "../../sections/prototypes/PrototypesSection";
import {Profile} from "../../store";
import {VarContextEditor} from "../VarContext/VarContextEditor";


type IRouterProps = {
    profile: Profile;
    setFullscreen: (flag: boolean) => void;
    isFullscreen: boolean;
}


function Router(props: IRouterProps): React.ReactElement {

    let {profile, setFullscreen, isFullscreen} = props;
    const loggedIn = profile.loggedIn;

    const location = useLocation();
    const screen = useContext(ScreenContext);

    useEffect(() => {
        if (loggedIn) {
            screen.dispatch({fullScreenMode: false});
        }
    }, [location, loggedIn, screen, isFullscreen]);

    const publicRoutes = [
        <Route key="login" exact path="/login">
            <PrivateRouteAuth profile={profile} setFullscreen={setFullscreen} mode={PrivateRouteModeLogin}/>
        </Route>,

        <Route key="register" exact path="/register">
            <PrivateRouteAuth profile={profile} setFullscreen={setFullscreen} mode={PrivateRouteModeRegister}/>
        </Route>,

        <Route key="logout" exact path="/logout">
            <Logout/>
        </Route>,
    ];

    const loggedInRoutes = [

        <PrivateRoute
            key="dashboard" exact path="/" component={Dashboard}
            profile={profile} setFullscreen={setFullscreen} isFullscreen={isFullscreen}
        />,

        <PrivateRoute
            key="account-settings" exact path="/account/settings" component={SettingsHome}
            profile={profile} setFullscreen={setFullscreen} isFullscreen={isFullscreen}
        />,

        <PrivateRoute
            key="account-settings-contexts" exact path="/account/contexts" component={VarContextEditor}
            profile={profile} setFullscreen={setFullscreen} isFullscreen={isFullscreen}
        />,

        ...PlaygroundCanvasRoutes({profile: profile, setFullscreen: setFullscreen, isFullscreen: isFullscreen}),
        ...TemplateEditorRoutes(),

        <Route key="home" exact path="/">
            <Dashboard/>
        </Route>,

        <Route key="dash" exact path="/dash">
            <Dashboard/>
        </Route>,

        <Route key="/account" exact path="/account">
            <AccountSettingsSection/>
        </Route>,

        <Route key="/prototypes" exact path="/prototypes">
            <PrototypesSection/>
        </Route>,

        <Route key="/prototypes/frame" path="/prototypes/:pid">
            <PrototypeFrame/>
        </Route>,

        <Route key="/numbers" exact path="/numbers">
            <NumbersSection/>
        </Route>,
    ];

    const routes = [

        ...publicRoutes,
        ...(loggedIn ? loggedInRoutes : []),

        <Route key="not-found">
            {loggedIn ? <NotFound setFullscreen={setFullscreen} isFullscreen={isFullscreen}/> : <Redirect to="/login"/>}
        </Route>
    ];

    return (
        <ScrollToTop>
            <Switch>{routes}</Switch>
        </ScrollToTop>
    );
}

export default Router;
