import React, { Component } from "react";
import Modal from "../../../components/common/modal/Modal";
import connect from "react-redux/es/connect/connect";
import { ActivateCloseModalAction, ResendActivateAction, ResendVerificationAction } from "../../../actions/auth";
import ActivateErrorAndResend from "../../../components/auth/activate/ActivateErrorAndResend";
import ResendActivateSuccess from "../../../components/auth/activate/ResendActivateSuccess";
import ActivateSuccess from "../../../components/auth/activate/ActivateSuccess";

const ActivateModalContent = ({
	loading,
	success,
	resendSuccess,
	closeModal,
	resendLink,
	resendError,
	fromLogin,
	email,
	forVerify
}) => {
	if (success) {
		return (
			<ActivateSuccess
				closeModal={closeModal}
				forVerify={forVerify}
			/>
		);
	}

	if (resendSuccess) {
		return (
			<ResendActivateSuccess
				closeModal={closeModal}
				forVerify={forVerify}
			/>
		);
	}

	return (
		<ActivateErrorAndResend
			closeModal={closeModal}
			resendLink={resendLink}
			loading={loading}
			error={resendError}
			fromLogin={fromLogin}
			email={email}
			forVerify={forVerify}
		/>
	);
};

class ActivateModal extends Component {
	render () {
		let { activate, closeModal, resendLink, resendVerifyLink } = this.props;
		if (!activate) {
			activate = {};
		}

		const open = !!(activate.open);
		if (!open) {
			return null;
		}

		const success = !!(activate.success);
		const error = !!(activate.error);
		const loading = !!(activate.loading);
		const fromLogin = !!(activate.fromLogin);
		const resendError = activate.resendError;
		const email = activate.email;
		const resendSuccess = !!(activate.resendSuccess);
		const forVerify = !!(activate.forVerify);

		if (open && (!success && !resendSuccess && !error && !fromLogin && !forVerify)) {
			closeModal();
			return "";
		}

		let resendAction = resendLink;
		if (forVerify) {
			resendAction = resendVerifyLink;
		}

		return (
			<Modal
				title={forVerify ? "Verification" : "Activation"}
				closeModal={closeModal}
				modalState={open}
			>
				<ActivateModalContent
					loading={loading}
					success={success}
					resendSuccess={resendSuccess}
					closeModal={closeModal}
					resendLink={resendAction}
					resendError={resendError}
					fromLogin={fromLogin}
					email={email}
					forVerify={forVerify}
				/>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(ActivateCloseModalAction()),
		resendLink: (payload) => dispatch(ResendActivateAction(payload)),
		resendVerifyLink: (payload) => dispatch(ResendVerificationAction(payload))
	};
};

const mapStateToProps = (state, props) => {
	const core = state.get("core");
	if (!core) {
		return {};
	}

	return {
		activate: core.get("activate")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateModal);
