import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import FilterTarget from "./FilterTarget";
import FilterNew from "./FilterNew";

import { extractCanvasByFilterId } from "../../../../lib/playground/update";

import "./Filter.scss";

class FilterSiphon extends Component {
	static propTypes = {
		siphonSel: PropTypes.object.isRequired,
		playgroundProps: PropTypes.object.isRequired,
		inlet: PropTypes.object.isRequired,
		isPlaceholder: PropTypes.bool,
		index: PropTypes.number,
		count: PropTypes.number,
		filter: PropTypes.object
	};

	state = {
		isHover: false
	};

	setHover = (isHover) => {
		this.setState(() => ({
			isHover: isHover
		}));

		return false;
	};

	render () {
		let { filter, siphonSel, index, count, isPlaceholder, inlet, playgroundProps } = this.props;
		const { dashboard } = playgroundProps;

		if (!filter) {
			filter = {};
		}
		const isLast = (isPlaceholder) ? true : (index + 1 === count);

		const { isHover } = this.state;

		const { openEditFilter } = playgroundProps;

		const canvas = extractCanvasByFilterId(dashboard, filter.id);

		const doEdit = () => {
			openEditFilter({ filter });
		};

		let siphon = "";
		if (!isPlaceholder) {
			siphon = <div ref={siphonSel} className={classNames("siphon", { disabled: !filter.enabled })}>
				{canvas.name
					? (
						<div
							className="canvas-name"
							onClick={doEdit}
							onMouseOver={() => this.setHover(true)}
							onMouseLeave={() => this.setHover(false)}
						>
							<div>{canvas.name}</div>
						</div>
					)
					: ""}
				<div
					className="siphon-icon"
					onClick={doEdit}
					onMouseOver={() => this.setHover(true)}
					onMouseLeave={() => this.setHover(false)}
				>
					<span className="icon filter"><i className="fa fa-solid fa-rotate-180 fa-arrows-split-up-and-left"/></span>
					<span className="icon edit"><i className="fas fa-pencil"/></span>
				</div>
				<div className="siphon-anchor"/>
			</div>;
		}

		return (
			<div className="pg-inlet-filter-siphon">
				<div className={classNames("siphon-container", { "is-hover": isHover })}>
					{ siphon }
					{isLast ? <FilterNew inlet={inlet} playgroundProps={playgroundProps} isFirst={isPlaceholder}/> : ""}
				</div>
			</div>
		);
	}
}

export class Filter extends Component {
	static propTypes = {
		playgroundProps: PropTypes.object.isRequired,
		inlet: PropTypes.object.isRequired,
		isPlaceholder: PropTypes.bool,
		index: PropTypes.number,
		count: PropTypes.number,
		filter: PropTypes.object
	};

	constructor (props) {
		super(props);
		this.siphonSel = React.createRef();
	}

	handleCanvasChangeDimensions = ({ offsetY }) => {
		if (this.siphonSel.current) {
			this.siphonSel.current.style.marginTop = offsetY + "px";
		}
	};

	render () {
		const { isPlaceholder } = this.props;

		return (
			<div className="pg-inlet-filter">
				<FilterSiphon siphonSel={this.siphonSel} {...this.props} />

				{!isPlaceholder
					? (
						<FilterTarget
							onCanvasChangeDimensions={this.handleCanvasChangeDimensions}
							{...this.props}
						/>
					)
					: ""}
			</div>
		);
	}
}

export default Filter;
