import { takeLatest } from "redux-saga/effects";
import { SetPreferenceAction } from "../../actions/preferences";
import { setPreference } from "../../lib/preferences";

///  Load
// Worker
function * setPreferenceWorker (action) {
	yield setPreference(action.payload);
}

// Saga
export function * setPreferenceSaga () {
	yield takeLatest(SetPreferenceAction.getType(), setPreferenceWorker);
}

// Export

export const setSagas = [
	setPreferenceSaga()
];

export default setSagas;
