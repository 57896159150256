import { ArrowLinkFactory } from "./ArrowLinkFactory";
import { SuccessArrowLinkModel } from "./SuccessArrowLinkModel";

export class SuccessArrowLinkFactory extends ArrowLinkFactory {
	constructor () {
		super("success");
	}

	generateModel (event) {
		return new SuccessArrowLinkModel();
	}
}
