import { call, put, takeLatest } from "redux-saga/effects";
import { postRegister } from "../../api/auth/register";
import { RegisterAction, RegisterFailedAction, RegisterRequestAction, RegisterSuccessAction } from "../../actions/auth";

/// / Register
// Worker
function * postRegisterWorker (action) {
	try {
		yield put(RegisterRequestAction());
		const r = yield call(postRegister, action.payload);
		if (r.json) {
			yield put(RegisterSuccessAction(r.json));
		} else {
			yield put(RegisterFailedAction("Failed to register user"));
		}
	} catch (e) {
		yield put(RegisterFailedAction(e));
	}
}

// Saga
export function * postRegisterSaga () {
	yield takeLatest(RegisterAction.getType(), postRegisterWorker);
}

// Export

export const registerSagas = [
	postRegisterSaga()
];

export default registerSagas;
