import React from "react";
import PropTypes from "prop-types";

export class Resources extends React.Component {
	render () {
		const { resources } = this.props;
		if (!resources || !resources.loaded || !resources.defs) {
			return "";
		}

		const { defs } = resources;

		return (
			<div dangerouslySetInnerHTML={{ __html: defs }}/>
		);
	}
}

Resources.propTypes = {
	resources: PropTypes.object
};
