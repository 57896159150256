// Used to avoid passing cruft into actions from the link event
export const wrapLinkClick = (fun) => {
	return () => {
		fun();
	};
};

export const getCurrentUri = () => {
	let uri = window.location.pathname;
	if (window.location.search !== "") {
		uri += window.location.search;
	}
	return uri;
};
