import { apiFetch } from "core/api/base";

export function * postPlaygroundCanvasCreate ({ namespace, playgroundId, model }) {
	return yield apiFetch({
		endpoint: `/v1/playground/${namespace}/${playgroundId}/canvas`,
		method: "POST",
		authenticated: true,
		body: JSON.stringify(model)
	});
}
