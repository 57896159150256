import {createTheme, ThemeProvider} from "@mui/material";
import React from "react";

export const colorspace = {

    grays: {
        dark: "#000000",
        darkerGray: "#777777",
        darkGray: "#999999",
        gray: "#DDDDDD",
        lightGray: "#EFEFEF",
        lighterGray: "#F5F5F5",
        light: "#FFFFFF",
    },

    primary: {
        upwireBlueAlt4: "#00CEC7",
        light: "#56CCF2",

        dark: "#3A5CB6",
        upwireBlueAlt3: "#3A5CB6",
        darkBlue: "#4054b2",
        upwireBlueAlt1: "#4747B5",

        upwire: "#2D9CDB",
        blue: "#2b98f0",
        altBlue: "#536cff",
        upwireBlueAlt2: "#536DFF",

        upwirePurple2Light: "#F8F0FB",
        upwirePurple1: "#9B51E0",
        upwirePurple2: "#BB6BD9",
        purple: "#663fb4",
    },

    good: {
        upwireGreen1: "#219653",
        green: "#46b748",
        upwireGreen2: "#27AE60",
        upwireGreen3: "#6FCF97",
        upwireGreen2Light: "#E9F7EF",
    },

    warning: {
        dark: "#FF9700",
        yellow: "#fec900",
        main: "#FEC009",
        upwireYellow1: "#F2C94C",
        light: "#fedf66",
        upwireYellow2Light: "#FFF9E6",
    },

    bad: {
        main: "#D32F2F",
        red: "#f23611",
        light: "#ff4181",
        dark: "#EB5757",
        upwirePink1: "#FC5768",

    }
};

export const muiUpwireTheme = createTheme({
    palette: {
        primary: {
            light: colorspace.primary.light,
            main: colorspace.primary.upwire,
            dark: colorspace.primary.dark,
            contrastText: colorspace.grays.light,
        },
        warning: {
            light: colorspace.warning.light,
            main: colorspace.warning.dark,
            dark: colorspace.warning.dark,
            contrastText: colorspace.grays.darkGray,
        },
        error: {
            light: colorspace.bad.light,
            main: colorspace.bad.main,
            dark: colorspace.bad.dark,
            contrastText: colorspace.grays.light,
        },
    },
    typography: {
        fontFamily: `"Sofia Pro", "Helvetica", "Arial", sans-serif`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500
    },
    zIndex: {
    }
});

export function UpwireThemeProvider({children}: { children?: React.ReactNode }) {
    return (
        <ThemeProvider theme={muiUpwireTheme}>
            {children}
        </ThemeProvider>
    );
}
