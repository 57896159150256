import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface IConfirmDialogProps {
    open: boolean;
    close: (flag: boolean) => void;
    title: string;
    message: string;
}

export default function ConfirmDialog({open, close, title, message}: IConfirmDialogProps) {

    function closer(event: React.UIEvent, flag: boolean) {
        event.stopPropagation();
        close(flag);
    }

    return (
        <Dialog
            open={open}
            onClose={() => close(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={(e) => closer(e, true)} autoFocus color="primary">Yes</Button>
                <Button onClick={(e) => closer(e, false)}>No</Button>
            </DialogActions>
        </Dialog>
    );
}
