import { BranchingKeys, BranchingOutcomes, GotoTargetEnd, GotoTargetNext } from "../../../constants";
import { NodeTypeStart } from "../../../../../config/node";

export const walkPhaseNodes = (phase, cb) => {
	const phaseTargets = {
		local: {}
	};

	if (phase) {
		if (phase.start) {
			if (phase.start.goto && phase.start.goto.target) {
				if (phase.start.goto.target !== GotoTargetEnd) {
					walkPhaseNode(phase, phase.start.goto.target, cb, phaseTargets);
				}
			}
		}
	}

	return phaseTargets;
};

export const walkPhaseNode = (phase, nodeId, cb, phaseTargets) => {
	if (!phaseTargets) {
		phaseTargets = {
			local: {}
		};
	}

	if (!phase.nodes) {
		throw new Error("failed to walk phase to nodeId: " + nodeId + " (phase has no nodes)");
	}

	const node = phase.nodes[nodeId];
	if (!node) {
		throw new Error("failed to walk phase to nodeId: " + nodeId + " (phase is missing node)");
	}

	if (!phaseTargets.local[nodeId]) {
		phaseTargets.local[nodeId] = true;

		if (cb) {
			cb(nodeId, NodeTypeStart);
		}

		walkNode(phase, node, nodeId, cb, phaseTargets);
	}

	return phaseTargets;
};

export const walkNode = (phase, node, nodeId, cb, phaseTargets) => {
	if (!phaseTargets) {
		phaseTargets = {
			local: {}
		};
	}

	if (node.branching) {
		BranchingOutcomes.forEach(outcome => {
			if (node.branching[outcome]) {
				BranchingKeys.forEach(branchingKey => {
					if (node.branching[outcome][branchingKey]) {
						walkNodeBranches(nodeId, phase, node.branching[outcome][branchingKey], cb, phaseTargets);
					}
				});
			}
		});
	}

	return phaseTargets;
};

export const walkNodeBranches = (parentNodeId, phase, branching, cb, phaseTargets) => {
	if (!phaseTargets) {
		phaseTargets = {
			local: {}
		};
	}

	if (branching) {
		branching.forEach(b => {
			if (b.goto && b.goto.target) {
				if (b.goto.target !== GotoTargetEnd && b.goto.target !== GotoTargetNext) {
					const nodeId = b.goto.target;

					if (!phaseTargets.local[nodeId]) {
						phaseTargets.local[nodeId] = true;

						if (cb) {
							cb(nodeId, parentNodeId);
						}

						if (phase) {
							const node = phase.nodes[nodeId];
							if (!node) {
								throw new Error("failed to walk phase to nodeId: " + nodeId + " (phase is missing node)");
							}

							walkNode(phase, node, nodeId, cb, phaseTargets);
						}
					}
				}
			}

			walkNodeBranches(parentNodeId, phase, b.branching, cb, phaseTargets);
		});
	}
};
