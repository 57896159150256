import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import WindowContainer from "components/common/window/WindowContainer";
import WindowContent from "components/common/window/WindowContent";
import { OpenNewPlaygroundModalAction } from "../../actions/playground/new";
import { PlaygroundListAction } from "../../actions/playground/list";

import PlaygroundListContent from "../../components/playground/list/PlaygroundListContent";
import { OpenNewPlaygroundGroupModalAction } from "../../actions/playground/group/new";
import { OpenEditPlaygroundGroupModalAction } from "../../actions/playground/group/update";
import { PlaygroundGroupDeleteAction } from "../../actions/playground/group/delete";
import { SetPreferenceAction } from "core/actions/preferences";

class PlaygroundList extends Component {
	componentDidMount () {
		this.props.setFullscreen(false);
		this.props.getPlaygroundList();
	}

	render () {
		const {
			playgroundList, isFullscreen, reloadPlaygrounds,
			openNewPlaygroundModal, openNewPlaygroundGroupModal, openEditPlaygroundGroupModal, deletePlaygroundGroup,
			profile, preferences, setPreference
		} = this.props;
		const error = playgroundList ? playgroundList.error : null;
		const loading = (!playgroundList || playgroundList.loading);

		return (
			<WindowContainer isFullscreen={isFullscreen}>
				<WindowContent error={error} loading={loading}>
					<PlaygroundListContent
						reloadPlaygrounds={reloadPlaygrounds}
						openNewPlaygroundGroupModal={openNewPlaygroundGroupModal}
						openEditPlaygroundGroupModal={openEditPlaygroundGroupModal}
						deletePlaygroundGroup={deletePlaygroundGroup}
						playgroundList={playgroundList || {}}
						openNewPlaygroundModal={openNewPlaygroundModal}
						profile={profile}
						preferences={preferences}
						setPreference={setPreference}
					/>
				</WindowContent>
			</WindowContainer>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		reloadPlaygrounds: () => dispatch(PlaygroundListAction()),
		openNewPlaygroundGroupModal: (payload) => dispatch(OpenNewPlaygroundGroupModalAction(payload)),
		openEditPlaygroundGroupModal: (payload) => dispatch(OpenEditPlaygroundGroupModalAction(payload)),
		deletePlaygroundGroup: (payload) => dispatch(PlaygroundGroupDeleteAction(payload)),
		getPlaygroundList: () => dispatch(PlaygroundListAction()),
		openNewPlaygroundModal: (payload) => dispatch(OpenNewPlaygroundModalAction(payload)),
		setPreference: (payload) => dispatch(SetPreferenceAction(payload))
	};
};

const mapStateToProps = (state, props) => {
	const core = state.get("core");

	const mapped = {
		core: core
	};

	if (mapped.core) {
		mapped.profile = mapped.core.get("profile");
	}

	const canvas = state.get("canvas");
	if (!canvas) {
		return mapped;
	}
	const playground = canvas.get("playground");
	if (!playground) {
		return mapped;
	}

	return {
		playgroundList: playground.get("list"),
		preferences: core.get("preferences"),
		...mapped
	};
};

PlaygroundList.propTypes = {
	setFullscreen: PropTypes.func.isRequired,
	isFullscreen: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaygroundList);
