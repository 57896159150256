import { apiFetch } from "core/api/base";

export function * postPlaygroundGroupUpdate ({ namespace, groupId, model }) {
	return yield apiFetch({
		endpoint: `/v1/playground/group/${namespace}/${groupId}`,
		method: "PUT",
		authenticated: true,
		body: JSON.stringify(model)
	});
}
