import { call, put, takeLatest } from "redux-saga/effects";
import {
	ProcessResetAction,
	ProcessResetFailedAction,
	ProcessResetRequestAction,
	ProcessResetSuccessAction,
	ResetPasswordRequestAction,
	SendResetPasswordAction,
	SendResetPasswordFailedAction,
	SendResetPasswordSuccessAction,
	VerifyResetTokenAction,
	VerifyResetTokenFailedAction,
	VerifyResetTokenRequestAction,
	VerifyResetTokenSuccessAction
} from "../../actions/auth";
import { getVerifyReset, postProcessReset, postSendReset } from "../../api/auth/reset";

/// / Send Reset Email
// Worker
function * sendResetWorker (action) {
	try {
		yield put(ResetPasswordRequestAction());
		const r = yield call(postSendReset, action.payload);
		if (r.json) {
			yield put(SendResetPasswordSuccessAction(r.json));
		} else {
			yield put(SendResetPasswordFailedAction("Failed to send reset email"));
		}
	} catch (e) {
		yield put(SendResetPasswordFailedAction(e));
	}
}

// Saga
export function * sendResetSaga () {
	yield takeLatest(SendResetPasswordAction.getType(), sendResetWorker);
}

/// / Verify Reset Token
// Worker
function * verifyResetTokenWorker (action) {
	try {
		yield put(VerifyResetTokenRequestAction());
		const r = yield call(getVerifyReset, action.payload);
		if (r.json) {
			yield put(VerifyResetTokenSuccessAction(r.json));
		} else {
			yield put(VerifyResetTokenFailedAction("Failed to verify reset token"));
		}
	} catch (e) {
		yield put(VerifyResetTokenFailedAction(e));
	}
}

// Saga
export function * verifyResetTokenSaga () {
	yield takeLatest(VerifyResetTokenAction.getType(), verifyResetTokenWorker);
}

/// / Process Reset
// Worker
function * processResetWorker (action) {
	try {
		yield put(ProcessResetRequestAction());
		const r = yield call(postProcessReset, action.payload);
		if (r.json) {
			yield put(ProcessResetSuccessAction(r.json));
		} else {
			yield put(ProcessResetFailedAction("Failed to process reset"));
		}
	} catch (e) {
		yield put(ProcessResetFailedAction(e));
	}
}

// Saga
export function * processResetSaga () {
	yield takeLatest(ProcessResetAction.getType(), processResetWorker);
}

// Export

export const resetSagas = [
	sendResetSaga(),
	verifyResetTokenSaga(),
	processResetSaga()
];

export default resetSagas;
