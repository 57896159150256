import "./DragAndDropInputAnalyzer.scss";
import {useDropzone} from "react-dropzone";
import {useCallback} from "react";
import {Divider} from "@mui/material";
import {analyzers} from "./analyzers";
import {clearVariableNames} from "./cleaner";


type DragAndDropVariableAnalyzerProps = {
    addVariable: (variable: string) => void;
};

export const DragAndDropInputAnalyzer = ({addVariable}: DragAndDropVariableAnalyzerProps) => {

    const onDrop = useCallback(async (acceptedFiles: File[]) => {

        let variables: string[] = [];
        for (let file of acceptedFiles) {
            for (let analyzer of analyzers) {
                if (analyzer.match(file)) {
                    try {
                        const analysis = await analyzer.analyze(file);
                        variables.push(...analysis);
                    } catch (e) {
                        console.error(e);
                    }

                }
            }
        }

        for (let variable of clearVariableNames(Array.from(new Set(variables)))) {
            addVariable(variable);
        }

    }, [addVariable]);

    const textCSVMimeType = "text/csv";
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: [textCSVMimeType]});

    return (
        <div className="drag-and-drop-variable-analyzer">
            <div {...getRootProps()} className="drag-area">
                <input {...getInputProps()} className="drag-input"/>

                <div className="drop-info">
                    <i className="fas fa-search"/>
                    <Divider/>
                    {isDragActive && <p>Drop the files here ...</p>}
                    {!isDragActive && <p>
						Drag and drop or click to select a data-file.
						We'll examine it for inputs, that can be included for your playground
					</p>}
                </div>

            </div>
        </div>
    );
};
