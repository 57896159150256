import React from "react";
import classNames from "classnames";

import { PortModelAlignment, PortWidget } from "@projectstorm/react-diagrams";

import { BranchingOutcomeFailure, BranchingOutcomeSuccess } from "../../../../lib/playground/constants";

import { DefaultPortFacet } from "./facet/DefaultPortFacet";

import "./DefaultPortWidget.scss";

export const DefaultPortDirectionInput = "input";
export const DefaultPortDirectionOutput = "output";

export const DefaultPortWidget = ({
	enabled, node, teleports, targets, direction, position, type, engine, size, widthPad, handleSetTarget,
	withSetTarget, withTeleportInlet
}) => {
	if (enabled === false) {
		return "";
	}

	let portSize = 28;
	if (size < 96) {
		portSize = 25;
	}

	const portPadding = 10;
	const portPaddingMultiplier = 3;
	const portOffset = 0;

	let [portWidth, portHeight, top, left] = [];
	switch (position) {
		case PortModelAlignment.LEFT:
			portWidth = portSize + (portPadding * portPaddingMultiplier);
			portHeight = portSize * 2 + (portPadding);
			top = size / 2 - (portHeight / 2);
			left = (-(portWidth / 2) + widthPad) - portOffset;

			break;
		case PortModelAlignment.TOP:
			portWidth = portSize * 2 + (portPadding);
			portHeight = portSize + (portPadding * portPaddingMultiplier);
			top = (-(portHeight / 2)) - portOffset - 10;
			left = (size / 2 - (portWidth / 2)) + widthPad;
			break;
		case PortModelAlignment.RIGHT:
			portWidth = portSize + (portPadding * portPaddingMultiplier);
			portHeight = portSize * 2 + (portPadding);
			top = size / 2 - (portHeight / 2);
			left = ((size - (portWidth / 2)) + widthPad) + portOffset;
			break;
		case PortModelAlignment.BOTTOM:
			portWidth = portSize * 2 + (portPadding);
			portHeight = portSize + (portPadding * portPaddingMultiplier);
			top = (size - (portHeight / 2)) + portOffset;
			left = (size / 2 - (portWidth / 2)) + widthPad;
			break;
		default:
	}

	return (
		<PortWidget
			style={{
				top: top,
				left: left,
				position: "absolute"
			}}
			port={node.getPort(position)}
			engine={engine}
		>
			<div
				className={classNames(
					"port",
					"port-default",
					`direction-${direction}`,
					type
				)}
				style={{
					width: portWidth + "px",
					height: portHeight + "px"
				}}
			>
				<DefaultPortFacet
					node={node}
					type={type}
					teleports={teleports}
					targets={targets}
					portWidth={portWidth}
					portHeight={portHeight}
					portSize={portSize}
					portPadding={portPadding}
					position={position}
					withSetTarget={withSetTarget}
					handleSetTarget={handleSetTarget}
					withTeleportInlet={withTeleportInlet}
				/>
			</div>
		</PortWidget>
	);
};

export const DefaultPortWidgets = ({
	engine, node, teleports, left, top, right, bottom, size, widthPad,
	targets, disableSetTarget, setTargetActionFn
}) => {
	if (!targets) {
		targets = {};
	}

	return (
		<>
			<DefaultPortWidget
				enabled={left}
				node={node}
				direction={DefaultPortDirectionInput}
				position={PortModelAlignment.LEFT}
				type={BranchingOutcomeSuccess}
				teleports={teleports}
				engine={engine}
				size={size}
				widthPad={widthPad}
				withTeleportInlet
			/>

			<DefaultPortWidget
				enabled={top}
				node={node}
				direction={DefaultPortDirectionInput}
				position={PortModelAlignment.TOP}
				type={BranchingOutcomeFailure}
				teleports={teleports}
				engine={engine}
				size={size}
				widthPad={widthPad}
			/>

			<DefaultPortWidget
				enabled={right}
				node={node}
				direction={DefaultPortDirectionOutput}
				position={PortModelAlignment.RIGHT}
				type={BranchingOutcomeSuccess}
				teleports={teleports}
				targets={targets[BranchingOutcomeSuccess]}
				engine={engine}
				size={size}
				widthPad={widthPad}
				handleSetTarget={() => setTargetActionFn(BranchingOutcomeSuccess)}
				withSetTarget={!disableSetTarget}
			/>

			<DefaultPortWidget
				enabled={bottom}
				node={node}
				direction={DefaultPortDirectionOutput}
				position={PortModelAlignment.BOTTOM}
				type={BranchingOutcomeFailure}
				teleports={teleports}
				targets={targets[BranchingOutcomeFailure]}
				engine={engine}
				size={size}
				widthPad={widthPad}
				handleSetTarget={() => setTargetActionFn(BranchingOutcomeFailure)}
				withSetTarget={!disableSetTarget}
			/>
		</>
	);
};
