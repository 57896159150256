import {store} from "../../store";
import {ErrorOpenModalAction} from "../actions/error/error";
import {ReactElement} from "react";


export function raiseGlobalError(title: string | ReactElement | null = null, code: string | null = null): never {
    const errorMessage = <>
        {title}
        {code && <pre>{code}</pre>}
    </>;

    const error = {
        content: errorMessage,
    };
    store.dispatch(ErrorOpenModalAction(error));
    throw new Error(code ?? "unknown error");
}
