import * as React from "react";
import {Box} from "@mui/material";

export type TabsPanelProps = {
    children: React.ReactNode;
    index: number;
    value: number;
}

export default function TabsPanel(props: TabsPanelProps) {
    const {children, value, index} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}