import React from "react";
import classNames from "classnames";
import Color from "color";

import { PortModelAlignment } from "@projectstorm/react-diagrams";

import "./PortSimpleFacet.scss";

export const PortSimpleFacet = ({
	setTargetNodeProps, position, portSize, portWidth, portHeight, isActive, setIsActive
}) => {
	const cr = portSize / 3.5;
	let cx = 0;
	let cy = 0;

	switch (position) {
		case PortModelAlignment.LEFT:
			cx = portWidth / 2;
			cy = portHeight / 2;
			break;
		case PortModelAlignment.TOP:
			cx = portWidth / 2;
			cy = portHeight / 2;
			break;
		case PortModelAlignment.RIGHT:
			cx = portWidth / 2;
			cy = portHeight / 2;
			break;
		case PortModelAlignment.BOTTOM:
			cx = portWidth / 2;
			cy = portHeight / 2;
			break;
		default:
	}

	return (
		<circle
			className={classNames(
				"port-simple-facet",
				{ "is-active": isActive }
			)}
			r={cr} cx={cx} cy={cy}
			fill={Color(setTargetNodeProps.fillHover).alpha("0.25").string()}
			onMouseOver={() => setIsActive(true)}
			onMouseLeave={() => setIsActive(false)}
		/>
	);
};
