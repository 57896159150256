import React, { Component } from "react";
import PropTypes from "prop-types";

import { NodeInfoCard } from "../../node/common/NodeInfoCard";
import Tabs, { TabLayoutDrawer } from "core/components/common/tabs/Tabs";
import { getExistingCanvasTabs } from "./ExistingCanvasTabs";

import "./ExistingTargetContent.scss";

class ExistingTargetContent extends Component {
	state = {
		activeCanvasId: null
	};

	handleSetActiveCanvasId = (activeCanvasId) => {
		this.setState(() => ({
			activeCanvasId: activeCanvasId
		}));

		return false;
	};

	render () {
		const { nodeProps, closeModal, onSelect, borderColor } = this.props;

		let { activeCanvasId } = this.state;
		const { nodeData, playgroundProps } = nodeProps;

		if (!activeCanvasId) {
			activeCanvasId = playgroundProps.canvas.canvas_id;
		}

		// Init external ref to avoid top level re-render.
		const nodeInfoCbRef = {};

		// Handlers
		const handleSelect = (payload) => {
			closeModal();
			onSelect(payload);
		};

		const handleHover = (node) => {
			if (nodeInfoCbRef && nodeInfoCbRef.setNode) {
				nodeInfoCbRef.setNode(node);
			}
		};

		return (
			<div className="up-form with-tabs">
				<section className="modal-card-body pg-existing-target-content">
					<Tabs
						layout={TabLayoutDrawer}
						onTabSelect={this.handleSetActiveCanvasId}
						selectedTab={activeCanvasId}
						borderColor={borderColor}
						minWidth={175}
					>
						{getExistingCanvasTabs({
							...nodeData,
							id: nodeProps.id
						}, playgroundProps, handleSelect, handleHover, borderColor)}
					</Tabs>
				</section>

				<footer className="modal-card-foot space-between">
					<button type="button" className="button" onClick={closeModal}>Cancel</button>
					<NodeInfoCard cbRef={nodeInfoCbRef}/>
				</footer>
			</div>
		);
	}
}

ExistingTargetContent.propTypes = {
	nodeProps: PropTypes.object.isRequired,
	closeModal: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	borderColor: PropTypes.string.isRequired
};

export default ExistingTargetContent;
