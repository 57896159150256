import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import cloneDeep from "lodash/cloneDeep";

import InputField from "../InputField";
import Loading from "../../../../../components/common/loading/Loading";
import InlineError from "../../message/InlineError";
import DynamicFormTabs, { getTabId } from "./DynamicFormTabs";

import { sortPositionalArray } from "../../../../lib/array/sort";
import {
	FieldTypeBool,
	FieldTypeEmail,
	FieldTypeEmailTemplatePicker,
	FieldTypeNumber,
	FieldTypePassword,
	FieldTypeSelect,
	FieldTypeSmsTemplatePicker,
	FieldTypeText,
	FieldTypeTextArea,
	FieldTypeVoiceTemplatePicker,
	ValidationFieldRequired,
	ValidationOneFieldRequired
} from "../constants";
import { SelectInputDivider } from "../SelectInput";
import { Accordion, AccordionGroup } from "../../accordion";
import { extractNestedProp, haveErrorInFields } from "../../../../util/form/helper";
import { Tab, TabSection } from "../../tabs/Tabs";

import "./DynamicForm.scss";
import { getTemplatePickSchema, TemplatePickerField } from "../TemplatePickerField";

const DynamicFormMessages = ({ error }) => {
	if (error) {
		return (
			<InlineError>
				<p>There was an error processing your request, please try again.</p>
			</InlineError>
		);
	}

	return "";
};

const extractYupFormSchema = (formSchema) => {
	const yupFormSchema = {};
	const nested = {};

	if (formSchema.sections) {
		formSchema.sections.forEach(s => {
			s.panels && s.panels.forEach(s => {
				if (s.fields) {
					s.fields.forEach(f => {
						if (f.validations) {
							let val = null;
							switch (f.type) {
								case FieldTypeText:
								case FieldTypeTextArea:
								case FieldTypeEmail:
								case FieldTypePassword:
								case FieldTypeSelect:
									val = Yup.string();
									break;
								case FieldTypeNumber:
									val = Yup.number();
									break;
								case FieldTypeBool:
									val = Yup.bool();
									break;

								case FieldTypeVoiceTemplatePicker:
								case FieldTypeSmsTemplatePicker:
								case FieldTypeEmailTemplatePicker:
									val = getTemplatePickSchema();
									break;

								default:
									val = Yup.string();
							}

							f.validations.forEach(v => {
								if (v === ValidationFieldRequired) {
									val = val.required(f.label + " is required");
								}
							});

							if (f.type === FieldTypeEmail) {
								val = val.email(f.label + " is not valid");
							}

							if (f.prefixName) {
								if (typeof nested[f.prefixName] === "undefined") {
									nested[f.prefixName] = {};
								}
								nested[f.prefixName][f.name] = val;
							} else {
								yupFormSchema[f.name] = val;
							}
						}
					});
				}
			});
		});
	}

	if (formSchema.validations) {
		let valId = 0;
		formSchema.validations.forEach(v => {
			valId++;

			if (v.fields && v.fields.length > 0) {
				const firstField = v.fields[0];
				let val = yupFormSchema[firstField];
				if (!val) {
					val = Yup.string();
				}

				switch (v.type) {
					case ValidationOneFieldRequired:
						val = val.test(v.type + valId, v.message, function () {
							let valid = false;
							v.fields.forEach(f => {
								const value = this.parent[f];
								if (value === 0 || value) {
									valid = true;
								}
							});

							return valid;
						});

						break;
					default:
				}

				yupFormSchema[firstField] = val;
			}
		});
	}

	Object.keys(nested).forEach(k => {
		yupFormSchema[k] = Yup.object().shape(nested[k]);
	});

	return yupFormSchema;
};

const extractInitialValues = (formValues, formSchema, formOptions) => {
	const values = {};
	if (!formValues) {
		formValues = {};
	}

	const set = (prefix, name, val) => {
		if (!prefix) {
			values[name] = val;
			return;
		}

		if (typeof values[prefix] === "undefined") {
			values[prefix] = {};
		}

		values[prefix][name] = val;
	};

	if (formSchema.sections) {
		formSchema.sections.forEach(s => {
			s.panels && s.panels.forEach(p => {
				p.fields && p.fields.forEach(f => {
					const val = extractNestedProp(formValues, f.name, f.prefixName);
					if (val !== undefined) {
						if (f.type === FieldTypeSelect) {
							if (isValidOption(f, formOptions, val)) {
								set(f.prefixName, f.name, val);
							} else {
								set(f.prefixName, f.name, "");
							}
						} else {
							set(f.prefixName, f.name, val);
						}
					} else {
						set(f.prefixName, f.name, "");
					}
				});
			});
		});
	}

	return values;
};

const getBaseOptions = (field, formOptions) => {
	let options = [];
	const opt = extractNestedProp(formOptions, field.name, field.prefixName);
	if (opt) {
		options = cloneDeep(opt);
	} else if (field.options) {
		options = cloneDeep(field.options);
	}

	return options;
};

const prepareSelectOptions = (field, formOptions) => {
	const options = getBaseOptions(field, formOptions);

	if (options && options.length > 0) {
		if (field.placeholder) {
			options.unshift({ value: SelectInputDivider });
			options.unshift({ label: field.placeholder });
		}
	} else {
		options.unshift({
			label: ((field.emptyPlaceholder)
				? field.emptyPlaceholder
				: "No options available")
		});
	}

	return options;
};

const isValidOption = (field, formOptions, val) => {
	const options = getBaseOptions(field, formOptions);
	let isValid = false;

	options.forEach(op => {
		if (op.value === val) {
			isValid = true;
		}
	});

	return isValid;
};

const isDisabled = (field, values, formOptions) => {
	let disabled = false;

	if (field.type === FieldTypeSelect) {
		const options = getBaseOptions(field, formOptions);
		if (!options || options.length < 1) {
			return true;
		}
	}

	// TODO: Nested something something ??
	if (field.disabledBy) {
		field.disabledBy.forEach(ofName => {
			const val = values[ofName];
			if (val !== "" && (val === 0 || val)) {
				disabled = true;
			}
		});
	}

	return disabled;
};

const filterSavedValues = (form, formSchema, formOptions) => {
	const savedForm = cloneDeep(form);

	const set = ({ name, prefixName }, val) => {
		if (!prefixName) {
			savedForm[name] = val;
			return;
		}

		if (typeof savedForm[prefixName] === "undefined") {
			savedForm[prefixName] = {};
		}
		savedForm[prefixName][name] = val;
	};

	formSchema.sections.forEach(s => {
		s.panels.forEach(p => {
			p.fields.forEach(f => {
				if (isDisabled(f, savedForm, formOptions)) {
					set(f, null);
				}
				switch (f.type) {
					case FieldTypeBool:
						if (!extractNestedProp(savedForm, f.name, f.prefixName)) {
							set(f, false);
						}
						break;
					default:
				}
			});
		});
	});

	return savedForm;
};

const SectionFields = ({ section, formOptions, values, ...props }) => {
	const sectionFields = [];

	sortPositionalArray(section.fields).forEach(f => {
		switch (f.type) {
			case FieldTypeEmail:
				sectionFields.push(<InputField
					key={f.name} name={f.name} label={f.label} values={values}
					prefixName={f.prefixName}
					disabled={isDisabled(f, values, formOptions)}
					type={FieldTypeText} placeholder={f.placeholder}
					autoComplete="email" {...props}
				/>);
				break;
			case FieldTypeSelect:
				sectionFields.push(<InputField
					key={f.name} name={f.name} label={f.label} values={values}
					prefixName={f.prefixName}
					disabled={isDisabled(f, values, formOptions)}
					options={prepareSelectOptions(f, formOptions)}
					type={FieldTypeSelect} placeholder={f.placeholder}
					autoComplete={f.name} {...props}
				/>);
				break;

			case FieldTypeEmailTemplatePicker:
			case FieldTypeVoiceTemplatePicker:
			case FieldTypeSmsTemplatePicker:
				const kind = f.type.split("-")[0];
				sectionFields.push(<TemplatePickerField key={f.name} kind={kind} field={f}
														values={values} {...props}/>);
				break;

			default:

				sectionFields.push(<InputField
					key={f.name} name={f.name} label={f.label} values={values}
					prefixName={f.prefixName}
					disabled={isDisabled(f, values, formOptions)}
					type={f.type} placeholder={f.placeholder} autoComplete={f.name} {...props}
				/>);
		}
	});

	return sectionFields;
};

const FormSections = ({ formSchema, formOptions, staticTabSections, activeTab, ...props }) => {
	let sections = [];
	if (formSchema && formSchema.sections) {
		sections = cloneDeep(formSchema.sections);
	}
	if (staticTabSections) {
		staticTabSections.forEach(s => {
			sections.push({
				...s.props,
				component: s
			});
		});
	}

	const componentProps = {
		setFieldValue: props.setFieldValue,
		values: props.values,
		errors: props.errors,
		touched: props.touched
	};

	const formSections = [];
	sortPositionalArray(sections).forEach(s => {
		const ourId = getTabId(s);
		if (!activeTab) {
			activeTab = ourId;
		}

		if (s.component) {
			formSections.push(React.cloneElement(s.component, componentProps));
		} else {
			formSections.push(
				<div
					key={ourId}
					className="tab-section"
					style={{ display: (activeTab === ourId) ? "block" : "none" }}
				>
					<FormPanels
						section={s}
						formOptions={formOptions}
						{...props}
					/>
				</div>
			);
		}
	});

	return (
		<div className="dynamic-form-sections">
			{formSections}
		</div>
	);
};

export const FooterSection = ({ children }) => {
	return children;
};
FooterSection.displayName = "FooterSection";

const panelHasErrors = (section, errors, touched) => {
	let hasErrors = false;

	if (errors && touched) {
		const fields = [];
		section.fields && section.fields.forEach(f => {
			fields.push([f.prefixName, f.name]);
		});

		hasErrors = haveErrorInFields(fields, errors, touched);
	}

	return hasErrors;
};

const FormPanels = ({ section, formOptions, errors, touched, ...props }) => {
	if (section.panels.length === 1) {
		return (
			<SectionFields
				section={section.panels[0]} formOptions={formOptions}
				touched={touched} errors={errors} {...props}
			/>
		);
	}

	return (
		<Accordion>
			{sortPositionalArray(section.panels).map(p => (
				<AccordionGroup
					title={p.label} icon={panelHasErrors(p, errors, touched) ? "exclamation-triangle" : undefined}
					key={getTabId(p)}
				>
					<SectionFields
						section={p} formOptions={formOptions}
						touched={touched} errors={errors} {...props}
					/>
				</AccordionGroup>
			))}
		</Accordion>
	);
};

const determineIfUseIcons = (formSchema) => {
	let useIcons = false;
	if (formSchema && formSchema.sections) {
		formSchema.sections.forEach(s => {
			if (s.icon) {
				useIcons = true;
			}
		});
	}

	return useIcons;
};

const extractStaticElements = (children, layout, activeTab, setActiveTab, borderColor, useIcons) => {
	const staticFooterSections = [];
	const staticTabs = [];
	const staticTabSections = [];

	if (children) {
		React.Children.toArray(children).forEach(c => {
			let { id, title, fields, isNested, icon, children, ...rest } = c.props;

			switch (c.type.displayName) {
				case "Tab":
					if (!useIcons) {
						icon = undefined;
					}

					staticTabs.push(
						<Tab
							key={"tab-" + id}
							id={id}
							layout={layout}
							title={title}
							borderColor={borderColor}
							icon={icon}
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							fields={fields}
							{...rest}
						/>
					);
					staticTabSections.push(
						<TabSection
							key={"tab-section-" + id}
							id={id}
							layout={layout}
							borderColor={borderColor}
							activeTab={activeTab}
							isNested={isNested}
							{...rest}
						>
							{children}
						</TabSection>
					);
					break;
				case "FooterSection":
					staticFooterSections.push(
						React.cloneElement(c, {})
					);

					break;
				default:
			}
		});
	}

	return [staticTabs, staticTabSections, staticFooterSections];
};

const DynamicForm = ({
	formState, formValues, formSchema, onSubmit, prepareInitialValues, prepareValidationSchema,
	buttonBgColor, buttonFontColor, buttonLabel, modalClassName, children
}) => {
	if (!formState) {
		formState = {};
	}
	const loading = formState.loading;
	const error = formState.error;
	const formData = (formState.formData) ? formState.formData : {};
	const formOptions = (formData.options) ? formData.options : {};
	buttonLabel = (buttonLabel) || "Continue";

	let initialValues = extractInitialValues(formValues, formSchema, formOptions);

	if (prepareInitialValues) {
		initialValues = prepareInitialValues(initialValues, formValues, formSchema, formOptions);
	}

	let validationSchema = extractYupFormSchema(formSchema);

	if (prepareValidationSchema) {
		validationSchema = prepareValidationSchema(validationSchema);
	}

	const [activeTab, setActiveTab] = useState(null);

	let layout = "default";
	if (formSchema.layout) {
		layout = formSchema.layout;
	}

	const useIcons = determineIfUseIcons(formSchema);

	const [staticTabs, staticTabSections, staticFooterSections] = extractStaticElements(children, layout,
		activeTab, setActiveTab, buttonBgColor, useIcons);

	return (
		<Formik
			onSubmit={(form) => onSubmit(filterSavedValues(form, formSchema, formOptions))}
			initialValues={initialValues}
			validationSchema={Yup.object().shape(validationSchema)}
		>
			{({
				setFieldValue, values, errors, touched,
				...props
			}) => (
				<Form className="up-form with-tabs dynamic-form">
					<section className={"modal-card-body " + ((modalClassName) || "")}>
						{loading
							? <Loading/>
							: (
								<div className={"tabs-container layout-" + layout}>
									<DynamicFormTabs
										formSchema={formSchema}
										staticTabs={staticTabs}
										activeTab={activeTab}
										setActiveTab={setActiveTab}
										setFieldValue={setFieldValue}
										values={values}
										errors={errors}
										touched={touched}
										borderColor={buttonBgColor}
									/>

									<div className="tab-content">
										<div className="dynamic-form-errors">
											<DynamicFormMessages error={error}/>
										</div>

										<FormSections
											formSchema={formSchema}
											formOptions={formOptions}
											staticTabSections={staticTabSections}
											activeTab={activeTab}
											setFieldValue={setFieldValue}
											values={values}
											errors={errors}
											touched={touched} {...props}
										/>
									</div>
								</div>
							)}
					</section>
					<footer className="modal-card-foot space-between">
						<button
							type="submit" className="button is-primary"
							disabled={loading} style={{
							backgroundColor: buttonBgColor,
							color: buttonFontColor
						}}
						>{buttonLabel}
						</button>

						{staticFooterSections}
					</footer>
				</Form>
			)}
		</Formik>
	);
};

DynamicForm.propTypes = {
	formState: PropTypes.object,
	formValues: PropTypes.object,
	formSchema: PropTypes.object,
	onSubmit: PropTypes.func,
	prepareInitialValues: PropTypes.func,
	prepareValidationSchema: PropTypes.func,
	buttonBgColor: PropTypes.string,
	buttonFontColor: PropTypes.string,
	buttonLabel: PropTypes.string,
	minHeight: PropTypes.number
};

export default DynamicForm;
