import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";

import ModalLoading from "../../components/common/modal/ModalLoading";

class LoadingModal extends Component {
	shouldComponentUpdate (nextProps, nextState, nextContext) {
		if (!this.props.loading) {
			return true;
		}
		if (!nextProps.loading) {
			return true;
		}

		return (this.props.loading.open !== nextProps.loading.open);
	}

	render () {
		const { loading } = this.props;
		const open = !!(loading && loading.open);
		if (!open) {
			return null;
		}

		return (
			<ModalLoading modalState={open}/>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {};
};

const mapStateToProps = (state, props) => {
	const core = state.get("core");
	if (!core) {
		return {};
	}

	return {
		loading: core.get("loading")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingModal);
