// eslint-disable-next-line no-redeclare
/* global localStorage */

import { call, put, takeLatest } from "redux-saga/effects";
import { getProfile } from "../../api/auth/profile";
import {
	CheckFetchProfileAction,
	FetchProfileAction,
	FetchProfileFailedAction,
	FetchProfileRequestAction,
	FetchProfileSuccessAction,
	LogoutAction
} from "../../actions/auth";

/// / Fetch Profile
// Worker
function * fetchProfileWorker (action) {
	try {
		yield put(FetchProfileRequestAction());
		const r = yield call(getProfile);
		if (r.json) {
			yield put(FetchProfileSuccessAction(r.json));
		} else {
			yield put(FetchProfileFailedAction("Failed to fetch profile"));
		}
	} catch (e) {
		yield put(FetchProfileFailedAction(e));
	}
}

// Saga
export function * fetchProfileSaga () {
	yield takeLatest(FetchProfileAction.getType(), fetchProfileWorker);
}

/// / Check Fetch Profile
// Worker
function * checkFetchProfileWorker (action) {
	try {
		const token = localStorage.getItem("token");
		if (token) {
			yield put(FetchProfileAction());
		} else {
			yield put(LogoutAction());
		}
	} catch (e) {
		yield put(FetchProfileFailedAction(e));
	}
}

// Saga
export function * checkFetchProfileSaga () {
	yield takeLatest(CheckFetchProfileAction.getType(), checkFetchProfileWorker);
}

// Export

export const profileSagas = [
	fetchProfileSaga(),
	checkFetchProfileSaga()
];

export default profileSagas;
