import { apiFetch } from "core/api/base";

export function * putPlaygroundUpdate ({ namespace, playgroundId, model }) {
	return yield apiFetch({
		endpoint: `/v1/playground/${namespace}/${playgroundId}`,
		method: "PUT",
		authenticated: true,
		body: JSON.stringify(model)
	});
}
