import React, { Component } from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import { Droppable } from "react-beautiful-dnd";

import config, { CfgColors } from "../../../../../../config";

import { ConditionRules } from "./ConditionRules";
import { conditionOptions } from "../../../../../../lib/logic/condition";
import LogicGroup from "../../../group/LogicGroup";
import LogicGroupAction from "../../../group/LogicGroupAction";
import { defaultConditionRule } from "../../../../../../lib/logic/condition/rule";

export class ConditionGroup extends Component {
	static propTypes = {
		group: PropTypes.object.isRequired,
		index: PropTypes.number.isRequired,
		count: PropTypes.number.isRequired,
		namePath: PropTypes.string.isRequired,
		labelWidth: PropTypes.number.isRequired,
		doDelete: PropTypes.func.isRequired,
		disableDelete: PropTypes.bool,
		setFieldValue: PropTypes.func.isRequired,
		values: PropTypes.object,
		touched: PropTypes.object,
		errors: PropTypes.object,
		moveUp: PropTypes.func,
		moveDown: PropTypes.func
	};

	render () {
		const {
			group, index, count, namePath, labelWidth, doDelete, disableDelete,
			values, touched, errors, setFieldValue, moveUp, moveDown
		} = this.props;

		const colourCfg = config.get(CfgColors);

		const rules = (group.rules)
			? group.rules
			: [];
		const isEmpty = (!rules || rules.length < 1);

		return (
			<FieldArray name={namePath + ".rules"}>
				{arrayHelpers => (
					<Droppable
						droppableId={namePath + ".rules"}
						type="rule"
					>
						{(provided, snapshot) => (
							<LogicGroup
								value={group.condition}
								options={conditionOptions}
								index={index}
								count={count}
								isEmpty={isEmpty}
								setFieldValue={setFieldValue}
								fieldName={namePath + ".condition"}
								color={colourCfg.blue}
								width={labelWidth}
								className="is-nested"
								moveUp={moveUp}
								moveDown={moveDown}
								doDelete={doDelete}
								disableDelete={disableDelete}
								isDraggingOver={snapshot.isDraggingOver}
							>

								<ConditionRules
									rules={rules}
									provided={provided}
									namePath={namePath}
									group={group}
									setFieldValue={setFieldValue}
									arrayHelpers={arrayHelpers}
									values={values}
									touched={touched}
									errors={errors}
								/>

								<LogicGroupAction
									onClick={() => arrayHelpers.push(defaultConditionRule())}
									color={colourCfg.blue}
									label="Add Rule"
									showDelete={rules.length < 1}
								/>
							</LogicGroup>
						)}
					</Droppable>
				)}
			</FieldArray>
		);
	}
}

export default ConditionGroup;
