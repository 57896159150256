import { v4 as uuid } from "uuid";

import {
	BranchingKeys,
	BranchingOutcomes,
	BranchingTypeAll,
	BranchingTypeDefault,
	BranchingTypeEnum
} from "../../../constants";

export const defaultNodeBranching = () => {
	return {
		_id: uuid(),
		branching_type: BranchingTypeAll,
		branching: []
	};
};

export const defaultNodeBranchingTarget = () => {
	return {
		_id: uuid(),
		operator: "",
		value: "",
		goto: {}
	};
};

export const ensureNodeInitialised = (node) => {
	if (!node.branching) {
		node.branching = {};
	}

	BranchingOutcomes.forEach(outcome => {
		if (!node.branching[outcome]) {
			node.branching[outcome] = {};
		}
		if (!node.branching[outcome].branching) {
			node.branching[outcome].branching = [];
		}

		const finalBranches = [];

		const outcomeBranching = node.branching[outcome].branching;
		if (outcomeBranching.length > 0) {
			// Ensure all top level branches are groups only.
			let topGroupSeq = -1;
			const toMove = [];
			for (let bi = 0; bi < outcomeBranching.length; bi++) {
				const branch = outcomeBranching[bi];
				if (branch.goto) {
					toMove.push(branch);
				} else if (branch.branching) {
					finalBranches.push(branch);

					if (topGroupSeq < 0) {
						if (branch.branching_type) {
							topGroupSeq = bi;
						}
					}
				}
			}

			if (toMove.length > 0) {
				if (topGroupSeq < 0) {
					finalBranches.push(defaultNodeBranching());
					topGroupSeq = outcomeBranching.length - 1;
				}

				if (!finalBranches[topGroupSeq].branching) {
					finalBranches[topGroupSeq].branching = [];
				}
				toMove.forEach(b => {
					finalBranches[topGroupSeq].branching.push(b);
				});
			}
		}

		if (finalBranches.length < 1) {
			finalBranches.push(defaultNodeBranching());
		}

		if (!node.branching[outcome].branching_type) {
			node.branching[outcome].branching_type = BranchingTypeAll;
		}

		node.branching[outcome].branching =
			ensureNodeBranchingInitialised(finalBranches,
				node.branching[outcome].branching_type);

		if (node.branching[outcome].default) {
			node.branching[outcome].default =
				ensureNodeBranchingInitialised(node.branching[outcome].default,
					BranchingTypeDefault);
		}

		// These don't belong here.
		["operator", "value"].forEach(k => {
			if (node.branching[outcome][k]) {
				delete node.branching[outcome][k];
			}
		});
	});

	return node;
};

export const ensureNodeBranchingInitialised = (branching, branchingType) => {
	if (!branchingType) {
		branchingType = BranchingTypeAll;
	}

	if (branching && branching.length > 0) {
		branching = branching.map(b => {
			if (b.branching && b.branching.length > 0) {
				b.branching = ensureNodeBranchingInitialised(b.branching, branchingType);
			} else {
				if (branchingType === BranchingTypeDefault) {
					// Set to empty (incase the target gets dragged into a normal target).
					b.operator = "";
					b.value = "";
				} else {
					if (branchingType === BranchingTypeEnum) {
						b.operator = "";
					} else if (b.operator === undefined || b.operator === null) {
						b.operator = "";
					}

					if (b.value === undefined || b.value === null) {
						b.value = "";
					}
				}
			}

			return b;
		});
	} else {
		branching = [];
	}

	return branching;
};

export const modifyNodeBranching = (node, cb) => {
	BranchingOutcomes.forEach(outcome => {
		if (node.branching[outcome]) {
			BranchingKeys.forEach(branchKey => {
				if (node.branching[outcome][branchKey]) {
					node.branching[outcome][branchKey] = modifyBranching(
						node.branching[outcome][branchKey], cb);
				}
			});
		}
	});

	return node;
};

export const modifyBranching = (branching, cb) => {
	const finalBranching = [];
	if (branching && branching.length > 0) {
		branching.forEach(b => {
			if (cb) {
				b = cb(b);

				if (!b) {
					return;
				}
			}

			if (b.branching && b.branching.length > 0) {
				b.branching = modifyBranching(b.branching, cb);
			}

			finalBranching.push(b);
		});
	}

	return finalBranching;
};

export const pruneNodeBranching = (node) => {
	modifyNodeBranching(node, (b) => {
		if (b.value === "") {
			delete (b.value);
		}
		if (b.operator === "") {
			delete (b.operator);
		}

		return b;
	});

	return node;
};

export const modifyNodeBranchingTempIds = (node, removeIds) => {
	modifyNodeBranching(node, (b) => {
		if (removeIds) {
			delete (b._id);
		} else {
			if (!b._id) {
				b._id = uuid();
			}
		}

		return b;
	});

	return node;
};

export const removeNodeFromTargets = (node, nodeId) => {
	modifyNodeBranching(node, (b) => {
		if (b.goto && b.goto.target) {
			if (b.goto.target === nodeId) {
				if (b.branching) {
					delete b.goto;
				} else {
					return null;
				}
			}
		}

		return b;
	});

	return node;
};
