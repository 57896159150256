import {TemplateKind} from "./types";
import {DispatchApi} from "../../core/dispatch/dispatch";
import {useToken} from "../../state/UserContextProvider";


export interface ITemplateDescriptor {

    published: boolean;
    publishedOn: number;
    publishedSettings: { variables: any[] };
    publishedLatest: boolean;

    data: any;
    history: number[];

    kind: TemplateKind;
    modified: number;
    name: string;

    templateId: string;
    namespace: string;
}

export interface ITemplateDescriptorMap {
    voice: ITemplateDescriptor[];
    sms: ITemplateDescriptor[];
    email: ITemplateDescriptor[];
}

export class TemplateDispatchApi extends DispatchApi {

    constructor(token: string | undefined | null) {
        super(token, "templates");
    }

    async getAllTemplates(): Promise<ITemplateDescriptorMap> {
        const list = await this.dispatch<ITemplateDescriptor[]>("list");

        const map: any = {
            "voice": [],
            "sms": [],
            "email": []
        };

        for (let template of list) {
            const subs = map[template.kind] = map[template.kind] ?? [];
            subs.push(template);
        }

        for (let type in map) {
            map[type].sort((a: ITemplateDescriptor, b: ITemplateDescriptor) => {
                if (a.modified < b.modified)
                    return 1;
                if (a.modified > b.modified)
                    return -1;
                return 0;
            });
        }

        return map;
    }

    async createTemplate(kind: TemplateKind) {
        return await this.dispatch("create", {kind: kind});
    }

    async deleteTemplate(templateId: string) {
        return await this.dispatch("delete", {templateId: templateId});
    }

    async duplicateTemplate(templateId: string) {
        return await this.dispatch("duplicate", {templateId: templateId});
    }
}


export function useTemplateDispatchApi() {
    return new TemplateDispatchApi(useToken());
}
