import React, { useState } from "react";

import PropTypes from "prop-types";
import Color from "color";

import IconSvgElem from "./IconSvgElem";
import DropShadowSvgFilter from "../defs/DropShadowSvgFilter";

import config from "../../../../config";
import { CfgColors } from "core/config";

import { NodeStyleCircle, NodeStyleSquare } from "../../../../lib/common/diagram/node";

import "./ControlButtonSvgElem.scss";

const ElemIdCircle = "circle";
const ElemIdBarLeft = "bar-left";
const ElemIdBarRight = "bar-right";

const ControlBar = ({
	elemId, width, height, barPad, barStroke, barStrokeWidth, barHeightPercent,
	svgSprite, elemHover, onHover, onClick
}) => {
	const barHeight = height * (barHeightPercent / 100);
	const barRX = (barHeight) * 0.50;
	const barRY = (barHeight) * 0.50;

	const barWidth = (width / 2) - (barPad * 2);
	const barY = (height / 2) - (barHeight / 2);
	let barX;

	const iconHeight = barHeight * 0.35;
	const iconY = barY + ((barHeight - iconHeight) / 2);
	const iconPaddingX = (((barHeight - iconHeight) / 2) * 0.80);
	let iconX;

	switch (elemId) {
		case ElemIdBarLeft:
			barX = barPad;
			iconX = barX + iconPaddingX;

			break;
		case ElemIdBarRight:
			barX = (width / 2) + barPad;
			iconX = barX + (barWidth - iconHeight - iconPaddingX);

			break;
		default:
	}

	return (
		<g className={`bar ${elemId} ` + ((elemHover[elemId]) ? "is-hover" : "")}>
			<rect
				stroke={barStroke} strokeWidth={barStrokeWidth}
				height={barHeight} width={barWidth}
				x={barX} y={barY}
				rx={barRX} ry={barRY}
				filter="url(#drop-shadow)"
			/>

			<IconSvgElem
				width={iconHeight} x={iconX} y={iconY}
				svgSprite={svgSprite}
			/>

			{/** Bar pressure pad (the icon e.t.c. looses focus) **/}
			<rect
				fill="transparent"
				height={barHeight} width={barWidth}
				x={barX} y={barY}
				rx={barRX} ry={barRY}
				onClick={onClick}
				onMouseOver={() => onHover(elemId, true)}
				onMouseLeave={() => onHover(elemId, false)}
			/>
		</g>
	);
};

export const ControlButtonSvgElem = ({
	isActive, setIsActive, width, height, widthPad, fill, fillHover,
	style, size, padding, iconPad, iconHoverPad, iconFill, iconFillHover, iconSvg, iconSvgSprite,
	iconSvgHover, iconSvgSpriteHover, iconUrl, iconUrlHover, barHeightPercent, barPad,
	barFill, barStroke, barStrokeWidth, onClickCircle, onClickActions, isActionsOpen,
	onClickNodeDeleteAction, nodeName
}) => {
	const colorCfg = config.get(CfgColors);

	const [elemHover, setElemHover] = useState({
		[ElemIdCircle]: false,
		[ElemIdBarLeft]: false,
		[ElemIdBarRight]: false
	});

	const onHover = (id, val) => {
		const newElemHover = {
			...elemHover,
			[id]: val
		};

		setElemHover(newElemHover);

		for (const id of Object.keys(newElemHover)) {
			if (newElemHover[id]) {
				return setIsActive(true);
			}
		}

		setIsActive(false);
	};

	let [
		iconX, iconY, iconWidth,
		iconHoverX, iconHoverY, iconHoverWidth
	] = [];
	let [circleR, circleX, circleY] = [];
	switch (style) {
		case NodeStyleCircle:
			circleR = size / 2 - padding;
			circleX = size / 2;
			circleY = size / 2;

			iconX = (circleX - circleR) + iconPad;
			iconY = (circleY - circleR) + iconPad;
			iconWidth = (circleR * 2) - (iconPad * 2);

			iconHoverX = (circleX - circleR) + iconHoverPad;
			iconHoverY = (circleY - circleR) + iconHoverPad;
			iconHoverWidth = (circleR * 2) - (iconHoverPad * 2);

			if (widthPad) {
				iconX = iconX + widthPad;
				iconHoverX = iconHoverX + widthPad;
				circleX = circleX + widthPad;
			}

			break;

		case NodeStyleSquare:
			break;
		default:
	}

	if (!fill) {
		fill = colorCfg.white;
	}

	if (!iconSvgHover) {
		iconSvgHover = iconSvg;
	}
	if (!iconSvgSpriteHover) {
		iconSvgSpriteHover = iconSvgSprite;
	}

	if (!barHeightPercent) {
		barHeightPercent = 35;
	}
	if (!barPad) {
		barPad = 12;
	}
	if (!barFill) {
		barFill = fillHover;
	}
	if (!barStroke) {
		barStroke = colorCfg.white;
	}
	if (!barStrokeWidth) {
		barStrokeWidth = 2;
	}

	return (
        // The CSS-vars below only apply to the 'hover' state (hence they all refer to hover).
        (<div
			className={"control-button " + (isActive ? "control-active" : "")}
			style={{
				"--active-color": fillHover,
				"--active-font-color": iconFillHover,
				"--inactive-color": fill,
				"--inactive-font-color": Color(iconFillHover).alpha("0.60").string(),
				"--inactive-bar-color": fillHover,
				"--inactive-bar-font-color": Color(iconFillHover).alpha("0.60").string()
			}}
		>
            <div className={"node-label"}>{nodeName}</div>
            <div className="control-card">
				<svg width={width} height={height}>
					<defs>
						<DropShadowSvgFilter id="drop-shadow"/>
					</defs>

					<ControlBar
						elemId={ElemIdBarLeft}
						width={width} height={height}
						barPad={barPad} barHeightPercent={barHeightPercent}
						barStroke={barStroke} barStrokeWidth={barStrokeWidth}
						svgSprite="#fas-trash"
						elemHover={elemHover} onHover={onHover} onClick={onClickNodeDeleteAction}
					/>

					<ControlBar
						key={(isActionsOpen) ? "actions-open" : "actions-closed"}
						elemId={ElemIdBarRight}
						width={width} height={height}
						barPad={barPad} barHeightPercent={barHeightPercent}
						barStroke={barStroke} barStrokeWidth={barStrokeWidth}
						svgSprite={(isActionsOpen) ? "#fas-chevron-left" : "#fas-ellipsis-h"}
						elemHover={elemHover} onHover={onHover} onClick={onClickActions}
					/>

					{style === NodeStyleCircle
						? (
							<circle
								className={"shape circle " + ((elemHover[ElemIdCircle]) ? "is-hover" : "")}
								r={circleR} cx={circleX} cy={circleY}
								filter="url(#drop-shadow)"
							/>
						)
						: ""}

					<IconSvgElem
						key={isActive ? iconSvgSpriteHover : iconSvgSprite}
						fill={isActive ? iconFillHover : iconFill}
						width={isActive ? iconHoverWidth : iconWidth}
						x={isActive ? iconHoverX : iconX}
						y={isActive ? iconHoverY : iconY}
						url={isActive ? iconUrlHover : iconUrl}
						svg={isActive ? iconSvgHover : iconSvg}
						svgSprite={isActive ? iconSvgSpriteHover : iconSvgSprite}
					/>

					{/** Circle pressure pad (the icon e.t.c. looses focus) **/}
					{style === NodeStyleCircle
						? (
							<circle
								className="shape-pad"
								r={circleR} cx={circleX} cy={circleY} fill="transparent"
								onMouseOver={() => onHover(ElemIdCircle, true)}
								onMouseLeave={() => onHover(ElemIdCircle, false)}
								onMouseDown={() => onHover(ElemIdCircle, false)}
								onClick={onClickCircle}
								onContextMenu={(e) => {
									e.preventDefault();
									onClickActions(e);
								}}
							/>
						)
						: ""}
				</svg>
			</div>
        </div>)
    );
};

ControlButtonSvgElem.propTypes = {
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	widthPad: PropTypes.number.isRequired,
	fill: PropTypes.string.isRequired,
	fillHover: PropTypes.string,
	size: PropTypes.number.isRequired,
	style: PropTypes.string.isRequired,
	padding: PropTypes.number.isRequired,
	iconPad: PropTypes.number.isRequired,
	iconFill: PropTypes.string,
	iconFillHover: PropTypes.string,
	iconSvg: PropTypes.string,
	iconSvgSprite: PropTypes.string,
	iconSvgHover: PropTypes.string,
	iconSvgSpriteHover: PropTypes.string,
	onClickCircle: PropTypes.func,
	nodeName: PropTypes.string
};

export default ControlButtonSvgElem;
