import config from "../../../config";
import { CfgColors } from "core/config";

const colourCfg = config.get(CfgColors);

export const PlaygroundRunStatusPending = "PENDING";
export const PlaygroundRunStatusQueued = "QUEUED";
export const PlaygroundRunStatusDeferred = "DEFERRED";
export const PlaygroundRunStatusRetry = "RETRY";
export const PlaygroundRunStatusRunning = "RUNNING";
export const PlaygroundRunStatusCancelled = "CANCELLED";
export const PlaygroundRunStatusFailed = "FAILED";
export const PlaygroundRunStatusCompleted = "COMPLETED";
export const PlaygroundRunStatusEnded = "ENDED";
export const PlaygroundRunStatusTimedOut = "TIMED_OUT";
export const PlaygroundRunStatusNOOP = "NOOP";

export const PlaygroundRunStatusOptions = [
	{ value: PlaygroundRunStatusPending, label: "Pending" },
	{ value: PlaygroundRunStatusQueued, label: "Queued" },
	{ value: PlaygroundRunStatusDeferred, label: "Deferred" },
	{ value: PlaygroundRunStatusRetry, label: "Retry" },
	{ value: PlaygroundRunStatusRunning, label: "Running" },
	{ value: PlaygroundRunStatusCancelled, label: "Cancelled" },
	{ value: PlaygroundRunStatusFailed, label: "Failed" },
	{ value: PlaygroundRunStatusCompleted, label: "Completed" },
	{ value: PlaygroundRunStatusEnded, label: "Ended" },
	{ value: PlaygroundRunStatusTimedOut, label: "Timed Out" },
	{ value: PlaygroundRunStatusNOOP, label: "No-Op" }
];

export const PlaygroundRunStatusAllRunning = [
	PlaygroundRunStatusPending,
	PlaygroundRunStatusQueued,
	PlaygroundRunStatusDeferred,
	PlaygroundRunStatusRetry,
	PlaygroundRunStatusRunning
];

export const PlaygroundRunStatusAllCompleted = [
	PlaygroundRunStatusCompleted,
	PlaygroundRunStatusNOOP,
	PlaygroundRunStatusEnded
];

export const PlaygroundRunStatusAllFailed = [
	PlaygroundRunStatusCancelled,
	PlaygroundRunStatusFailed,
	PlaygroundRunStatusTimedOut
];

export const getPlaygroundRunStatusColor = (status) => {
	switch (status) {
		case PlaygroundRunStatusPending:
		case PlaygroundRunStatusQueued:
		case PlaygroundRunStatusDeferred:
		case PlaygroundRunStatusRetry:
		case PlaygroundRunStatusRunning:
			return colourCfg.purple;
		case PlaygroundRunStatusCompleted:
		case PlaygroundRunStatusNOOP:
		case PlaygroundRunStatusEnded:
			return colourCfg.green;
		case PlaygroundRunStatusCancelled:
			return colourCfg.upwireOrange1;
		case PlaygroundRunStatusFailed:
		case PlaygroundRunStatusTimedOut:
			return colourCfg.red;
		default:
			return colourCfg.blue;
	}
};

// Is it running? (can it be killed)
export const isPlaygroundRunStatusRunning = (status) => {
	switch (status) {
		case PlaygroundRunStatusPending:
		case PlaygroundRunStatusQueued:
		case PlaygroundRunStatusDeferred:
		case PlaygroundRunStatusRetry:
		case PlaygroundRunStatusRunning:
			return true;
		default:
			return false;
	}
};
