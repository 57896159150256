import { ConditionAnd } from "./index";
import cloneDeep from "lodash/cloneDeep";
import { v4 as uuid } from "uuid";

export const defaultConditionGroup = () => {
	return {
		condition: ConditionAnd,
		groups: [
			defaultNestedConditionGroup()
		]
	};
};

export const defaultNestedConditionGroup = () => {
	return {
		_id: uuid(),
		condition: ConditionAnd,
		rules: []
	};
};

// DJR: For use with top level condition groups with the Basic builder.
export const ensureBasicConditionGroupInitialised = (inGroup) => {
	let group = cloneDeep(inGroup);

	if (group.rules && group.rules.length > 0) {
		// Re-nest top level rules if it's not inside a group.
		const newGroup = defaultConditionGroup();
		newGroup.groups[0] = group;
		group = newGroup;
	} else if (!group.groups || group.groups.length < 1) {
		// Re-parent top level group in a correctly initilised structure.
		delete (group.groups); // Delete to ensure neat merge below.

		group = {
			...defaultConditionGroup(),
			...group
		};
	}

	return group;
};

export const modifyConditionGroupTempIds = (group, removeIds) => {
	if (group) {
		if (removeIds) {
			delete (group._id);
		} else {
			if (!group._id) {
				group._id = uuid();
			}
		}

		if (group.rules && group.rules.length > 0) {
			group.rules = group.rules.map(r => {
				if (removeIds) {
					delete (r._id);
				} else {
					if (!r._id) {
						r._id = uuid();
					}
				}

				return r;
			});
		}

		if (group.groups && group.groups.length > 0) {
			group.groups = group.groups.map(g => {
				return modifyConditionGroupTempIds(g, removeIds);
			});
		}
	}

	return group;
};
