import React, {Component} from "react";

import "./WindowTaskBar.scss";

export const TaskBarAction = ({icon, onClick}: { icon: string, onClick: () => void }) => {
    return (
        <div className="action" onClick={onClick}>
            <span className="icon"><i className={icon}/></span>
        </div>
    );
};



type WindowTaskBarProps = {
    title: string;
    start: any[];
    actions: any[];
    widgets?: any[];
}

export class WindowTaskBar extends Component<WindowTaskBarProps> {
    render() {
        const {title, actions, widgets} = this.props;

        return (
            <div className="task-bar">

                <div className="names text-selection-none">
                    <span>{title}</span>
                    {widgets ? widgets : null}
                </div>

                <div className="actions">
                    {actions ? (<div className="task-actions">{actions}</div>) : ""}
                </div>

            </div>
        );
    }
}

export default WindowTaskBar;
