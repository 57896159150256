import React, {useEffect} from "react";

export function capitalizeFirstLetter(str: string) {
    if (str.length === 0)
        return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function titleCase(str: string) {
    return str.split(" ").map(capitalizeFirstLetter).join(" ");
}

export async function getHash(str: string): Promise<string> {
    const msgUint8 = new TextEncoder().encode(str);                     // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer));          // convert buffer to byte array
    return hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
}

export function useHashDisplay(str: string, numChars: number = 8) {
    str = str.toString();
    const [text, setText] = React.useState(str);
    useEffect(() => {
        let cancelled = false;
        let handle = setTimeout(async () => {
            const hashed = await getHash(str);
            if (!cancelled)
                setText(hashed.slice(0, numChars));
        });

        return () => {
            cancelled = true;
            clearTimeout(handle);
        };
    });

    return text;
}
