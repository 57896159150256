import React, { Component } from "react";
import PropTypes from "prop-types";

import Filter from "./Filter";

export class Filters extends Component {
	render () {
		const { playgroundProps, inlet } = this.props;

		if (!inlet) {
			return "";
		}

		return (
			<div className="pg-inlet-filters">
				{inlet.filters && inlet.filters.length > 0
					? inlet.filters.map((filter, i) =>
						<Filter
							key={`pg-inlet-filter-${i}`} inlet={inlet} playgroundProps={playgroundProps}
							filter={filter} index={i} count={inlet.filters.length}
						/>
					)
					: <Filter inlet={inlet} playgroundProps={playgroundProps} isPlaceholder/>}
			</div>
		);
	}
}

Filters.propTypes = {
	playgroundProps: PropTypes.object.isRequired,
	inlet: PropTypes.object.isRequired
};

export default Filters;
