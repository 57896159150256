import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

import PrivateRouteAuth from "../../../containers/auth/private/PrivateRouteAuth.container";
import { PrivateRouteModeLogin } from "../../../actions/auth";

const PrivateRoute = ({ profile, component: Component, ...rest }) => (
	<Route
		{...rest} render={props => (
		(!profile || profile.loading || !profile.loggedIn)
			? <PrivateRouteAuth profile={profile} mode={PrivateRouteModeLogin} {...rest} />
			: <Component profile={profile} {...rest} />
	)}
	/>
);

PrivateRoute.propTypes = {
	profile: PropTypes.object,
	setFullscreen: PropTypes.func.isRequired
};

export default PrivateRoute;
