import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import config from "../../../config";
import { CfgColors, OptIconHoverPadPercent, OptIconPadPercent } from "core/config";
import { initNodeProps } from "../../../lib/common/diagram/node";
import { initStyleProps } from "../../../lib/common/diagram/style";

import ShapeButtonSvgElem from "../svg/elem/ShapeButtonSvgElem";

import "./PlaygroundModal.scss";

class PlaygroundModal extends PureComponent {
	render () {
		const { children, closeModal, modalState, title, nodeProps, browser, isHidden } = this.props;
		if (!modalState) {
			return null;
		}

		const colourCfg = config.get(CfgColors);

		const isMobile = browser.lessThan.desktop;
		const shapeProps = initNodeProps(
			initStyleProps({
				[OptIconPadPercent]: 16,
				[OptIconHoverPadPercent]: 16,
				...nodeProps,
				shapePad: 12,
				shapeSize: (isMobile) ? 64 : 76
			})
		);

		let headBgColor = "whitesmoke";
		if (shapeProps.fill) {
			headBgColor = shapeProps.fill;
		}

		let fontColor = colourCfg.black;
		if (shapeProps.iconFill) {
			fontColor = shapeProps.iconFill;
		}

		return (
			<div className={"modal playground " + ((isHidden) ? "is-hidden" : "is-active")}>
				<div className="modal-background" onClick={closeModal}/>
				<div className={"modal-card " + ((isMobile) ? "mobile" : "")}>
					<header className="modal-card-head" style={{ backgroundColor: headBgColor }}>
						<svg className="modal-svg-icon" width={shapeProps.shapeSize} height={shapeProps.shapeSize}>
							<ShapeButtonSvgElem
								style={shapeProps.style}
								size={shapeProps.shapeSize}
								padding={shapeProps.shapePad}
								fill={shapeProps.fill}
								iconFill={shapeProps.iconFill}
								iconPad={shapeProps.iconPad}
								iconHoverPad={shapeProps.iconHoverPad}
								iconUrl={shapeProps.iconUrl}
								iconSvg={shapeProps.iconSvg}
								iconSvgSprite={shapeProps.iconSvgSprite}
								disableHover
							/>
						</svg>
						<p className="modal-card-title" style={{ color: fontColor }}>{title}</p>
						<button className="delete" aria-label="close" onClick={closeModal}/>
					</header>
					{children}
				</div>
			</div>
		);
	}
}

PlaygroundModal.propTypes = {
	nodeProps: PropTypes.object.isRequired,
	closeModal: PropTypes.func.isRequired,
	modalState: PropTypes.bool.isRequired,
	browser: PropTypes.object.isRequired,
	title: PropTypes.string,
	icon: PropTypes.string,
	isHidden: PropTypes.bool
};

export default PlaygroundModal;
