import { combineReducers } from "redux-immutable";

import { newReducer } from "./new";
import { editReducer } from "./edit";
import { deleteReducer } from "./delete";

export default combineReducers({
	new: newReducer,
	edit: editReducer,
	delete: deleteReducer
});
