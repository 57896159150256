import { ArrowLinkModel } from "./ArrowLinkModel";
import config from "../../../../config";
import { CfgColors } from "core/config";

export class FailureArrowLinkModel extends ArrowLinkModel {
	constructor () {
		const colors = config.get(CfgColors);

		super("failure", colors.red, colors.red);
	}
}
