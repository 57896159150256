import React, { Component } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import InputField from "../../common/form/InputField";
import { ErrCodeAuthFailed } from "../../../errs";
import InlineError from "../../common/message/InlineError";
import { wrapLinkClick } from "../../../lib/link";
import InlineInfo from "../../common/message/InlineInfo";
import Loading from "../../../../components/common/loading/Loading";

const LoginMessages = ({ error, existing }) => {
	if (error) {
		return (
			<InlineError error={error}>
				{error.error_code === ErrCodeAuthFailed
					? <p>The email or password you entered is incorrect.</p>
					: <p>There was an error processing your request, please try again.</p>}
			</InlineError>
		);
	}

	if (existing) {
		return (
			<InlineInfo title="Account Exists">
				<p>Looks like you already have an account with us. Try logging in below, if you
					have any issues you can always reset your password.
				</p>
			</InlineInfo>
		);
	}

	return "";
};

const LoginFormSchema = Yup.object().shape({
	username: Yup.string()
		.required("User name is required."),
	password: Yup.string()
		.required("Password is required.")
});

class LoginForm extends Component {
	render () {
		let { login, doLogin, closeModal, openRegister, openReset, isDialog } = this.props;
		if (!login) {
			login = {};
		}
		const loading = login.loading;
		const error = login.error;
		const existing = login.existing;

		const openResetWrapper = () => {
			openReset({ email: login.username });
		};

		return (
			<Formik
				onSubmit={(form => {
					doLogin({ ...form });
				})}
				initialValues={{
					username: login.username || "",
					password: ""
				}}
				validationSchema={LoginFormSchema}
			>
				{({ setFieldValue, ...props }) => (
					<Form className="up-form">
						<section className="modal-card-body">
							{loading
								? <Loading/>
								: (
									<>
										<LoginMessages
											error={error}
											existing={existing}
										/>

										<InputField
											name="username" label="Username"
											type="text" placeholder="Your email" {...props}
										/>

										<InputField
											name="password" label="Password"
											type="password" placeholder="Password" autoComplete="password" {...props}
										/>

										<div className="form-hint-actions">
											<div className="buttons">
												<button type="button" className="button-link"
														onClick={wrapLinkClick(openRegister)}>
													Register
												</button>
												<button type="button" className="button-link unimportant"
														onClick={openResetWrapper}>
													Forgot your password?
												</button>
											</div>
										</div>
									</>
								)}
						</section>
						<footer className="modal-card-foot">
							<button type="submit" className="button is-primary" disabled={loading}>Login</button>
							{!isDialog
								? <button type="button" className="button" onClick={closeModal}>Cancel</button>
								: ""}
						</footer>
					</Form>
				)}
			</Formik>
		);
	}
}

export default LoginForm;
