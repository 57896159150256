import React, { Component } from "react";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

import Loading from "../../../../../../components/common/loading/Loading";
import LogicConditionBuilderFields, {
	BuilderTypeSimple,
	extractConditionBuilderForm,
	getConditionBuilderInitialValues,
	getConditionBuilderYupSchema
} from "./LogicConditionBuilderFields";

class LogicConditionBuilderForm extends Component {
	static propTypes = {
		builder: PropTypes.object,
		onSubmit: PropTypes.func.isRequired,
		fontColor: PropTypes.string.isRequired,
		headBgColor: PropTypes.string.isRequired
	};

	state = {
		builderType: undefined
	};

	setBuilderType = (builderType) => {
		this.setState(() => ({
			builderType: builderType
		}));

		return false;
	};

	render () {
		let { builder, headBgColor, fontColor, onSubmit, closeModal, isDialog } = this.props;
		if (!builder) {
			builder = {};
		}

		let { builderType } = this.state;
		if (!builderType) {
			builderType = BuilderTypeSimple;
		}

		const loading = builder.loading;

		const doSubmit = (form) => {
			if (onSubmit) {
				onSubmit(extractConditionBuilderForm(builderType, form));
			}
		};

		return (
			<Formik
				onSubmit={doSubmit}
				initialValues={getConditionBuilderInitialValues(builderType, builder.model)}
				validationSchema={getConditionBuilderYupSchema(builderType)}
			>
				{({ ...props }) => (
					<Form className="up-form with-tabs dynamic-form">
						<section className="modal-card-body no-scroll">
							{loading
								? <Loading/>
								: <LogicConditionBuilderFields
									builderType={builderType}
									setBuilderType={this.setBuilderType}
									borderColor={headBgColor}
									{...props}
								/>}
						</section>
						<footer className="modal-card-foot">
							<button
								type="submit" className="button is-primary"
								disabled={loading} style={{
								backgroundColor: headBgColor,
								color: fontColor
							}}
							>Apply
							</button>

							{!isDialog
								? <button type="button" className="button" onClick={closeModal}>Cancel</button>
								: ""}
						</footer>
					</Form>
				)}
			</Formik>
		);
	}
}

export default LogicConditionBuilderForm;
