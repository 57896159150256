import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import config from "../../../../../../../../../config";
import { CfgColors, OptIconHoverPadPercent, OptIconPadPercent } from "core/config";

import { NodeCardIcon } from "../../../node/common/NodeCards";
import { getNodeTypeOptions, NodeTypePhase } from "../../../../../../../../../config/node";
import { extractNodeProps } from "../../../../../../../../../lib/playground/canvas/phase/node/props";
import {
	GotoScopeCanvas,
	GotoScopePhase,
	GotoTargetFirst,
	GotoTargetNext
} from "../../../../../../../../../lib/playground/constants";

const colourCfg = config.get(CfgColors);

const localNodeProps = {
	shapeSize: 36,
	shapePad: 0,
	[OptIconPadPercent]: 24,
	[OptIconHoverPadPercent]: 24,
	disableDropShadow: true
};

export const getPhaseNodeInfo = (name, payload, disabled) => {
	const phase = payload.definition.phase;

	const nodeData = {
		type: NodeTypePhase,
		name: name
	};

	let nodeProps = getNodeTypeOptions(nodeData.type,
		extractNodeProps(nodeData, {})
	);

	let scope = null;
	if (payload.definition.canvas) {
		scope = GotoScopeCanvas;
	} else {
		scope = GotoScopePhase;
	}

	if (scope) {
		if (nodeProps.scopes && nodeProps.scopes[scope]) {
			nodeProps = {
				...nodeProps,
				...nodeProps.scopes[scope]
			};
		}
	}

	if (phase) {
		if (nodeProps.targets && nodeProps.targets[phase]) {
			nodeProps = {
				...nodeProps,
				...nodeProps.targets[phase]
			};
		}
	}

	// Override for disabled
	if (disabled) {
		nodeProps.fill = colourCfg.greyDark;
		nodeProps.iconFill = colourCfg.white;
		nodeProps.description = "Not available from the current context.";
	}

	// Generic Node Info
	const node = {
		nodeProps: {
			...nodeProps
		}
	};

	// Locally Configured Node Info
	const localNode = {
		nodeProps: {
			...nodeProps,
			...localNodeProps
		}
	};

	if (nodeData.name) {
		localNode.nodeProps.label = nodeData.name;
		node.nodeProps.label = nodeData.name;
	}

	return [node, localNode];
};

export const PhaseCard = ({ title, payload, disabled, handleSelect, handleHover }) => {
	const [node, localNode] = getPhaseNodeInfo(title, payload, disabled);

	const handleClick = () => {
		if (disabled) {
			return false;
		}

		handleSelect(payload);
	};

	return (
		<div className={classNames(
			"phase-card-container",
			{ "is-disabled": disabled }
		)}
		>
			<div
				className="phase-card"
				onClick={handleClick}
				onMouseOver={() => handleHover(node)}
				onMouseLeave={() => handleHover()}
			>
				<NodeCardIcon node={localNode}/>
				<div className="phase-label">
					<div>{title}</div>
				</div>
			</div>
		</div>
	);
};

PhaseCard.propTypes = {
	title: PropTypes.string.isRequired,
	payload: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	handleSelect: PropTypes.func.isRequired,
	handleHover: PropTypes.func.isRequired
};

export const getPhaseCard = (phase, canvas, isCurrentCanvas, currentPhase, handleSelect, handleHover) => {
	const payload = {
		definition: {
			phase: phase.id
		}
	};

	if (!isCurrentCanvas) {
		payload.definition.canvas = canvas.canvas_id;
	}

	const disabled = (isCurrentCanvas && (phase.id === currentPhase.id));

	return (
		<PhaseCard
			key={phase.id}
			title={phase.name}
			payload={payload}
			handleHover={handleHover}
			handleSelect={handleSelect}
			disabled={disabled}
		/>
	);
};

export const getPhaseTargetedCard = (
	target, canvas, isCurrentCanvas, currentPhase,
	playgroundProps, handleSelect, handleHover
) => {
	let disabled = false;
	let title = null;
	switch (target) {
		case GotoTargetFirst:
			title = "First Phase";
			break;
		case GotoTargetNext:
			title = "Next Phase";

			if (!isCurrentCanvas) {
				disabled = true;
			}
			break;
		default:
			throw new Error("unhandled phase target: " + target);
	}

	const payload = {
		definition: {
			phase: target
		}
	};

	if (!isCurrentCanvas) {
		payload.definition.canvas = canvas.canvas_id;
	}

	if (canvas && canvas.schema && canvas.schema.phases && canvas.schema.phases.length > 0) {
		// Is there a first/next?
		canvas.schema.phases.forEach((p, pi) => {
			if (isCurrentCanvas) {
				if (currentPhase.id === p.id) {
					if (target === GotoTargetFirst) {
						if (pi === 0) {
							disabled = true;
						}
					} else if (target === GotoTargetNext) {
						if (pi + 1 === canvas.schema.phases.length) {
							disabled = true;
						}
					}
				}
			}
		});
	} else {
		// Disable if no phases found
		disabled = true;
	}

	return (
		<PhaseCard
			key={target}
			title={title}
			payload={payload}
			handleHover={handleHover}
			handleSelect={handleSelect}
			disabled={disabled}
		/>
	);
};

export const PhaseCards = ({
	canvas, isCurrentCanvas, currentPhase, playgroundProps, handleSelect, handleHover
}) => {
	const cards = [
		getPhaseTargetedCard(GotoTargetFirst, canvas, isCurrentCanvas, currentPhase,
			playgroundProps, handleSelect, handleHover),
		getPhaseTargetedCard(GotoTargetNext, canvas, isCurrentCanvas, currentPhase,
			playgroundProps, handleSelect, handleHover)
	];

	if (canvas && canvas.schema) {
		if (canvas.schema.phases) {
			canvas.schema.phases.forEach(p => {
				cards.push(
					getPhaseCard(p, canvas, isCurrentCanvas, currentPhase, handleSelect, handleHover)
				);
			});
		}
	}

	return (
		<div className="pg-existing-target-tab-content">
			<div className="phase-cards">
				{cards}
			</div>
		</div>
	);
};

PhaseCards.propTypes = {
	canvas: PropTypes.object.isRequired,
	isCurrentCanvas: PropTypes.bool,
	currentPhase: PropTypes.object.isRequired,
	playgroundProps: PropTypes.object.isRequired,
	handleSelect: PropTypes.func.isRequired,
	handleHover: PropTypes.func.isRequired
};
