import React, { Component } from "react";
import { connect } from "react-redux";

import config from "../../../../../../config";
import { CfgColors } from "core/config";
import Modal from "core/components/common/modal/Modal";
import { getNestedState } from "core/lib/state";
import { CloseNewPhaseModalAction } from "../../../../../../actions/playground/inlet/filter/canvas/phase/new";
import { CanvasNewAction } from "../../../../../../actions/playground/inlet/filter/canvas/new";
import PhaseEditForm from "../../../../../../components/playground/inlet/filter/canvas/phase/PhaseEditForm";
import { CanvasUpdateAction } from "../../../../../../actions/playground/inlet/filter/canvas/update";
import { addNewCanvasPhase } from "../../../../../../lib/playground/canvas/phase/update";

class PhaseNewModal extends Component {
	render () {
		let { closeModal, phaseNew, dashboard, updateCanvas } = this.props;
		if (!phaseNew) {
			phaseNew = {};
		}
		if (!dashboard) {
			dashboard = {};
		}

		const open = !!(phaseNew && phaseNew.open);
		if (!open) {
			return null;
		}

		if (phaseNew.loading) {
			closeModal = () => {
				// Disabled.
			};
		}

		const colourCfg = config.get(CfgColors);

		const onSubmit = (form) => {
			const [phaseId, model] = addNewCanvasPhase(dashboard, phaseNew.canvas.canvas_id, form);

			updateCanvas({
				namespace: phaseNew.canvas.namespace,
				playgroundId: phaseNew.canvas.playground_id,
				canvasId: phaseNew.canvas.canvas_id,
				model: model
			});

			closeModal();

			if (phaseNew.onCreate) {
				phaseNew.onCreate(phaseId);
			}
		};

		return (
			<>
				<Modal
					title="Create Flow"
					closeModal={closeModal}
					icon="fas fa-code-branch"
					modalState={open}
					headBgColor={colourCfg.purple}
					fontColor={colourCfg.white}
				>
					<PhaseEditForm
						editState={phaseNew}
						onSubmit={onSubmit}
						buttonBgColor={colourCfg.purple}
						buttonFontColor={colourCfg.white}
						buttonLabel="Create"
					/>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(CloseNewPhaseModalAction()),
		doCreateCanvas: (payload) => dispatch(CanvasNewAction(payload)),
		updateCanvas: (payload) => dispatch(CanvasUpdateAction(payload))
	};
};

const mapStateToProps = (state, props) => {
	const mapped = {
		core: state.get("core")
	};

	if (mapped.core) {
		mapped.profile = mapped.core.get("profile");
	}

	const playground = getNestedState(state, ["canvas", "playground"]);
	if (!playground) {
		return mapped;
	}

	const dashboard = getNestedState(playground, ["dashboard"]);
	if (!dashboard) {
		return mapped;
	}

	const phase = getNestedState(playground, ["inlet", "filter", "canvas", "phase"]);
	if (!phase) {
		return mapped;
	}

	return {
		...mapped,
		dashboard: dashboard,
		phaseNew: phase.get("new")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PhaseNewModal);
