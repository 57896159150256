import React, { Component } from "react";
import PropTypes from "prop-types";

import CanvasBar from "./CanvasBar";
import Phase from "./phase/Phase";

import "./Canvas.scss";

export class Canvas extends Component {
	state = {
		phaseId: undefined
	};

	setPhaseId = (phaseId) => {
		this.setState({
			phaseId: phaseId
		});

		return false;
	};

	render () {
		const { playgroundProps, canvas, onChangeDimensions } = this.props;
		const { phaseId } = this.state;

		if (!canvas || !canvas.schema || !canvas.schema.phases || canvas.schema.phases.length < 1) {
			return "";
		}

		let firstPhase;
		let phase;
		canvas.schema.phases.forEach(p => {
			if (!firstPhase) {
				firstPhase = p;
			}
			if (p.id === phaseId) {
				phase = p;
			}
		});
		if (!phase) {
			phase = firstPhase;
		}

		return (
			<div className="pg-inlet-filter-canvas">
				<CanvasBar
					playgroundProps={playgroundProps}
					canvas={canvas}
					phase={phase} setPhaseId={this.setPhaseId}
				/>

				<Phase
					playgroundProps={playgroundProps}
					canvas={canvas}
					phase={phase}
					onChangeDimensions={onChangeDimensions}
				/>
			</div>
		);
	}
}

Canvas.propTypes = {
	playgroundProps: PropTypes.object.isRequired,
	canvas: PropTypes.object.isRequired,
	onChangeDimensions: PropTypes.func
};

export default Canvas;
