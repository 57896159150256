import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { PlaygroundContent } from "../../components/playground/PlaygroundContent";
import WindowContainer from "components/common/window/WindowContainer";
import WindowContent from "components/common/window/WindowContent";

import { OpenPlaygroundSettingsModalAction } from "../../actions/playground/settings";
import { OpenEditNodeModalAction } from "../../actions/playground/inlet/filter/canvas/phase/node/edit";
import { PlaygroundDashboardAction } from "../../actions/playground/dashboard";
import { OpenNewFilterModalAction } from "../../actions/playground/inlet/filter/new";
import { OpenNewPhaseModalAction } from "../../actions/playground/inlet/filter/canvas/phase/new";
import { OpenSetTargetModalAction } from "../../actions/playground/inlet/filter/canvas/phase/target/set";
import { CanvasUpdateAction } from "../../actions/playground/inlet/filter/canvas/update";
import { OpenNodeNewModalAction } from "../../actions/playground/inlet/filter/canvas/phase/node/new";
import { ConditionBuilderOpenModalAction } from "core/actions/logic/condition/builder";
import { OpenEditFilterModalAction } from "../../actions/playground/inlet/filter/edit";
import { OpenEditPhaseModalAction } from "../../actions/playground/inlet/filter/canvas/phase/edit";
import { HideContextMenuAction, ShowContextMenuAction } from "core/actions/menu/actions";
import { OpenTerminateTargetModalAction } from "../../actions/playground/inlet/filter/canvas/phase/target/terminate";
import { ConfirmOpenModalAction } from "core/actions/info/confirm";
import { OpenPlaygroundDebuggerModalAction } from "../../actions/playground/debugger";
import { EnvironmentScopeAccount, EnvironmentScopeNamespace } from "core/lib/constants";
import { PlaygroundContext } from "./PlaygroundContext";

class PlaygroundDashboard extends Component {

	loadPlayground = () => {
		if (this.props.computedMatch && this.props.computedMatch.params) {
			this.props.getPlaygroundDashboard(this.props.computedMatch.params);
		}
	};

	getEnvironmentPath = () => {
		if (this.props.computedMatch && this.props.computedMatch.params) {
			const { namespace, playgroundId } = this.props.computedMatch.params;

			return `@playground/${namespace}/${playgroundId}`;
		}

		return "";
	};

	componentDidMount () {
		this.props.setFullscreen(false);
		this.loadPlayground();
	}

	render () {
		const {
			dashboard, openConfirm, openNewFilter, openEditFilter, openSetTarget, openTerminateTarget, openEditNode,
			openEditPlayground, openPlaygroundDebugger, openConditionBuilder, updateCanvas, openNewNode,
			showContextMenu, hideContextMenu, setFullscreen, isFullscreen, openNewPhase, openEditPhase,
			openEditEnvironment
		} = this.props;

		const loading = (!dashboard || dashboard.loading);
		const error = dashboard ? dashboard.error : null;

		if (!dashboard || !dashboard.playground) {
			return "";
		}

		const reloadPlayground = () => {
			this.loadEnvironments();
			this.loadPlayground();
		};
		const closePlayground = () => {
			this.props.history.push("/playgrounds");
		};

		const playgroundProps = {
			dashboard: dashboard,
			openConfirm: openConfirm,
			openNewFilter: openNewFilter,
			openEditFilter: openEditFilter,
			openNewPhase: openNewPhase,
			openEditPhase: openEditPhase,
			openSetTarget: openSetTarget,
			openTerminateTarget: openTerminateTarget,
			openNewNode: openNewNode,
			openEditNode: openEditNode,
			showContextMenu: showContextMenu,
			hideContextMenu: hideContextMenu,
			openEditPlayground: openEditPlayground,
			openPlaygroundDebugger: openPlaygroundDebugger,
			reloadPlayground: reloadPlayground,
			closePlayground: closePlayground,
			openConditionBuilder: openConditionBuilder,
			updateCanvas: updateCanvas,
			openEditEnvironment: () => {
				openEditEnvironment({
					title: "Edit Environments",
					scope: EnvironmentScopeAccount,
					path: this.getEnvironmentPath(),
					inherits: [EnvironmentScopeNamespace, EnvironmentScopeAccount]
				});
			}
		};

		return (
			<PlaygroundContext.Provider value={playgroundProps.dashboard.playground}>
				<WindowContainer isFullscreen={isFullscreen}>
					<WindowContent error={error} loading={loading}>
						<PlaygroundContent
							playgroundProps={playgroundProps}
							setFullscreen={setFullscreen}
							isFullscreen={isFullscreen}
						/>
					</WindowContent>
				</WindowContainer>
			</PlaygroundContext.Provider>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		getPlaygroundDashboard: (payload) => dispatch(PlaygroundDashboardAction(payload)),
		openConfirm: (payload) => dispatch(ConfirmOpenModalAction(payload)),
		openNewFilter: (payload) => dispatch(OpenNewFilterModalAction(payload)),
		openEditFilter: (payload) => dispatch(OpenEditFilterModalAction(payload)),
		openNewPhase: (payload) => dispatch(OpenNewPhaseModalAction(payload)),
		openEditPhase: (payload) => dispatch(OpenEditPhaseModalAction(payload)),
		openSetTarget: (payload) => dispatch(OpenSetTargetModalAction(payload)),
		openTerminateTarget: (payload) => dispatch(OpenTerminateTargetModalAction(payload)),
		openNewNode: (payload) => dispatch(OpenNodeNewModalAction(payload)),
		openEditNode: (payload) => dispatch(OpenEditNodeModalAction(payload)),
		showContextMenu: (payload) => dispatch(ShowContextMenuAction(payload)),
		hideContextMenu: (payload) => dispatch(HideContextMenuAction(payload)),
		openEditPlayground: (payload) => dispatch(OpenPlaygroundSettingsModalAction(payload)),
		openPlaygroundDebugger: (payload) => dispatch(OpenPlaygroundDebuggerModalAction(payload)),
		openConditionBuilder: (payload) => dispatch(ConditionBuilderOpenModalAction(payload)),
		updateCanvas: (payload) => dispatch(CanvasUpdateAction(payload))
	};
};

const mapStateToProps = (state) => {

	const canvas = state.get("canvas");
	if (!canvas) {
		return {};
	}
	const playground = canvas.get("playground");
	if (!playground) {
		return {};
	}

	return {
		dashboard: playground.get("dashboard")
	};
};

PlaygroundDashboard.propTypes = {
	setFullscreen: PropTypes.func.isRequired,
	isFullscreen: PropTypes.bool
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlaygroundDashboard));
