export const FieldTypeText = "text";
export const FieldTypeTextArea = "textarea";
export const FieldTypePassword = "password";
export const FieldTypeEmail = "email";
export const FieldTypeNumber = "number";
export const FieldTypeBool = "bool";
export const FieldTypeSelect = "select";

export const FieldTypeSmsTemplatePicker = "sms-template-picker";
export const FieldTypeVoiceTemplatePicker = "email-template-picker";
export const FieldTypeEmailTemplatePicker = "voice-template-picker";

export const ValidationFieldRequired = "required";
export const ValidationOneFieldRequired = "one-required";
