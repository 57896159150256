export const parseModuleUrl = (moduleUrl) => {
	if (moduleUrl && moduleUrl.indexOf("://") > 1) {
		const [proto, path] = moduleUrl.split("://");
		const [namespace, moduleId, version] = path.split("/");

		return {
			proto: proto,
			path: path,
			namespace: namespace,
			moduleId: moduleId,
			version: version
		};
	}
};
