import React, {ReactNode} from "react";
import {Alert} from "@mui/material";
import {DispatchApi} from "../../../core/dispatch/dispatch";
import {useToken} from "../../../state/UserContextProvider";

export type VariableValues = { [name: string]: string }

export type TestCase = {
    name: string;
    values: VariableValues

    varContexts: { [key: string]: string };
}

export type SavedTestCase = TestCase & {
    id: string;
}

export type Variable = {
    name: string;
    required: boolean;
    default: string | null;
}

export function getTestCaseError(testCase: SavedTestCase, variables: Variable[]): ReactNode | null {

    for (const variable of variables) {
        const set = testCase.values[variable.name];
        if (variable.required && !set) {
            return <Alert severity="error">A required variable <strong>{variable.name}</strong> is not set</Alert>;
        }
    }

    return null;
}

export function filterValidTests(testCases: SavedTestCase[], variables: Variable[]): SavedTestCase[] {
    return testCases.filter(testCase => !getTestCaseError(testCase, variables));
}

export class TestRunnerDispatchApi extends DispatchApi {

    constructor(token: string | undefined | null) {
        super(token, "playground", false);
    }

    async getTestCases(playgroundId: string): Promise<SavedTestCase[]> {
        return await this.dispatch<SavedTestCase[]>("getTestCases", {playgroundId});
    }

    async deleteTestCase(testCaseId: string, playgroundId: string) {
        return await this.dispatch("deleteTestCase", {testCaseId, playgroundId});
    }

    async upsertTestCase(testCase: TestCase, playgroundId: string, testCaseId: string = "0"): Promise<SavedTestCase> {

        const payload: any = {testCase, playgroundId};
        if (testCaseId)
            payload["testCaseId"] = testCaseId;

        return await this.dispatch<SavedTestCase>("upsertTestCase", payload);
    }
}

export function useTestRunnerDispatchApi() {
    return new TestRunnerDispatchApi(useToken());
}
