import React, { Component } from "react";
import InlineMessage from "./InlineMessage";

class InlineInfo extends Component {
	render () {
		const { title, children } = this.props;

		return (
			<InlineMessage title={title} colourClass="is-info">
				{children}
			</InlineMessage>
		);
	}
}

export default InlineInfo;
