import React from "react";

import config, { CfgBlankSplashUrl } from "../../../config";

import Dialog from "../dialog/Dialog";
import { ErrorMessageDetails } from "../message/MessageDetails";
import SplashContainer from "../../../../components/layout/splash/SplashContainer";

export const ErrorContent = ({ title, error }) => {
	const splashUrl = config.get(CfgBlankSplashUrl);

	return (
		<SplashContainer url={splashUrl}>
			<Dialog title={title || "Error"} theme="is-danger is-light">
				<section className="modal-card-body">
					<ErrorMessageDetails
						error={error}
						showMessage={false}
						showGeneric={!error.content}
					/>
				</section>
				<footer className="modal-card-foot">
					<a href="/" type="submit" className="button is-danger is-light">Go Home</a>
				</footer>
			</Dialog>
		</SplashContainer>
	);
};
