import * as React from "react";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { DefaultNodeWidget } from "./DefaultNodeWidget";
import { TargetNodeModel } from "./TargetNodeModel";
import { BranchingOutcomeSuccess } from "../../../../lib/playground/constants";

export class TargetNodeFactory extends AbstractReactFactory {
	constructor () {
		super("target");
	}

	generateReactWidget (event) {
		const opts = event.model.getOptions();
		const { linkData } = opts;
		const { type } = linkData;

		const widgetProps = {
			top: false,
			bottom: false,
			right: false,
			left: false
		};
		if (type === BranchingOutcomeSuccess) {
			widgetProps.left = true;
		} else {
			widgetProps.top = true;
		}

		let outcomeOpts = {};
		if (opts[type]) {
			outcomeOpts = opts[type];
		}

		return (
			<DefaultNodeWidget
				engine={this.engine}
				node={event.model}
				{...widgetProps}
				{...opts}
				{...outcomeOpts}
			/>
		);
	}

	generateModel (event) {
		return new TargetNodeModel();
	}
}
