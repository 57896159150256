import React from "react";
import classNames from "classnames";

import ShapeButtonSvgElem from "../../../svg/elem/ShapeButtonSvgElem";

import "./PortTeleportInletFacet.scss";

export const PortTeleportInletFacet = ({
	node, teleports, size, offsetX, offsetY, isActive, setIsActive
}) => {
	if (!teleports) {
		return "";
	}

	let teleport = {};
	if (teleports[node.getID()]) {
		teleport = teleports[node.getID()];
	}
	if (!teleport || !teleport.active) {
		return "";
	}

	const shapeSize = size * 0.60;

	return (
		<g
			className={classNames(
				"port-teleports-inlet-facet",
				{ "is-active": isActive }
			)}
			onMouseOver={() => setIsActive(true)}
			onMouseLeave={() => setIsActive(false)}
		>
			<ShapeButtonSvgElem
				size={shapeSize}
				padding={0}
				fill={teleport.color}
				stroke="#FFF"
				strokeWidth={1}
				iconFill="white"
				iconPad={3}
				iconHoverPad={3}
				iconSvgSprite="fad-dot-circle"
				offsetX={offsetX + (shapeSize * 0.22)}
				offsetY={offsetY + (shapeSize * 0.30)}
			/>
		</g>
	);
};
