import React, { Component } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";

import Diagram from "../../../../../common/diagram/canvas/Diagram";
import DiagramContainer from "../../../../../common/diagram/canvas/DiagramContainer";
import { RecycleBinWidget } from "./bin/RecycleBinWidget";
import { extractDangingNodes } from "../../../../../../lib/playground/canvas/phase/node/targets";
import { updateNodePositionAction } from "../../../../../../lib/playground/canvas/phase/node/actions";

import "./Phase.scss";

const updateNodePosition = (e, node, playgroundProps, data) => {
	updateNodePositionAction(node, playgroundProps, data)(
		e.entity.getCentrePosition()
	);
};

export class Phase extends Component {
	render () {
		const { playgroundProps, canvas, phase, onChangeDimensions } = this.props;

		if (!canvas || !phase) {
			return "";
		}

		let pendingUpdate;

		const phasePlaygroundProps = {
			...cloneDeep(playgroundProps),
			canvas: canvas,
			phase: phase,
			onNodePositionChanged: (e, node) => {
				pendingUpdate = { e, node };
			}
		};

		const phaseData = cloneDeep(phase);

		const danglingNodes = extractDangingNodes(phaseData, phasePlaygroundProps);

		const handleMouseUp = () => {
			if (pendingUpdate && pendingUpdate.e && pendingUpdate.node) {
				updateNodePosition(pendingUpdate.e, pendingUpdate.node,
					phasePlaygroundProps, phaseData);
				pendingUpdate = undefined;
			}
		};

		// Ensures the canvas is re-rendered on saved changes.
		const uniqueKey = phase.id + "_" + canvas.version_id;

		return (
			<div
				key={uniqueKey}
				className="pg-canvas-phase"
				onMouseUp={handleMouseUp}
			>
				<DiagramContainer onChangeDimensions={onChangeDimensions}>
					<Diagram
						playgroundProps={phasePlaygroundProps} data={phaseData}
					/>
				</DiagramContainer>

				<RecycleBinWidget
					playgroundProps={phasePlaygroundProps}
					data={phaseData}
					danglingNodes={danglingNodes}
				/>
			</div>
		);
	}
}

Phase.propTypes = {
	playgroundProps: PropTypes.object.isRequired,
	canvas: PropTypes.object.isRequired,
	phase: PropTypes.object,
	onChangeDimensions: PropTypes.func
};

export default Phase;
