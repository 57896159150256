import { all } from "redux-saga/effects";

import coreSagas from "../core/sagas";
import canvasSagas from "../canvas/sagas";

export default function * rootSaga () {
	yield all([
		...coreSagas,
		...canvasSagas
	]);
}
