import React, {useState} from "react";

import WindowTaskBar, {TaskBarAction} from "components/common/window/WindowTaskBar";

import Inlets from "./inlet/Inlets";

import "./PlaygroundContent.scss";
import {CircularProgress, Menu, MenuItem} from "@mui/material";
import {useDispatch} from "react-redux";
import {PlaygroundDashboardAction} from "../../actions/playground/dashboard";
import {usePlaygroundDispatchApi} from "../../api/playground/dispatch";
import classNames from "classnames";

type PromoteMenuProps = {
    playground: { playground_id: number, namespace: string, name: string, is_head: boolean, is_test: boolean, is_prod: boolean }
}

function PromoteMenu(props: PromoteMenuProps) {

    const {playground} = props;

    const dispatch = useDispatch();
    const api = usePlaygroundDispatchApi();

    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {

        if (playground.is_test && playground.is_prod)
            return;

        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const promote = async (env: "test" | "prod") => {
        closeMenu();
        setLoading(true);
        await api.promote(playground.playground_id, env);
        setLoading(false);
        dispatch(PlaygroundDashboardAction({
            "namespace": playground.namespace,
            "playgroundId": playground.playground_id,
        }));
    };

    const promoteComplex = <div
        className="promote-complex"
        onClick={handleClick}
    >
        <div className={classNames({"indicator": true, "outdated": !playground.is_test})}>
            <div className="name">Test</div>
            <div className="value">{playground.is_test ? "Current" : "Outdated"}</div>
        </div>

        <div className={classNames({"indicator": true, "outdated": !playground.is_prod})}>
            <div className="name">Prod</div>
            <div className="value">{playground.is_prod ? "Current" : "Outdated"}</div>
        </div>
    </div>;

    return <>
        {!loading && promoteComplex}
        {loading && <CircularProgress size={20}></CircularProgress>}
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            MenuListProps={{
                "aria-labelledby": "basic-button",
            }}
        >
            {!playground.is_test && <MenuItem onClick={() => promote("test")}>
				Promote Playground to TEST</MenuItem>}

            {!playground.is_prod && <MenuItem onClick={() => promote("prod")}>
				Promote Playground to PROD</MenuItem>
            }
        </Menu>
    </>;
}

export function PlaygroundContent(props: any) {
    const {playgroundProps} = props;
    const {
        dashboard,
        openEditPlayground,
        openPlaygroundDebugger,
    } = playgroundProps;
    const {playground} = dashboard;

    const actions = [
        <TaskBarAction key="debug" icon="fas fa-debug" onClick={openPlaygroundDebugger}/>,
        <TaskBarAction key="settings" icon="fas fa-cog" onClick={openEditPlayground}/>
    ];

    const widgets = [
        <PromoteMenu key="promote-menu" playground={playground}/>
    ];

    return (
        <>
            <WindowTaskBar start={[]} title={playground.name} actions={actions} widgets={widgets}/>
            <Inlets playgroundProps={playgroundProps}/>
        </>
    );
}
