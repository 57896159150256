import { combineReducers } from "redux-immutable";

import { setReducer } from "./set";
import { terminateReducer } from "./terminate";
import { existingReducer } from "./existing";

export default combineReducers({
	set: setReducer,
	terminate: terminateReducer,
	existing: existingReducer
});
