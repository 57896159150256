import {
	CloseNewPlaygroundModalAction,
	OpenNewPlaygroundModalAction,
	PlaygroundNewAction,
	PlaygroundNewFailedAction,
	PlaygroundNewSuccessAction
} from "../../actions/playground/new";
import { registerLoadingActions } from "core/reducers/loading";
import { registerErrorActions } from "core/reducers/error";

registerLoadingActions(
	[PlaygroundNewAction.getType()],
	[PlaygroundNewSuccessAction.getType(), PlaygroundNewFailedAction.getType()]
);

registerErrorActions([
	PlaygroundNewFailedAction.getType()
]);

export const newReducer = (state = null, action) => {
	switch (action.type) {
		case OpenNewPlaygroundModalAction.getType():
			return { open: true, model: action.payload };
		case CloseNewPlaygroundModalAction.getType():
			return { open: false };
		case PlaygroundNewAction.getType():
			return { ...state, loading: true, error: null, model: action.payload };
		case PlaygroundNewSuccessAction.getType():
			return { ...state, loading: false, model: action.payload };
		case PlaygroundNewFailedAction.getType():
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};
