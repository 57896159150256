import React, { useState } from "react";
import { Animate } from "@nahanil/react-show";

export const AccordionGroup = ({ title, icon, children }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="up-accordion-group">
			<button
				className={"button is-white is-fullwidth up-accordion-group-title" + (isOpen ? " is-open" : "")}
				onClick={() => setIsOpen(!isOpen)}
				type="button"
			>
				{icon && <span className="icon"><i className={`fas fa-${icon}`}/></span>}
				{title}
				<span className="icon toggle-icon">
          <i className="fas fa-chevron-up"/>
        </span>
			</button>
			<Animate
				show={isOpen}
				stayMounted
				duration={300}
				start={{ opacity: 0, height: 0, overflow: "hidden" }}
				enter={{ opacity: 1, height: "auto", overflow: "hidden" }}
			>
				<div className="up-accordion-group-body">
					<div>TAG-IF-FOUND-REPORT-THIS-TAG-TO-GLEB</div>
					{children}
				</div>
			</Animate>
		</div>
	);
};
