import {API_BASE_URL, FormatData, FormatterOutput} from "./formatter";

const header = `
import requests

response = requests.post('|BASE_URL|/|ENDPOINT|', headers={
  "Authorization": "Bearer |TOKEN|",
  "UP-Account-Key": "|ACCOUNT_KEY|",
}, json=|DATA|).json()

match response:
    case {"id": id, "run_id": run_id, "status": status, "correlation_id": correlation_id}:
        print(f"ID: {id}, Run ID: {run_id}, Status: {status}, Correlation ID: {correlation_id}")
`;

export function format_py({payload, endpoint, token, accountKey}: FormatData): FormatterOutput {
    let code = header.replace(/\|DATA\|/, JSON.stringify(payload, null, 2));
    code = code.replace(/\|ENDPOINT\|/, endpoint);
    code = code.replace(/\|TOKEN\|/, token);
    code = code.replace(/\|BASE_URL\|/, API_BASE_URL);
    code = code.replace(/\|ACCOUNT_KEY\|/, accountKey);

    const end = "}),";
    if (code.endsWith(end))
        code = code.substring(0, code.length - end.length) + "  })\n}";

    // code += ending;

    return {
        code: code,
        language: "python"
    };
}
