export const CategoryAll = "all";
export const CategoryBasic = "basic";
export const CategoryCore = "core";
export const CategoryCustom = "custom";

export const defaultNodeCategories = [
	{
		id: CategoryAll,
		name: "All",
		icon: "fas fa-shapes",
		position: 10
	}, {
		id: CategoryBasic,
		name: "Basic",
		icon: "fas fa-random",
		position: 20
	}, {
		id: CategoryCore,
		name: "Core",
		icon: "fas fa-cubes",
		position: 30
	}, {
		id: CategoryCustom,
		name: "Custom",
		icon: "fas fa-code",
		position: 40
	}
];
