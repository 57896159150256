import {default as jwtDecode} from "jwt-decode";

export const RootOrSuperRoles = ["SUPER", "ROOT"];
export const AdminRoles = ["ADMIN", "OWNER"];

export const AuthTokenChanged = "AuthTokenChanged";

const tokenKey = "token";
const tokenTimestampKey = "tokenTimestamp";
const namespaceKey = "namespace";
const accountKey = "account";

const authTokenData = Symbol("authTokenData");

type DecodedToken = {
    user_id: string;
    username: string;
    roles: string[]

    namespace_access: {
        [namespace: string]: {
            name: string;
            roles: string[];
        }
    }

    account_access: {
        [account: string]: {
            account_key: string
            namespace: string
            roles: string[]
        }
    }

    options: {
        allow_super: boolean
        allow_root: boolean
    }

    Restricted: boolean
    RestrictionSpecification: string

    exp: number // unix timestamp
}

export function decode(token: string): DecodedToken | null {
    if (!token)
        return null;

    return jwtDecode(token);
}

export function getTokenStatus(): "ok" | "refresh" | "expired" | "missing" {

    const auth = getAuthTokenData();
    if (!auth)
        return "missing";

    const now = new Date().getTime();
    const exp = new Date(auth.exp * 1000).getTime();
    const ttlMinutes = (exp - now) / (1000 * 60);

    if (ttlMinutes < 0)
        return "expired";

    if (ttlMinutes < 10) {
        console.log("Token is nearing expiration, refreshing");
        return "refresh";
    }

    const timestamp = localStorage.getItem(tokenTimestampKey);
    if (timestamp) {
        const lastTokenUpdate = new Date(parseInt(timestamp));
        const lastTokenUpdateMinutes = (now - lastTokenUpdate.getTime()) / (1000 * 60);
        if (lastTokenUpdateMinutes > 60) {
            console.log("Token may be stale, refreshing");
            return "refresh";
        }
    }

    return "ok";
}


export function setToken(token: string) {
    const updated = token !== getToken();
    localStorage.setItem(tokenKey, token);
    delete (window as any)[authTokenData];

    if (updated)
        localStorage.setItem(tokenTimestampKey, new Date().getTime().toString());

    window.dispatchEvent(new Event(AuthTokenChanged));
}

export function getToken() {
    return localStorage.getItem(tokenKey);
}

export function clearToken() {

    localStorage.removeItem(tokenKey);
    localStorage.removeItem(tokenTimestampKey);

    // Clear decoded value
    (window as any)[authTokenData] = null;
}

export function setNamespace(namespace: string) {
    localStorage.setItem(namespaceKey, namespace);
}

export function getNamespace() {
    return localStorage.getItem(namespaceKey);
}

export function setAccountKey(account: string) {
    localStorage.setItem(accountKey, account);
}

export const getAccountKey = () => {
    return localStorage.getItem(accountKey);
};

export function getAuthTokenData(): DecodedToken | null {
    const tokenData = (window as any)[authTokenData];
    if (tokenData)
        return tokenData;

    const token = getToken();
    if (!token)
        return null;

    return (window as any)[authTokenData] = decode(token);
}

export function authRequestedSuperOrRoot() {

    const options = getAuthTokenData()?.options;
    if (!options)
        return false;

    return options.allow_root || options.allow_super;
}

function haveAnyRole(roles: string[], wantRoles: string[]) {
    return roles.some(v => wantRoles.includes(v));
}

export function authIsRootForNamespace() {
    const namespace = getNamespace();
    const authTokeData = getAuthTokenData();

    if (!namespace || !authTokeData || !authTokeData.namespace_access)
        return false;


    if (!authTokeData.namespace_access[namespace])
        return false;

    const roles = authTokeData.namespace_access[namespace].roles;
    return haveAnyRole(roles, AdminRoles);
}

export function authHasAnySuperOrRootRoles() {
    return authHasAnyRoles(RootOrSuperRoles);
}

export function authHasAnyRoles(roles: string[]) {
    if (!roles || roles.length === 0) {
        return false;
    }

    const authTokeData = getAuthTokenData();
    if (!authTokeData) {
        return false;
    }

    for (let role of roles) {
        if (role === "ROOT") {
            if (authIsRootForNamespace()) {
                return true;
            }
        }

        if (authTokeData.roles.includes(role))
            return true;
    }

    return false;
}
