// eslint-disable-next-line no-redeclare
/* global localStorage */

import React, { Component } from "react";
import { getCurrentUri } from "../../../lib/link";

class RegisterSuccess extends Component {
	render () {
		const { closeModal, isDialog } = this.props;

		// Record the URL so we can return the user here.
		const curUri = getCurrentUri();
		if (curUri !== "" && curUri !== "/") {
			localStorage.setItem("postActivateUri", curUri);
		}

		return (
			<>
				<section className="modal-card-body">
					<article className="media">
						<div className="media-left">
              <span className="icon is-medium has-text-success">
                <i className="fas fa-2x fa-envelope"/>
              </span>
						</div>
						<div className="media-content">
							<p className="title is-size-4">Activation Email Sent</p>
							<p className="msg">To finish your registration, you'll need to verify your account.
								Please check your email inbox and you should have an email from us.
							</p>
						</div>
					</article>
				</section>
				<footer className="modal-card-foot">
					{!isDialog
						? <button type="button" className="button is-primary" onClick={closeModal}>Ok</button>
						: ""}
				</footer>
			</>
		);
	}
}

export default RegisterSuccess;
