import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./WindowContainer.scss";

export const WindowContainer = ({ isFullscreen, isDiscrete, children }) => {
	return (
		<div className={classNames(
			"page-flex",
			"window-container",
			{ "is-fullscreen": isFullscreen },
			{ "is-discrete": !isFullscreen && isDiscrete }
		)}
		>
			{children}
		</div>
	);
};

WindowContainer.propTypes = {
	isFullscreen: PropTypes.bool,
	isDiscrete: PropTypes.bool
};

export default WindowContainer;
