import {Profile} from "../store";

export function authTokenPropProvider(state: Map<string, any>) {

    let mapping: any = {
        token: null
    };

    const core = state.get("core");
    if (!core) return mapping;

    const profile = core.get("profile") as Profile;
    if (!profile) return mapping;

    if (!profile.auth)
        return mapping;

    if (!profile.auth.token)
        return mapping;

    mapping.token = profile.auth.token;
    return mapping;
}
