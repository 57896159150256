import { call, put, takeLatest } from "redux-saga/effects";

import {
	PlaygroundGroupDeleteAction,
	PlaygroundGroupDeleteFailedAction,
	PlaygroundGroupDeleteRequestAction,
	PlaygroundGroupDeleteSuccessAction
} from "../../../actions/playground/group/delete";
import { deletePlaygroundGroup } from "../../../api/playground/group/delete";
import { PlaygroundListAction } from "../../../actions/playground/list";

/// / Playground Group Delete
// Worker
function * deletePlaygroundGroupWorker (action) {
	try {
		yield put(PlaygroundGroupDeleteRequestAction());

		const r = yield call(deletePlaygroundGroup, action.payload);
		if (r.json) {
			yield put(PlaygroundGroupDeleteSuccessAction(r.json));

			// Reload Playgrounds
			yield put(PlaygroundListAction());
		} else {
			yield put(PlaygroundGroupDeleteFailedAction("Failed to delete playground group"));
		}
	} catch (e) {
		yield put(PlaygroundGroupDeleteFailedAction(e));
	}
}

// Saga
export function * deletePlaygroundGroupSaga () {
	yield takeLatest(PlaygroundGroupDeleteAction.getType(), deletePlaygroundGroupWorker);
}

// Export

export const deleteSagas = [
	deletePlaygroundGroupSaga()
];

export default deleteSagas;
