import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import config from "../../../../../../../../../config";
import { CfgColors, OptIconHoverPadPercent, OptIconPadPercent } from "core/config";

import { walkPhaseNodes } from "../../../../../../../../../lib/playground/canvas/phase/node/walker";
import { NodeCardIcon } from "../../../node/common/NodeCards";
import { getNodeTypeOptions } from "../../../../../../../../../config/node";
import { extractNodeProps } from "../../../../../../../../../lib/playground/canvas/phase/node/props";

const colourCfg = config.get(CfgColors);

const localNodeProps = {
	shapeSize: 36,
	shapePad: 0,
	[OptIconPadPercent]: 24,
	[OptIconHoverPadPercent]: 24,
	disableDropShadow: true
};

export const getNodeInfo = (nodeData, disabled, playgroundProps) => {
	const nodeProps = getNodeTypeOptions(nodeData.type,
		extractNodeProps(nodeData, playgroundProps)
	);

	if (disabled) {
		nodeProps.fill = colourCfg.greyDark;
		nodeProps.iconFill = colourCfg.white;
	}

	// Generic Node Info
	const node = {
		nodeProps: {
			...nodeProps
		}
	};

	// Locally Configured Node Info
	const localNode = {
		nodeProps: {
			...nodeProps,
			...localNodeProps
		}
	};

	if (nodeData.name) {
		localNode.nodeProps.label = nodeData.name;
	}

	return [node, localNode];
};

export const NodeCard = ({ nodeData, payload, disabled, playgroundProps, handleSelect, handleHover }) => {
	const [node, localNode] = getNodeInfo(nodeData, disabled, playgroundProps);

	const handleClick = () => {
		if (disabled) {
			return false;
		}

		handleSelect(payload);
	};

	return (
		<div className={classNames(
			"phase-card-container",
			{ "is-disabled": disabled }
		)}
		>
			<div
				className="phase-card" onClick={handleClick}
				onMouseOver={() => {
					if (disabled) {
						return false;
					}

					handleHover(node);
				}}
				onMouseLeave={() => {
					if (disabled) {
						return false;
					}

					handleHover();
				}}
			>
				<NodeCardIcon node={localNode}/>
				<div className="phase-label">
					<div>{localNode.nodeProps.label}</div>
				</div>
			</div>
		</div>
	);
};

NodeCard.propTypes = {
	nodeData: PropTypes.object.isRequired,
	payload: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	playgroundProps: PropTypes.object.isRequired,
	handleSelect: PropTypes.func.isRequired,
	handleHover: PropTypes.func.isRequired
};

export const getNodeCard = (nodeId, node, playgroundProps, handleSelect, handleHover) => {
	const payload = {
		definition: {
			target: nodeId
		}
	};

	// TODO: Disabled if current node === target

	const disabled = false;

	return (
		<NodeCard
			key={nodeId}
			nodeData={node}
			payload={payload}
			playgroundProps={playgroundProps}
			handleHover={handleHover}
			handleSelect={handleSelect}
			disabled={disabled}
		/>
	);
};

export const CurrentPhaseNodeCards = ({
	canvas, isCurrentCanvas, currentPhase, currentNode,
	playgroundProps, handleSelect, handleHover
}) => {
	const cards = [];
	const seenNodes = {};

	walkPhaseNodes(currentPhase, nodeId => {
		if (currentNode.id === nodeId) {
			return true;
		}
		if (seenNodes[nodeId]) {
			return true;
		}
		seenNodes[nodeId] = true;

		const node = currentPhase.nodes[nodeId];
		if (!node) {
			return true;
		}

		cards.push(
			getNodeCard(nodeId, node, playgroundProps, handleSelect, handleHover)
		);
	});

	return (
		<div className="pg-existing-target-tab-content">
			<div className="phase-cards nodes">
				{cards.length > 0
					? cards
					: (
						<div className="no-nodes">
							No other nodes found.
						</div>
					)}
			</div>
		</div>
	);
};

CurrentPhaseNodeCards.propTypes = {
	canvas: PropTypes.object.isRequired,
	isCurrentCanvas: PropTypes.bool,
	currentPhase: PropTypes.object.isRequired,
	playgroundProps: PropTypes.object.isRequired,
	handleSelect: PropTypes.func.isRequired,
	handleHover: PropTypes.func.isRequired
};
