import { PortModelAlignment } from "@projectstorm/react-diagrams";
import { SuccessPortModel } from "../port/SuccessPortModel";
import { FailurePortModel } from "../port/FailurePortModel";
import { BaseNodeModel } from "./BaseNodeModel";
import { isNodeBranchingOutcomeAvailable } from "../../../../config/node/util";
import { BranchingOutcomeFailure, BranchingOutcomeSuccess } from "../../../../lib/playground/constants";

export class DefaultNodeModel extends BaseNodeModel {
	constructor (opts) {
		if (!opts) {
			opts = {};
		}
		if (!opts.size) {
			opts.size = 96;
		}

		super({
			type: "default",
			...opts
		});

		this.addPort(new SuccessPortModel(PortModelAlignment.LEFT, true));

		if (isNodeBranchingOutcomeAvailable(opts, BranchingOutcomeSuccess)) {
			this.addPort(new SuccessPortModel(PortModelAlignment.RIGHT));
		}

		this.addPort(new FailurePortModel(PortModelAlignment.TOP, true));

		if (isNodeBranchingOutcomeAvailable(opts, BranchingOutcomeFailure)) {
			this.addPort(new FailurePortModel(PortModelAlignment.BOTTOM));
		}
	}
}
