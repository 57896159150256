import {
	CloseNewPlaygroundGroupModalAction,
	OpenNewPlaygroundGroupModalAction,
	PlaygroundGroupNewAction,
	PlaygroundGroupNewFailedAction,
	PlaygroundGroupNewSuccessAction
} from "../../../actions/playground/group/new";
import { registerLoadingActions } from "core/reducers/loading";
import { registerErrorActions } from "core/reducers/error";

registerLoadingActions(
	[PlaygroundGroupNewAction.getType()],
	[PlaygroundGroupNewSuccessAction.getType(), PlaygroundGroupNewFailedAction.getType()]
);

registerErrorActions([
	PlaygroundGroupNewFailedAction.getType()
]);

export const newReducer = (state = null, action) => {
	switch (action.type) {
		case OpenNewPlaygroundGroupModalAction.getType():
			return { open: true, ...action.payload };
		case CloseNewPlaygroundGroupModalAction.getType():
			return { open: false };
		case PlaygroundGroupNewAction.getType():
			return { ...state, loading: true, error: null, model: action.payload };
		case PlaygroundGroupNewSuccessAction.getType():
			return { open: false };
		case PlaygroundGroupNewFailedAction.getType():
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};
