import React, { Component } from "react";
import { Field } from "formik";

export const SelectInputDivider = "SELECT_INPUT_DIVIDER";
export const SelectInputGroup = "SELECT_INPUT_GROUP";

export const firstViableOption = (opts) => {
	let first = null;
	if (opts && opts.length > 0) {
		opts.forEach(o => {
			if (first) {
				return;
			}

			if (o.value !== SelectInputDivider && o.value !== SelectInputGroup) {
				first = o;
			}
		});
	}

	return first;
};

class SelectInput extends Component {
	state = {
		isFocused: false
	};

	setIsFocused = (isFocused) => {
		this.setState(() => ({
			isFocused: isFocused
		}));

		return false;
	};

	getOptions = (options, isFocused) => {
		let currentGroup = null;
		let pendingItems = [];

		const optItems = options.map((option, oi) => {
			if (option.value === SelectInputDivider) {
				return <option key={"option-divider-" + oi} value="" disabled>──────────</option>;
			}

			if (option.value === SelectInputGroup) {
				if (currentGroup) {
					if (pendingItems.length > 0) {
						const grpItem = (
							<optgroup key={"option-group-" + currentGroup.label} label={currentGroup.label}>
								{pendingItems}
							</optgroup>
						);

						pendingItems = [];
						currentGroup = option;

						return grpItem;
					}
				}

				pendingItems = [];
				currentGroup = option;

				return "";
			}

			const val = (option.value !== undefined && option.value !== null) ? option.value : "";

			let label = option.label;
			if (isFocused) {
				if (option.title) {
					label += " (" + option.title + ")";
				}
			}

			if (currentGroup) {
				pendingItems.push(
					<option key={"option-" + ((val === "") ? "empty" : val) + "-" + oi} value={val}>{label}</option>
				);
			} else {
				return (
					<option key={"option-" + ((val === "") ? "empty" : val) + "-" + oi} value={val}>{label}</option>
				);
			}

			return "";
		});

		if (currentGroup) {
			if (pendingItems.length > 0) {
				optItems.push(
					<optgroup key={"option-group-" + currentGroup.label} label={currentGroup.label}>
						{pendingItems}
					</optgroup>
				);
			}
		}

		return optItems;
	};

	render () {
		const { name, options, isFullWidth, isSmall, autoComplete, className, disabled, onChange } = this.props;
		const { isFocused } = this.state;

		return (
			<div
				className={"select " + ((className) ? className + " " : "") + (isFullWidth ? "is-fullwidth " : "") + (isSmall ? "is-small " : "")}>
				<Field id={name} name={name} type="select">
					{({ field, form, ...props }) => {
						const { setFieldValue } = form;

						return (
							<select
								name={name}
								value={field.value}
								autoComplete={autoComplete}
								disabled={disabled}
								onFocus={() => this.setIsFocused(true)}
								onBlur={() => this.setIsFocused(false)}
								onChange={(e) => {
									e.target.blur();
									this.setIsFocused(false);
									setFieldValue(name, e.target.value);

									if (onChange) {
										onChange(e);
									}
								}}
							>
								{this.getOptions(options, isFocused)}
							</select>
						);
					}}
				</Field>
			</div>
		);
	}
}

export default SelectInput;
