import { apiFetch } from "core/api/base";

export function * postPlaygroundCreate ({ namespace, model }) {
	return yield apiFetch({
		endpoint: `/v1/playground/${namespace}`,
		method: "POST",
		authenticated: true,
		body: JSON.stringify(model)
	});
}
