import {
	ConditionBuilderCloseModalAction,
	ConditionBuilderOpenModalAction
} from "../../../actions/logic/condition/builder";

export const builderReducer = (state = null, action) => {
	switch (action.type) {
		case ConditionBuilderOpenModalAction.getType():
			return { open: true, ...action.payload };
		case ConditionBuilderCloseModalAction.getType():
			return { open: false };
		default:
			return state;
	}
};
