import React, { PureComponent } from "react";
import connect from "react-redux/es/connect/connect";

import { ErrorCloseModalAction } from "../../actions/error/error";
import config, { CfgColors } from "../../config";

import Modal from "../../components/common/modal/Modal";
import { ErrorMessageDetails } from "../../components/common/message/MessageDetails";

const colourCfg = config.get(CfgColors);

class ErrorModal extends PureComponent {
	render () {
		const { error, closeModal } = this.props;
		const open = !!(error && error.open);

		if (!open) {
			return null;
		}

		const title = (error.title) ? error.title : "Error";

		return (
			<Modal
				title={title}
				closeModal={closeModal}
				modalState={open}
				icon="fas fa-exclamation-triangle"
				fontColor={colourCfg.white}
				headBgColor={colourCfg.upwireOrange1}
			>
				<section className="modal-card-body">
					{error.content}

					{error && (
						<ErrorMessageDetails error={error} showMessage={false} showGeneric={!error.content}/>
					)}
				</section>
				<footer className="modal-card-foot">
					{error && error.primaryAction}
					<button type="button" className="button" onClick={closeModal}>Close</button>
				</footer>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(ErrorCloseModalAction())
	};
};

const mapStateToProps = (state, props) => {
	const core = state.get("core");
	if (!core) {
		return {};
	}

	return {
		error: core.get("error")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal);
