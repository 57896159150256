import React from "react";
import {Link} from "react-router-dom";
import "./NavBar.scss";


export default class NavBar extends React.PureComponent {
    render() {
        return (
            <nav className="upwire-navbar" role="navigation" aria-label="main navigation">
                <Link to="/">
                    <img className="navbar-logo" src="/images/upwire-logo.png" alt="Upwire"/>
                </Link>
            </nav>
        );
    }
}

