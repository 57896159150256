import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as Yup from "yup";
import { FieldArray, getIn } from "formik";
import { DragDropContext } from "react-beautiful-dnd";

import {
	defaultConditionGroup,
	defaultNestedConditionGroup,
	ensureBasicConditionGroupInitialised
} from "../../../../../../lib/logic/condition/group";
import { LogicTypeParam } from "../../../../../../lib/logic/type";
import { operatorValueIsRequired } from "../../../../../../lib/logic/operator";

import { formListMove, handleFormOnDrag } from "../../../../../../util/form/helper";

import LogicGroup from "../../../group/LogicGroup";
import LogicGroupAction from "../../../group/LogicGroupAction";
import { conditionOptions } from "../../../../../../lib/logic/condition";
import ConditionGroup from "./ConditionGroup";

import "./BasicConditionBuilder.scss";

export const getBasicInitialValues = (model) => {
	return (model)
		? ensureBasicConditionGroupInitialised(model)
		: defaultConditionGroup();
};

export const getBasicYupSchema = () => {
	return Yup.object().shape({
		groups: Yup.array()
			.of(
				Yup.object().shape({
					rules: Yup.array()
						.of(
							Yup.object().shape({
								key: Yup.string().test("KeyRequired", "Key is required", function () {
									if (!this.from[1] || !this.from[1].value) {
										return true;
									}

									const groupValue = this.from[1].value;
									if (!groupValue.logic_type || groupValue.logic_type === LogicTypeParam) {
										if (!this.parent.key) {
											return false;
										}
									}

									return true;
								}),
								operator: Yup.string().test("OperatorRequired", "Operator is required", function () {
									if (!this.from[1] || !this.from[1].value) {
										return true;
									}

									const groupValue = this.from[1].value;
									if (!groupValue.logic_type || groupValue.logic_type === LogicTypeParam) {
										if (!this.parent.operator) {
											return false;
										}
									}

									return true;
								}),
								value: Yup.string().test("ValueRequired", "Value is required", function () {
									if (!this.from[1] || !this.from[1].value) {
										return true;
									}

									const groupValue = this.from[1].value;
									if (!groupValue.logic_type || groupValue.logic_type === LogicTypeParam) {
										if (this.parent.operator && operatorValueIsRequired(this.parent.operator)) {
											if (!this.parent.value && this.parent.value !== 0) {
												return false;
											}
										}
									}

									return true;
								})
							})
						)
				})
			)
	});
};

class BasicConditionBuilder extends Component {
	static propTypes = {
		namePath: PropTypes.string,
		values: PropTypes.object,
		touched: PropTypes.object,
		errors: PropTypes.object,
		setFieldValue: PropTypes.func
	};

	state = {
		isDragging: false
	};

	setIsDragging = (isDragging) => {
		this.setState(() => ({
			isDragging: isDragging
		}));

		return false;
	};

	render () {
		const { namePath, values, touched, errors, setFieldValue } = this.props;
		const { isDragging } = this.state;

		const groupLabelWidth = 105;

		let model = (namePath)
			? getIn(values, namePath)
			: values;
		if (!model) {
			model = {};
		}

		const groups = (model.groups)
			? model.groups
			: [];

		let namePathPrefix = "";
		if (namePath) {
			namePathPrefix = `${namePath}.`;
		}

		const doMoveGroup = (from, to) => {
			// arrayHelpers.move is glitchy.
			formListMove(`${namePathPrefix}groups`, values, setFieldValue, from, to);
		};

		return (
			<div
				className={classNames(
					"basic-condition-builder",
					{ "is-dragging": isDragging }
				)}
			>
				<DragDropContext
					onBeforeCapture={() => { this.setIsDragging(true); }}
					onDragEnd={(source, destination) => {
						handleFormOnDrag(values, setFieldValue)(source, destination);
						this.setIsDragging(false);
					}}
				>
					<LogicGroup
						value={model.condition}
						options={conditionOptions}
						isEmpty={groups.length <= 0}
						width={groupLabelWidth}
						className={(!groups || groups.length < 2) ? "minimal" : ""}
						setFieldValue={setFieldValue}
						fieldName="condition"
					>
						<FieldArray name={`${namePathPrefix}groups`}>
							{arrayHelpers => (
								<>
									{groups.map((branch, index) => {
										return (
											<ConditionGroup
												key={"group-" + index}
												group={branch}
												index={index}
												count={groups.length}
												namePath={`${namePathPrefix}groups[${index}]`}
												labelWidth={groupLabelWidth}
												moveUp={() => {
													doMoveGroup(index, index - 1);
												}}
												moveDown={() => {
													doMoveGroup(index, index + 1);
												}}
												doDelete={() => arrayHelpers.remove(index)}
												disableDelete={groups.length <= 1}
												setFieldValue={setFieldValue}
												values={values}
												touched={touched}
												errors={errors}
											/>
										);
									})}

									<LogicGroupAction onClick={() => arrayHelpers.push(defaultNestedConditionGroup())}/>
								</>
							)}
						</FieldArray>
					</LogicGroup>
				</DragDropContext>
			</div>
		);
	}
}

export default BasicConditionBuilder;
