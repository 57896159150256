import React, { Component } from "react";
import PropTypes from "prop-types";

import config from "../../../../../config";
import { CfgColors } from "core/config";

import Card from "./Card";

import "./PlaygroundCard.scss";
import VersionLabels from "core/components/common/version/VersionLabels";
import { Link } from "react-router-dom";

export class PlaygroundCard extends Component {
	render () {
		const { playground } = this.props;

		const colours = config.get(CfgColors);

		let accentColor = colours.upwireGreen2;
		if (playground.status !== "OK") {
			accentColor = colours.upwireOrange1;
		}

		return (
			<Card accentColor={accentColor}>
				<Link to={`/playground/${playground.namespace}/${playground.playground_id}`}
					  className="playground-card">
					<div className="start">
						<div className="playground-label">
							<span>{playground.name}</span>
						</div>
						<VersionLabels versions={playground.versions}/>
					</div>
					<div className="end">
						<div className="pg-quick-stats">
							<div className="stat">
								<div className="stat-label success">0</div>
								<div className="stat-legend">
									<div>success</div>
									<div>/24h</div>
								</div>
							</div>
							<div className="stat">
								<div className="stat-label failure">0</div>
								<div className="stat-legend">
									<div>failure</div>
									<div>/24h</div>
								</div>
							</div>
						</div>
					</div>
				</Link>
			</Card>
		);
	}
}

PlaygroundCard.propTypes = {
	playground: PropTypes.object.isRequired
};

export default PlaygroundCard;
