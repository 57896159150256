import React, {useState} from "react";
import {CircularProgress, IconButton, List, ListItem, ListItemButton, ListItemIcon} from "@mui/material";
import classNames from "classnames";
import ConfirmDialog from "../confirm/ConfirmDialog";

type BigSelectorDelete = {
    onDelete: (itemId: string) => void;
    confirm?: {
        title: string;
        message: string;
    }
};

export type BigSelectorItem = {
    id: string;
    name: string | React.ReactElement;
    subtitle?: string;
    icon: string | React.ReactElement;
    inlineComponents?: React.ReactElement[];
    onActivate?: (itemId: string) => void;
    deleter?: BigSelectorDelete;
}

export type BigAction = {
    icon: string;
    onClick: () => void;
}

export type BigSelectorProps = {
    title: string;
    emptyMessage?: string | React.ReactElement;
    items?: BigSelectorItem[];
    actions?: BigAction | BigAction[];
    loading?: boolean;
    className?: string;
}

function BigSelectorListItem(item: BigSelectorItem) {

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const {deleter} = item;

    const needsConfirmation = deleter && deleter.confirm !== undefined;

    function onDeletionConfirmed(confirmed: boolean) {

        if (confirmed)
            item.deleter?.onDelete(item.id);

        setShowDeleteConfirmation(false);
    }

    function beginDeletion(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {

        if (!needsConfirmation) {
            item.deleter?.onDelete(item.id);
            return;
        }

        event.stopPropagation();
        event.nativeEvent.stopPropagation();
        setShowDeleteConfirmation(true);
    }

    function activate() {
        item.onActivate?.(item.id);
    }

    const actions: React.ReactElement[] = [];

    if (deleter) {
        actions.push(<IconButton key="delete-action-button" onClickCapture={beginDeletion}><i className="fas fa-trash"/></IconButton>);
    }

    const icon = typeof item.icon === "string" ? <i className={item.icon}/> : item.icon;

    let content = <>
        <ListItemIcon>{icon}</ListItemIcon>
        <div className="big-item-info">
            <div className="big-item-name">{item.name || "Untitled"}</div>
            {item.subtitle && <div className="big-item-subtitle">{item.subtitle}</div>}
        </div>
        <div className="spacer"/>
        {item.inlineComponents && item.inlineComponents.map((component, index) =>
            <div key={index}
                 className="inline-component">{component}
            </div>)}
        {actions.length > 0 && <div className="big-item-actions-container">
			<div className="big-item-actions">{actions}</div>
		</div>}
    </>;

    let selectable = false;
    if (item.onActivate) {
        selectable = true;
        content = <ListItemButton className="listing" onClick={activate}>{content}</ListItemButton>;
    } else {
        content = <div className="listing">{content}</div>;
    }

    return <ListItem key={item.id} className={classNames({"big-list-item": true, "selectable": selectable})}>

        {content}

        {deleter && deleter.confirm &&
			<ConfirmDialog
				open={showDeleteConfirmation}
				close={onDeletionConfirmed}
				title={deleter.confirm.title}
				message={deleter.confirm.message}/>
        }

    </ListItem>;
}

function BigSelector(props: BigSelectorProps) {

    const items = props.items;
    const isEmpty = items === undefined || items.length === 0;

    let content: React.ReactElement;
    if (props.loading) {
        content = <div className="big-selector-loading"><CircularProgress/></div>;
    } else if (isEmpty) {
        const emptyMessage = props.emptyMessage || "This section is empty";
        const isDefault = props.emptyMessage === undefined || typeof props.emptyMessage === "string";
        const classes = classNames({
            "big-selector-empty": true,
            "default": isDefault
        });

        content = <div className={classes}>{emptyMessage}</div>;
    } else {
        content = <List className="big-item-list">{items?.map(item =>
            <BigSelectorListItem key={item.id} {...item}/>)
        }</List>;
    }

    let actionSection = <div className="spacer"/>;
    if (props.actions && !props.loading) {

        let actions = props.actions;
        if (!Array.isArray(actions))
            actions = [actions];

        actionSection = <div className="actions spacer">
            {actions.map((action, index) =>
                <IconButton key={index} onClick={action.onClick}>
                    <i className={action.icon}/>
                </IconButton>)}
        </div>;
    }

    return (
        <>
            <div className={classNames("big-selector", props.className)}>

                <div className="big-selector-header">
                    <div className="spacer"/>
                    <h1>{props.title}</h1>
                    {actionSection}
                </div>

                <div className="big-selector-content">
                    {content}
                </div>

            </div>

        </>
    );
}

export default BigSelector;
