import { floorToPlaces } from "../../../../lib/math";

/**
 * source: https://bufferwall.com/posts/330881001ji1a/
 */
export const PieChart = ({
	r, cx, cy, data, colours
}) => {
	const decimals = 4;
	let total = 0;
	let offset = 0;
	let offset2;
	let x;
	let y;
	let la;
	let radians;

	const paths = [];

	for (let i = 0; i < data.length; i++) { total += data[i].value; }

	for (let i = 0; i < data.length; i++) {
		const item = data[i];

		radians = floorToPlaces(((((item.value / total) * 360) * Math.PI) / 180), decimals);
		offset2 = floorToPlaces(((offset / total) * 360), decimals);

		x = floorToPlaces((cx + Math.sin(radians) * r), decimals);
		y = floorToPlaces((cy - Math.cos(radians) * r), decimals);
		la = radians > Math.PI ? 1 : 0;

		offset += item.value;

		paths.push(
			<g
				key={"path-" + i}
				transform={`rotate(${offset2}, ${cx}, ${cy})`}
			>
				<path
					d={`M${cx} ${cy},L${cx} ${cy - r},A${r} ${r},0 ${la} 1,${x} ${y}Z`}
					fill={colours[i]}
				/>
			</g>
		);
	}

	return (
		<g>
			{paths}
		</g>
	);
};
