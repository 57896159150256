import {FormikValues} from "formik";

export type FormFieldSetter = (fieldName: string, param: any, shouldValidate?: boolean) => void
export type FormValues = FormikValues

export type FormType =
    "text"
    | "textarea"
    | "password"
    | "email"
    | "number"
    | "bool"
    | "select"
    | "sms-template-picker"
    | "email-template-picker"
    | "voice-template-picker"
    | "required"
    | "one-required"


export type FormField = {
    name: string
    label: string;
    type: FormType;
    validations?: string[];
}

export type FieldDataProvider<T> = { get: () => T | null, set: (value: T) => T };

export function getFieldDataProvider(field: FormField, setFieldValue: FormFieldSetter, values: FormValues): FieldDataProvider<any> {
    return {
        get: () => {
            const value = values[field.name] ?? null;
            if (value === "")
                return null;
            return value;
        },
        set: (value: any) => {
            setFieldValue(field.name, value, false);
            return value;
        }
    };
}


