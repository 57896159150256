import {
	CloseEditPhaseModalAction,
	OpenEditPhaseModalAction
} from "../../../../../../actions/playground/inlet/filter/canvas/phase/edit";

export const editReducer = (state = null, action) => {
	switch (action.type) {
		case OpenEditPhaseModalAction.getType():
			return { open: true, ...action.payload };
		case CloseEditPhaseModalAction.getType():
			return { open: false };
		default:
			return state;
	}
};
