import { call, put, takeLatest } from "redux-saga/effects";
import { getActivate, postActivateResend } from "../../api/auth/activate";
import {
	ActivateAction,
	ActivateFailedAction,
	ActivateRequestAction,
	ActivateSuccessAction,
	ResendActivateAction,
	ResendActivateFailedAction,
	ResendActivateRequestAction,
	ResendActivateSuccessAction
} from "../../actions/auth";

/// / Activate
// Worker
function * getActivateWorker (action) {
	try {
		yield put(ActivateRequestAction());
		const r = yield call(getActivate, action.payload);
		if (r.json) {
			yield put(ActivateSuccessAction(r.json));
		} else {
			yield put(ActivateFailedAction("Failed to activate account"));
		}
	} catch (e) {
		yield put(ActivateFailedAction(e));
	}
}

// Saga
export function * getActivateSaga () {
	yield takeLatest(ActivateAction.getType(), getActivateWorker);
}

/// / Resend Activate
// Worker
function * getResendActivateWorker (action) {
	try {
		yield put(ResendActivateRequestAction());
		const r = yield call(postActivateResend, action.payload);
		if (r.json) {
			yield put(ResendActivateSuccessAction(r.json));
		} else {
			yield put(ResendActivateFailedAction("Failed to resend activate link"));
		}
	} catch (e) {
		yield put(ResendActivateFailedAction(e));
	}
}

// Saga
export function * getResendActivateSaga () {
	yield takeLatest(ResendActivateAction.getType(), getResendActivateWorker);
}

// Export

export const activateSagas = [
	getActivateSaga(),
	getResendActivateSaga()
];

export default activateSagas;
