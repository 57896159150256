import { call, put, takeLatest } from "redux-saga/effects";

import {
	PlaygroundGroupUpdateAction,
	PlaygroundGroupUpdateFailedAction,
	PlaygroundGroupUpdateRequestAction,
	PlaygroundGroupUpdateSuccessAction
} from "../../../actions/playground/group/update";
import { postPlaygroundGroupUpdate } from "../../../api/playground/group/update";
import { PlaygroundListAction } from "../../../actions/playground/list";
/// / Playground Group Update
// Worker
function * updatePlaygroundGroupWorker (action) {
	try {
		yield put(PlaygroundGroupUpdateRequestAction());

		const r = yield call(postPlaygroundGroupUpdate, action.payload);
		if (r.json) {
			yield put(PlaygroundGroupUpdateSuccessAction(r.json));

			// Reload Playgrounds
			yield put(PlaygroundListAction());
		} else {
			yield put(PlaygroundGroupUpdateFailedAction("Failed to put playground group update"));
		}
	} catch (e) {
		yield put(PlaygroundGroupUpdateFailedAction(e));
	}
}

// Saga
export function * updatePlaygroundGroupSaga () {
	yield takeLatest(PlaygroundGroupUpdateAction.getType(), updatePlaygroundGroupWorker);
}

// Export

export const updateSagas = [
	updatePlaygroundGroupSaga()
];

export default updateSagas;
