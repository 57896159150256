import { DefaultPortModel } from "@projectstorm/react-diagrams";

export class BasePortModel extends DefaultPortModel {
	canLinkToPort (port) {
		// Do not link to self
		if (this.getParent().getOptions().id === port.getParent().getOptions().id) {
			return false;
		}

		// Only allow connections 'to' In
		if (!port.getOptions().in) {
			return false;
		}

		// Only allow one link
		if (Object.keys(port.getLinks()).length > 0) {
			return false;
		}

		return this.getOptions().in !== port.getOptions().in;
	}
}
