import React, { Component } from "react";
import { connect } from "react-redux";

import config from "../../../../../../../config";
import { CfgColors } from "core/config";
import Modal from "core/components/common/modal/Modal";
import { getNestedState } from "core/lib/state";
import {
	CloseSetTargetModalAction
} from "../../../../../../../actions/playground/inlet/filter/canvas/phase/target/set";
import SetTargetContent
	from "../../../../../../../components/playground/inlet/filter/canvas/phase/target/SetTargetContent";
import { OpenNodeNewModalAction } from "../../../../../../../actions/playground/inlet/filter/canvas/phase/node/new";
import {
	SetTargetModeCreate,
	SetTargetModeExisting,
	SetTargetModeTerminate
} from "../../../../../../../lib/playground/constants";
import {
	OpenTerminateTargetModalAction
} from "../../../../../../../actions/playground/inlet/filter/canvas/phase/target/terminate";
import {
	OpenExistingTargetModalAction
} from "../../../../../../../actions/playground/inlet/filter/canvas/phase/target/existing";
import { NodeTypeStart } from "../../../../../../../config/node";

class SetTargetModal extends Component {
	render () {
		let { closeModal, setTarget, openNewNode, openExistingTarget, openTerminateTarget } = this.props;
		if (!setTarget) {
			setTarget = {};
		}

		let { nodeProps } = setTarget;
		if (!nodeProps) {
			nodeProps = {};
		}

		const { id, linkData } = nodeProps;

		const open = !!(setTarget && setTarget.open);
		if (!open) {
			return null;
		}

		if (setTarget.loading) {
			closeModal = () => {
				// Disabled.
			};
		}

		const handleClose = () => {
			closeModal();

			if (setTarget.onClose) {
				setTarget.onClose();
			}
		};

		const newEnabled = true;
		let existingEnabled = true;
		let terminateEnabled = true;

		if (id === NodeTypeStart || linkData.firstNode) {
			existingEnabled = false;
			terminateEnabled = false;
		}

		const colourCfg = config.get(CfgColors);

		return (
			<>
				<Modal
					title="Set Target"
					closeModal={handleClose}
					icon="fas fa-crosshairs"
					modalState={open}
					headBgColor={colourCfg.green}
					fontColor={colourCfg.white}
				>
					<SetTargetContent
						closeModal={handleClose}
						newEnabled={newEnabled}
						existingEnabled={existingEnabled}
						terminateEnabled={terminateEnabled}
						openNewNode={() => {
							closeModal();
							openNewNode({
								onClose: handleClose,
								onSelect: (payload) => {
									setTarget.onSetTarget({
										mode: SetTargetModeCreate,
										payload: payload
									});
								},
								nodeProps: nodeProps
							});
						}}
						openExisting={() => {
							closeModal();
							openExistingTarget({
								onClose: handleClose,
								onSelect: (payload) => {
									setTarget.onSetTarget({
										mode: SetTargetModeExisting,
										payload: payload
									});
								},
								nodeProps: nodeProps
							});
						}}
						openTerminate={() => {
							closeModal();
							openTerminateTarget({
								onClose: handleClose,
								onSelect: (payload) => {
									setTarget.onSetTarget({
										mode: SetTargetModeTerminate,
										payload: payload
									});
								},
								nodeProps: nodeProps
							});
						}}
					/>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(CloseSetTargetModalAction()),
		openNewNode: (payload) => dispatch(OpenNodeNewModalAction(payload)),
		openExistingTarget: (payload) => dispatch(OpenExistingTargetModalAction(payload)),
		openTerminateTarget: (payload) => dispatch(OpenTerminateTargetModalAction(payload))
	};
};

const mapStateToProps = (state, props) => {
	const mapped = {};

	const core = state.get("core");
	if (core) {
		mapped.profile = core.get("profile");
	}

	const playground = getNestedState(state, ["canvas", "playground"]);
	if (!playground) {
		return mapped;
	}

	const target = getNestedState(playground, ["inlet", "filter", "canvas", "phase", "target"]);
	if (!target) {
		return mapped;
	}

	return {
		...mapped,
		setTarget: target.get("set")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SetTargetModal);
