import { call, put, takeLatest } from "redux-saga/effects";

import {
	CanvasNewAction,
	CanvasNewFailedAction,
	CanvasNewRequestAction,
	CanvasNewSuccessAction
} from "../../../actions/playground/inlet/filter/canvas/new";
import { postPlaygroundCanvasCreate } from "../../../api/playground/canvas/new";

/// / Playground Canvas New
// Worker
function * newPlaygroundCanvasWorker (action) {
	try {
		yield put(CanvasNewRequestAction());

		const r = yield call(postPlaygroundCanvasCreate, action.payload);
		if (r.json) {
			yield put(CanvasNewSuccessAction(r.json));
		} else {
			yield put(CanvasNewFailedAction("Failed to post playground create"));
		}
	} catch (e) {
		yield put(CanvasNewFailedAction(e));
	}
}

// Saga
export function * newPlaygroundCanvasSaga () {
	yield takeLatest(CanvasNewAction.getType(), newPlaygroundCanvasWorker);
}

// Export

export const newSagas = [
	newPlaygroundCanvasSaga()
];

export default newSagas;
