import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import PlaygroundScreen from "./components/layout/PlaygroundScreen";

import { CheckFetchProfileAction } from "core/actions/auth";
import { ResourcesLoadAction } from "core/actions/resources/resources";
import { LoadPreferencesAction } from "core/actions/preferences";

import "./scss/styles.scss";
import { AppContextProvider } from "./state/AppContextProvider";

export class App extends Component {

	componentDidMount () {
		this.props.loadPreferences();
		this.props.loadResources();
		this.props.fetchProfile();
	}

	render () {
		const { resources, profile } = this.props;

		return (
			<Router>
				<AppContextProvider>
					<PlaygroundScreen resources={resources} profile={profile}/>
				</AppContextProvider>
			</Router>
		);
	}
}

App.propTypes = {
	resources: PropTypes.object,
	profile: PropTypes.object
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		loadPreferences: () => dispatch(LoadPreferencesAction()),
		fetchProfile: () => dispatch(CheckFetchProfileAction()),
		loadResources: () => dispatch(ResourcesLoadAction())
	};
};

function mapStateToProps (state) {
	const core = state.get("core");
	if (!core) {
		return {};
	}

	return {
		resources: core.get("resources"),
		profile: core.get("profile")
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
