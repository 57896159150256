import React, { Component } from "react";

class ResendActivateSuccess extends Component {
	render () {
		const { closeModal, forVerify, isDialog } = this.props;

		return (
			<>
				<section className="modal-card-body">
					<article className="media">
						<div className="media-left">
              <span className="icon is-medium has-text-success">
                <i className="fas fa-2x fa-envelope"/>
              </span>
						</div>
						<div className="media-content">
							{forVerify
								? (
									<>
										<p className="title is-size-4">Verification Email Sent</p>
										<p className="msg">We've resent the email verification link to you.
											Please check your email inbox and you should have an email from us.
										</p>
									</>
								)
								: (
									<>
										<p className="title is-size-4">Activation Email Sent</p>
										<p className="msg">We've resent the activation link to you to complete your
											registration.
											Please check your email inbox and you should have an email from us.
										</p>
									</>
								)}
						</div>
					</article>
				</section>
				<footer className="modal-card-foot">
					{!isDialog
						? <button type="button" className="button is-primary" onClick={closeModal}>Ok</button>
						: ""}
				</footer>
			</>
		);
	}
}

export default ResendActivateSuccess;
