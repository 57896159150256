import Papa from "papaparse";

interface Analyzer {
    match(file: File): boolean;

    analyze(file: File): Promise<string[]>;
}

class CsvAnalyzer implements Analyzer {

    match(file: File): boolean {

        if (file.name.endsWith(".csv"))
            return true;

        if (file.type === "text/csv")
            return true;

        return false;
    }

    analyze(file: File): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            Papa.parse(file, {
                header: true,
                complete: (results: Papa.ParseResult<any>) => {
                    resolve(results.meta.fields || []);
                }, error(error: Error) {
                    reject(error);
                }
            });
        });
    }
}

export const analyzers: Analyzer[] = [
    new CsvAnalyzer()
];
