import React from "react";
import {Playground} from "../../components/playground/types";

export const PlaygroundContext = React.createContext<Playground | null>(null);

export function usePlayground(): Playground {
    const context = React.useContext(PlaygroundContext);
    if (!context) {
        throw new Error("usePlayground must be used within a PlaygroundContextProvider");
    }

    return context;
}
