import React from "react";
import PropTypes from "prop-types";
import Color from "color";

import config from "../../../../../../../../config";
import { CfgColors } from "core/config";

import Tabs, { Tab } from "core/components/common/tabs/Tabs";
import { PhaseCards } from "./phase/PhaseCards";
import { CurrentPhaseNodeCards } from "./current/CurrentPhaseNodeCards";

const colourCfg = config.get(CfgColors);

export const ExistingCanvasTabSection = ({
	canvas, isCurrentCanvas, currentPhase, currentNode,
	playgroundProps, handleSelect, handleHover, borderColor
}) => {
	return (
		<Tabs
			borderColor={borderColor}
		>
			{isCurrentCanvas && (
				<Tab
					key="current"
					id="current"
					title="Current Phase"
					isNested
				>
					<CurrentPhaseNodeCards
						canvas={canvas}
						isCurrentCanvas={isCurrentCanvas}
						currentPhase={currentPhase}
						currentNode={currentNode}
						playgroundProps={playgroundProps}
						handleSelect={handleSelect}
						handleHover={handleHover}
					/>
				</Tab>
			)}

			<Tab
				key="phases"
				id="phases"
				title={isCurrentCanvas ? "All Phases" : "Phases"}
				isNested
			>
				<PhaseCards
					canvas={canvas}
					isCurrentCanvas={isCurrentCanvas}
					currentPhase={currentPhase}
					playgroundProps={playgroundProps}
					handleSelect={handleSelect}
					handleHover={handleHover}
				/>
			</Tab>
		</Tabs>
	);
};

ExistingCanvasTabSection.propTypes = {
	canvas: PropTypes.object.isRequired,
	isCurrentCanvas: PropTypes.bool,
	currentPhase: PropTypes.object.isRequired,
	playgroundProps: PropTypes.object.isRequired,
	handleSelect: PropTypes.func.isRequired,
	handleHover: PropTypes.func.isRequired,
	borderColor: PropTypes.string.isRequired
};

export const getExistingCanvasTab = (canvas, canvasActive, currentPhase, currentNode,
	playgroundProps, handleSelect, handleHover, borderColor) => {
	const isCurrentCanvas = (canvas.canvas_id === playgroundProps.canvas.canvas_id);

	let iconColor = null;

	if (isCurrentCanvas) {
		iconColor = colourCfg.purple;
	} else if (canvasActive) {
		iconColor = colourCfg.green;
	} else {
		iconColor = colourCfg.greyDark;
	}

	const inactiveIconColor = Color(iconColor).lighten("0.15").hsl().string();

	return (
		<Tab
			key={canvas.canvas_id}
			id={canvas.canvas_id}
			title={canvas.name}
			icon="fad fa-sort-shapes-down-alt"
			iconColor={iconColor}
			inactiveIconColor={inactiveIconColor}
			maxWidth={170}
			isNested
		>
			<ExistingCanvasTabSection
				canvas={canvas}
				isCurrentCanvas={isCurrentCanvas}
				currentPhase={currentPhase}
				currentNode={currentNode}
				playgroundProps={playgroundProps}
				handleSelect={handleSelect}
				handleHover={handleHover}
				borderColor={borderColor}
			/>
		</Tab>
	);
};

export const getExistingCanvasTabs = (currentNode, playgroundProps, handleSelect, handleHover, borderColor) => {
	const { phase, dashboard } = playgroundProps;
	const { playground } = dashboard;

	const tabs = [];

	if (playground && playground.schema && playground.schema.inlets) {
		playground.schema.inlets.forEach(i => {
			if (i && i.filters) {
				i.filters.forEach(f => {
					if (f && f.result && f.result.canvas_id) {
						const canvas = dashboard.canvases[f.result.canvas_id];
						if (canvas) {
							tabs.push(getExistingCanvasTab(canvas, f.enabled, phase, currentNode,
								playgroundProps, handleSelect, handleHover, borderColor));
						}
					}
				});
			}
		});
	}

	return tabs;
};
