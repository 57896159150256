import cloneDeep from "lodash/cloneDeep";

import { defaultNodeList, getNodeTypeOptions, mergeNodeTypeOptions, NodeTypeModule } from "../../../../../config/node";
import { NamespaceUpwire } from "core/config";
import { CategoryCore, CategoryCustom } from "../../../../../config/node/category";
import { nameToCapUnderScoreSlugKey } from "core/lib/variable/keys";
import { defaultNodeBranching } from "./init";
import { BranchingOutcomeFailure, BranchingOutcomeSuccess } from "../../../constants";

export const createNodeDefinition = (opts) => {
	return {
		branching: {
			[BranchingOutcomeSuccess]: defaultNodeBranching(),
			[BranchingOutcomeFailure]: defaultNodeBranching()
		},
		settings: {},
		...opts
	};
};

export const extractCreatableNodeList = (dashboard, builtInNodes, filter) => {
	const nodeList = [];

	// Built in
	if (builtInNodes) {
		cloneDeep(builtInNodes).forEach(n => {
			const nodeProps = getNodeTypeOptions(n.type,
				mergeNodeTypeOptions(
					{
						modelType: n.type
					},
					{}
				)
			);

			nodeList.push({
				id: nameToCapUnderScoreSlugKey(n.type + "_" + nodeProps.label),
				nodeProps: nodeProps,
				definition: createNodeDefinition({
					type: n.type
				}),
				...n
			});
		});
	}

	// Modules
	if (dashboard && dashboard.modules) {
		const modules = cloneDeep(dashboard.modules);
		for (const proto in modules) {
			if (Object.prototype.hasOwnProperty.call(modules, proto)) {
				for (const namespace in modules[proto]) {
					if (Object.prototype.hasOwnProperty.call(modules[proto], namespace)) {
						for (const moduleId in modules[proto][namespace]) {
							if (Object.prototype.hasOwnProperty.call(modules[proto][namespace], moduleId)) {
								const moduleData = modules[proto][namespace][moduleId];
								const url = proto + "://" + namespace + "/" + moduleId;

								const categories = [];
								if (namespace === NamespaceUpwire) {
									categories.push(CategoryCore);
								} else {
									categories.push(CategoryCustom);
								}

								const nodeProps = getNodeTypeOptions(NodeTypeModule,
									mergeNodeTypeOptions(
										{
											type: NodeTypeModule,
											modelType: NodeTypeModule
										},
										moduleData.node_props || {}
									)
								);

								if (moduleData.name) {
									nodeProps.label = moduleData.name;
								}

								nodeList.push({
									id: nameToCapUnderScoreSlugKey(NodeTypeModule + "_" + url),
									type: NodeTypeModule,
									nodeProps: nodeProps,
									definition: createNodeDefinition({
										type: NodeTypeModule,
										module: url
									}),
									categories: categories
								});
							}
						}
					}
				}
			}
		}
	}

	return filterNodeList(nodeList, filter);
};

export const filterNodeList = (nodeList, filter) => {
	return nodeList.filter(n => {
		if (filter) {
			if (filter.term) {
				const termReStr = filter.term.replaceAll(/\s+/g, "\\s*");
				const termRe = new RegExp(termReStr, "i");

				if (n && n.nodeProps) {
					if (n.nodeProps.label) {
						if (!n.nodeProps.label.match(termRe)) {
							return false;
						}
					}
				}
			}
		}

		return true;
	});
};

export function getNodeCreationPayload (dashboard, { type, module }) {
	const list = extractCreatableNodeList(dashboard, defaultNodeList, {})
		.filter(n => n.type === type && n.definition.module === module);

	if (list.length > 0) {
		return list[0];
	}

	return null;
}
