import React, { Component } from "react";

class InlineMessage extends Component {
	render () {
		let { title, colourClass, children } = this.props;

		if (!colourClass) {
			colourClass = "is-success";
		}

		return (
			<article className={"message " + colourClass}>
				<div className="message-body">
					{title
						? <b className="message-title is-size-6">{title}</b>
						: ""}
					{children}
				</div>
			</article>
		);
	}
}

export default InlineMessage;
