import {alpha, Button, Menu, MenuItem, MenuProps, styled} from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        {...props}
    />
))(({theme}) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


export type DropOption = {
    icon: any;
    text: string;
    onSelect: () => void
}

export type DropOptionsProps = {
    text: string;
    options: DropOption[];
}

export function DropOptions(props: DropOptionsProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const openDrop = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeDrop = (option: DropOption | null = null) => {
        setAnchorEl(null);
        option?.onSelect();
    };

    const menuItems = props.options.map((option, idx) => {
        return <MenuItem key={idx} onClick={() => closeDrop(option)} disableRipple>
            {option.icon}
            {option.text}
        </MenuItem>;
    });

    return (
        <div>
            <Button
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={openDrop}
                endIcon={<KeyboardArrowDownIcon/>}
            >
                {props.text}
            </Button>

            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={() => closeDrop()}
            >{menuItems} </StyledMenu>
        </div>
    );
}
