import { call, put, takeLatest } from "redux-saga/effects";

import { putPlaygroundUpdate } from "../../api/playground/update";
import {
	PlaygroundUpdateAction,
	PlaygroundUpdateFailedAction,
	PlaygroundUpdateRequestAction,
	PlaygroundUpdateSuccessAction
} from "../../actions/playground/update";

/// / Playground Update
// Worker
function * updatePlaygroundWorker (action) {
	try {
		yield put(PlaygroundUpdateRequestAction());

		const r = yield call(putPlaygroundUpdate, action.payload);
		if (r.json) {
			yield put(PlaygroundUpdateSuccessAction(r.json));
		} else {
			yield put(PlaygroundUpdateFailedAction("Failed to put playground update"));
		}
	} catch (e) {
		yield put(PlaygroundUpdateFailedAction(e));
	}
}

// Saga
export function * editPlaygroundSaga () {
	yield takeLatest(PlaygroundUpdateAction.getType(), updatePlaygroundWorker);
}

// Export

export const editSagas = [
	editPlaygroundSaga()
];

export default editSagas;
