import React, { Component } from "react";
import { ResendActivateAction, ResendVerificationAction } from "../../../actions/auth";
import connect from "react-redux/es/connect/connect";
import Dialog from "../../../components/common/dialog/Dialog";
import ActivateErrorAndResend from "../../../components/auth/activate/ActivateErrorAndResend";
import ResendActivateSuccess from "../../../components/auth/activate/ResendActivateSuccess";

class ActivateResend extends Component {
	render () {
		let { activate, resendLink, resendVerifyLink, email } = this.props;
		if (!activate) {
			activate = {};
		}

		const resendSuccess = !!(activate.resendSuccess);
		const resendError = activate.resendError;
		const loading = !!(activate.loading);

		let { forVerify } = this.props;
		if (!forVerify) {
			forVerify = !!(activate.forVerify);
		}

		let resendAction = resendLink;
		if (forVerify) {
			resendAction = resendVerifyLink;
		}

		return (
			<Dialog title={forVerify ? "Verification" : "Activation"}>
				{resendSuccess
					? <ResendActivateSuccess forVerify={forVerify} isDialog/>
					: <ActivateErrorAndResend
						resendLink={resendAction} loading={loading} error={resendError}
						fromLogin email={email} forVerify={forVerify} isDialog
					/>}
			</Dialog>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		resendLink: (payload) => dispatch(ResendActivateAction(payload)),
		resendVerifyLink: (payload) => dispatch(ResendVerificationAction(payload))
	};
};

const mapStateToProps = (state, props) => {
	const core = state.get("core");
	if (!core) {
		return {};
	}

	return {
		activate: core.get("activate")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateResend);
