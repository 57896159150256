import { DefaultNodeModel } from "../node/DefaultNodeModel";
import { TargetNodeModel } from "../node/TargetNodeModel";
import { SmallNodeModel } from "../node/SmallNodeModel";
import { StartNodeModel } from "../node/StartNodeModel";

import {
	getNodeTypeOptions,
	mergeNodeTypeOptions,
	NodeModelTypeSmall,
	NodeModelTypeStart,
	NodeModelTypeTarget
} from "../../../../config/node";
import { extractNodeProps } from "../../../../lib/playground/canvas/phase/node/props";

const buildNodeModel = (opts) => {
	switch (opts.modelType) {
		case NodeModelTypeStart:
			return new StartNodeModel(opts);
		case NodeModelTypeTarget:
			return new TargetNodeModel(opts);
		case NodeModelTypeSmall:
			return new SmallNodeModel(opts);
		default:
			return new DefaultNodeModel(opts);
	}
};

export const buildNode = (nodeId, nodeData, parentNodeId, opts, data, playgroundProps) => {

	if (!playgroundProps) {
		throw new Error("No 'playgroundProps' given to buildNode for '" + nodeId + "'");
	}

	const modelOpts = getNodeTypeOptions(nodeData.type,
		mergeNodeTypeOptions(
			{
				id: nodeId,
				parentNodeId: parentNodeId,
				modelType: nodeData.type,
				data: data,
				playgroundProps: playgroundProps,
				nodeData: nodeData,
				...opts
			},
			extractNodeProps(nodeData, playgroundProps)
		)
	);

	// Apply data directly (to keep references).
	modelOpts.data = data;
	return buildNodeModel(modelOpts);

};


