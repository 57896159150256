import React from "react";
import PropTypes from "prop-types";
import Inlet from "./Inlet";

export function Inlets (props) {
	const { playgroundProps } = props;
	const { dashboard } = playgroundProps;
	const { playground } = dashboard;

	return (
		<>
			<div className="pg-inlets">
				{playground.schema.inlets.map((inlet, i) =>
					<Inlet
						key={`pg-inlet-${i}`} playgroundProps={playgroundProps}
						inlet={inlet} index={i} count={playground.schema.inlets.length}
					/>
				)}
			</div>
		</>
	);
}

Inlets.propTypes = {
	playgroundProps: PropTypes.object.isRequired
};

export default Inlets;
