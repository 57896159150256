import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./Loading.scss";

export interface LoadingProps {
    contained?: boolean;
}

function Loading(props: LoadingProps) {
    const {contained} = props;

    const indicator = <CircularProgress/>;

    if (contained) {
        return (
            <div className="loading-container">
                {indicator}
            </div>
        );
    }

    return indicator;
}

export default Loading;
