import {
	ClosePlaygroundSettingsModalAction,
	OpenPlaygroundSettingsModalAction
} from "../../actions/playground/settings";

export const settingsReducer = (state = null, action) => {
	switch (action.type) {
		case OpenPlaygroundSettingsModalAction.getType():
			return { open: true, ...action.payload };
		case ClosePlaygroundSettingsModalAction.getType():
			return { open: false };
		default:
			return state;
	}
};
