import React from "react";
import * as Yup from "yup";

import config from "../../../../config";
import { CfgPickerTitleBgColors } from "core/config";
import InputField from "core/components/common/form/InputField";

import "./GroupBasicFields.scss";

export const getBasicInitialValues = (model) => {
	return {
		name: model.name || "",
		color: model.color || ""
	};
};

export const getBasicYupSchema = () => {
	return {
		name: Yup.string()
			.required("Name is required"),
		color: Yup.string()
			.required("Colour is required")
	};
};

const GroupBasicFields = ({ values, ...props }) => {
	const pickerColors = config.get(CfgPickerTitleBgColors);

	return (
		<div className="pg-group-basic-fields">
			<InputField
				key="name" name="name" label="Name" values={values}
				type="text" placeholder="Group name"
				{...props}
			/>
			<InputField
				key="color" name="color" label="Color" values={values}
				type="color" placeholder="Select color" options={pickerColors}
				{...props}
			/>
		</div>
	);
};

export default GroupBasicFields;
