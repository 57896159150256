import React, {useEffect} from "react";
import {Redirect} from "react-router-dom";
import {useDispatch} from "react-redux";
import {LogoutAction} from "../../core/actions/auth";

function Logout() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(LogoutAction());
    });
    return <Redirect to="/"/>;
}

export default Logout;
