import newSagas from "./new";
import updateSagas from "./update";
import deleteSagas from "./delete";

export const groupSagas = [
	...newSagas,
	...updateSagas,
	...deleteSagas
];

export default groupSagas;
