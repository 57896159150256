import React, { Component } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import InputField from "../../common/form/InputField";
import { ErrCodeUserEmailExists } from "../../../errs";
import InlineError from "../../common/message/InlineError";
import { wrapLinkClick } from "../../../lib/link";
import RegisterSuccess from "./RegisterSuccess";
import Loading from "../../../../components/common/loading/Loading";

const RegisterFormSchema = Yup.object().shape({
	first_name: Yup.string()
		.required("First name is required."),
	last_name: Yup.string()
		.required("Last name is required."),
	email: Yup.string()
		.required("Username is required."),
	password: Yup.string()
		.required("Password is required.")
		.min(8, "Password is too short - should be 8 chars minimum.")
});

class RegisterForm extends Component {
	shouldComponentUpdate (nextProps, nextState) {
		let { register, closeModal, openLogin, isDialog } = nextProps;
		if (!register) {
			register = {};
		}

		if (register.error) {
			if (register.error.error_code && register.error.error_code === ErrCodeUserEmailExists) {
				openLogin({ username: register.email, existing: true });
				if (!isDialog) {
					closeModal();
				}
			}
		}

		return true;
	}

	render () {
		let { register, onSubmit, closeModal, openLogin, isDialog } = this.props;
		if (!register) {
			register = {};
		}
		const loading = !!(register.loading);

		if (register.success) {
			return (
				<RegisterSuccess
					closeModal={closeModal}
					isDialog={isDialog}
				/>
			);
		}

		return (
			<Formik
				onSubmit={onSubmit}
				initialValues={{
					first_name: register.first_name || "",
					last_name: register.last_name || "",
					email: register.email || "",
					password: ""
				}}
				validationSchema={RegisterFormSchema}
			>
				{({ setFieldValue, ...props }) => (
					<Form className="ed-form">
						<section className="modal-card-body">
							{loading
								? <Loading/>
								: (
									<>
										{register.error
											? (
												<InlineError error={register.error} title="Internal Error">
													<p>There was an error processing your request, please try again.</p>
												</InlineError>
											)
											: ""}

										<div className="field">
											<label className="label">Name</label>
											<div className="field-body">
												<InputField name="first_name" type="text" placeholder="First name"
															autoComplete="given-name" isInline {...props} />
												<InputField name="last_name" type="text" placeholder="Last name"
															autoComplete="family-name" isInline {...props} />
											</div>
											{(props.errors.first_name && props.touched.first_name) || (props.errors.last_name && props.touched.last_name)
												? <p className="help is-danger">Your first and last name are
													required.</p>
												: ""}
										</div>

										<InputField
											name="email" label="Email"
											type="email" placeholder="Your email" autoComplete="email" {...props}
										/>

										<InputField
											name="password" label="Password"
											type="password" placeholder="Password" autoComplete="password" {...props}
										/>

										<div className="form-hint-actions">
											<p>Already a member? <button type="button" className="button-link"
																		 onClick={wrapLinkClick(openLogin)}>Login</button>
											</p>
										</div>
									</>
								)}
						</section>
						<footer className="modal-card-foot">
							<button type="submit" className="button is-primary" disabled={loading}>Continue</button>
							{!isDialog
								? <button type="button" className="button" onClick={closeModal}>Cancel</button>
								: ""}
						</footer>
					</Form>
				)}
			</Formik>
		);
	}
}

export default RegisterForm;
