import React, { Component } from "react";

class SendResetSuccess extends Component {
	render () {
		const { closeModal, isDialog } = this.props;

		return (
			<>
				<section className="modal-card-body">
					<article className="media">
						<div className="media-left">
              <span className="icon is-medium has-text-success">
                <i className="fas fa-2x fa-envelope"/>
              </span>
						</div>
						<div className="media-content">
							<p className="title is-size-4">Reset Email Sent</p>
							<p className="msg">We've sent you a link that you can use to reset your password.
								Please check your email inbox and you should have an email from us.
							</p>
						</div>
					</article>
				</section>
				<footer className="modal-card-foot">
					{!isDialog
						? <button type="button" className="button is-primary" onClick={closeModal}>Ok</button>
						: ""}
				</footer>
			</>
		);
	}
}

export default SendResetSuccess;
