import { apiFetch } from "core/api/base";

export function * putPlaygroundCanvasUpdate ({ namespace, playgroundId, canvasId, model }) {
	return yield apiFetch({
		endpoint: `/v1/playground/${namespace}/${playgroundId}/canvas/${canvasId}`,
		method: "PUT",
		authenticated: true,
		body: JSON.stringify(model)
	});
}
