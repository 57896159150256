import { extractUpdatableCanvas } from "../../update";

export const updateNodeLayout = (dashboard, canvasId, phaseId, nodeId, layout) => {
	const model = extractUpdatableCanvas(dashboard, canvasId);
	const { canvas } = model;

	let foundPhase = false;
	for (let pi = 0; pi < canvas.schema.phases.length; pi++) {
		const phase = canvas.schema.phases[pi];
		if (phase.id !== phaseId) {
			continue;
		}

		if (!phase.layout) {
			phase.layout = {};
		}
		if (!phase.layout.models) {
			phase.layout.models = {};
		}

		if (!phase.layout.models[nodeId]) {
			phase.layout.models[nodeId] = {};
		}

		phase.layout.models[nodeId] = {
			...phase.layout.models[nodeId],
			...layout
		};

		canvas.schema.phases[pi] = phase;
		foundPhase = true;
	}

	if (!foundPhase) {
		throw new Error("failed to find phase id '" + phaseId + "' in canvas id '" + canvasId + "'");
	}

	return model;
};
