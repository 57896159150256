import config, { CfgDiagram, CfgTeleportColors } from "../../../../config";
import {
	BranchingKeys,
	BranchingOutcomeFailure,
	BranchingOutcomes,
	BranchingOutcomeSuccess,
	GotoTargetEnd
} from "../../../../lib/playground/constants";
import { haveSeenTargetAndSetSeen } from "../../../../lib/playground/canvas/phase/node/targets";
import { walkPhaseNodes } from "../../../../lib/playground/canvas/phase/node/walker";

export const TargetKeyChildren = "children";
export const TargetKeyTeleports = "teleports";
export const TargetKeyTerminals = "terminals";

export const TargetKeys = [
	TargetKeyChildren,
	TargetKeyTeleports,
	TargetKeyTerminals
];

const diagramCfg = config.get(CfgDiagram);
const teleportColors = config.get(CfgTeleportColors);

export const calculateOffsetY = (minY) => {
	const minFromEdge = (diagramCfg.gridSize * diagramCfg.minFromEdgeMultiplier);
	const minFromCenter = (diagramCfg.gridSize * diagramCfg.minFromCenterMultiplier);

	if (minY < 0) {
		const offset = ((minY * -1) + minFromEdge);
		if (offset > minFromCenter) {
			return offset;
		}
	}

	return minFromCenter;
};

export const updateStateBoundsFromNode = (state, node) => {
	const [x, y] = node.getCentrePosition();

	// Store initial position
	node.initialCentrePosition = [x, y];
	node.positionChanged = false;

	// Max
	if (y > state.maxY) {
		state.maxY = y;
	}
	if (x > state.maxX) {
		state.maxX = x;
	}

	// Min
	if (state.minY > y) {
		state.minY = y;
	}
	if (state.minX > x) {
		state.minX = x;
	}
};

export const positionChangeNodeListener = (node, playgroundProps) => {
	return {
		eventDidFire: (e) => {
			if (e.function === "positionChanged") {
				if (node.initialCentrePosition) {
					const [iX, iY] = node.initialCentrePosition;
					const [cX, cY] = e.entity.getCentrePosition();

					if (iX !== cX || iY !== cY) {
						// Mark the node (disable click actions when moving)
						node.positionChanged = true;

						// Update position
						if (playgroundProps.onNodePositionChanged) {
							playgroundProps.onNodePositionChanged(e, node);
						}
					}
				}
			}
		}
	};
};

export const calculateTargets = (opts, data) => {
	const { state } = opts;

	if (!state.targets) {
		state.targets = {};
	}
	if (!state.teleportSeq && state.teleportSeq !== 0) {
		state.teleportSeq = 0;
	}
	if (!state.teleports) {
		state.teleports = {};
	}

	walkPhaseNodes(data, (nodeId) => {
		if (!data.nodes || !data.nodes[nodeId]) {
			throw new Error("Failed to find node data for nodeId '" + nodeId + "' in data");
		}

		state.targets[nodeId] = extractTargets(data, data.nodes[nodeId], nodeId, state);

		syncTeleportTarges(state, state.targets[nodeId]);
	});
};

export const syncTeleportTarges = (state, nodeTargets) => {
	BranchingOutcomes.forEach(outcome => {
		if (nodeTargets[outcome][TargetKeyTeleports].length > 0) {
			nodeTargets[outcome][TargetKeyTeleports].forEach(t => {
				const teleportNodeId = t.goto.target;
				if (teleportNodeId && !t.goto.canvas && !t.goto.phase) {
					if (!state.teleports[teleportNodeId]) {
						state.teleports[teleportNodeId] = {};
					}

					const teleport = {
						...state.teleports[teleportNodeId],
						active: true
					};
					if (!teleport.color) {
						teleport.color = teleportColors[state.teleportSeq];

						state.teleportSeq++;
					}

					state.teleports[teleportNodeId] = teleport;
				}
			});
		}
	});
};

export const extractTargets = (phase, nodeData, nodeId, state) => {
	const targets = {
		[BranchingOutcomeSuccess]: {
			[TargetKeyChildren]: [],
			[TargetKeyTeleports]: [],
			[TargetKeyTerminals]: []
		},
		[BranchingOutcomeFailure]: {
			[TargetKeyChildren]: [],
			[TargetKeyTeleports]: [],
			[TargetKeyTerminals]: []
		}
	};

	if (nodeData && nodeData.branching) {
		const branching = nodeData.branching;

		BranchingOutcomes.forEach(outcome => {
			const outcomeSeenTargets = {};

			if (branching[outcome]) {
				BranchingKeys.forEach(branchingKey => {
					if (branching[outcome][branchingKey]) {
						const branch = branching[outcome][branchingKey];

						if (branch && branch.length > 0) {
							const curBranching = extractBranchingTargets(phase, nodeId, state, branch, outcomeSeenTargets);

							if (curBranching) {
								TargetKeys.forEach(k => {
									if (curBranching[k] && curBranching[k].length > 0) {
										if (targets[outcome][k].length > 0) {
											targets[outcome][k] = [
												...targets[outcome][k],
												...curBranching[k]
											];
										} else {
											targets[outcome][k] = curBranching[k];
										}
									}
								});
							}
						}
					}
				});
			}
		});
	}

	return targets;
};

export const extractBranchingTargets = (phase, nodeId, state, branching, seenTargets) => {
	if (!seenTargets) {
		seenTargets = {};
	}

	const targets = {
		[TargetKeyChildren]: [],
		[TargetKeyTeleports]: [],
		[TargetKeyTerminals]: []
	};

	for (const branch of branching) {
		if (branch.goto) {
			if (!haveSeenTargetAndSetSeen(branch.goto, seenTargets)) {
				if (branch.goto.target === GotoTargetEnd) {
					targets[TargetKeyTerminals].push(branch);
				} else if (isTargetTeleport(state, branch, phase, nodeId)) {
					targets[TargetKeyTeleports].push(branch);
				} else {
					targets[TargetKeyChildren].push(branch);
				}
			}
		}

		if (branch.branching) {
			const descendant = extractBranchingTargets(phase, nodeId, state, branch.branching, seenTargets);

			if (descendant) {
				TargetKeys.forEach(k => {
					if (descendant[k].length > 0) {
						targets[k].push(...descendant[k]);
					}
				});
			}
		}
	}

	return targets;
};

export const isTargetTeleport = (state, branch, phase, parentNodeId) => {
	const { targets } = state;

	if (branch.goto.canvas || branch.goto.phase) {
		return true;
	}

	if (branch.goto.target) {
		if (targets[branch.goto.target]) {
			return true;
		}

		const node = phase.nodes[branch.goto.target];
		if (node && node.parent) {
			if (node.parent !== parentNodeId) {
				return true;
			}
		}
	}

	return false;
};
