import React, { Component } from "react";
import PropTypes from "prop-types";

import Group from "./Group";

import "./Groups.scss";

export class Groups extends Component {
	render () {
		const {
			groups, openNewPlaygroundModal, openEditPlaygroundGroupModal, deletePlaygroundGroup,
			preferences, setPreference
		} = this.props;

		if (!groups || groups.length < 1) {
			return "";
		}

		return (
			<div className="pg-groups">
				{groups.map((group, i) =>
					<Group
						key={`pg-group-${i}`} group={group}
						index={i} count={groups.length}
						openNewPlaygroundModal={openNewPlaygroundModal}
						openEditPlaygroundGroupModal={openEditPlaygroundGroupModal}
						deletePlaygroundGroup={deletePlaygroundGroup}
						preferences={preferences}
						setPreference={setPreference}
					/>
				)}
			</div>
		);
	}
}

Groups.propTypes = {
	groups: PropTypes.array.isRequired,
	openNewPlaygroundModal: PropTypes.func.isRequired,
	openEditPlaygroundGroupModal: PropTypes.func.isRequired,
	deletePlaygroundGroup: PropTypes.func.isRequired,
	preferences: PropTypes.object.isRequired,
	setPreference: PropTypes.func.isRequired
};

export default Groups;
