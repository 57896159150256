export const OperatorTypeAbsent = "!";
export const OperatorTypePresent = "!!";
export const OperatorTypeEq = "==";
export const OperatorTypeNotEq = "!=";
export const OperatorTypeGreaterThan = ">";
export const OperatorTypeGreaterThanEq = ">=";
export const OperatorTypeLessThan = "<";
export const OperatorTypeLessThanEq = "<=";

export const OperatorTypeComparisonOptions = [
	{ value: OperatorTypeEq, label: OperatorTypeEq, title: "Equals" },
	{ value: OperatorTypeNotEq, label: OperatorTypeNotEq, title: "Not Equals" },
	{ value: OperatorTypeGreaterThan, label: OperatorTypeGreaterThan, title: "Greater Than" },
	{ value: OperatorTypeGreaterThanEq, label: OperatorTypeGreaterThanEq, title: "Greater Than Equals" },
	{ value: OperatorTypeLessThan, label: OperatorTypeLessThan, title: "Less Than" },
	{ value: OperatorTypeLessThanEq, label: OperatorTypeLessThanEq, title: "Less Than Equals" }
];

export const OperatorTypeOptions = [
	{ value: OperatorTypeAbsent, label: OperatorTypeAbsent, title: "Absent" },
	{ value: OperatorTypePresent, label: OperatorTypePresent, title: "Present" },
	...OperatorTypeComparisonOptions
];

export const operatorValueIsRequired = (operator) => {
	switch (operator) {
		case OperatorTypeAbsent:
		case OperatorTypePresent:
			return false;
		default:
			return true;
	}
};
