import { mergeNodeTypeOptions, NodeTypeModule } from "../../../../../config/node";
import { extractModuleData } from "core/lib/module/data";

export const extractNodeProps = (nodeData, playgroundProps) => {
	const { props } = nodeData;
	const { dashboard } = playgroundProps;

	// Extract module.
	let moduleData = {};
	if (NodeTypeModule === nodeData.type && dashboard.modules) {
		moduleData = extractModuleData(nodeData.module, dashboard.modules);
	}

	return {
		...mergeNodeTypeOptions(moduleData.node_props || {}, props),
		moduleData: moduleData,
		nodeData: nodeData
	};
};
