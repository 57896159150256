export const nameToCapUnderScoreSlugKey = (name) => {
	if (name) {
		// Strip everything but alphanumeric
		name = name.replaceAll(/[^A-Za-z0-9_ ]/g, " ");

		// Reduce multiple spaces to one
		name = name.replaceAll(/\s+/g, " ");

		// Replace spaces with _
		name = name.replaceAll(/\s+/g, "_");

		// Replace _ prefixes and suffixes
		name = name.replaceAll(/^[_]/g, "");
		name = name.replaceAll(/[_]$/g, "");

		// Uppercase
		name = name.toUpperCase();
	}

	return name;
};

export const nameToLowerDashedSlugKey = (name) => {
	if (name) {
		// Strip everything but alphanumeric
		name = name.replaceAll(/[^A-Za-z0-9_ ]/g, " ");

		// Reduce multiple spaces to one
		name = name.replaceAll(/\s+/g, " ");

		// Replace spaces with -
		name = name.replaceAll(/\s+/g, "-");

		// Replace _ prefixes and suffixes
		name = name.replaceAll(/^[-]/g, "");
		name = name.replaceAll(/[-]$/g, "");

		// Uppercase
		name = name.toLowerCase();
	}

	return name;
};

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};
