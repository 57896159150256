import {
	CloseExistingTargetModalAction,
	OpenExistingTargetModalAction
} from "../../../../../../../actions/playground/inlet/filter/canvas/phase/target/existing";

export const existingReducer = (state = null, action) => {
	switch (action.type) {
		case OpenExistingTargetModalAction.getType():
			return { open: true, ...action.payload };
		case CloseExistingTargetModalAction.getType():
			return { open: false };
		default:
			return state;
	}
};
