import React, { Component } from "react";

class ProcessResetSuccess extends Component {
	render () {
		const { closeModal, isDialog } = this.props;

		return (
			<>
				<section className="modal-card-body">
					<article className="media">
						<div className="media-left">
              <span className="icon is-medium has-text-success">
                <i className="fas fa-2x fa-check-circle"/>
              </span>
						</div>
						<div className="media-content">
							<p className="title is-size-4">Password Changed</p>
							<p className="msg">Your password has now been changed and we've also logged you in.</p>
						</div>
					</article>
				</section>
				<footer className="modal-card-foot">
					{!isDialog
						? <button type="button" className="button is-primary" onClick={closeModal}>Ok</button>
						: ""}
				</footer>
			</>
		);
	}
}

export default ProcessResetSuccess;
