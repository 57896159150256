import React from "react";
import BigSelector, {BigSelectorItem} from "../../components/common/BigSelector/BigSelector";
import {useHistory} from "react-router-dom";
import {useProfile} from "../../state/UserContextProvider";


export function AccountSettings() {

    const history = useHistory();
    const profile = useProfile();

    const username = profile.claims.username;

    const items: BigSelectorItem[] = [
        {
            name: "Variable Contexts",
            id: "accountEnvironments",
            icon: "fas fa-cubes",
            onActivate: () => history.push("/account/contexts")
        },
        {
            name: "Prototypes",
            id: "prototypes",
            icon: "fas fa-puzzle-piece",
            onActivate: () => history.push("/prototypes")
        },
        {
            name: "Payment and Billing",
            id: "payment",
            icon: "fas fa-credit-card",
        },
        {
            name: "Support",
            id: "support",
            icon: "fas fa-life-ring",
        },
        {
            name: "Logout",
            id: "logout",
            icon: <i className="warning fas fa-sign-out-alt"/>,
            onActivate: () => {
                history.push("/logout");
            },
        }
    ];


    return (
        <>
            <BigSelector title={username + " Account Settings"} items={items}/>
        </>
    );
}

export default AccountSettings;
