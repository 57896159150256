import { ArrowLinkFactory } from "./ArrowLinkFactory";
import { FailureArrowLinkModel } from "./FailureArrowLinkModel";

export class FailureArrowLinkFactory extends ArrowLinkFactory {
	constructor () {
		super("failure");
	}

	generateModel () {
		return new FailureArrowLinkModel();
	}
}
