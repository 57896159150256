import {Reducer} from "react";
import _ from "lodash";

export function makeAutoReducer<T>(): Reducer<T, Partial<T>> {
    return (state, action) => {
        const join = {...state, ...action};
        if (_.isEqual(state, join))
            return state; // return stable reference if possible
        return join;
    };
}
