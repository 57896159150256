import * as dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export function fromNow(timestamp: number | string | Date) {
    if (typeof timestamp === "string") {

        return dayjs.default(new Date(timestamp)).fromNow();
    }

    if (timestamp instanceof Date) {
        return dayjs.default(timestamp).fromNow();
    }

    return dayjs.unix(timestamp / 1000).fromNow();
}
