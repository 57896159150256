import React from "react";
import classNames from "classnames";
import Color from "color";

import config from "../../../../../config";
import { CfgColors, OptIconHoverPadPercent, OptIconPadPercent } from "core/config";

import { GotoScopeCanvas, GotoScopePhase, GotoScopes } from "../../../../../lib/playground/constants";
import { getNodeTypeOptions, NodeTypeEnd, NodeTypePhase } from "../../../../../config/node";
import { initNodeProps, NodeStyleCircle } from "../../../../../lib/common/diagram/node";
import { initStyleProps } from "../../../../../lib/common/diagram/style";
import ShapeButtonSvgElem from "../../../svg/elem/ShapeButtonSvgElem";
import { calculateOrbit } from "core/lib/math/circle";
import IconSvgElem from "../../../svg/elem/IconSvgElem";
import { PieChart } from "core/components/common/chart/pie/PieChart";

import "./PortTargetsFacet.scss";

const OrbiterTypeTeleport = "teleport";
const OrbiterTypeTerminal = "terminal";

const colorCfg = config.get(CfgColors);

const OrbiterBadge = ({
	type, nodeType, scope, seq, count, offsetX, offsetY, radius, setTargetNodeProps
}) => {
	let startingRadial = null;
	let iconSvgSprite = null;

	switch (type) {
		case OrbiterTypeTeleport:
			startingRadial = 0.75;
			iconSvgSprite = "fas-play";
			break;
		case OrbiterTypeTerminal:
			startingRadial = 0.25;
			iconSvgSprite = "fas-stop";
			break;
		default:
			throw new Error("unknown orbiter type: " + type);
	}

	let nodeProps = getNodeTypeOptions(nodeType,
		{
			style: NodeStyleCircle,
			shapeSize: 10,
			shapePad: 0,
			[OptIconPadPercent]: 22,
			[OptIconHoverPadPercent]: 22,
			iconSvgSprite: iconSvgSprite,
			fillHover: colorCfg.grey2
		}
	);

	if (count <= 2) {
		nodeProps.shapeSize += 2;
	}

	if (nodeProps.scopes && nodeProps.scopes[scope]) {
		nodeProps = {
			...nodeProps,
			...nodeProps.scopes[scope]
		};
	}

	const shapeProps = initNodeProps(
		initStyleProps({
			...nodeProps
		})
	);

	const padding = 2;
	const adjustedRadius = radius + padding;

	const calcOffsetX = offsetX - (nodeProps.shapeSize / 2);
	const calcOffsetY = offsetY - (nodeProps.shapeSize / 2);

	// 0.75, 0.25 below refer to percentage of the circle (1 being 100%)

	const [calcX, calcY] = calculateOrbit(adjustedRadius, calcOffsetX, calcOffsetY, count, seq,
		0.25, startingRadial);

	return (
		<ShapeButtonSvgElem
			style={shapeProps.style}
			size={shapeProps.shapeSize}
			padding={shapeProps.shapePad}
			fill={shapeProps.fillHover}
			iconFill={shapeProps.fill}
			iconPad={shapeProps.iconPad}
			iconHoverPad={shapeProps.iconHoverPad}
			iconSvg={shapeProps.iconSvg}
			iconSvgSprite={shapeProps.iconSvgSprite}
			stroke="#AAA"
			strokeWidth={0.5}
			offsetX={calcX}
			offsetY={calcY}
			disableHover
			disableDropShadow={shapeProps.disableDropShadow}
		/>
	);
};

const TeleportOrbiterBadges = ({
	targets, offsetX, offsetY, radius, setTargetNodeProps
}) => {
	if (!targets || targets.length < 1) {
		return "";
	}

	const activeScopes = {};

	targets.forEach(t => {
		if (t && t.goto) {
			if (t.goto.target) {
				return true;
			}

			if (t.goto.canvas) {
				activeScopes[GotoScopeCanvas] = true;
			} else if (t.goto.phase) {
				activeScopes[GotoScopePhase] = true;
			}
		}
	});

	const totalScopes = Object.keys(activeScopes).length;

	let childSeq = 0;
	const children = [];

	GotoScopes.forEach((s, si) => {
		if (activeScopes[s]) {
			children.push(
				<OrbiterBadge
					key={s}
					scope={s}
					type={OrbiterTypeTeleport}
					nodeType={NodeTypePhase}
					seq={childSeq++}
					count={totalScopes}
					offsetX={offsetX}
					offsetY={offsetY}
					radius={radius}
					setTargetNodeProps={setTargetNodeProps}
				/>
			);
		}
	});

	return children;
};

const TerminalOrbiterBadges = ({
	targets, offsetX, offsetY, radius, setTargetNodeProps
}) => {
	if (!targets || targets.length < 1) {
		return "";
	}

	const activeScopes = {};

	targets.forEach(t => {
		if (t && t.goto && t.goto.scope) {
			activeScopes[t.goto.scope] = true;
		}
	});

	const totalScopes = Object.keys(activeScopes).length;

	let childSeq = 0;
	const children = [];

	GotoScopes.forEach((s, si) => {
		if (activeScopes[s]) {
			children.push(
				<OrbiterBadge
					key={s}
					scope={s}
					type={OrbiterTypeTerminal}
					nodeType={NodeTypeEnd}
					seq={childSeq++}
					count={totalScopes}
					offsetX={offsetX}
					offsetY={offsetY}
					radius={radius}
					setTargetNodeProps={setTargetNodeProps}
				/>
			);
		}
	});

	return children;
};

const TeleportDonutChart = ({
	type, teleports, targets, radius, offsetX, offsetY, setTargetNodeProps
}) => {
	const iconPad = (radius * 2) * 0.2;
	const iconSize = radius * 2 - iconPad;
	const emptyPad = (radius * 2) * 0.4;
	const emptySize = radius * 2 - emptyPad;

	const colours = [];
	const data = [];

	let hasTeleport = true;

	if (targets && targets.length > 0) {
		targets.forEach(t => {
			if (!t.goto.canvas && !t.goto.phase) {
				if (t.goto.target) {
					const targetNodeId = t.goto.target;
					const targetTeleport = teleports[targetNodeId];

					if (targetTeleport) {
						colours.push(targetTeleport.color);
					}
				}
			}
		});
	}

	if (colours.length < 1) {
		colours.push(
			Color(setTargetNodeProps.fillHover).lighten("0.75").hsl().string()
		);

		hasTeleport = false;
	}

	colours.forEach(() => {
		data.push({ value: 100 / colours.length });
	});

	return (
		<g>
			<PieChart
				cx={offsetX}
				cy={offsetY}
				r={radius}
				data={data}
				colours={colours}
			/>

			{hasTeleport
				? (
					<IconSvgElem
						fill="white"
						width={iconSize}
						x={offsetX - iconSize / 2}
						y={offsetY - iconSize / 2}
						svgSprite="fad-dot-circle"
					/>
				)
				: (
					<circle
						r={emptySize / 2}
						cx={offsetX}
						cy={offsetY}
						fill="white"
					/>
				)}
		</g>
	);
};

export const PortTargetsFacet = ({
	type, teleports, targets, setTargetNodeProps, size, offsetX, offsetY, isActive, setIsActive
}) => {
	if (!targets || (targets.teleports.length < 1 &&
		targets.terminals.length < 1)) {
		return "";
	}

	return (
		<g
			className={classNames(
				"port-targets-facet",
				{ "is-active": isActive }
			)}
			onMouseOver={() => setIsActive(true)}
			onMouseLeave={() => setIsActive(false)}
		>
			<circle
				className="port-targets-circle"
				r={size / 2} cx={(size / 2) + offsetX} cy={(size / 2) + offsetY}
				fill="white" stroke={setTargetNodeProps.fillHover} strokeWidth={2}
				filter="url(#drop-shadow)"
			/>

			<TeleportDonutChart
				type={type}
				teleports={teleports}
				targets={targets.teleports}
				offsetX={offsetX + (size / 2)}
				offsetY={offsetY + (size / 2)}
				radius={(size / 2)}
				setTargetNodeProps={setTargetNodeProps}
			/>

			<TeleportOrbiterBadges
				targets={targets.teleports}
				offsetX={offsetX + (size / 2)}
				offsetY={offsetY + (size / 2)}
				radius={size / 2}
				setTargetNodeProps={setTargetNodeProps}
			/>

			<TerminalOrbiterBadges
				targets={targets.terminals}
				offsetX={offsetX + (size / 2)}
				offsetY={offsetY + (size / 2)}
				radius={size / 2}
				setTargetNodeProps={setTargetNodeProps}
			/>
		</g>
	);
};
