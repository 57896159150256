// placementRadials: number from 0 to 1 (percent) to represent how much of the circle to place the objects around.
// startingRadial: number from 0 to 1 (percent) to represent how far around the circle to start.
export const calculateOrbit = (radius, offsetX, offsetY, count, seq, placementRadials, startingRadial) => {
	let radialPos = 0;
	if (count === 1) {
		radialPos = (0.25 / 2) + startingRadial;
	} else {
		radialPos = ((0.25 / (count - 1)) * (seq)) + startingRadial;
	}

	const calcX = offsetX + radius * Math.cos(2 * Math.PI * radialPos);
	const calcY = offsetY + radius * Math.sin(2 * Math.PI * radialPos);

	return [calcX, calcY];
};
