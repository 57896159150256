export default class CompletableDeferred<T> {
    readonly resolve: (t?: T) => void;
    readonly reject: (t?: T) => void;

    readonly promise: Promise<T>;

    constructor() {
        let resolverG: any = null;
        let rejectG: any = null;

        this.promise = new Promise<T>((resolve, reject) => {
            resolverG = resolve;
            rejectG = reject;
        });

        this.resolve = (value) => {
            this._resolved = true;
            resolverG(value);
        };

        this.reject = rejectG;
    }

    private _resolved = false;

    get resolved() {
        return this._resolved;
    }
}
