import { PortModelAlignment } from "@projectstorm/react-diagrams";
import { SuccessPortModel } from "../port/SuccessPortModel";
import { BaseNodeModel } from "./BaseNodeModel";
import config from "../../../../config";
import { CfgColors } from "core/config";

export class StartNodeModel extends BaseNodeModel {
	constructor (opts) {
		const colorsCfg = config.get(CfgColors);

		if (!opts) {
			opts = {};
		}
		if (!opts.size) {
			opts.size = 34;
		}
		if (!opts.fill) {
			opts.fill = colorsCfg.green;
		}

		super({
			type: "start",
			...opts
		});
		this.addPort(new SuccessPortModel(PortModelAlignment.RIGHT));
	}
}
