import React, { Component } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import cloneDeep from "lodash/cloneDeep";

import BasicConditionBuilder, { getBasicInitialValues, getBasicYupSchema } from "./basic/BasicConditionBuilder";
import { modifyConditionGroupTempIds } from "../../../../../lib/logic/condition/group";

export const BuilderTypeSimple = "SIMPLE";

export const getConditionBuilderYupSchema = (builderType) => {
	switch (builderType) {
		case BuilderTypeSimple:
			return getBasicYupSchema();
		default:
			return Yup.object().shape();
	}
};

export const getConditionBuilderInitialValues = (builderType, model) => {
	// Clone first.
	model = cloneDeep(model);

	switch (builderType) {
		case BuilderTypeSimple:
			return modifyConditionGroupTempIds(
				getBasicInitialValues(model), false);
		default:
			return model;
	}
};

export const extractConditionBuilderForm = (builderType, form) => {
	// Clone first.
	form = cloneDeep(form);

	switch (builderType) {
		case BuilderTypeSimple:
			return modifyConditionGroupTempIds(form, true);
		default:
			return form;
	}
};

class LogicConditionBuilderFields extends Component {
	static propTypes = {
		builderType: PropTypes.string.isRequired,
		setBuilderType: PropTypes.func.isRequired,
		namePath: PropTypes.string,
		borderColor: PropTypes.string,
		setFieldValue: PropTypes.func.isRequired,
		values: PropTypes.object,
		touched: PropTypes.object,
		errors: PropTypes.object
	};

	render () {
		let { builderType, setBuilderType, namePath, ...rest } = this.props;
		if (!namePath) {
			namePath = "";
		}

		return (<BasicConditionBuilder namePath={namePath} {...rest} />);
	}
}

export default LogicConditionBuilderFields;
