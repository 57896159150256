// Environments

import { SelectInputGroup } from "../components/common/form/SelectInput";
import { useIsRoot, useIsSuper } from "../../state/UserContextProvider";

export const EnvironmentTest = "test";
export const EnvironmentProd = "prod";

export const EnvironmentList = [EnvironmentTest, EnvironmentProd];
export const EnvironmentLabel = {
	[EnvironmentTest]: "Test",
	[EnvironmentProd]: "Production"
};

export const environmentQueryToOptions = (environments) => {
	const opts = [];

	if (!environments) {
		environments = {};
	}

	const seenNames = {};

	EnvironmentList.forEach(env => {
		opts.push({
			label: EnvironmentLabel[env].toUpperCase(),
			value: SelectInputGroup
		});

		for (const curPath of Object.values(environments)) {
			if (!curPath[env]) {
				continue;
			}

			const curEnv = curPath[env];

			for (const curGrp of Object.values(curEnv)) {
				const { name } = curGrp;

				if (seenNames[name]) {
					continue;
				}
				seenNames[name] = true;

				opts.push({
					label: name, value: name
				});
			}
		}
	});

	return opts;
};

// Environment Scopes

export const EnvironmentScopeNamespace = "@namespace";
export const EnvironmentScopeAccount = "@account";

export function useScopeOptions () {

	const options = [
		{ label: "Account", value: EnvironmentScopeAccount }
	];

	const isRoot = useIsRoot();
	const isSuper = useIsSuper();

	if (isRoot || isSuper) {
		// options.push({ label: "Namespace", value: EnvironmentScopeNamespace });
	}

	return options;
}
