import { DefaultLinkFactory } from "@projectstorm/react-diagrams";

export class CanvasInitFactory extends DefaultLinkFactory {
	constructor () {
		super("canvas-init");
	}

	generateReactWidget (event) {

	}
}
