import { apiFetch } from "../base";

export function * postRegister (payload) {
	return yield apiFetch({
		endpoint: "/register",
		method: "POST",
		authenticated: false,
		body: JSON.stringify(payload)
	});
}
