import React, { Component } from "react";
import { Field } from "formik";
import { ColorPicker } from "./color-input/ColorInput";
import classNames from "classnames";
import { isEmpty } from "underscore";
import { isFirefox, isIE } from "react-device-detect";

import SelectInput from "./SelectInput";
import AutoCompleteField from "./AutoCompleteField";
import { extractNestedProp } from "../../../util/form/helper";

import "./InputField.scss";

export const BoolIcon = ({ value, color, onClick, editDisabled }) => {
	return value
		? (
			<span
				key="true"
				className={classNames(
					"icon", "is-medium",
					{ "has-text-success": isEmpty(color) },
					{ "bool-input-action": !editDisabled && onClick },
					{ "disabled-cursor": editDisabled }
				)}
				style={{ color: color }}
				onClick={onClick}
			>
        <i className="fas fa-lg fa-check-circle"/>
      </span>
		)
		: (
			<span
				key="false"
				className={classNames(
					"icon",
					"is-medium",
					"has-text-grey-lighter",
					{ "bool-input-action": !editDisabled && onClick },
					{ "disabled-cursor": editDisabled }
				)}
				onClick={onClick}
			>
        <i className="fas fa-lg fa-circle"/>
      </span>
		);
};

export const RawField = ({
	prefixName, name, type, fieldType, placeholder, hasError, options, doSearch, searchState,
	renderValue, values, autoComplete, disabled, isSmall, onChange, children
}) => {
	if (children) {
		return React.cloneElement(children, {
			className: "input " + (hasError ? "is-danger" : ""),
			id: name,
			name: name,
			type: fieldType,
			placeholder: placeholder
		});
	}

	let fullName = name;
	if (prefixName) {
		fullName = `${prefixName}.${name}`;
	}

	if (type === "select") {
		return (
			<SelectInput
				className={"" + (hasError ? "is-danger " : "")}
				name={fullName} options={options} autoComplete={autoComplete}
				values={values} onChange={onChange} disabled={disabled} isSmall={isSmall}
			/>
		);
	} else if (type === "autocomplete") {
		return (
			<Field name={fullName} type={fieldType}>
				{({ field, form, ...props }) => {
					const { setFieldValue } = form;
					return (
						<AutoCompleteField
							options={options}
							doSearch={doSearch}
							searchState={searchState}
							hasError={hasError}
							placeholder={placeholder}
							renderValue={renderValue}
							field={field}
							disabled={disabled}
							onChange={(value) => setFieldValue(fullName, value, false)}
						/>
					);
				}}
			</Field>
		);
	} else if (type === "bool") {
		return (
			<Field name={fullName} type={fieldType} disabled={disabled}>
				{({ field, form, ...props }) => {
					const { setFieldValue } = form;
					const toggleChoice = () => {
						if (disabled) {
							return;
						}

						if (!field.value) {
							setFieldValue(fullName, true, false);
						} else {
							setFieldValue(fullName, false, false);
						}
					};

					return (
						<div
							key={fullName + "-bool-" + (field.value ? "-checked" : "")}
							className={classNames("bool-input", { disabled: disabled })}
							onClick={toggleChoice}
						>
							<BoolIcon value={field.value}/>
						</div>
					);
				}}
			</Field>
		);
	} else if (type === "textarea") {
		return (
			<Field name={fullName} type={fieldType}>
				{({ field, form, ...props }) => {
					const { setFieldValue } = form;

					return (
						<textarea
							name={fullName} className={"input " + (hasError ? "is-danger" : "")}
							value={field.value} placeholder={placeholder} disabled={disabled}
							onChange={(e) => setFieldValue(fullName, e.target.value, false)}
						/>
					);
				}}
			</Field>
		);
	} else if (type === "color") {
		return (
			<Field name={fullName} type={fieldType}>
				{({ field, form, ...props }) => {
					const { setFieldValue } = form;
					return (
						<ColorPicker
							color={field.value}
							presetColors={options}
							onClear={() => setFieldValue(fullName, "", false)}
							onChange={(val) => setFieldValue(fullName, val.hex, false)}
						/>
					);
				}}
			</Field>
		);
	} else if (type === "secret") {
		if (fieldType !== "text") {
			if (isFirefox || isIE) {
				fieldType = "password";
			} else {
				fieldType = "text";
			}

			return (
				<Field
					className={"input is-secret " + (hasError ? "is-danger " : "") + ((isSmall) ? "is-small " : "")}
					name={fullName} type={fieldType}
					disabled={disabled} placeholder={placeholder} autoComplete={autoComplete}
				/>
			);
		}
	}

	return (
		<Field
			className={"input " + (hasError ? "is-danger " : "") + ((isSmall) ? "is-small " : "")}
			name={fullName} type={fieldType}
			disabled={disabled} placeholder={placeholder} autoComplete={autoComplete}
		/>
	);
};

const InputRightIcon = ({ type, hasError, verified, unmask, toggleUnmask }) => {
	if (type === "password" || type === "secret") {
		return (
			<>
        <span
			className={"icon is-small is-right password-unmask " + (unmask ? "hidden" : "")}
			onClick={toggleUnmask}
		>
          <i className="fas fa-eye"/>
        </span>
				<span
					className={"icon is-small is-right password-unmask " + (!unmask ? "hidden" : "")}
					onClick={toggleUnmask}
				>
          <i className="fas fa-eye-slash"/>
        </span>
			</>
		);
	}

	if (hasError) {
		return (
			<span className="icon is-small is-right">
        <i className="fas fa-exclamation-triangle"/>
      </span>
		);
	}

	if (verified !== undefined) {
		if (verified) {
			return (
				<span className="icon is-small is-right allow-pointer tooltip has-text-success" data-tooltip="Verified">
          <i className="fas fa-check-circle"/>
        </span>
			);
		} else {
			return (
				<span
					className="icon is-small is-right allow-pointer tooltip has-text-warning"
					data-tooltip="Pending verification"
				>
          <i className="fas fa-clock"/>
        </span>
			);
		}
	}

	return "";
};

const InputLayoutVertical = ({
	prefixName, name, type, fieldType, label, placeholder, controlClasses, hasError, errors,
	leftIcon, verified, unmask, toggleUnmask, isNarrow, isInline, noRightIcon, options, values,
	autoComplete, doSearch, searchState, renderValue, disabled, onChange, children
}) => {
	return (
		<div className={"field " + (isNarrow ? "is-narrow" : "")}>
			{label
				? <label className="label">{label}</label>
				: ""}
			<div className={controlClasses}>
				<RawField
					prefixName={prefixName} name={name} type={type} fieldType={fieldType}
					placeholder={placeholder} hasError={hasError} options={options} doSearch={doSearch}
					searchState={searchState} renderValue={renderValue} values={values} autoComplete={autoComplete}
					onChange={onChange} disabled={disabled}
				>
					{children}
				</RawField>
				{leftIcon
					? (
						<span className="icon is-small is-left">
              <i className={"fas " + leftIcon}/>
            </span>
					)
					: ""}
				{!noRightIcon
					? <InputRightIcon
						type={type}
						hasError={hasError}
						verified={verified}
						unmask={unmask}
						toggleUnmask={toggleUnmask}
					/>
					: ""}
			</div>

			{!isInline && hasError
				? <p className="help is-danger">{extractNestedProp(errors, name, prefixName)}</p>
				: ""}
		</div>
	);
};

const InputLayoutHorizontal = ({
	prefixName, name, type, fieldType, label, placeholder, controlClasses,
	values, hasError, errors, leftIcon, verified, unmask, toggleUnmask, options,
	isNarrow, isInline, isSimplified, noRightIcon, autoComplete, doSearch, searchState, renderValue,
	disabled, onChange, children
}) => {
	return (
		<div className={"field is-horizontal " + ((isSimplified) ? "is-simplified " : "")}>
			{label
				? (
					<div className="field-label is-normal">
						<label className="label">{label}</label>
					</div>
				)
				: ""}
			<div className="field-body">
				<div className={"field " + (isNarrow ? "is-narrow " : "")}>
					<div className={controlClasses}>
						<RawField
							prefixName={prefixName} name={name} type={type} fieldType={fieldType}
							placeholder={placeholder} hasError={hasError} options={options} doSearch={doSearch}
							searchState={searchState} renderValue={renderValue} values={values}
							autoComplete={autoComplete}
							onChange={onChange} disabled={disabled}
						>
							{children}
						</RawField>
						{leftIcon
							? (
								<span className="icon is-small is-left">
                  <i className={"fas " + leftIcon}/>
                </span>
							)
							: ""}
						{!noRightIcon
							? <InputRightIcon
								type={type}
								hasError={hasError}
								verified={verified}
								unmask={unmask}
								toggleUnmask={toggleUnmask}
							/>
							: ""}
					</div>
					{!isInline && hasError
						? <p className="help is-danger">{extractNestedProp(errors, name, prefixName)}</p>
						: ""}
				</div>
			</div>
		</div>
	);
};

class InputField extends Component {
	state = {
		unmask: false
	};

	toggleUnmask = () => {
		this.setState({
			unmask: !this.state.unmask
		});
	};

	render () {
		let {
			prefixName,
			name,
			label,
			type,
			placeholder,
			leftIcon,
			values,
			errors,
			touched,
			verified,
			options,
			doSearch,
			searchState,
			renderValue,
			isInline,
			isNarrow,
			isHorizontal,
			isSimplified,
			noRightIcon,
			autoComplete,
			disabled,
			onChange,
			children
		} = this.props;
		const { unmask } = this.state;

		if (type === "email") {
			leftIcon = "fa-envelope";
		} else if (type === "password") {
			leftIcon = "fa-lock";
		}

		const hasError = !!(extractNestedProp(errors, name, prefixName) &&
			extractNestedProp(touched, name, prefixName));

		let controlClasses = "control";
		if (leftIcon) {
			controlClasses += " has-icons-left";
		}
		if (!noRightIcon && (hasError || type === "password" || type === "secret" || verified !== undefined)) {
			controlClasses += " has-icons-right";
		}

		let fieldType = type;
		if (type === "password" || type === "secret") {
			if (unmask) {
				fieldType = "text";
			}
		} else if (type === "autocomplete") {
			fieldType = "text";
		}

		const allProps = {
			prefixName: prefixName,
			name: name,
			type: type,
			fieldType: fieldType,
			label: label,
			controlClasses: controlClasses,
			values: values,
			hasError: hasError,
			errors: errors,
			placeholder: placeholder,
			leftIcon: leftIcon,
			verified: verified,
			unmask: unmask,
			toggleUnmask: this.toggleUnmask,
			isInline: isInline,
			isNarrow: isNarrow,
			isSimplified: isSimplified,
			noRightIcon: noRightIcon,
			options: options,
			doSearch: doSearch,
			searchState: searchState,
			renderValue: renderValue,
			children: children,
			autoComplete: autoComplete,
			disabled: disabled,
			onChange: onChange
		};

		if (isHorizontal || isSimplified) {
			return (
				<InputLayoutHorizontal {...allProps} />
			);
		}

		return (
			<InputLayoutVertical {...allProps} />
		);
	}
}

export default InputField;
