import {createAction} from "redux-act";
import {Dispatch} from "redux";
import CompletableDeferred from "../../../util/CompletableDeferred";
import {colorspace} from "../../../theme";
import React from "react";
import {useDispatch} from "react-redux";

export type ConfirmModalConfig = {
    title: string,

    headBgColor: string,
    fontColor: string,

    confirmLabel?: string | JSX.Element,
    content?: string | JSX.Element,
    onConfirm?: () => void
    task: CompletableDeferred<boolean>
}

export const ConfirmOpenModalAction = createAction<ConfirmModalConfig>("Confirm - Open Modal");
export const ConfirmCloseModalAction = createAction("Confirm - Close Modal");

type ConfirmStyle = "danger" | "warning" | "info";

export type SimpleConfirm = {
    title?: string,
    content?: string,
    confirmLabel?: string,
    style?: ConfirmStyle
}

const styles = {
    danger: {
        headBgColor: colorspace.bad.main,
        fontColor: colorspace.grays.light
    },
    warning: {
        headBgColor: colorspace.warning.dark,
        fontColor: colorspace.grays.light
    },
    info: {
        headBgColor: colorspace.primary.upwire,
        fontColor: colorspace.grays.light
    }
};

function confirm(dispatch: Dispatch, config: Partial<SimpleConfirm> = {}): Promise<boolean> {
    const task = new CompletableDeferred<boolean>();

    const {headBgColor, fontColor} = styles[config.style ?? "warning"];

    dispatch(ConfirmOpenModalAction({
        title: "Confirm",
        headBgColor: headBgColor,
        fontColor: fontColor,
        content: "Are you sure?",
        task: task,
        ...config
    }));

    return task.promise;
}

type ConfirmGuard = Partial<SimpleConfirm> & { action: () => void };

export function useConfirmGuard(config: ConfirmGuard) {
    const dispatch = useDispatch();
    return async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        event.nativeEvent.stopPropagation();
        const ok = await confirm(dispatch, config);
        if (ok) {
            config.action();
        }
    };
}
