import { clone } from "underscore";

import { defaultNodeCategories } from "./node/category";
import { ConfigMap } from "core/config";

const { Map } = require("immutable");

// Base

export const CfgDiagram = "diagram";
export const CfgNodeStyles = "nodeStyles";
export const CfgNodeEdit = "nodeEdit";
export const CfgNodeCategories = "nodeCategories";
export const CfgTeleportColors = "teleportColors";

// Defaults

const defaultConfig = {};

defaultConfig[CfgDiagram] = {
	gridSize: 12,
	startXMultiplier: 1,
	startYMultiplier: 0, // Do not change.
	minFromEdgeMultiplier: 6,
	minFromCenterMultiplier: 10,
	minTrashSpacingMultiplier: 4,
	nodeSpacingMultiplier: 14,
	nodeSplayingMultiplier: 10, // Needs to be divisible by 2
	sourcePortOffsetElbow: 12,
	targetPortOffsetElbow: 12,
	targetPortOffset: 5,
	arrowOffset: -13,
	arrowScale: "0.6"
};

defaultConfig[CfgTeleportColors] = [
	"#3BAFDA",
	"#967ADC",
	"#8CC152",
	"#FFEC80",
	"#E28746",
	"#DA4453",
	"#4B63E0",
	"#BD72DA",
	"#409768",
	"#F6FC7F",
	"#E2AA46",
	"#F66D7A",
	"#60C0E4",
	"#BAA4ED",
	"#B6E484",
	"#FFAB70",
	"#9B196E"
];

defaultConfig[CfgNodeStyles] = {};
defaultConfig[CfgNodeEdit] = {};
defaultConfig[CfgNodeCategories] = clone(defaultNodeCategories);

export const config = { ...defaultConfig, ...clone(ConfigMap) };

export default Map(config);
