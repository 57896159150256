import { PortModelAlignment } from "@projectstorm/react-diagrams";
import { SuccessPortModel } from "../port/SuccessPortModel";
import { BaseNodeModel } from "./BaseNodeModel";
import { FailurePortModel } from "../port/FailurePortModel";
import { OptIconHoverPadPercent, OptIconPadPercent } from "core/config";

export class SmallNodeModel extends BaseNodeModel {
	constructor (opts) {
		if (!opts) {
			opts = {};
		}
		if (!opts.size) {
			opts.size = 72;
		}
		if (!opts[OptIconPadPercent]) {
			opts[OptIconPadPercent] = 16;
		}
		if (!opts[OptIconHoverPadPercent]) {
			opts[OptIconHoverPadPercent] = 16;
		}

		super({
			type: "small",
			...opts
		});
		this.addPort(new SuccessPortModel(PortModelAlignment.LEFT, true));
		this.addPort(new SuccessPortModel(PortModelAlignment.RIGHT));
		this.addPort(new FailurePortModel(PortModelAlignment.TOP, true));
		this.addPort(new FailurePortModel(PortModelAlignment.BOTTOM));
	}
}
