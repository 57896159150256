import { defaultCanvasPhase } from "./phase/init";

export const ensureCanvasInitialised = (canvas) => {
	if (canvas.schema) {
		if (!canvas.schema.phases || canvas.schema.phases.length < 1) {
			canvas.schema.phases = [
				defaultCanvasPhase()
			];
		}
	}

	return canvas;
};
