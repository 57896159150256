import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import Dialog from "../../components/common/dialog/Dialog";

import { Link } from "react-router-dom";
import SplashContainer from "../../../components/layout/splash/SplashContainer";

class NotFound extends PureComponent {

	componentDidMount () {
		this.props.setFullscreen(true);
	}

	render () {
		return (
			<SplashContainer>
				<Dialog title="Page Not Found" theme="is-danger is-light">
					<section className="modal-card-body">
						<span>Sorry, the page you are looking for doesn't exist!</span>
					</section>
					<footer className="modal-card-foot">
						<Link className="button is-danger is-light" to="/" replace={true}>Go Home</Link>
					</footer>
				</Dialog>
			</SplashContainer>
		);
	}
}

NotFound.propTypes = {
	setFullscreen: PropTypes.func.isRequired
};

export default NotFound;
