import cloneDeep from "lodash/cloneDeep";
import { OptIconHoverPad, OptIconHoverPadPercent, OptIconPad, OptIconPadPercent } from "core/config";

export const NodeStyleCircle = "circle";
export const NodeStyleSquare = "square";

export const initNodeProps = (inProps) => {
	const props = cloneDeep({
		size: 64,
		style: NodeStyleCircle,
		...inProps
	});

	// Defaults
	if (!props.shapeSize) {
		props.shapeSize = props.size;
	}

	if (props[OptIconPadPercent]) {
		// Often when passing nodeProps previous steps populate the iconPad
		delete props[OptIconPad];
	}
	if (props[OptIconHoverPadPercent]) {
		// Often when passing nodeProps previous steps populate the iconPad
		delete props[OptIconHoverPad];
	}

	if (!props[OptIconPad] && !props[OptIconPadPercent]) {
		props[OptIconPadPercent] = 19;
	}
	if (!props[OptIconHoverPad] && !props[OptIconHoverPadPercent]) {
		props[OptIconHoverPadPercent] = 19;
	}

	// Icon Padding
	return {
		...props,
		[OptIconPad]: extractIconPad(props, OptIconPad, OptIconPadPercent),
		[OptIconHoverPad]: extractIconPad(props, OptIconHoverPad, OptIconHoverPadPercent)
	};
};

export const extractIconPad = (props, padKey, padPercentKey) => {
	let size = props.shapeSize;
	if (!size) {
		size = props.size;
	}

	let iconPad = props[padKey];
	if (!iconPad) {
		if (props[padPercentKey]) {
			iconPad = size * (props[padPercentKey] / 100);
		}
	}

	return iconPad;
};
