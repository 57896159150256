import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./PhaseTabNew.scss";

export class PhaseTabNew extends Component {
	state = {
		isHover: false
	};

	setHover = (isHover) => {
		this.setState(() => ({
			isHover: isHover
		}));

		return false;
	};

	render () {
		const { canvas, playgroundProps, setPhaseId } = this.props;
		const { isHover } = this.state;

		const { openNewPhase } = playgroundProps;

		return (
			<div
				className={classNames(
					"pg-phase-tab-new-action",
					"tab-label",
					{ hover: isHover }
				)}
				onClick={() => {
					openNewPhase({
						canvas: canvas,
						onCreate: setPhaseId
					});
				}}
				onMouseOver={() => this.setHover(true)}
				onMouseLeave={() => this.setHover(false)}
			>
				<div className="pg-phase-tab-new-icon">
          <span className="icon">
            <i className="fa fa-plus"/>
          </span>
				</div>
			</div>
		);
	}
}

PhaseTabNew.propTypes = {
	playgroundProps: PropTypes.object.isRequired,
	canvas: PropTypes.object.isRequired,
	setPhaseId: PropTypes.func.isRequired
};

export default PhaseTabNew;
