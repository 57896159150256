import React from "react";
import classNames from "classnames";
import ShapeButtonSvgElem from "../../../svg/elem/ShapeButtonSvgElem";

import "./PortSetTargetFacet.scss";

export class PortSetTargetFacet extends React.Component {
	render () {
		const { nodeProps, size, offsetX, offsetY, onClick, isActive, setIsActive } = this.props;

		return (
			<g
				className={classNames(
					"port-set-target-facet",
					{ "is-active": isActive }
				)}
				onMouseOver={() => setIsActive(true)}
				onMouseLeave={() => setIsActive(false)}
			>
				<ShapeButtonSvgElem
					size={size}
					padding={nodeProps.shapePad}
					fill={nodeProps.fill}
					fillHover={nodeProps.fillHover}
					stroke="#FFF"
					strokeWidth={2}
					iconFill={nodeProps.iconFill}
					iconFillHover={nodeProps.iconFillHover}
					iconPad={3}
					iconHoverPad={3}
					iconSvgSprite={nodeProps.iconSvgSprite}
					iconSvgSpriteHover={nodeProps.iconSvgSpriteHover}
					offsetX={offsetX}
					offsetY={offsetY}
					onClick={onClick}
				/>
			</g>
		);
	}
}
