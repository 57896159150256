import cloneDeep from "lodash/cloneDeep";

import config, { CfgNodeStyles } from "../";
import {
	CfgColors,
	OptFill,
	OptFillHover,
	OptIcon,
	OptIconFill,
	OptIconFillHover,
	OptIconHover,
	OptIconSvg,
	OptIconSvgSprite,
	OptIconSvgSpriteHover,
	OptIconUrl,
	OptIconUrlHover
} from "core/config";
import { CategoryBasic } from "./category";
import {
	BranchingOutcomeFailure,
	BranchingOutcomeSuccess,
	GotoScopeCanvas,
	GotoScopePhase,
	GotoScopePlayground,
	GotoTargetFirst,
	GotoTargetNext
} from "../../lib/playground/constants";
import { NodeStyleSquare } from "../../lib/common/diagram/node";

export const NodeTypeModule = "module";
export const NodeTypeStart = "start";
export const NodeTypeTarget = "target";
export const NodeTypeUpwire = "upwire";

export const NodeTypeLogic = "logic";

export const NodeTypeTimer = "timer";
export const NodeTypeWebhook = "webhook"; // TODO: Implement Webhooks
export const NodeTypePlaceholder = "placeholder";

// Special Types

export const NodeTypeEnd = "end";
export const NodeTypePhase = "phase";

export const NodeModelTypeStart = "start";
export const NodeModelTypeSmall = "small";
export const NodeModelTypeTarget = "target";

export const ColorOpts = [OptFill, OptFillHover, OptIconFill, OptIconFillHover];

const colorsCfg = config.get(CfgColors);

// Defaults

const defaultConfig = {};

defaultConfig[NodeTypeModule] = {
	label: "Module",
	iconSvgSprite: "pe-7s-box2",
	iconSvgSpriteHover: "fad-edit",
	fill: colorsCfg.blue,
	iconFill: colorsCfg.white,
	isControl: true
};

defaultConfig[NodeTypeStart] = {
	label: "Start",
	fill: colorsCfg.green
};

defaultConfig[NodeTypeTarget] = {
	iconSvgSprite: "far-plus",
	iconSvgSpriteHover: "far-plus",
	fill: colorsCfg.white,
	iconFill: colorsCfg.green,
	iconFillHover: colorsCfg.white,
	isControl: false,

	[BranchingOutcomeSuccess]: {
		iconFill: colorsCfg.green,
		fillHover: colorsCfg.green
	},
	[BranchingOutcomeFailure]: {
		iconFill: colorsCfg.red,
		fillHover: colorsCfg.red
	}
};

defaultConfig[NodeTypeUpwire] = {
	label: "Upwire API",
	iconSvg: "/images/svg/upwire-mark-standard-REV.svg",
	iconSvgHover: "/images/svg/upwire-mark-standard.svg",
	fill: colorsCfg.blue,
	iconFill: colorsCfg.white
};

defaultConfig[NodeTypeTimer] = {
	label: "Timer",
	description: "Delay the execution of some steps by using a timer.",
	iconSvgSprite: "pe-li-alarm",
	fill: colorsCfg.red,
	iconFill: colorsCfg.white,

	[BranchingOutcomeFailure]: {
		disabled: true
	}
};

defaultConfig[NodeTypeLogic] = {
	label: "Logic",
	description: "A simple node type that is purely logic oriented (i.e. branching rules).",
	iconSvgSprite: "fas-cogs",
	fill: colorsCfg.upwireGreen2,
	iconFill: colorsCfg.white,

	[BranchingOutcomeFailure]: {
		disabled: true
	}
};

defaultConfig[NodeTypeWebhook] = {
	label: "Webhook",
	description: "Execute a remote webhook and inject the response into the system.",
	iconSvgSprite: "fas-anchor",
	fill: colorsCfg.greenPrimary,
	iconFill: colorsCfg.white
};

defaultConfig[NodeTypePlaceholder] = {
	label: "Placeholder",
	description: "Not sure what to put here yet? Add a placeholder instead.",
	iconSvgSprite: "far-construction",
	iconSvgSpriteHover: "far-plus",
	fill: colorsCfg.upwireOrange1,
	iconFill: colorsCfg.white,
	isControl: false,
	fillHover: colorsCfg.green,
	iconFillHover: colorsCfg.white
};

defaultConfig[NodeTypeEnd] = {
	style: NodeStyleSquare,
	label: "End Step",
	description: "End the current step.",
	iconSvgSprite: "fad-stop",
	fill: colorsCfg.blue,
	iconFill: colorsCfg.white,
	isControl: false,

	scopes: {
		[GotoScopePhase]: {
			label: "End Phase",
			description: "End the current phase.",
			fill: colorsCfg.purple
		},
		[GotoScopeCanvas]: {
			label: "End Canvas",
			description: "End the current canvas.",
			fill: colorsCfg.yellow
		},
		[GotoScopePlayground]: {
			label: "Full Stop",
			description: "Stop everything in the playground.",
			fill: colorsCfg.red
		}
	}
};

defaultConfig[NodeTypePhase] = {
	style: NodeStyleSquare,
	label: "Phase",
	description: "Targets this specific phase.",
	iconSvgSprite: "fad-dot-circle",
	fill: colorsCfg.purple,
	iconFill: colorsCfg.white,
	isControl: false,

	targets: {
		[GotoTargetFirst]: {
			label: "First Phase",
			description: "Target the first phase of this canvas.",
			iconSvgSprite: "fad-fast-backward"
		},
		[GotoTargetNext]: {
			label: "Next Phase",
			description: "Target the next phase of this canvas.",
			iconSvgSprite: "fad-forward"
		}
	},

	scopes: {
		[GotoScopePhase]: {
			fill: colorsCfg.purple
		},
		[GotoScopeCanvas]: {
			fill: colorsCfg.yellow
		}
	}
};

// List

export const defaultNodeList = [
	{
		type: NodeTypeTimer,
		categories: [CategoryBasic]
	}, {
		type: NodeTypePlaceholder,
		categories: [CategoryBasic]
	}, {
		type: NodeTypeLogic,
		categories: [CategoryBasic]
	}, {
		type: NodeTypeWebhook,
		categories: [CategoryBasic]
	}
];

// Util

export const mergeNodeTypeOptions = (opts, inOpts) => {
	const ourOpts = cloneDeep(opts);
	let applyOpts = cloneDeep(inOpts);

	if (!applyOpts) {
		applyOpts = {};
	}

	// URL override
	if (applyOpts[OptIconUrl]) {
		[OptIcon, OptIconSvgSprite].forEach(svgSpriteKey => {
			delete ourOpts[svgSpriteKey];
			delete applyOpts[svgSpriteKey];
		});
	}
	if (applyOpts[OptIconUrlHover]) {
		[OptIconHover, OptIconSvgSpriteHover].forEach(svgSpriteKey => {
			delete ourOpts[svgSpriteKey];
			delete applyOpts[svgSpriteKey];
		});
	}

	// Remap aliases
	if (applyOpts[OptIcon]) {
		applyOpts[OptIconSvgSprite] = applyOpts[OptIcon];
		delete applyOpts[OptIcon];
	}
	if (applyOpts[OptIconHover]) {
		applyOpts[OptIconSvgSpriteHover] = applyOpts[OptIconHover];
		delete applyOpts[OptIconHover];
	}

	// Delete when new icon type is provided.
	[OptIconSvgSprite, OptIconSvgSpriteHover].forEach(svgSpriteKey => {
		if (applyOpts[svgSpriteKey]) {
			if (svgSpriteKey === OptIconSvgSprite) {
				delete ourOpts[OptIconSvg];
			}

			const svgSprite = applyOpts[svgSpriteKey];
			if (typeof svgSprite === "object") {
				delete applyOpts[svgSpriteKey];

				if (svgSprite.sprite) {
					applyOpts = {
						...applyOpts,
						[svgSpriteKey]: svgSprite.sprite
					};
				}
			} else {
				applyOpts = {
					...applyOpts,
					[svgSpriteKey]: svgSprite
				};
			}
		}
	});

	// Replace colour keys with their real value.
	ColorOpts.forEach(colorKey => {
		if (applyOpts[colorKey]) {
			const colorVal = applyOpts[colorKey];
			if (!colorVal.startsWith("#")) {
				if (colorsCfg[colorVal]) {
					applyOpts[colorKey] = colorsCfg[colorVal];
				}
			}
		}
	});

	return {
		...ourOpts,
		...applyOpts
	};
};

export const getNodeTypeOptions = (nodeType, opts, subType) => {
	const nodeStylesCfg = config.get(CfgNodeStyles);

	let cfgOpts = {};
	if (nodeStylesCfg[nodeType]) {
		cfgOpts = nodeStylesCfg[nodeType];
	}

	let defaultOpts = mergeNodeTypeOptions({}, defaultConfig[NodeTypeModule]);

	if (defaultConfig[nodeType]) {
		defaultOpts = mergeNodeTypeOptions(defaultOpts, defaultConfig[nodeType]);
	}

	if (subType && defaultOpts[subType]) {
		defaultOpts = {
			...defaultOpts,
			...defaultOpts[subType]
		};
	}

	return mergeNodeTypeOptions(
		mergeNodeTypeOptions(defaultOpts, cfgOpts), opts
	);
};


