import {AuthTokenChanged, getToken} from "../../core/lib/auth/token";
import CompletableDeferred from "../../util/CompletableDeferred";

const tokenReady = new CompletableDeferred<boolean>();
let isTokenProviderConfigured = false;
let currentToken: string = "";

export function setupFrameTokenProvider() {

    if (isTokenProviderConfigured)
        return;
    isTokenProviderConfigured = true;

    function updateToken() {
        const token = getToken();
        if (token) {
            currentToken = token;
            tokenReady.resolve(true);
        }
    }

    window.addEventListener(AuthTokenChanged, updateToken);
    updateToken();

    window.addEventListener("message", async (event) => {
        if (event.data.type === "get-auth-token") {
            await tokenReady.promise;
            if (event.source) {
                event.source.postMessage({
                    type: "auth-token",
                    data: currentToken
                }, event.origin as any);
            }
        }
    });
}
