import React, { Component } from "react";
import PropTypes from "prop-types";

import PlaygroundCard from "./PlaygroundCard";

export class PlaygroundCards extends Component {
	render () {
		const { group } = this.props;

		if (!group.playgrounds || group.playgrounds.length < 1) {
			return "";
		}

		return group.playgrounds.map((playground, i) =>
			<PlaygroundCard key={`pg-card-pg-${i}`} playground={playground}/>
		);
	}
}

PlaygroundCards.propTypes = {
	group: PropTypes.object.isRequired
};

export default PlaygroundCards;
