import React, { Component } from "react";
import Modal from "../../../components/common/modal/Modal";
import connect from "react-redux/es/connect/connect";
import {
	ActivateOpenModalAction,
	ProcessResetAction,
	ResetPasswordCloseModalAction,
	ResetPasswordModeProcess,
	ResetPasswordModeRequest,
	SendResetPasswordAction
} from "../../../actions/auth";
import SendResetForm from "../../../components/auth/reset/SendResetForm";
import ProcessResetForm from "../../../components/auth/reset/ProcessResetForm";
import { ErrCodeUserNotActivated } from "../../../errs";

class ResetPasswordModal extends Component {
	render () {
		let { reset, closeModal, sendReset, processReset, openActivateModal } = this.props;
		if (!reset) {
			reset = {};
		}

		const open = !!(reset.open);
		if (!open) {
			return null;
		}

		if (reset.error && reset.error.error_code === ErrCodeUserNotActivated) {
			closeModal();
			openActivateModal({ fromLogin: true, email: reset.email });
			return "";
		}

		const mode = reset.mode || ResetPasswordModeRequest;

		return (
			<Modal
				title="Reset Password"
				closeModal={closeModal}
				modalState={open}
			>
				{mode === ResetPasswordModeRequest
					? <SendResetForm
						reset={reset}
						closeModal={closeModal}
						sendReset={sendReset}
					/>
					: mode === ResetPasswordModeProcess
						? <ProcessResetForm
							reset={reset}
							closeModal={closeModal}
							processReset={processReset}
						/>
						: ""}
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(ResetPasswordCloseModalAction()),
		sendReset: (payload) => dispatch(SendResetPasswordAction(payload)),
		processReset: (payload) => dispatch(ProcessResetAction(payload)),
		openActivateModal: (payload) => dispatch(ActivateOpenModalAction(payload))
	};
};

const mapStateToProps = (state, props) => {
	const core = state.get("core");
	if (!core) {
		return {};
	}

	return {
		reset: core.get("reset")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal);
