import React from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import Loading from "components/common/loading/Loading";
import Tabs, { Tab } from "core/components/common/tabs/Tabs";
import InputField from "core/components/common/form/InputField";
import InlineError from "core/components/common/message/InlineError";
import {
	getPlaygroundRunStatusColor,
	isPlaygroundRunStatusRunning,
	PlaygroundRunStatusAllCompleted,
	PlaygroundRunStatusAllFailed,
	PlaygroundRunStatusAllRunning,
	PlaygroundRunStatusOptions
} from "../../../../lib/playground/constants/status";
import { SelectInputDivider } from "core/components/common/form/SelectInput";

export const QueryTabIdQuery = "query";
export const QueryTabIdResult = "result";

export const searchStatusOptions = [
	{ value: "", label: "Any" },
	{ value: SelectInputDivider },
	{ value: PlaygroundRunStatusAllRunning.join(","), label: "All Running" },
	{ value: PlaygroundRunStatusAllCompleted.join(","), label: "All Completed" },
	{ value: PlaygroundRunStatusAllFailed.join(","), label: "All Failed" },
	{ value: SelectInputDivider },
	...PlaygroundRunStatusOptions
];

const PlaygroundDebuggerQueryFormSchema = Yup.object().shape({});

const applyModelToForm = (model, dashboard) => {
	return {
		env: model.env || "",
		status: model.status || "",
		correlation_id: model.correlationId || ""
	};
};

const applyFormToModel = (form) => {
	return {
		env: form.env,
		status: form.status,
		correlationId: form.correlation_id
	};
};

const getInitialValues = (formData) => {
	return {
		status: formData.status || "",
		correlation_id: formData.correlation_id || ""
	};
};

const DebuggerQueryResultAction = ({
	buttonStyle, icon, onClick, disabled
}) => {
	if (disabled) {
		buttonStyle = "is-light";
		onClick = () => {};
	}

	return (
		<button
			type="button"
			className={"button is-small " + buttonStyle + ((disabled) ? " disabled" : "")}
			onClick={onClick}
		>
      <span className="icon is-small">
        <i className={icon}/>
      </span>
		</button>
	);
};

const DebuggerQueryResult = ({
	result, handleTrace, handleKill, handleSubmit
}) => {
	return (
		<div className="query-result">
			<div className="result-field id code">{result.id}</div>
			<div className="result-field status">
				<span className="tag is-info is-normal"
					  style={{ backgroundColor: getPlaygroundRunStatusColor(result.status) }}>{result.status}
				</span>
			</div>
			<div className="result-field code">{result.correlation_id}</div>
			<div className="result-field date">
				{moment(result.run_at).format("DD-MM-YYYY HH:MM:SS")}
			</div>
			<div className="result-field actions">
				<p className="buttons">
					<DebuggerQueryResultAction
						buttonStyle="is-danger"
						icon="far fa-skull"
						onClick={() => {
							handleKill({
								runId: result.id
							}, () => {
								handleSubmit();
							});
						}}
						disabled={!isPlaygroundRunStatusRunning(result.status)}
					/>
					<DebuggerQueryResultAction
						buttonStyle="is-info"
						icon="far fa-route"
						onClick={() => {
							handleTrace({
								runId: result.id,
								verbose: false
							});
						}}
					/>
					<DebuggerQueryResultAction
						buttonStyle="is-success"
						icon="far fa-route"
						onClick={() => {
							handleTrace({
								runId: result.id,
								verbose: true
							});
						}}
					/>
				</p>
			</div>
		</div>
	);
};

const DebuggerQueryResults = ({
	model, handleTrace, handleKill, handleSubmit
}) => {
	if (!model || !model.result || model.result.length < 1) {
		return (
			<div className="query-results no-results">
				No results found.
			</div>
		);
	}

	return (
		<div className="query-results with-results">
			{model.result.map((r, ri) => (
				<DebuggerQueryResult
					key={"result-" + ri}
					result={r}
					handleTrace={handleTrace}
					handleKill={handleKill}
					handleSubmit={handleSubmit}
				/>
			))}
		</div>
	);
};

export const PlaygroundDebuggerQueryForm = ({
	model, dashboard, onSubmit, handleTrace, handleKill, reset, buttonBgColor, buttonFontColor,
	activeTab, setActiveTab
}) => {
	if (!model) {
		model = {};
	}

	const resultsEnabled = (model.result && !model.error);

	if (!resultsEnabled) {
		if (activeTab === QueryTabIdResult) {
			// Default to first tab when no results.
			activeTab = QueryTabIdQuery;
		}
	}

	const formData = applyModelToForm(model, dashboard);

	const onSubmitAdaptor = (form) => {
		return onSubmit(applyFormToModel(form));
	};

	return (
		<Formik
			onSubmit={onSubmitAdaptor}
			initialValues={getInitialValues(formData)}
			enableReinitialize
			validationSchema={PlaygroundDebuggerQueryFormSchema}
		>
			{({ errors, touched, handleReset, ...props }) => (
				<Form className="up-form with-tabs">
					<div className="pg-debugger-tab-content">
						{model.loading
							? <Loading minHeight={180}/>
							: (
								<Tabs
									borderColor={buttonBgColor}
									errors={errors}
									touched={touched}
									onTabSelect={setActiveTab}
									selectedTab={activeTab}
									{...props}
								>
									<Tab id={QueryTabIdQuery} title="Query" fields={["env", "status", "correlation_id"]}
										 fullHeight>
										<InputField
											key="status" name="status" label="Status"
											type="select" options={searchStatusOptions}
											errors={errors} touched={touched}
											{...props}
										/>
										<InputField
											key="correlation_id" name="correlation_id" label="Correlation Id"
											type="text"
											errors={errors} touched={touched}
											{...props}
										/>
									</Tab>
									<Tab id={QueryTabIdResult} title="Result" disabled={!resultsEnabled} fullHeight
										 isNested>
										{/*eslint-disable*/}
										<DebuggerQueryResults
											model={model}
											handleTrace={handleTrace}
											handleKill={handleKill}
											handleSubmit={props.handleSubmit}
										/>
									</Tab>
								</Tabs>
							)}
						{model.error && (
							<div className="up-form-inline-messages">
								{model.error && (
									<InlineError title="Error" error={model.error} showMessage/>
								)}
							</div>
						)}
					</div>
					<div className="up-form-inline-controls">
						<div className="buttons">
							<button
								type="submit" className="button is-small primary" style={{
								backgroundColor: buttonBgColor,
								color: buttonFontColor
							}} disabled={model.loading}
							>
								{(activeTab === QueryTabIdResult)
									? "Refresh"
									: "Search"}
							</button>

							<button
								type="button" className="button is-small" onClick={() => {
								handleReset();
								reset();
							}} disabled={model.loading}
							>
								Reset
							</button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

PlaygroundDebuggerQueryForm.propTypes = {
	model: PropTypes.object.isRequired,
	dashboard: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	handleTrace: PropTypes.func.isRequired,
	handleKill: PropTypes.func.isRequired,
	buttonBgColor: PropTypes.string.isRequired,
	buttonFontColor: PropTypes.string.isRequired,
	activeTab: PropTypes.string,
	setActiveTab: PropTypes.func.isRequired
};

export default PlaygroundDebuggerQueryForm;
