import { HideContextMenuAction, ShowContextMenuAction } from "../../actions/menu/actions";

export const menuReducer = (state = null, action) => {
	switch (action.type) {
		case ShowContextMenuAction.getType():
			return { open: true, ...action.payload };
		case HideContextMenuAction.getType():
			return { open: false };
		default:
			return state;
	}
};
