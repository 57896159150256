import React from "react";

import "./MessageDetails.scss";

const systemErrorMessage = "An internal system error occurred, please try again or contact support";

export const MessageDetail = ({ title, value }) => {
	if (!value) {
		return "";
	}

	return (
		<div className="message-detail">
			<div className="detail-title">
				{title}
			</div>
			<div className="detail-value">
				{value}
			</div>
		</div>
	);
};

export const MessageDetails = ({ details }) => {
	return (
		<div className="message-details">
			{details.map(d => {
				return <MessageDetail key={d.title} title={d.title} value={d.value}/>;
			})}
		</div>
	);
};

export const ErrorMessageDetails = ({ error }) => {

	let message = systemErrorMessage;
	if (error.message) {
		message = error.message;
	}

	if (error.details) {
		message = error.details;
	}

	if (error.public_details) {
		message = error.public_details;
	}

	return (
		<div className="message-details">
			<MessageDetail title="Correlation Id" value={error.correlation_id}/>
			<MessageDetail title="Error Code" value={error.error_code}/>
			<MessageDetail title="Message" value={message}/>
		</div>
	);
};
