import React from "react";

import CoreModals from "core/modals";
import CanvasModals from "canvas/modals";

const Modals = () => (
	<>
		<CanvasModals/>
		<CoreModals/>
	</>
);

export default Modals;
