import { extractUpdatablePlayground } from "../update";
import cloneDeep from "lodash/cloneDeep";
import { ensureCanvasInitialised } from "./init";

export const extractUpdatableCanvas = (dashboard, canvasId) => {
	const playground = extractUpdatablePlayground(dashboard);

	if (!dashboard.canvases[canvasId]) {
		throw new Error("failed to find canvas by id '" + canvasId + "' in dashboard data");
	}

	const canvas = ensureCanvasInitialised(
		cloneDeep(dashboard.canvases[canvasId]));

	return {
		playground: playground,
		canvas: {
			...canvas,
			apply: {
				// TODO: determine (currently not in the data, will need to add).
				source_version: "HEAD"
			}
		}
	};
};
