import * as React from "react";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { StartNodeModel } from "./StartNodeModel";
import { StartNodeWidget } from "./StartNodeWidget";

export class StartNodeFactory extends AbstractReactFactory {
	constructor () {
		super("start");
	}

	generateReactWidget (event) {
		const opts = event.model.getOptions();

		return (
			<StartNodeWidget
				engine={this.engine}
				node={event.model}
				{...opts}
			/>
		);
	}

	generateModel (event) {
		return new StartNodeModel();
	}
}
