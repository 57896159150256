import React from "react";
import { DefaultLinkWidget, PointModel, PortModelAlignment } from "@projectstorm/react-diagrams";
import { Point } from "@projectstorm/geometry";
import { LinkWidget } from "@projectstorm/react-diagrams-core";
import { ArrowLinkSegmentWidget } from "./ArrowLinkSegmentWidget";
import config, { CfgDiagram } from "../../../../config";

const diagramCfg = config.get(CfgDiagram);

const LinkArrowWidget = props => {
	const { point, previousPoint } = props;

	const angle = 90 + (Math.atan2(
		point.getPosition().y - previousPoint.getPosition().y,
		point.getPosition().x - previousPoint.getPosition().x
	) * 180) / Math.PI;

	return (
		<g className="arrow" transform={`translate(${point.getPosition().x}, ${point.getPosition().y})`}>
			<g style={{ transform: `rotate(${angle}deg)` }}>
				<g transform={`translate(0, ${diagramCfg.arrowOffset}) scale(${diagramCfg.arrowScale} ${diagramCfg.arrowScale})`}>
					<polygon
						points="0,10 8,30 -8,30"
						fill={props.color}
						onMouseLeave={() => { this.setState({ selected: false }); }}
						onMouseEnter={() => { this.setState({ selected: true }); }}
						data-id={point.getID()}
						data-linkid={point.getLink().getID()}
					/>
				</g>
			</g>
		</g>
	);
};

export class ArrowLinkWidget extends DefaultLinkWidget {
	generateArrow (point, previousPoint) {
		return (
			<LinkArrowWidget
				key={point.getID()}
				point={point}
				previousPoint={previousPoint}
				colorSelected={this.props.link.getOptions().selectedColor}
				color={this.props.link.getOptions().color}
			/>
		);
	}

	generateLink (path, extraProps, id) {
		const ref = React.createRef();
		this.refPaths.push(ref);
		return (
			<ArrowLinkSegmentWidget
				key={path + "_" + id}
				path={path}
				selected={this.state.selected}
				diagramEngine={this.props.diagramEngine}
				factory={this.props.diagramEngine.getFactoryForLink(this.props.link)}
				link={this.props.link}
				forwardRef={ref}
				onSelection={selected => {
					this.setState({ selected: selected });
				}}
				extras={extraProps}
			/>
		);
	}

	createOffsetPointModel (port, portPoint, type) {
		let offset = diagramCfg.sourcePortOffsetElbow;
		if (type === "target") {
			offset = diagramCfg.targetPortOffsetElbow;
		}

		const position = this.calcPortOffsetPosition(port, offset);
		if (position != null) {
			return new PointModel({
				link: portPoint.getParent(),
				position: position,
				isPortOffset: true,
				offsetType: type
			});
		}

		return null;
	}

	calcPortOffsetPosition (port, offset) {
		let position = null;
		const portCenter = port.getCenter();
		const alignment = port.getOptions().alignment;
		switch (alignment) {
			case PortModelAlignment.TOP:
				position = new Point(portCenter.x, portCenter.y - offset);
				break;
			case PortModelAlignment.BOTTOM:
				position = new Point(portCenter.x, portCenter.y + offset);
				break;
			case PortModelAlignment.LEFT:
				position = new Point(portCenter.x - offset, portCenter.y);
				break;
			case PortModelAlignment.RIGHT:
				position = new Point(portCenter.x + offset, portCenter.y);
				break;
			default:
				position = null;
		}

		return position;
	}

	hasPortOffset (points, idx, type) {
		return points[idx] &&
			points[idx].getOptions().isPortOffset &&
			points[idx].getOptions().offsetType === type;
	}

	offsetTargetPoint (port, portPoint) {
		const position = this.calcPortOffsetPosition(port, diagramCfg.targetPortOffset);

		if (position != null) {
			portPoint.setPosition(position);
		}

		return portPoint;
	}

	isElbowRequired (port, points, existing, type) {
		const alignment = port.getOptions().alignment;
		let offset = 0;
		if (existing) {
			offset = 1;
		}

		if (type === "source") {
			return !this.arePointsAligned(alignment, points[offset], points[offset + 1]);
		} else if (type === "target") {
			return !this.arePointsAligned(alignment, points[points.length - (1 + offset)],
				points[points.length - (2 + offset)]);
		}

		return true;
	}

	arePointsAligned (alignment, a, b) {
		if (a && b) {
			if (alignment === PortModelAlignment.TOP || alignment === PortModelAlignment.BOTTOM) {
				return (a.getPosition().x === b.getPosition().x);
			} else if (alignment === PortModelAlignment.LEFT || alignment === PortModelAlignment.RIGHT) {
				return (a.getPosition().y === b.getPosition().y);
			}
		}

		return false;
	}

	reCalculatePoints (points) {
		if (!points || points.length === 0)
			return points;

		const sourcePort = this.props.link.getSourcePort();
		const targetPort = this.props.link.getTargetPort();
		if (sourcePort === null || targetPort === null)
			return points;

		// Source
		const srcOffsetIdx = 1;
		if (this.hasPortOffset(points, srcOffsetIdx, "source")) {
			if (this.isElbowRequired(sourcePort, points, true, "source")) {
				points[srcOffsetIdx] = this.createOffsetPointModel(sourcePort, points[0], "source");
			} else {
				const firstPoint = points.shift();
				points.shift(); // remove elbow
				points.unshift(firstPoint);
			}
		} else {
			if (this.isElbowRequired(sourcePort, points, false, "source")) {
				const firstPoint = points.shift();

				points.unshift(this.createOffsetPointModel(sourcePort, firstPoint, "source"));
				points.unshift(firstPoint);
			}
		}

		// Target
		const targetOffsetIdx = points.length - 2;
		if (this.hasPortOffset(points, targetOffsetIdx, "target")) {
			if (this.isElbowRequired(targetPort, points, true, "target")) {
				const lastPoint = points[points.length - 1];

				points[targetOffsetIdx] = this.createOffsetPointModel(targetPort, lastPoint, "target");
				points[points.length - 1] = this.offsetTargetPoint(targetPort, lastPoint);
			} else {
				const lastPoint = this.offsetTargetPoint(targetPort, points.pop());
				points.pop(); // remove elbow
				points.push(lastPoint);
			}
		} else {
			if (this.isElbowRequired(targetPort, points, false, "target")) {
				const lastPoint = points.pop();

				points.push(this.createOffsetPointModel(targetPort, lastPoint, "target"));
				points.push(this.offsetTargetPoint(targetPort, lastPoint));
			} else {
				points[points.length - 1] = this.offsetTargetPoint(targetPort, points[points.length - 1]);
			}
		}

		return points;
	}

	drawLinkSegment (paths, points, fromIdx, toIdx, keyPrefix) {
		for (let j = fromIdx; j < toIdx; j++) {
			paths.push(
				this.generateLink(
					LinkWidget.generateLinePath(points[j], points[j + 1]),
					{
						"data-linkid": this.props.link.getID(),
						"data-point": j,
						onMouseDown: (event) => {
							this.addPointToLink(event, j + 1);
						}
					},
					j,
					keyPrefix
				)
			);
		}
	}

	render () {

		const points = this.reCalculatePoints(this.props.link.getPoints());
		const paths = [];
		this.refPaths = [];

		if (points.length === 4) {
			this.drawLinkSegment(paths, points, 0, 1, "src-link");

			paths.push(
				this.generateLink(
					this.props.link.getSVGPath(),
					{
						onMouseDown: event => {
							this.addPointToLink(event, 2);
						}
					},
					"0"
				)
			);

			this.drawLinkSegment(paths, points, 2, 3, "target-link");

			// draw the link as dangeling
			if (this.props.link.getTargetPort() == null) {
				paths.push(this.generatePoint(points[1]));
			}

			if (this.props.link.getTargetPort() !== null) {
				paths.push(this.generateArrow(points[points.length - 1], points[points.length - 2]));
			} else {
				paths.push(this.generatePoint(points[points.length - 1]));
			}
		} else {
			// draw the multiple anchors and complex line instead
			this.drawLinkSegment(paths, points, 0, points.length - 1, "link");

			// draw control points (skipping the offset points)
			for (let i = 1; i < points.length - 1; i++) {
				if (!points[i].getOptions().isPortOffset) {
					paths.push(this.generatePoint(points[i]));
				}
			}

			if (this.props.link.getTargetPort() !== null) {
				paths.push(this.generateArrow(points[points.length - 1], points[points.length - 2]));
			} else {
				paths.push(this.generatePoint(points[points.length - 1]));
			}
		}

		return <g data-default-link-test={this.props.link.getOptions().testName}>{paths}</g>;
	}
}
