import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Color from "color";

import {
	BranchingOutcomeFailure,
	GotoScopeCanvas,
	GotoScopePhase,
	GotoTargetEnd,
	GotoTargetFirst,
	GotoTargetNext
} from "../../../../../../../../../lib/playground/constants";

import config from "../../../../../../../../../config";
import { CfgColors, OptIconHoverPadPercent, OptIconPadPercent } from "core/config";

import { getNodeTypeOptions, NodeTypeEnd, NodeTypePhase } from "../../../../../../../../../config/node";
import { extractNodeProps } from "../../../../../../../../../lib/playground/canvas/phase/node/props";
import { NodeCardIcon } from "../../common/NodeCards";
import { createGotoTarget } from "../../../../../../../../../lib/playground/canvas/phase/node/update";
import { getPhaseById } from "../../../../../../../../../lib/playground/canvas/phase/update";

import "./BranchingTargetGoto.scss";

const localNodeProps = {
	shapeSize: 26,
	shapePad: 0,
	[OptIconPadPercent]: 24,
	[OptIconHoverPadPercent]: 24,
	disableDropShadow: true
};

const applySetTarget = (namePath, setFieldValue, values, target) => {
	const [targetEntry, newNodes] = createGotoTarget(target);

	if (newNodes && newNodes.length > 0) {
		let allNewNodes = values.newNodes;
		if (!allNewNodes) {
			allNewNodes = {};
		}

		for (let nni = 0; nni < newNodes.length; nni++) {
			allNewNodes[newNodes[nni].id] = newNodes[nni].definition;
		}

		setFieldValue("newNodes", allNewNodes);
	}

	if (targetEntry && targetEntry.goto) {
		setFieldValue(namePath, targetEntry.goto);
	}
};

const extractTeleportNodeProps = (goto, nodeProps, playgroundProps) => {
	let { canvas, dashboard } = playgroundProps;
	let teleportTarget = null;

	if (goto.canvas) {
		canvas = dashboard.canvases[goto.canvas];
		if (!canvas) {
			throw new Error("failed to find canvas by id: " + goto.canvas);
		}

		nodeProps = {
			...nodeProps,
			canvas: canvas,
			localLabel: canvas.name
		};
	}

	if (goto.phase) {
		if (goto.phase === GotoTargetFirst) {
			teleportTarget = GotoTargetFirst;
		} else if (goto.phase === GotoTargetNext) {
			teleportTarget = GotoTargetNext;
		} else {
			if (canvas) {
				const phase = getPhaseById(canvas, goto.phase);

				nodeProps = {
					...nodeProps,
					phase: phase,
					localLabel: phase.name
				};
			}
		}
	}

	if (teleportTarget) {
		if (nodeProps.targets && nodeProps.targets[teleportTarget]) {
			nodeProps = {
				...nodeProps,
				...nodeProps.targets[teleportTarget]
			};
		}
	}

	return nodeProps;
};

const TargetAction = ({ icon, iconClass, onClick }) => {
	if (!iconClass) {
		iconClass = "";
	}

	return (
		<div className="action" onClick={onClick}>
      <span className={classNames("icon", iconClass)}>
        <i className={icon}/>
      </span>
		</div>
	);
};

const TargetActions = ({ onExchange, onDelete }) => {
	return (
		<div className="target-actions">
			{onExchange
				? <TargetAction icon="fad fa-exchange" onClick={onExchange}/>
				: ""}
			{onDelete
				? <TargetAction icon="fad fa-trash" iconClass="is-danger" onClick={onDelete}/>
				: ""}
		</div>
	);
};

const BranchingGotoTarget = ({
	goto, nodes, namePath, disableEdit, playgroundProps, nodeProps, nodeInfoCbRef, doDelete,
	setModalHidden, setFieldValue, values
}) => {
	const { openSetTarget } = playgroundProps;
	const { nodeData, data, moduleData } = nodeProps;

	let newNodeData = {};

	const isEnd = (goto.target === GotoTargetEnd);
	const isTeleport = (!isEnd && !goto.target && (goto.canvas || goto.phase));

	let scope = goto.scope;

	if (isEnd) {
		newNodeData = {
			type: NodeTypeEnd
		};
	} else if (isTeleport) {
		newNodeData = {
			type: NodeTypePhase
		};

		if (goto.canvas) {
			scope = GotoScopeCanvas;
		} else {
			scope = GotoScopePhase;
		}
	} else {
		newNodeData = nodes[goto.target];
	}

	if (!newNodeData) {
		newNodeData = {};
	}

	let newNodeProps = getNodeTypeOptions(newNodeData.type,
		extractNodeProps(newNodeData, playgroundProps)
	);

	if (scope) {
		if (newNodeProps.scopes && newNodeProps.scopes[scope]) {
			newNodeProps = {
				...newNodeProps,
				...newNodeProps.scopes[scope]
			};
		}
	}

	if (isTeleport) {
		newNodeProps = extractTeleportNodeProps(goto, newNodeProps, playgroundProps);
	}

	// Generic Node Info
	const node = {
		nodeProps: {
			...newNodeProps
		}
	};

	// Locally Configured Node Info
	const localNode = {
		nodeProps: {
			...newNodeProps,
			...localNodeProps
		}
	};

	if (newNodeData.name) {
		localNode.nodeProps.label = newNodeData.name;
	}
	if (newNodeProps.localLabel) {
		localNode.nodeProps.label = newNodeProps.localLabel;
	}

	// doExchange
	const doExchange = () => {
		setModalHidden(true);

		openSetTarget({
			data: data,
			nodeProps: nodeProps,
			nodeData: nodeData,
			moduleData: moduleData,
			onClose: () => {
				setModalHidden(false);
			},
			onSetTarget: (target) => {
				applySetTarget(namePath, setFieldValue, values, target);
			}
		});
	};

	const showNodeInfo = (node) => {
		if (nodeInfoCbRef && nodeInfoCbRef.setNode) {
			nodeInfoCbRef.setNode(node);
		}
	};

	return (
		<div
			className={classNames(
				"goto-target",
				((isEnd) ? "is-end" : "is-goto")
			)}
			onMouseEnter={() => showNodeInfo(node)}
			onMouseLeave={() => showNodeInfo()}
			onClick={() => showNodeInfo()}
		>
			<NodeCardIcon node={localNode}/>

			<div className="goto-target-node-name text-selection-none">
				<div>{localNode.nodeProps.label}</div>
			</div>

			{!disableEdit
				? (
					<TargetActions
						onExchange={doExchange}
						onDelete={doDelete}
					/>
				)
				: ""}
		</div>
	);
};

class BranchingGotoSetTarget extends PureComponent {
	static propTypes = {
		goto: PropTypes.object.isRequired,
		outcome: PropTypes.string.isRequired,
		namePath: PropTypes.string.isRequired,
		doDelete: PropTypes.func.isRequired,
		setModalHidden: PropTypes.func.isRequired,
		nodeProps: PropTypes.object.isRequired,
		playgroundProps: PropTypes.object.isRequired,
		setFieldValue: PropTypes.func.isRequired,
		hasError: PropTypes.bool,
		values: PropTypes.object
	};

	render () {
		const {
			outcome, namePath, doDelete, nodeProps, playgroundProps, setModalHidden, setFieldValue,
			hasError, values
		} = this.props;

		const { openSetTarget } = playgroundProps;
		const { nodeData, data, moduleData } = nodeProps;

		const colourCfg = config.get(CfgColors);

		let fill = colourCfg.green;
		const iconFill = colourCfg.white;

		if (outcome === BranchingOutcomeFailure) {
			fill = colourCfg.red;
		}

		const localNode = {
			nodeProps: {
				iconSvgSprite: "#fas-plus",
				...localNodeProps,
				shapeSize: 20,
				fill: fill,
				iconFill: iconFill
			}
		};

		const handleClick = () => {
			setModalHidden(true);

			openSetTarget({
				data: data,
				nodeProps: nodeProps,
				nodeData: nodeData,
				moduleData: moduleData,
				onClose: () => {
					setModalHidden(false);
				},
				onSetTarget: (target) => {
					applySetTarget(namePath, setFieldValue, values, target);
				}
			});
		};

		return (
			<div className="goto-target is-set-goto">
				<div
					className="goto-set-action"
					style={{
						"--active-color": fill,
						"--active-font-color": fill,
						"--inactive-color": (hasError)
							? colourCfg.red
							: Color("#777").lighten("1").string()
					}}
					onClick={handleClick}
				>
					<NodeCardIcon node={localNode}/>

					<div className="label text-selection-none">
						Set Target
					</div>
				</div>

				<TargetActions onDelete={doDelete}/>
			</div>
		);
	}
}

export class BranchingTargetGoto extends PureComponent {
	static propTypes = {
		target: PropTypes.object.isRequired,
		group: PropTypes.object.isRequired,
		outcome: PropTypes.string.isRequired,
		namePath: PropTypes.string.isRequired,
		disableEdit: PropTypes.bool,
		nodeProps: PropTypes.object.isRequired,
		playgroundProps: PropTypes.object.isRequired,
		nodeInfoCbRef: PropTypes.object.isRequired,
		doDelete: PropTypes.func.isRequired,
		setModalHidden: PropTypes.func.isRequired,
		setFieldValue: PropTypes.func.isRequired,
		hasError: PropTypes.bool,
		values: PropTypes.object,
		touched: PropTypes.object,
		errors: PropTypes.object
	};

	render () {
		const {
			target, outcome, namePath, disableEdit, nodeProps, playgroundProps, nodeInfoCbRef, doDelete,
			setModalHidden, setFieldValue, hasError, values
		} = this.props;
		let { phase } = playgroundProps;
		if (!phase) {
			phase = {};
		}
		let { nodes } = phase;
		if (!nodes) {
			nodes = {};
		}
		if (values.newNodes) {
			nodes = {
				...nodes,
				...values.newNodes
			};
		}

		const colourCfg = config.get(CfgColors);

		let arrowColor = colourCfg.green;
		if (outcome === BranchingOutcomeFailure) {
			arrowColor = colourCfg.red;
		}

		let haveTarget = false;
		if (target && target.goto && (target.goto.target || target.goto.canvas || target.goto.phase)) {
			haveTarget = true;
		}

		if (!haveTarget && disableEdit) {
			return "";
		}

		return (
			<div className="branching-target-goto-container">
				<div className="goto-arrow-icon">
          <span className="icon" style={{ color: arrowColor }}>
            <i className="fas fa-arrow-right"/>
          </span>
				</div>
				<div className="goto-target-container">
					{haveTarget
						? <BranchingGotoTarget
							goto={target.goto}
							nodes={nodes}
							namePath={namePath}
							disableEdit={disableEdit}
							nodeProps={nodeProps}
							playgroundProps={playgroundProps}
							nodeInfoCbRef={nodeInfoCbRef}
							doDelete={doDelete}
							setModalHidden={setModalHidden}
							setFieldValue={setFieldValue}
							values={values}
						/>
						: <BranchingGotoSetTarget
							goto={target.goto}
							outcome={outcome}
							namePath={namePath}
							doDelete={doDelete}
							setModalHidden={setModalHidden}
							nodeProps={nodeProps}
							playgroundProps={playgroundProps}
							setFieldValue={setFieldValue}
							hasError={hasError}
							values={values}
						/>}
				</div>
			</div>
		);
	}
}

export default BranchingTargetGoto;
