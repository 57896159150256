// eslint-disable-next-line no-redeclare

import { NodeModelTypeTarget, NodeTypePlaceholder } from "../../../../../config/node";
import { addCanvasPhaseNodeTarget, replaceCanvasPhaseNodeTarget, unlinkCanvasPhaseNode } from "./update";
import { BranchingOutcomeSuccess, SetTargetModeCreate } from "../../../constants";
import { updateNodeLayout } from "./layout";

import { ContextMenuPositionVerticalDefault } from "core/components/common/context/ContextMenuContent";
import { getNodeBranchingOutcomes } from "../../../../../config/node/util";
import { getNodeCreationPayload } from "./new";

export const setTargetAction = (
	nodeProps, nodeId
) => {
	return (type) => {

		const { nodeData, data, moduleData, playgroundProps } = nodeProps;
		const { dashboard, openSetTarget, updateCanvas, canvas, phase } = playgroundProps;
		const { playground } = dashboard;

		openSetTarget({
			data: data,
			nodeProps: nodeProps,
			nodeData: nodeData,
			moduleData: moduleData,
			onSetTarget: (target) => {
				updateCanvas({
					namespace: playground.namespace,
					playgroundId: playground.playground_id,
					canvasId: canvas.canvas_id,
					model: addCanvasPhaseNodeTarget(dashboard, canvas.canvas_id, phase.id, nodeId, type, target)
				});
			}
		});
	};
};

export const replaceTargetAction = (
	nodeProps
) => {
	return () => {
		const { playgroundProps } = nodeProps;
		const { dashboard, openNewNode, updateCanvas } = playgroundProps;

		openNewNode({
			onSelect: (payload) => {
				const { playground } = dashboard;
				const { canvas, phase } = playgroundProps;

				updateCanvas({
					namespace: playground.namespace,
					playgroundId: playground.playground_id,
					canvasId: canvas.canvas_id,
					model: replaceCanvasPhaseNodeTarget(dashboard, canvas.canvas_id, phase.id,
						nodeProps.id, {
							mode: SetTargetModeCreate,
							payload: payload
						})
				});
			}
		});
	};
};

function addOn (nodeProps, type) {
	return () => {
		const { playgroundProps, id: nodeId } = nodeProps;
		const { dashboard, openNewNode, updateCanvas } = playgroundProps;

		openNewNode({
			onSelect: (payload) => {
				const { playground } = dashboard;
				const { canvas, phase } = playgroundProps;

				console.log("nodeProps: ", nodeProps);
				console.log("PAYLOAD: ", payload);

				updateCanvas({
					namespace: playground.namespace,
					playgroundId: playground.playground_id,
					canvasId: canvas.canvas_id,
					model: addCanvasPhaseNodeTarget(dashboard, canvas.canvas_id, phase.id, nodeId, type, {
						mode: SetTargetModeCreate,
						payload: payload
					})
				});
			}
		});
	};
}

export const editNodeAction = (
	nodeProps
) => {
	return () => {
		const { nodeData, data, moduleData, playgroundProps } = nodeProps;
		const { openEditNode } = playgroundProps;

		const payload = {
			data: data,
			nodeProps: nodeProps,
			nodeData: nodeData,
			moduleData: moduleData
		};
		openEditNode(payload);
	};
};

export const clickNodeAction = (
	nodeProps
) => {
	return () => {
		const { node, nodeData, linkData } = nodeProps;

		if (node.positionChanged) {
			// When moving we don't trigger the action.
			return;
		}

		if (nodeProps.type === NodeModelTypeTarget) {
			setTargetAction(nodeProps, nodeProps.parentNodeId)(linkData.type);
		} else if (nodeData.type === NodeTypePlaceholder) {
			replaceTargetAction(nodeProps)();
		} else {
			editNodeAction(nodeProps)();
		}
	};
};

export const clickNodeDeleteAction = (
	nodeProps
) => deleteNodeAction(nodeProps);

export const clickNodeContextMenuAction = (
	nodeProps, height, width, isMenuOpen, setIsMenuOpen
) => {
	return (e) => {
		const { node, nodeData, data, playgroundProps } = nodeProps;
		const { showContextMenu, hideContextMenu } = playgroundProps;

		if (nodeProps.type === NodeModelTypeTarget) {
			// not supported
			return;
		}

		const positionVertical = ContextMenuPositionVerticalDefault;
		let [eventY, eventX] = [];

		if (e) {
			eventX = e.clientX;
			eventY = e.clientY;
		} else {
			// not supported
			return;
		}

		if (isMenuOpen) {
			hideContextMenu();
		} else {

			const actions = [{
				label: "Copy",
				icon: "fad fa-copy",
				onClick: copyNodeAction(nodeProps)
			}, {
				label: "Replace",
				icon: "fad fa-exchange",
				onClick: replaceTargetAction(nodeProps)
			}, {
				label: "Delete",
				icon: "fad fa-trash",
				onClick: deleteNodeAction(nodeProps)
			}, {
				label: "Reset Layout",
				icon: "fad fa-border-inner",
				onClick: updateNodePositionAction(node, playgroundProps, data)
			}];

			const outcomes = getNodeBranchingOutcomes(nodeProps);
			outcomes.reverse();

			for (let outcome of outcomes) {
				const label = outcome.charAt(0).toUpperCase() + outcome.slice(1);
				const action = {
					label: `On ${label}`,
					icon: outcome === BranchingOutcomeSuccess ? "fad fa-plus" : "fad fa-bolt",
					onClick: addOn(nodeProps, outcome)
				};

				if (copiedNode) {
					actions.unshift({
						label: `On ${label} Paste`,
						icon: "fad fa-paste",
						onClick: pasteNodeAction(nodeProps, outcome)
					});
				}

				actions.unshift(action);
			}

			if (nodeData.type !== NodeTypePlaceholder) {
				actions.push({
					label: "Step Settings",
					icon: "fad fa-cog",
					onClick: editNodeAction(nodeProps)
				});
			}

			showContextMenu({
				onClose: () => {
					setIsMenuOpen(false);
				},
				actions: actions,
				eventY: eventY,
				eventX: eventX,
				positionVertical: positionVertical,
				itemActiveColor: (nodeData.type !== NodeTypePlaceholder)
					? nodeProps.fillHover
					: nodeProps.fill,
				itemActiveFontColor: (nodeData.type !== NodeTypePlaceholder)
					? nodeProps.iconFillHover
					: nodeProps.iconFill
			});
		}

		setIsMenuOpen(!isMenuOpen);
	};
};

export const updateNodePositionAction = (node, playgroundProps, data) => {
	return (position) => {
		const { dashboard, canvas, phase, updateCanvas } = playgroundProps;
		const { playground } = dashboard;

		const layout = (data.layout) ? data.layout : {};
		const modelsLayout = (layout.models) ? layout.models : {};

		let curLayout = modelsLayout[node.getID()];
		if (!curLayout) {
			curLayout = {};
		}

		curLayout.position = position;

		updateCanvas({
			namespace: playground.namespace,
			playgroundId: playground.playground_id,
			canvasId: canvas.canvas_id,
			model: updateNodeLayout(dashboard, canvas.canvas_id,
				phase.id, node.getID(), curLayout)
		});
	};
};

export const deleteNodeAction = (
	nodeProps
) => {
	return () => {
		const { playgroundProps, node } = nodeProps;
		const { dashboard, canvas, phase, updateCanvas } = playgroundProps;

		const canvasId = canvas.canvas_id;
		const phaseId = phase.id;
		const nodeId = node.options.id;

		updateCanvas({
			namespace: dashboard.playground.namespace,
			playgroundId: dashboard.playground.playground_id,
			canvasId: canvas.canvas_id,
			model: unlinkCanvasPhaseNode(dashboard, canvasId, phaseId, nodeId)
		});
	};
};

let copiedNode = null;

export function copyNodeAction (nodeProps) {
	console.log("COPY", nodeProps);
	return () => {
		copiedNode = nodeProps;
	};
}

export function pasteNodeAction (nodeProps, outcome) {
	if (!copiedNode) {
		return;
	}

	function getNodeModel (nodeProps) {
		return undefined;
	}

	return () => {

		// check that nodeProps points to the same playground
		const { playgroundProps, id: nodeId } = nodeProps;
		const { dashboard } = playgroundProps;
		const { playground } = dashboard;
		const { canvas, phase, updateCanvas } = playgroundProps;
		const { playground: copiedPlayground } = copiedNode.playgroundProps.dashboard;

		if (playground.playground_id !== copiedPlayground.playground_id) {
			copiedNode = null;
			return;
		}

		const { nodeData } = copiedNode;
		const payload = getNodeCreationPayload(dashboard, { type: nodeData.type, module: nodeData.module });

		if (!payload) {
			copiedNode = null;
			return;
		}

		const { settings, name } = nodeData;
		if (settings) {
			payload.definition.settings = settings;
		}

		payload.definition.name = `${name ?? ""} (Copy)`;

		updateCanvas({
			namespace: playground.namespace,
			playgroundId: playground.playground_id,
			canvasId: canvas.canvas_id,
			model: addCanvasPhaseNodeTarget(dashboard, canvas.canvas_id, phase.id, nodeId, outcome, {
				mode: SetTargetModeCreate,
				payload: payload
			})
		});

	};

}
