import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import { Droppable } from "react-beautiful-dnd";

import config from "../../../../../../../../../config";
import { CfgColors } from "core/config";

import {
	BranchingTypeAll,
	BranchingTypeEnum,
	BranchingTypeFirst
} from "../../../../../../../../../lib/playground/constants";
import { capitalizeFirstLetter } from "core/lib/variable/keys";
import LogicGroup from "core/components/common/logic/group/LogicGroup";
import LogicGroupAction from "core/components/common/logic/group/LogicGroupAction";
import { defaultNodeBranchingTarget } from "../../../../../../../../../lib/playground/canvas/phase/node/init";
import { BranchingGroupItems, ItemTypeBranching } from "./BranchingGroupItems";

const branchingTypeOptions = [
	{ value: BranchingTypeAll, label: capitalizeFirstLetter(BranchingTypeAll) },
	{ value: BranchingTypeFirst, label: capitalizeFirstLetter(BranchingTypeFirst) },
	{ value: BranchingTypeEnum, label: "Switch" }
];

export class BranchingGroup extends PureComponent {
	static propTypes = {
		group: PropTypes.object.isRequired,
		index: PropTypes.number.isRequired,
		count: PropTypes.number.isRequired,
		outcome: PropTypes.string.isRequired,
		namePath: PropTypes.string.isRequired,
		labelWidth: PropTypes.number.isRequired,
		nodeProps: PropTypes.object.isRequired,
		playgroundProps: PropTypes.object.isRequired,
		nodeInfoCbRef: PropTypes.object.isRequired,
		doDelete: PropTypes.func.isRequired,
		disableDelete: PropTypes.bool,
		setModalHidden: PropTypes.func.isRequired,
		setFieldValue: PropTypes.func.isRequired,
		values: PropTypes.object,
		touched: PropTypes.object,
		errors: PropTypes.object,
		moveUp: PropTypes.func,
		moveDown: PropTypes.func
	};

	render () {
		const {
			group, index, count, outcome, namePath, labelWidth, nodeProps, playgroundProps, nodeInfoCbRef,
			setModalHidden, doDelete, disableDelete, values, touched, errors, setFieldValue, moveUp, moveDown
		} = this.props;

		const colourCfg = config.get(CfgColors);

		const branching = (group.branching)
			? group.branching
			: [];
		const isEmpty = (!branching || branching.length < 1);

		return (
			<FieldArray name={namePath + ".branching"}>
				{arrayHelpers => (
					<Droppable
						droppableId={namePath + ".branching"}
						type="target"
					>
						{(provided, snapshot) => (
							<LogicGroup
								value={group.branching_type}
								options={branchingTypeOptions}
								index={index}
								count={count}
								isEmpty={isEmpty}
								setFieldValue={setFieldValue}
								fieldName={namePath + ".branching_type"}
								color={colourCfg.blue}
								width={labelWidth}
								className="is-nested"
								moveUp={moveUp}
								moveDown={moveDown}
								doDelete={doDelete}
								disableDelete={disableDelete}
								isDraggingOver={snapshot.isDraggingOver}
							>
								<BranchingGroupItems
									items={branching}
									itemType={ItemTypeBranching}
									provided={provided}
									namePath={namePath}
									group={group}
									outcome={outcome}
									nodeProps={nodeProps}
									playgroundProps={playgroundProps}
									nodeInfoCbRef={nodeInfoCbRef}
									setModalHidden={setModalHidden}
									setFieldValue={setFieldValue}
									arrayHelpers={arrayHelpers}
									values={values}
									touched={touched}
									errors={errors}
								/>

								<LogicGroupAction
									onClick={() => arrayHelpers.push(defaultNodeBranchingTarget())}
									color={colourCfg.blue}
									label="Add Target"
									showDelete={branching.length < 1}
								/>
							</LogicGroup>
						)}
					</Droppable>
				)}
			</FieldArray>
		);
	}
}

export default BranchingGroup;
