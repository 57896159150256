// Set Target Modes

export const SetTargetModeCreate = "create";
export const SetTargetModeExisting = "existing";
export const SetTargetModeTerminate = "terminate";

// Branching Outcomes

export const BranchingOutcomeSuccess = "success";
export const BranchingOutcomeFailure = "failure";

export const BranchingOutcomes = [BranchingOutcomeSuccess, BranchingOutcomeFailure];

// Branching Keys

export const BranchingKeyBranching = "branching";
export const BranchingKeyDefault = "default";

export const BranchingKeys = [BranchingKeyBranching, BranchingKeyDefault];

// Branching Types

export const BranchingTypeAll = "all";
export const BranchingTypeEnum = "enum";
export const BranchingTypeFirst = "first";
export const BranchingTypeDefault = "default";

// Targets

export const GotoScopeStep = "step";
export const GotoScopePhase = "phase";
export const GotoScopeCanvas = "canvas";
export const GotoScopePlayground = "playground";

export const GotoScopes = [GotoScopeStep, GotoScopePhase, GotoScopeCanvas, GotoScopePlayground];

export const GotoTargetEnd = "end";
export const GotoTargetFirst = "first";
export const GotoTargetNext = "next";
