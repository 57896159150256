import React, { PureComponent } from "react";
import classNames from "classnames";

import {
	clickNodeAction,
	clickNodeContextMenuAction,
	clickNodeDeleteAction,
	setTargetAction
} from "../../../../lib/playground/canvas/phase/node/actions";

import DropShadowSvgFilter from "../../svg/defs/DropShadowSvgFilter";
import ShapeButtonSvgElem from "../../svg/elem/ShapeButtonSvgElem";
import ControlButtonSvgElem from "../../svg/elem/ControlButtonSvgElem";
import { DefaultPortWidgets } from "../port/DefaultPortWidget";

import { initNodeProps } from "../../../../lib/common/diagram/node";
import { initStyleProps } from "../../../../lib/common/diagram/style";

import "./DefaultNodeWidget.scss";

export class DefaultNodeWidget extends PureComponent {
	state = {
		isActive: null,
		isMenuOpen: false
	};

	componentDidMount () {
		const localProps = this.extractLocalProps();

		if (localProps && localProps.widthPad > 0) {
			if (this.elem && this.elem.parentElement) {
				this.elem.parentElement.style.transform = `translate(-${localProps.widthPad}px, 0)`;
			}
		}
	}

	setIsActive = (isActive) => {
		this.setState(() => ({
			isActive: isActive
		}));

		return false;
	};

	setIsMenuOpen = (isMenuOpen) => {
		this.setState(() => ({
			isMenuOpen: isMenuOpen
		}));

		return false;
	};

	extractLocalProps = () => {
		let width = this.props.size;
		const height = this.props.size;

		const isControl = this.props.isControl;
		let widthPad = 0;
		if (isControl) {
			widthPad = (width * 0.25);
			width = (width) + (widthPad * 2);
		}

		return {
			width, height, widthPad
		};
	};

	render () {
		let { node, nodeData, linkData, data, moduleData, playgroundProps, engine, ...rest } = this.props;

		let { isActive, isMenuOpen } = this.state;

		const nodeName = nodeData.name ?? "";

		if (!data) {
			data = {};
		}

		if (isMenuOpen) {
			isActive = true;
		}
		if (!data.init) {
			isActive = false;
		}

		const nodeProps = initNodeProps(
			initStyleProps({
				shapePad: 12,
				...rest
			})
		);

		const fullNodeProps = {
			...this.props,
			...nodeProps
		};

		const isControl = this.props.isControl;

		const localProps = this.extractLocalProps();

		const onClickCircle = clickNodeAction(fullNodeProps);
		const setTargetActionFn = setTargetAction(fullNodeProps, node.getID());
		const onClickActions = clickNodeContextMenuAction(fullNodeProps, localProps.height, localProps.width,
			isMenuOpen, this.setIsMenuOpen);
		const onTrashClickAction = clickNodeDeleteAction(fullNodeProps);

		return (
			<div
				ref={elem => {
					this.elem = elem;
				}}
				className={classNames(
					"default-node",
					{ "is-control": isControl },
					{ "is-active": isActive }
				)}
				style={{
					position: "relative",
					width: localProps.width,
					height: localProps.height
				}}
			>
				{isControl
					? <ControlButtonSvgElem
						isActive={isActive}
						setIsActive={this.setIsActive}
						width={localProps.width}
						height={localProps.height}
						widthPad={localProps.widthPad}
						barPad={nodeProps.shapePad}
						style={nodeProps.style}
						size={nodeProps.shapeSize}
						padding={nodeProps.shapePad}
						fill={nodeProps.fill}
						fillHover={nodeProps.fillHover}
						iconFill={nodeProps.iconFill}
						iconFillHover={nodeProps.iconFillHover}
						iconPad={nodeProps.iconPad}
						iconHoverPad={nodeProps.iconHoverPad}
						iconSvg={nodeProps.iconSvg}
						iconSvgSprite={nodeProps.iconSvgSprite}
						iconSvgHover={nodeProps.iconSvgHover}
						iconSvgSpriteHover={nodeProps.iconSvgSpriteHover}
						iconUrl={nodeProps.iconUrl}
						iconUrlHover={nodeProps.iconUrlHover}
						iconUrlPadPercent={nodeProps.iconUrlPadPercent}
						onClickCircle={onClickCircle}
						onClickActions={onClickActions}
						onClickNodeDeleteAction={onTrashClickAction}
						isActionsOpen={isMenuOpen}
						nodeName={nodeName}
					/>
					: (
						<svg width={localProps.width} height={localProps.height}>
							<defs>
								<DropShadowSvgFilter id="drop-shadow"/>
							</defs>
							<ShapeButtonSvgElem
								style={nodeProps.style}
								size={nodeProps.shapeSize}
								padding={nodeProps.shapePad}
								fill={nodeProps.fill}
								fillHover={nodeProps.fillHover}
								iconFill={nodeProps.iconFill}
								iconFillHover={nodeProps.iconFillHover}
								iconPad={nodeProps.iconPad}
								iconHoverPad={nodeProps.iconHoverPad}
								iconUrl={nodeProps.iconUrl}
								iconSvg={nodeProps.iconSvg}
								iconSvgSprite={nodeProps.iconSvgSprite}
								iconSvgHover={nodeProps.iconSvgHover}
								iconSvgSpriteHover={nodeProps.iconSvgSpriteHover}
								onClick={onClickCircle}
								onContextMenu={(e) => {
									e.preventDefault();
									onClickActions(e);
								}}
							/>
						</svg>
					)}

				<DefaultPortWidgets
					engine={engine}
					widthPad={localProps.widthPad}
					setTargetActionFn={setTargetActionFn}
					targets={linkData.targets}
					{...this.props}
				/>

			</div>
		);
	}
}
