import { ConfirmCloseModalAction, ConfirmOpenModalAction } from "../../actions/info/confirm";

export const confirmReducer = (state = null, action) => {
	switch (action.type) {
		case ConfirmOpenModalAction.getType():
			return { open: true, ...action.payload };
		case ConfirmCloseModalAction.getType():
			return { open: false };
		default:
			return state;
	}
};
