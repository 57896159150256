import tokenSagas from "./token";
import registerSagas from "./register";
import loginSagas from "./login";
import activateSagas from "./activate";
import resetSagas from "./reset";
import verifySagas from "./verify";
import profileSagas from "./profile";

export const authSagas = [
	...tokenSagas,
	...registerSagas,
	...loginSagas,
	...activateSagas,
	...resetSagas,
	...verifySagas,
	...profileSagas
];

export default authSagas;
