import {
	CloseEditNodeModalAction,
	OpenEditNodeModalAction
} from "../../../../../../../actions/playground/inlet/filter/canvas/phase/node/edit";

export const editReducer = (state = null, action) => {
	switch (action.type) {
		case OpenEditNodeModalAction.getType():
			return { open: true, ...action.payload };
		case CloseEditNodeModalAction.getType():
			return { open: false };
		default:
			return state;
	}
};
