import {
	PrivateRouteAction,
	ProcessResetAction,
	ProcessResetFailedAction,
	ProcessResetSuccessAction,
	ResetPasswordCloseModalAction,
	ResetPasswordModeProcess,
	ResetPasswordModeRequest,
	ResetPasswordOpenModalAction,
	SendResetPasswordAction,
	SendResetPasswordFailedAction,
	SendResetPasswordSuccessAction,
	VerifyResetTokenAction,
	VerifyResetTokenFailedAction,
	VerifyResetTokenSuccessAction
} from "../../actions/auth";
import { ErrCodeUserResetTokenInvalid, ErrCodeUserResetTokenMissing } from "../../errs";

export const resetReducer = (state = null, action) => {
	const payload = (action.payload) ? action.payload : {};
	let invalid = false;
	let mode = (state) ? state.mode : null;
	switch (action.type) {
		case ResetPasswordOpenModalAction.getType():
			return { open: true, ...payload };
		case ResetPasswordCloseModalAction.getType():
			return { open: false };
		// Send Reset Email
		case SendResetPasswordAction.getType():
			return { ...state, loading: true, error: null, invalid: false, success: null, ...payload };
		case SendResetPasswordSuccessAction.getType():
			return { ...state, loading: false, error: null, success: true, ...payload };
		case SendResetPasswordFailedAction.getType():
			return { ...state, loading: false, error: payload };
		// Verify Reset Token
		case VerifyResetTokenAction.getType():
			return { ...state, loading: true, error: null, verifySuccess: false, invalid: false, ...payload };
		case VerifyResetTokenSuccessAction.getType():
			mode = ResetPasswordModeProcess;
			return { ...state, open: true, mode: mode, verifySuccess: true, loading: false, error: null, ...payload };
		case VerifyResetTokenFailedAction.getType():
			return { ...state, open: true, loading: false, verifySuccess: false, error: payload, invalid: true };
		// Process Reset
		case ProcessResetAction.getType():
			return { ...state, loading: true, error: null, success: false, invalid: false, ...payload };
		case ProcessResetSuccessAction.getType():
			return { ...state, loading: false, error: null, success: true, ...payload };
		case ProcessResetFailedAction.getType():
			// In some error states we will switch mode
			if (payload.error_code) {
				// Token expired since we verified it
				if (payload.error_code === ErrCodeUserResetTokenInvalid || payload.error_code === ErrCodeUserResetTokenMissing) {
					mode = ResetPasswordModeRequest;
					invalid = true;
				}
			}

			return { ...state, loading: false, mode: mode, invalid: invalid, error: payload };
		// Reset when private route changes
		case PrivateRouteAction.getType():
			return {};
		default:
			return state;
	}
};
