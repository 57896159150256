import { apiFetch } from "../base";

export function * postSendReset (payload) {
	return yield apiFetch({
		endpoint: "/reset/send",
		method: "POST",
		authenticated: false,
		body: JSON.stringify(payload)
	});
}

export function * getVerifyReset ({ token }) {
	return yield apiFetch({
		endpoint: `/reset/verify/${token}`,
		method: "GET",
		authenticated: false
	});
}

export function * postProcessReset ({ token, ...payload }) {
	return yield apiFetch({
		endpoint: `/reset/process/${token}`,
		method: "POST",
		authenticated: false,
		body: JSON.stringify(payload)
	});
}
