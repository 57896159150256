import {makeUUID} from "../../util/uuid";
import {TemplateKind} from "./types";
import CompletableDeferred from "../../util/CompletableDeferred";
import {environment} from "../../generated/env";

export type Scaffold = {
    editor: TemplateKind;
    templateId: string
    token: string;
}

export interface IFramedEditor {
    ready(): Promise<boolean>;

    update(token: string): void;

    detach(): void;
}

interface IPayload {
    scaffold: string;
    ready: CompletableDeferred<boolean>;
}

const windowMessageGlobalPayloadName = Symbol("windowMessageGlobalPayloadName");
const windowMessageGlobalHandlerName = Symbol("windowUpwireEditorMessageGlobalHandler");

function getPayloadContainer() {
    const window = global.window as any;

    if (!window.hasOwnProperty(windowMessageGlobalPayloadName))
        window[windowMessageGlobalPayloadName] = {};
    return window[windowMessageGlobalPayloadName];
}

function pushPayload(uuid: string, payload: IPayload) {
    const container = getPayloadContainer();
    container[uuid] = payload;
}

function getPayload(uuid: string, remove: boolean = false): IPayload | null {
    const container = getPayloadContainer();
    const payload = container[uuid];
    if (remove)
        delete container[uuid];
    return payload;
}

function attachWindowFrameEventListener() {

    if (window.hasOwnProperty(windowMessageGlobalHandlerName))
        return;

    window.addEventListener("message", (event: any) => {

        if (!event.data)
            return;

        const {type, editorId: uuid} = event.data;

        if (type === "reload")
            window.location.reload();

        if (!type || !uuid)
            return;

        const payload = getPayload(uuid);
        if (!payload)
            return;

        const {scaffold, ready} = payload;

        if (type === "ready")
            event.source.postMessage({type: "init", data: scaffold}, "*");

        if (type === "loaded")
            ready.resolve(true);

        event.stopImmediatePropagation();
        event.preventDefault();
        return true;

    }, false);
}

export function attachUpwireEditorFrame(host: HTMLElement, scaffold: Scaffold): IFramedEditor {

    const ready = new CompletableDeferred<boolean>();
    const uuid = makeUUID();

    let detached = false;

    // clear all children
    while (host.firstChild)
        host.removeChild(host.firstChild);

    // add a new iframe into the hosted template editor
    const iframe = document.createElement("iframe");

    pushPayload(uuid, {
        scaffold: JSON.stringify(scaffold),
        ready
    });

    attachWindowFrameEventListener();

    host.appendChild(iframe);

    iframe.src = `${(environment.external)}/editors/index.html?editorId=${uuid}`;

    ready.promise.then(() => {
        getPayload(uuid, true);
    });

    return {
        update(token: string) {
            const proxy = iframe.contentWindow;
            if (proxy)
                proxy.postMessage({type: "token", data: JSON.stringify({token})}, "*");
        },
        ready: () => ready.promise,
        detach: () => {
            if (!detached) {
                host.removeChild(iframe);
                ready.resolve(false);
                detached = true;
            }
        }
    };
}
