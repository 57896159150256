import {
	ClosePlaygroundDebuggerModalAction,
	OpenPlaygroundDebuggerModalAction,
	PlaygroundDebuggerExecAction,
	PlaygroundDebuggerExecFailedAction,
	PlaygroundDebuggerExecResetAction,
	PlaygroundDebuggerExecSuccessAction,
	PlaygroundDebuggerQueryAction,
	PlaygroundDebuggerQueryFailedAction,
	PlaygroundDebuggerQueryResetAction,
	PlaygroundDebuggerQuerySuccessAction,
	PlaygroundDebuggerTraceAction,
	PlaygroundDebuggerTraceFailedAction,
	PlaygroundDebuggerTraceResetAction,
	PlaygroundDebuggerTraceSuccessAction
} from "../../actions/playground/debugger";

export const debuggerReducer = (state = null, action) => {
	switch (action.type) {
		case OpenPlaygroundDebuggerModalAction.getType():
			return { open: true, exec: {}, trace: {}, ...action.payload };
		case ClosePlaygroundDebuggerModalAction.getType():
			return { open: false };

		// Exec
		case PlaygroundDebuggerExecResetAction.getType():
			return { ...state, exec: {} };
		case PlaygroundDebuggerExecAction.getType():
			return {
				...state,
				exec: { ...state.exec, loading: true, result: undefined, error: undefined, ...action.payload }
			};
		case PlaygroundDebuggerExecSuccessAction.getType():
			return { ...state, exec: { ...state.exec, loading: false, result: action.payload } };
		case PlaygroundDebuggerExecFailedAction.getType():
			return { ...state, exec: { ...state.exec, loading: false, error: action.payload } };

		// Trace
		case PlaygroundDebuggerTraceResetAction.getType():
			return { ...state, trace: {} };
		case PlaygroundDebuggerTraceAction.getType():
			return {
				...state,
				trace: { ...state.trace, loading: true, result: undefined, error: undefined, ...action.payload }
			};
		case PlaygroundDebuggerTraceSuccessAction.getType():
			return { ...state, trace: { ...state.trace, loading: false, result: action.payload } };
		case PlaygroundDebuggerTraceFailedAction.getType():
			return { ...state, trace: { ...state.trace, loading: false, error: action.payload } };

		// Query
		case PlaygroundDebuggerQueryResetAction.getType():
			return { ...state, query: {} };
		case PlaygroundDebuggerQueryAction.getType():
			return {
				...state,
				query: { ...state.query, loading: true, result: undefined, error: undefined, ...action.payload }
			};
		case PlaygroundDebuggerQuerySuccessAction.getType():
			return { ...state, query: { ...state.query, loading: false, result: action.payload } };
		case PlaygroundDebuggerQueryFailedAction.getType():
			return { ...state, query: { ...state.query, loading: false, error: action.payload } };

		default:
			return state;
	}
};
