import cloneDeep from "lodash/cloneDeep";
import { v4 as uuid } from "uuid";

export const extractUpdatablePlayground = (dashboard) => {
	if (!dashboard.playground) {
		throw new Error("no 'playground' found in dashboard data");
	}

	return {
		...cloneDeep(dashboard.playground),
		apply: {
			// TODO: determine (currently not in the data, will need to add).
			source_version: "HEAD"
		}
	};
};

export const addNewInlet = (playground) => {
	if (!playground) {
		throw new Error("no playground given");
	}
	if (!playground.schema) {
		throw new Error("no 'schema' found in playground data");
	}
	if (!playground.schema.inlets) {
		playground.schema.inlets = [];
	}

	const inletId = uuid();

	playground.schema.inlets.push({
		id: inletId,
		name: "Default",
		enabled: true
	});

	return [playground, inletId];
};

export const addNewFilter = (playground, inletId, opts) => {
	if (!playground) {
		throw new Error("no playground given");
	}
	if (!playground.schema) {
		throw new Error("no 'schema' found in playground data");
	}

	if (!inletId) {
		[playground, inletId] = addNewInlet(playground);
	} else {
		if (!playground.schema.inlets) {
			throw new Error("no 'schema.inlets' found in playground data (given inletId: " + inletId + ")");
		}
	}

	for (let i = 0; i < playground.schema.inlets.length; i++) {
		const inlet = playground.schema.inlets[i];
		if (inlet.id === inletId) {
			const filterId = opts.id || uuid();
			if (!playground.schema.inlets[i].filters) {
				playground.schema.inlets[i].filters = [];
			}

			playground.schema.inlets[i].filters.push({
				id: filterId,
				enabled: false,
				...opts
			});

			return [playground, filterId];
		}
	}

	throw new Error("inletId '" + inletId + "' not found in schema.inlets");
};

export const updateFilter = (playground, filterId, opts) => {
	if (!playground) {
		throw new Error("no 'playground' provided");
	}
	if (!filterId) {
		throw new Error("no 'filterId' provided");
	}
	if (!opts) {
		opts = {};
	}

	if (playground.schema.inlets) {
		playground.schema.inlets.forEach((i, ii) => {
			if (i.filters) {
				i.filters.forEach((f, fi) => {
					if (f.id === filterId) {
						playground.schema.inlets[ii].filters[fi] = {
							...playground.schema.inlets[ii].filters[fi],
							...opts
						};
					}
				});
			}
		});
	}

	return playground;
};

export const extractFilterFromDashboard = (dashboard, filterId) => {
	if (!dashboard.playground) {
		throw new Error("no 'playground' found in dashboard data");
	}
	if (!dashboard.playground) {
		throw new Error("no 'playground' found in dashboard data");
	}
	if (!dashboard.canvases) {
		throw new Error("no 'canvases' found in dashboard data");
	}
	if (!filterId) {
		throw new Error("no 'filterId' provided");
	}

	const { playground } = dashboard;
	if (!playground.schema) {
		throw new Error("no 'schema' found in playground data");
	}

	let filter;
	if (playground.schema.inlets) {
		playground.schema.inlets.forEach(i => {
			if (i.filters) {
				i.filters.forEach(f => {
					if (f.id === filterId) {
						filter = f;
					}
				});
			}
		});
	}

	if (!filter) {
		throw new Error("failed to find filterId: " + filterId + " in dashboard data");
	}

	return filter;
};

export const extractCanvasByFilterId = (dashboard, filterId) => {
	return extractCanvasByFilter(dashboard,
		extractFilterFromDashboard(dashboard, filterId));
};

export const extractCanvasByFilter = (dashboard, filter) => {
	if (!filter) {
		throw new Error("no 'filter' given");
	}
	if (!filter.result || !filter.result.canvas_id) {
		throw new Error("filterId: " + filter.id + " has no canvas attached to it");
	}

	const canvas = dashboard.canvases[filter.result.canvas_id];
	if (!canvas) {
		throw new Error("failed to find canvasId: " + filter.result.canvas_id + " in dashboard data");
	}

	return canvas;
};

export const extractUpdatableEditFilterModel = (dashboard, filterId) => {
	const filter = extractFilterFromDashboard(dashboard, filterId);
	const canvas = extractCanvasByFilter(dashboard, filter);

	return {
		...filter,
		name: canvas.name
	};
};

export const updateDashboardSettings = (dashboard, opts) => {
	const playground = extractUpdatablePlayground(dashboard);

	const { name, settings } = opts;

	playground.name = name;
	playground.settings = settings;

	return {
		...playground
	};
};
