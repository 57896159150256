import {
	CloseEditFilterModalAction,
	OpenEditFilterModalAction
} from "../../../../actions/playground/inlet/filter/edit";

export const editReducer = (state = null, action) => {
	switch (action.type) {
		case OpenEditFilterModalAction.getType():
			return { open: true, ...action.payload };
		case CloseEditFilterModalAction.getType():
			return { open: false };
		default:
			return state;
	}
};
