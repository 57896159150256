import React, { Component } from "react";
import PropTypes from "prop-types";
import { FieldArray } from "formik";
import { Droppable } from "react-beautiful-dnd";

import config from "../../../../../../../../../config";
import { CfgColors } from "core/config";

import LogicGroupAction from "core/components/common/logic/group/LogicGroupAction";
import LogicGroup from "core/components/common/logic/group/LogicGroup";
import { BranchingGroupItems, ItemTypeCatchAll } from "./BranchingGroupItems";

import { defaultNodeBranchingTarget } from "../../../../../../../../../lib/playground/canvas/phase/node/init";

import "./BranchingOutcomeCatchAll.scss";

const colourCfg = config.get(CfgColors);

// TODO: Move and implement better.
const defaultTargets = [
	{
		goto: {
			target: "end"
		}
	}
];

const CatchAllOverview = ({ addTarget }) => {
	return (
		<div className="catch-all-overview-container">
			<div className="catch-all-overview">
				<div className="overview-header">
					When no conditions match
				</div>
				<div className="overview-description">
					The targets to the left will be executed when none of the targets in
					the rules above are matched.
				</div>
			</div>
		</div>
	);
};

export class BranchingOutcomeCatchAll extends Component {
	static propTypes = {
		namePath: PropTypes.string.isRequired,
		outcome: PropTypes.string.isRequired,
		outcomeBranching: PropTypes.object,
		nodeProps: PropTypes.object.isRequired,
		playgroundProps: PropTypes.object.isRequired,
		nodeInfoCbRef: PropTypes.object.isRequired,
		setModalHidden: PropTypes.func.isRequired,
		groupLabelWidth: PropTypes.number,
		values: PropTypes.object,
		touched: PropTypes.object,
		errors: PropTypes.object,
		setFieldValue: PropTypes.func
	};

	render () {
		let {
			namePath, outcome, outcomeBranching, nodeProps, playgroundProps, nodeInfoCbRef, setModalHidden,
			groupLabelWidth, values, touched, errors, setFieldValue
		} = this.props;
		if (!outcomeBranching) {
			outcomeBranching = {};
		}

		const branching = (outcomeBranching.default)
			? outcomeBranching.default
			: [];
		const isDefaulted = (!branching || branching.length <= 0);
		const curValues = (!isDefaulted) ? branching : defaultTargets;
		const isEmpty = (curValues.length < 1);

		return (
			<FieldArray name={namePath + ".default"}>
				{arrayHelpers => (
					<Droppable
						droppableId={namePath + ".default"}
						type="target"
					>
						{(provided, snapshot) => (
							<LogicGroup
								label="Catch-All"
								isEmpty={isEmpty}
								className="catch-all"
								width={groupLabelWidth}
								color={colourCfg.upwireOrange1}
								isDraggingOver={snapshot.isDraggingOver}
							>

								<div className={"catch-all-content " + ((isDefaulted) ? "is-defaulted " : "")}>
									<div className="catch-all-items">
										<BranchingGroupItems
											items={curValues}
											itemType={ItemTypeCatchAll}
											provided={provided}
											namePath={namePath}
											subPath="default"
											group={outcomeBranching}
											outcome={outcome}
											disableEdit={isDefaulted}
											nodeProps={nodeProps}
											playgroundProps={playgroundProps}
											nodeInfoCbRef={nodeInfoCbRef}
											setModalHidden={setModalHidden}
											setFieldValue={setFieldValue}
											arrayHelpers={arrayHelpers}
											values={values}
											touched={touched}
											errors={errors}
										/>

										{!isDefaulted
											? <LogicGroupAction
												label="Add Target"
												color={colourCfg.upwireOrange1}
												onClick={() => arrayHelpers.push(defaultNodeBranchingTarget())}
											/>
											: (
												<button
													type="button"
													className="button is-small is-info is-rounded customise-button"
													onClick={() => arrayHelpers.push(defaultNodeBranchingTarget())}
													style={{ backgroundColor: colourCfg.upwireOrange1 }}
												>
													Customise
												</button>
											)}
									</div>

									<CatchAllOverview
										isDefaulted={isDefaulted}
										addTarget={() => arrayHelpers.push(defaultNodeBranchingTarget())}
									/>
								</div>
							</LogicGroup>
						)}
					</Droppable>
				)}
			</FieldArray>
		);
	}
}
