import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import config from "../../config";
import { CfgColors } from "core/config";
import Modal from "core/components/common/modal/Modal";
import { CloseNewPlaygroundModalAction, PlaygroundNewAction } from "../../actions/playground/new";
import PlaygroundSettingsForm from "../../components/playground/settings/PlaygroundSettingsForm";
import { getNamespace } from "core/lib/auth/token";

class PlaygroundNewModal extends Component {
	componentWillUnmount () {
		let { closeModal, playgroundNew } = this.props;
		if (!playgroundNew) {
			playgroundNew = {};
		}
		let model = playgroundNew.model;
		if (!model) {
			model = {};
		}

		if (playgroundNew.open) {
			if (model.namespace && model.playground_id) {
				closeModal();
			}
		}
	}

	render () {
		let { closeModal, playgroundNew, playgroundList, doCreatePlayground } = this.props;
		if (!playgroundNew) {
			playgroundNew = {};
		}
		if (!playgroundList) {
			playgroundList = {};
		}
		let model = playgroundNew.model;
		if (!model) {
			model = {};
		}

		const groups = playgroundList.groups || [];

		const open = !!(playgroundNew && playgroundNew.open);
		if (!open) {
			return null;
		}

		if (model.namespace && model.playground_id) {
			return <Redirect to={`/playground/${model.namespace}/${model.playground_id}`}/>;
		}
		if (playgroundNew.loading) {
			closeModal = () => {
				// Disabled.
			};
		}

		const colourCfg = config.get(CfgColors);

		const onSubmit = (form) => {
			doCreatePlayground({
				namespace: getNamespace(),
				model: {
					...form,
					// Automatically create the first canvas.
					init_canvas: true
				}
			});
		};

		return (
			<>
				<Modal
					title="Create Playground"
					closeModal={closeModal}
					icon="fas fa-code-branch"
					modalState={open}
					headBgColor={colourCfg.blue}
					fontColor={colourCfg.white}
				>
					<PlaygroundSettingsForm
						model={model}
						groups={groups}
						onSubmit={onSubmit}
						buttonBgColor={colourCfg.blue}
						buttonFontColor={colourCfg.white}
						buttonLabel="Create"
					/>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(CloseNewPlaygroundModalAction()),
		doCreatePlayground: (payload) => dispatch(PlaygroundNewAction(payload))
	};
};

const mapStateToProps = (state, props) => {
	const mapped = {
		core: state.get("core")
	};

	const canvas = state.get("canvas");
	if (!canvas) {
		return mapped;
	}
	const playground = canvas.get("playground");
	if (!playground) {
		return mapped;
	}

	return {
		...mapped,
		playgroundNew: playground.get("new"),
		playgroundList: playground.get("list")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaygroundNewModal);
