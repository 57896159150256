import { NodeModel } from "@projectstorm/react-diagrams";

export class BaseNodeModel extends NodeModel {
	setCentrePosition (x, y) {
		const size = this.getOptions().size;
		if (size) {
			return super.setPosition(x - (size / 2), y - (size / 2));
		}

		return super.setPosition(x, y);
	}

	getCentrePosition () {
		const size = this.getOptions().size;
		const position = this.getPosition();

		return [position.x + (size / 2), position.y + (size / 2)];
	}
}
