import { createAction } from "redux-act";

export const OpenPlaygroundDebuggerModalAction = createAction("Playground Debugger - Open Modal");
export const ClosePlaygroundDebuggerModalAction = createAction("Playground Debugger - Close Modal");

export const PlaygroundDebuggerExecAction = createAction("Playground Debugger Exec");
export const PlaygroundDebuggerExecRequestAction = createAction("Playground Debugger Exec - Request");
export const PlaygroundDebuggerExecSuccessAction = createAction("Playground Debugger Exec - Success");
export const PlaygroundDebuggerExecFailedAction = createAction("Playground Debugger Exec - Failed");

export const PlaygroundDebuggerExecResetAction = createAction("Playground Debugger Exec - Reset");

export const PlaygroundDebuggerTraceAction = createAction("Playground Debugger Trace");
export const PlaygroundDebuggerTraceRequestAction = createAction("Playground Debugger Trace - Request");
export const PlaygroundDebuggerTraceSuccessAction = createAction("Playground Debugger Trace - Success");
export const PlaygroundDebuggerTraceFailedAction = createAction("Playground Debugger Trace - Failed");

export const PlaygroundDebuggerTraceResetAction = createAction("Playground Debugger Trace - Reset");

export const PlaygroundDebuggerQueryAction = createAction("Playground Debugger Query");
export const PlaygroundDebuggerQueryRequestAction = createAction("Playground Debugger Query - Request");
export const PlaygroundDebuggerQuerySuccessAction = createAction("Playground Debugger Query - Success");
export const PlaygroundDebuggerQueryFailedAction = createAction("Playground Debugger Query - Failed");

export const PlaygroundDebuggerQueryResetAction = createAction("Playground Debugger Query - Reset");
