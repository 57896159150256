export const getNestedState = (state, keys) => {
	let curState = state;
	if (keys && keys.length > 0) {
		keys.forEach(k => {
			if (curState) {
				curState = curState.get(k);
			}
		});
	}

	return curState;
};
