import { v4 as uuid } from "uuid";
import { walkPhaseNodes } from "./node/walker";

export const defaultCanvasPhase = () => {
	return {
		id: uuid(),
		name: "Default"
	};
};

export const ensurePhaseInitialised = (phase) => {
	if (!phase.nodes) {
		phase.nodes = {};
	}

	walkPhaseNodes(phase, (nodeId, parentNodeId) => {
		if (phase.nodes[nodeId]) {
			if (!phase.nodes[nodeId].parent) {
				phase.nodes[nodeId].parent = parentNodeId;
			}
		}
	});

	return phase;
};
