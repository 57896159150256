import React from "react";
import {makeAutoReducer} from "../util/reducers";

export interface IScreenState {
    fullScreenMode: boolean;
    editorMode: boolean;
}

const initialScreenState: IScreenState = {
    fullScreenMode: false,
    editorMode: false
};

export const ScreenContext = React.createContext({
    state: initialScreenState,
    dispatch: (update: Partial<IScreenState>) => {}
});

export const ScreenContextProvider = ({children}: { children?: React.ReactNode }) => {
    const [state, dispatch] = React.useReducer(makeAutoReducer<IScreenState>(), initialScreenState);
    return (
        <ScreenContext.Provider value={{state: state, dispatch: dispatch}}>
            {children}
        </ScreenContext.Provider>
    );
};

