import {
	PrivateRouteAction,
	PrivateRouteLoginAction,
	PrivateRouteModeLogin,
	PrivateRouteModeRegister,
	PrivateRouteRegisterAction
} from "../../actions/auth";

export const privateReducer = (state = null, action) => {
	switch (action.type) {
		case PrivateRouteAction.getType():
			return {};
		case PrivateRouteLoginAction.getType():
			return { ...state, mode: PrivateRouteModeLogin, ...action.payload };
		case PrivateRouteRegisterAction.getType():
			return { ...state, mode: PrivateRouteModeRegister, ...action.payload };
		default:
			return state;
	}
};
