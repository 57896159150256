import React from "react";

import InputField from "core/components/common/form/InputField";
import * as Yup from "yup";

export const getBasicInitialValues = (model) => {
	return {
		name: model.name || "",
		group_id: (model.group_id) ? model.group_id : ""
	};
};

export const getBasicYupSchema = () => {
	return {
		name: Yup.string().required("Name is required")
	};
};

const BasicFields = ({ groups, values, ...props }) => {
	const options = [
		{ value: "", label: "Default" }
	];

	if (groups && groups.length > 0) {
		groups.forEach(g => {
			options.push({ value: g.id, label: g.name });
		});
	}

	return (
		<>
			<InputField
				key="name" name="name" label="Name" values={values}
				type="text" placeholder="Playground name"
				{...props}
			/>
			<InputField
				key="group_id" name="group_id" label="Group" values={values}
				type="select" placeholder="Select group" options={options}
				{...props}
			/>
		</>
	);
};

export default BasicFields;
