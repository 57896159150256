import { combineReducers } from "redux-immutable";

import nodeReducers from "./node";
import targetReducers from "./target";
import { newReducer } from "./new";
import { editReducer } from "./edit";

export default combineReducers({
	new: newReducer,
	edit: editReducer,
	node: nodeReducers,
	target: targetReducers
});
