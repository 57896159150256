import { combineReducers } from "redux-immutable";

import { newReducer } from "./new";
import { editReducer } from "./edit";

import canvasReducers from "./canvas";

export default combineReducers({
	new: newReducer,
	edit: editReducer,
	canvas: canvasReducers
});
