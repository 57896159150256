import { InfoCloseModalAction, InfoOpenModalAction } from "../../actions/info/info";

export const infoReducer = (state = null, action) => {
	switch (action.type) {
		case InfoOpenModalAction.getType():
			return { open: true, ...action.payload };
		case InfoCloseModalAction.getType():
			return { open: false };
		default:
			return state;
	}
};
