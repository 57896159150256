import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import IconSvgElem from "./IconSvgElem";

import { NodeStyleCircle, NodeStyleSquare } from "../../../../lib/common/diagram/node";

import "./ShapeButtonSvgElem.scss";

export const ShapeSvg = ({
	style, size, padding, fill, fillHover, isHover,
	disableDropShadow, offsetX, offsetY, stroke, strokeWidth
}) => {
	switch (style) {
		case NodeStyleCircle:
			return (
				<circle
					r={size / 2 - padding} cx={(size / 2) + offsetX} cy={(size / 2) + offsetY}
					fill={isHover ? fillHover : fill} stroke={stroke} strokeWidth={strokeWidth}
					filter={((!disableDropShadow) ? "url(#drop-shadow)" : "")} className="circle"
				/>
			);
		case NodeStyleSquare:
			return (
				<rect
					width={size - (padding * 2)} height={size - (padding * 2)}
					x={offsetX + padding} y={offsetY + padding} rx={size * 0.10} ry={size * 0.10}
					fill={isHover ? fillHover : fill}
					filter={((!disableDropShadow) ? "url(#drop-shadow)" : "")} className="square"
				/>
			);
		default:
	}

	return "";
};

export const ShapeButtonSvgElem = ({
	fill, fillHover, style, size, padding, iconPad, iconHoverPad, iconFill, iconFillHover,
	iconUrl, iconHoverUrl, iconSvg, iconSvgSprite, iconSvgHover, iconSvgSpriteHover,
	disableHover, disableDropShadow, offsetX, offsetY, stroke, strokeWidth,
	hasAction, onClick, onContextMenu, onMouseOver, onMouseLeave
}) => {
	if (!style) {
		style = NodeStyleCircle;
	}
	if (!offsetX) {
		offsetX = 0;
	}
	if (!offsetY) {
		offsetY = 0;
	}

	let [isHover, setIsHover] = useState(false);

	if (disableHover) {
		isHover = false;
	}

	if (style === NodeStyleSquare) {
		// Pad Squares
		padding += (size * 0.05);
		// Remove padding on icon.
		iconPad -= (size * 0.05);
	}

	const iconX = iconPad + padding + offsetX;
	const iconY = iconPad + padding + offsetY;
	const iconWidth = size - ((iconPad * 2) + padding * 2);

	const iconHoverX = iconHoverPad + padding + offsetX;
	const iconHoverY = iconHoverPad + padding + offsetY;
	const iconHoverWidth = size - ((iconHoverPad * 2) + padding * 2);

	if (!fillHover) {
		fillHover = "#EEEEEE";
	}
	if (!iconSvgHover) {
		iconSvgHover = iconSvg;
	}
	if (!iconSvgSpriteHover) {
		iconSvgSpriteHover = iconSvgSprite;
	}

	return (
		<g
			className={classNames(
				"svg-shape-button",
				{ "no-hover": disableHover },
				{ "has-action": (onClick || hasAction) }
			)}
			onClick={onClick}
			onContextMenu={onContextMenu}
			onMouseOver={() => {
				setIsHover(true);

				if (onMouseOver) {
					onMouseOver();
				}
			}}
			onMouseLeave={() => {
				setIsHover(false);

				if (onMouseLeave) {
					onMouseLeave();
				}
			}}
		>

			<ShapeSvg
				style={style} size={size} padding={padding}
				fill={fill} fillHover={fillHover} stroke={stroke} strokeWidth={strokeWidth}
				isHover={isHover} disableDropShadow={disableDropShadow}
				offsetX={offsetX} offsetY={offsetY}
			/>

			<IconSvgElem
				key={isHover ? iconSvgSpriteHover : iconSvgSprite}
				fill={isHover ? iconFillHover : iconFill}
				width={isHover ? iconHoverWidth : iconWidth}
				x={isHover ? iconHoverX : iconX}
				y={isHover ? iconHoverY : iconY}
				url={isHover ? iconHoverUrl : iconUrl}
				svg={isHover ? iconSvgHover : iconSvg}
				svgSprite={isHover ? iconSvgSpriteHover : iconSvgSprite}
			/>
		</g>
	);
};

ShapeButtonSvgElem.propTypes = {
	style: PropTypes.string,
	fill: PropTypes.string.isRequired,
	fillHover: PropTypes.string,
	size: PropTypes.number.isRequired,
	padding: PropTypes.number.isRequired,
	iconPad: PropTypes.number.isRequired,
	iconFill: PropTypes.string,
	iconFillHover: PropTypes.string,
	iconSvg: PropTypes.string,
	iconSvgSprite: PropTypes.string,
	iconSvgHover: PropTypes.string,
	iconSvgSpriteHover: PropTypes.string,
	offsetX: PropTypes.number,
	offsetY: PropTypes.number,
	onClick: PropTypes.func,
	hasAction: PropTypes.bool
};

export default ShapeButtonSvgElem;
