import { apiFetch } from "../base";

export function * getVerify ({ token }) {
	return yield apiFetch({
		endpoint: `/verify/${token}`,
		method: "GET",
		authenticated: false
	});
}

export function * postVerificationResend (payload) {
	return yield apiFetch({
		endpoint: "/verify/resend",
		method: "POST",
		authenticated: false,
		body: JSON.stringify(payload)
	});
}
