import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { ErrorContent } from "../../../core/components/common/error/ErrorContent";
import Loading from "../loading/Loading";

import "./WindowContent.scss";

export const WindowContent = ({ error, loading, children }) => {
	return (
		<>
			{error
				? (
					<ErrorContent error={error}/>
				)
				: (
					loading && <Loading/>
				)}

			<div className={classNames(
				"window-content",
				{ hidden: loading || error }
			)}
			>
				{children}
			</div>
		</>
	);
};

WindowContent.propTypes = {
	error: PropTypes.object,
	loading: PropTypes.bool
};

export default WindowContent;
