import React, { PureComponent } from "react";
import connect from "react-redux/es/connect/connect";

import config, { CfgColors } from "../../../../config";
import { getNestedState } from "../../../../lib/state";

import Modal from "../../../../components/common/modal/Modal";
import { ConditionBuilderCloseModalAction } from "../../../../actions/logic/condition/builder";
import LogicConditionBuilderForm from "../../../../components/common/logic/condition/builder/LogicConditionBuilderForm";

const colourCfg = config.get(CfgColors);

class ConditionBuilderModal extends PureComponent {
	render () {
		const { builder, closeModal } = this.props;
		const open = !!(builder && builder.open);

		if (!open) {
			return null;
		}

		const title = builder.title || "Conditions";
		const icon = builder.icon || "fad fa-ballot-check";
		const fontColor = builder.fontColor || colourCfg.white;
		const headBgColor = builder.headBgColor || colourCfg.blue;

		const doClose = () => {
			closeModal();

			if (builder.onClose) {
				builder.onClose();
			}
		};

		const onSubmit = (form) => {
			doClose();

			if (builder.onSave) {
				builder.onSave(form);
			}
		};

		return (
			<Modal
				title={title}
				closeModal={doClose}
				modalState={open}
				icon={icon}
				fontColor={fontColor}
				headBgColor={headBgColor}
			>

				<LogicConditionBuilderForm
					builder={builder}
					onSubmit={onSubmit}
					fontColor={fontColor}
					headBgColor={headBgColor}
					closeModal={doClose}
					isDialog={false}
				/>

			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(ConditionBuilderCloseModalAction())
	};
};

const mapStateToProps = (state, props) => {
	const core = state.get("core");
	if (!core) {
		return {};
	}

	const builder = getNestedState(core, ["logic", "condition", "builder"]);
	if (!builder) {
		return {};
	}

	return {
		builder: builder
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConditionBuilderModal);
