import React from "react";

import ConditionRule from "./ConditionRule";

import "./ConditionRules.scss";

export const ConditionRules = ({
	rules, provided, namePath, group, disableEdit,
	setFieldValue, arrayHelpers, values, touched, errors
}) => {
	return (
		<div
			className="condition-rules"
			ref={provided.innerRef}
			{...provided.droppableProps}
		>

			{rules.map((rule, index) => {
				const curNamePath = `${namePath}.rules[${index}]`;

				return (
					<ConditionRule
						key={"rule-" + index}
						rule={rule}
						index={index}
						group={group}
						namePath={curNamePath}
						disableEdit={disableEdit}
						doDelete={() => arrayHelpers.remove(index)}
						setFieldValue={setFieldValue}
						values={values}
						touched={touched}
						errors={errors}
					/>
				);
			})}

			{provided.placeholder}
		</div>
	);
};

export default ConditionRules;
