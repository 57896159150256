import React from "react";
import {ArrayHelpers, Field, FieldArray} from "formik";
import * as Yup from "yup";
import {extractArrayErrors, extractErrorMessageFromArrayErrors} from "core/util/form/helper";

import "./SessionVars.scss";
import {SessionVar, Settings} from "../../types";


function makeNewSessionVariable(name = ""): SessionVar {
    return {
        type: "string",
        name: name,
        default: "",
    };
}

export const getSessionVarsValues = (settings: Settings) => {
    return (settings.sessionVars) ? settings.sessionVars : [];
};

export const getSessionVariablesYupSchema = () => {
    return Yup.array()
        .of(Yup.object().shape({
                type: Yup.string().required("Input type is required"),
                name: Yup.string().required("Input name is required"),
                default: Yup.string().test("NameRequired", "Default value is invalid", function () {
                    return true;
                })
            })
        );
};


type TextFieldProps = {
    fullName: string;
    hasError: boolean;
    placeholder: string;
}

function TextField(props: TextFieldProps) {
    const {fullName, hasError, placeholder} = props;
    const isSmall = false;
    return <Field
        className={"input " + (hasError ? "is-danger " : "") + ((isSmall) ? "is-small " : "")}
        name={fullName}
        placeholder={placeholder}
    />;
}

type SessionVariableFieldProps = {
    index: number;
    touched: any;
    errors: any;
    arrayHelpers: ArrayHelpers;
}


function SessionVariableField(props: SessionVariableFieldProps) {

    const {index, touched, errors, arrayHelpers} = props;

    const errorData = extractArrayErrors("sessionVars", index, errors, touched);
    const errorMessage = extractErrorMessageFromArrayErrors(errorData);

    return (
        <div className="data-variable-field-container">
            <div className="data-variable-field">
                <div className="field-name">
                    <TextField fullName={`sessionVars[${index}].name`}
                               hasError={!!errorData.name} placeholder="Variable name"/>
                </div>
                <div className="field-default">
                    <TextField
                        fullName={`sessionVars[${index}].default`}
                        hasError={!!errorData.default}
                        placeholder="Initial Value" {...props}
                    />
                </div>

                <div className="field-actions">
                    <button type="button" className="button is-danger" onClick={() => arrayHelpers.remove(index)}>
            		    <span className="icon is-small">
						    <i className="fa fa-trash"/>
                        </span>
                    </button>
                </div>
            </div>
            {errorMessage ? <p className="help is-danger">{errorMessage}</p> : ""}
        </div>
    );
}

// { values, ...props }

type SessionVariablesFieldProps = {
    values: any;
    errors: any;
    touched: any
}

export function SessionVariableFieldEditor(props: SessionVariablesFieldProps) {

    const {values} = props;

    function push(ah: ArrayHelpers, name = "") {
        ah.push(makeNewSessionVariable(name));
    }

    const hint = "{{inputs.key}}";

    return <>

        <div className="settings-description">
            Session variables can be used and modified throughout one run of a playground.
            Use <code>{hint}</code> to reference an input. Session variables can also be created on the fly.
        </div>

        <FieldArray name="sessionVars">
            {arrayHelpers => {

                return (
                    <>
                        {values.sessionVars.length > 0
                            ? (
                                <div className="data-variable-header-fields">
                                    <div className="padding"/>
                                    <div className="field-actions"/>
                                </div>
                            )
                            : ""}
                        <div className="data-variable-fields">
                            {values.sessionVars.map((variable: any, index: number) => (
                                <SessionVariableField
                                    key={index}
                                    index={index}
                                    arrayHelpers={arrayHelpers}
                                    {...props}
                                />
                            ))}
                        </div>
                        <div className="data-variable-field-new">
                            <div className="new-action-label">Add Session Variable</div>
                            <div className="new-action-button">
                                <button type="button" className="button is-success"
                                        onClick={() => push(arrayHelpers)}>
									<span className="icon is-small">
									  <i className="fa fa-plus-circle"/>
									</span>
                                </button>
                            </div>
                        </div>
                    </>
                );
            }}
        </FieldArray>
    </>;
}

