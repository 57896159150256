import React from "react";

import PlaygroundGroupNewModal from "./containers/playground/PlaygroundGroupNewModal.container";
import PlaygroundNewModal from "./containers/playground/PlaygroundNewModal.container";
import PlaygroundSettingsModal from "./containers/playground/PlaygroundSettingsModal.container";
import PlaygroundDebuggerModal from "./containers/playground/PlaygroundDebuggerModal.container";
import FilterNewModal from "./containers/playground/inlet/filter/FilterNewModal.container";
import FilterEditModal from "./containers/playground/inlet/filter/FilterEditModal.container";
import PhaseNewModal from "./containers/playground/inlet/filter/canvas/phase/PhaseNewModal.container";
import PhaseEditModal from "./containers/playground/inlet/filter/canvas/phase/PhaseEditModal.container";
import SetTargetModal from "./containers/playground/inlet/filter/canvas/phase/target/SetTargetModal.container";
import ExistingTargetModal
	from "./containers/playground/inlet/filter/canvas/phase/target/ExistingTargetModal.container";
import TerminateTargetModal
	from "./containers/playground/inlet/filter/canvas/phase/target/TerminateTargetModal.container";
import NodeEditModal from "./containers/playground/inlet/filter/canvas/phase/node/NodeEditModal.container";
import NodeNewModal from "./containers/playground/inlet/filter/canvas/phase/node/NodeNewModal.container";
import PlaygroundGroupEditModal from "./containers/playground/PlaygroundGroupEditModal.container";

export const CanvasModals = () => {
	return (
		<>
			<PlaygroundGroupNewModal/>
			<PlaygroundGroupEditModal/>
			<PlaygroundNewModal/>
			<PlaygroundSettingsModal/>
			<PlaygroundDebuggerModal/>
			<FilterNewModal/>
			<FilterEditModal/>
			<PhaseNewModal/>
			<PhaseEditModal/>
			<SetTargetModal/>
			<ExistingTargetModal/>
			<TerminateTargetModal/>
			<NodeNewModal/>
			<NodeEditModal/>
		</>
	);
};

export default CanvasModals;
