import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "./ModalLoading.scss";
import Loading from "../../../../components/common/loading/Loading";

class ModalLoading extends PureComponent {
	render () {
		const { modalState } = this.props;
		if (!modalState) {
			return null;
		}

		return (
			<div className="modal modal-loading is-active">
				<div className="modal-background"/>
				<Loading/>
			</div>
		);
	}
}

ModalLoading.propTypes = {
	modalState: PropTypes.bool.isRequired
};

export default ModalLoading;
