import {
	CloseSetTargetModalAction,
	OpenSetTargetModalAction
} from "../../../../../../../actions/playground/inlet/filter/canvas/phase/target/set";

export const setReducer = (state = null, action) => {
	switch (action.type) {
		case OpenSetTargetModalAction.getType():
			return { open: true, ...action.payload };
		case CloseSetTargetModalAction.getType():
			return { open: false };
		default:
			return state;
	}
};
