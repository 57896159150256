import React from "react";
import {Alert, AlertTitle} from "@mui/material";

export function Stub() {
    return <Alert severity="warning">
        <AlertTitle>Stub</AlertTitle>
        <p>This section is a stub.</p>
    </Alert>;
}

export default Stub;
