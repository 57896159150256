// eslint-disable-next-line no-redeclare

import React, { Component } from "react";
import PropTypes from "prop-types";

import { deleteCanvasPhaseTrash } from "../../../../../../../lib/playground/canvas/phase/update";

import config from "../../../../../../../config";
import { CfgColors } from "core/config";

import "./RecycleBinWidget.scss";
import { ContextMenuPositionHorizontalRight } from "core/components/common/context/ContextMenuContent";

const cfgColors = config.get(CfgColors);

export class RecycleBinWidget extends Component {
	static propTypes = {
		danglingNodes: PropTypes.array.isRequired
	};

	render () {
		const { danglingNodes, playgroundProps } = this.props;
		const { showContextMenu } = playgroundProps;

		const count = danglingNodes.length;

		const handleEmptyTrash = (playgroundProps) => {
			playgroundProps.openConfirm({
				title: "Empty Trash?",
				confirmLabel: "Delete",
				headBgColor: cfgColors.red,
				fontColor: cfgColors.white,
				content: "Are you sure you want to empty the trash? This action is not reversible and all nodes in the trash bin will be removed.",
				onConfirm: () => {
					const { dashboard, canvas, phase, updateCanvas } = playgroundProps;
					const canvasId = canvas.canvas_id;
					const phaseId = phase.id;

					updateCanvas({
						namespace: canvas.namespace,
						playgroundId: canvas.playground_id,
						canvasId: canvasId,
						model: deleteCanvasPhaseTrash(dashboard, canvasId, phaseId, danglingNodes)
					});
				}
			});
		};

		if (count < 1) {
			return "";
		}

		const backgroundColor = cfgColors.purple;
		const fontColor = cfgColors.white;

		const handleContextMenu = (e) => {
			e.preventDefault();

			showContextMenu({
				actions: [
					{
						label: "Empty Trash",
						icon: "fas fa-dumpster-fire",
						onClick: () => {
							handleEmptyTrash(playgroundProps);
						}
					}
				],
				eventY: e.clientY,
				eventX: e.clientX,
				positionHorizontal: ContextMenuPositionHorizontalRight,
				itemActiveColor: backgroundColor,
				itemActiveFontColor: fontColor
			});
		};

		return (
			<div
				className="phase-recycle-bin-widget"
				onContextMenu={handleContextMenu}
				style={{
					"--background-color": backgroundColor,
					"--font-color": fontColor
				}}
				onClick={() => { handleEmptyTrash(playgroundProps); }}
			>
				<div className="bin-background">
          <span className="icon">
            <i className="fa fa-trash"/>
          </span>
				</div>
				<div className="bin-badge">
					<div className="count">{count}</div>
				</div>
			</div>
		);
	}
}
