import { call, put, takeLatest } from "redux-saga/effects";

import {
	PlaygroundDashboardAction,
	PlaygroundDashboardFailedAction,
	PlaygroundDashboardRequestAction,
	PlaygroundDashboardSuccessAction
} from "../../actions/playground/dashboard";
import { getPlaygroundDashboard } from "../../api/playground/dashboard";

/// / Playground Dashboard
// Worker
function * getPlaygroundDashboardWorker (action) {
	try {
		yield put(PlaygroundDashboardRequestAction());
		const r = yield call(getPlaygroundDashboard, action.payload);
		if (r.json) {
			yield put(PlaygroundDashboardSuccessAction(r.json));
		} else {
			yield put(PlaygroundDashboardFailedAction("Failed to fetch playground dashboard"));
		}
	} catch (e) {
		yield put(PlaygroundDashboardFailedAction(e));
	}
}

// Saga
export function * getPlaygroundDashboardSaga () {
	yield takeLatest(PlaygroundDashboardAction.getType(), getPlaygroundDashboardWorker);
}

// Export

export const dashboardSagas = [
	getPlaygroundDashboardSaga()
];

export default dashboardSagas;
