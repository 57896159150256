import React, { Component } from "react";
import PropTypes from "prop-types";

import config from "../../../../../config";
import { CfgColors } from "core/config";

import Card from "./Card";

import "./NewPlaygroundCard.scss";

export class NewPlaygroundCard extends Component {
	state = {
		hover: false
	};

	setHover = (hover) => {
		this.setState(({
			hover: hover
		}));

		return false;
	};

	render () {
		const { group, openNewPlaygroundModal } = this.props;
		const { hover } = this.state;

		const colours = config.get(CfgColors);

		let accentColor = colours.grey;
		if (hover) {
			accentColor = colours.blue;
		}

		return (
			<Card accentColor={accentColor}>
				<div
					className={"new-playground-card " + ((hover) ? "is-hover" : "")}
					onMouseOver={() => this.setHover(true)}
					onMouseLeave={() => this.setHover(false)}
					onClick={() => openNewPlaygroundModal({
						group_id: group.id
					})}
				>
					<div className="new-icon">
            <span className="icon">
              <i className="fa fa-plus"/>
            </span>
					</div>
					<div className="new-label">
						<span>New Playground</span>
					</div>
				</div>
			</Card>
		);
	}
}

NewPlaygroundCard.propTypes = {
	group: PropTypes.object.isRequired,
	openNewPlaygroundModal: PropTypes.func.isRequired
};

export default NewPlaygroundCard;
