import React, { Component } from "react";
import PropTypes from "prop-types";

import {
	GotoScopeCanvas,
	GotoScopePhase,
	GotoScopePlayground,
	GotoScopeStep,
	GotoTargetEnd
} from "../../../../../../../../lib/playground/constants";
import { getNodeTypeOptions, NodeTypeEnd } from "../../../../../../../../config/node";
import ShapeButtonSvgElem from "../../../../../../../common/svg/elem/ShapeButtonSvgElem";
import { initNodeProps } from "../../../../../../../../lib/common/diagram/node";
import { initStyleProps } from "../../../../../../../../lib/common/diagram/style";
import { NodeInfoCard } from "../../node/common/NodeInfoCard";

import "./TerminateTargetContent.scss";

const localNodeProps = {
	shapeSize: 100,
	shapePad: 8
};

const TerminateTargetAction = ({ scope, onSelect, closeModal, nodeInfoCbRef }) => {
	let nodeProps = getNodeTypeOptions(NodeTypeEnd, {});

	if (scope) {
		if (nodeProps.scopes && nodeProps.scopes[scope]) {
			nodeProps = {
				...nodeProps,
				...nodeProps.scopes[scope]
			};
		}
	}

	const node = {
		nodeProps: nodeProps
	};

	// Locally Configured Node Info
	const localNode = {
		nodeProps: {
			...nodeProps,
			...localNodeProps
		}
	};

	const shapeProps = initNodeProps(
		initStyleProps({
			...localNode.nodeProps
		})
	);

	const handleClick = () => {
		closeModal();

		onSelect({
			type: NodeTypeEnd,
			nodeProps: nodeProps,
			definition: {
				scope: scope,
				target: GotoTargetEnd
			}
		});
	};

	return (
		<div
			className="terminate-target-action"
			onClick={handleClick}
			onMouseOver={() => {
				if (nodeInfoCbRef && nodeInfoCbRef.setNode) {
					nodeInfoCbRef.setNode(node);
				}
			}}
			onMouseLeave={() => {
				if (nodeInfoCbRef && nodeInfoCbRef.setNode) {
					nodeInfoCbRef.setNode();
				}
			}}
		>
			<svg className="svg-icon" width={shapeProps.shapeSize} height={shapeProps.shapeSize}>
				<ShapeButtonSvgElem
					style={shapeProps.style}
					size={shapeProps.shapeSize}
					padding={shapeProps.shapePad}
					fill={shapeProps.fill}
					iconFill={shapeProps.iconFill}
					iconPad={shapeProps.iconPad}
					iconHoverPad={shapeProps.iconHoverPad}
					iconSvg={shapeProps.iconSvg}
					iconSvgSprite={shapeProps.iconSvgSprite}
					disableDropShadow={shapeProps.disableDropShadow}
					hasAction disableHover
				/>
			</svg>
			<div className="target-label">
				{shapeProps.label}
			</div>
		</div>
	);
};

TerminateTargetAction.propTypes = {
	scope: PropTypes.string,
	onSelect: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	nodeInfoCbRef: PropTypes.object.isRequired
};

class TerminateTargetContent extends Component {
	render () {
		const { closeModal, onSelect } = this.props;

		// Init external ref to avoid top level re-render.
		const nodeInfoCbRef = {};

		return (
			<>
				<section className="modal-card-body">
					<div className="terminate-target-actions">
						<TerminateTargetAction
							scope={GotoScopeStep}
							onSelect={onSelect}
							closeModal={closeModal}
							nodeInfoCbRef={nodeInfoCbRef}
						/>
						<TerminateTargetAction
							scope={GotoScopePhase}
							onSelect={onSelect}
							closeModal={closeModal}
							nodeInfoCbRef={nodeInfoCbRef}
						/>
						<TerminateTargetAction
							scope={GotoScopeCanvas}
							onSelect={onSelect}
							closeModal={closeModal}
							nodeInfoCbRef={nodeInfoCbRef}
						/>
						<TerminateTargetAction
							scope={GotoScopePlayground}
							onSelect={onSelect}
							closeModal={closeModal}
							nodeInfoCbRef={nodeInfoCbRef}
						/>
					</div>
				</section>

				<footer className="modal-card-foot space-between">
					<button type="button" className="button" onClick={closeModal}>Cancel</button>
					<NodeInfoCard cbRef={nodeInfoCbRef}/>
				</footer>
			</>
		);
	}
}

TerminateTargetContent.propTypes = {
	closeModal: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired
};

export default TerminateTargetContent;
