import {
	CloseTerminateTargetModalAction,
	OpenTerminateTargetModalAction
} from "../../../../../../../actions/playground/inlet/filter/canvas/phase/target/terminate";

export const terminateReducer = (state = null, action) => {
	switch (action.type) {
		case OpenTerminateTargetModalAction.getType():
			return { open: true, ...action.payload };
		case CloseTerminateTargetModalAction.getType():
			return { open: false };
		default:
			return state;
	}
};
