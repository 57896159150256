import React from "react";
import {NavLink} from "react-router-dom";

export interface IMenuItem {
    iconClass: string;
    label: string;
    to: any;
    extraClass?: string;
    closeNav?: () => void;
}

const MenuItem = ({to, iconClass, label, closeNav, extraClass}: IMenuItem) => {
    const baseClass = `menu-item-link ${extraClass ?? ""}`;
    return <NavLink className={baseClass} activeClassName="is-active" to={to} onClick={closeNav} exact>
        <div className="icon-text">
            <span className="icon">
                <i className={`fas fa-${iconClass}`} aria-hidden="true"/>
            </span>
            <span className="menu-item-label">{label}</span>
        </div>
    </NavLink>;
};

export default MenuItem;
