import React from "react";

import "./SplashContainer.scss";

export const SplashContainer = ({ children }) => {
	return (
		<div className="splash-container page-flex">
			{children}
		</div>
	);
};

export default SplashContainer;
