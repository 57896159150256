import { call, put, takeLatest } from "redux-saga/effects";

import {
	PlaygroundGroupNewAction,
	PlaygroundGroupNewFailedAction,
	PlaygroundGroupNewRequestAction,
	PlaygroundGroupNewSuccessAction
} from "../../../actions/playground/group/new";
import { postPlaygroundGroupCreate } from "../../../api/playground/group/new";
import { PlaygroundListAction } from "../../../actions/playground/list";
/// / Playground Group New
// Worker
function * newPlaygroundGroupWorker (action) {
	try {
		yield put(PlaygroundGroupNewRequestAction());

		const r = yield call(postPlaygroundGroupCreate, action.payload);
		if (r.json) {
			yield put(PlaygroundGroupNewSuccessAction(r.json));

			// Reload Playgrounds
			yield put(PlaygroundListAction());
		} else {
			yield put(PlaygroundGroupNewFailedAction("Failed to post playground group create"));
		}
	} catch (e) {
		yield put(PlaygroundGroupNewFailedAction(e));
	}
}

// Saga
export function * newPlaygroundGroupSaga () {
	yield takeLatest(PlaygroundGroupNewAction.getType(), newPlaygroundGroupWorker);
}

// Export

export const newSagas = [
	newPlaygroundGroupSaga()
];

export default newSagas;
