import React, { Component } from "react";
import { connect } from "react-redux";

import config from "../../../../../../../config";
import { CfgColors } from "core/config";
import Modal from "core/components/common/modal/Modal";
import { getNestedState } from "core/lib/state";
import {
	CloseExistingTargetModalAction
} from "../../../../../../../actions/playground/inlet/filter/canvas/phase/target/existing";
import ExistingTargetContent
	from "../../../../../../../components/playground/inlet/filter/canvas/phase/target/existing/ExistingTargetContent";

class ExistingTargetModal extends Component {
	render () {
		let { closeModal, existingTarget } = this.props;
		if (!existingTarget) {
			existingTarget = {};
		}

		let { nodeProps } = existingTarget;
		if (!nodeProps) {
			nodeProps = {};
		}

		const open = !!(existingTarget && existingTarget.open);
		if (!open) {
			return null;
		}

		if (existingTarget.loading) {
			closeModal = () => {
				// Disabled.
			};
		}

		const handleClose = () => {
			closeModal();

			if (existingTarget.onClose) {
				existingTarget.onClose();
			}
		};

		const colourCfg = config.get(CfgColors);

		return (
			<>
				<Modal
					title="Select Existing"
					closeModal={handleClose}
					icon="fas fa-list"
					modalState={open}
					headBgColor={colourCfg.yellow}
					fontColor={colourCfg.white}
					width="750px"
					maxWidth="calc(100vw - 30px)"
				>
					<ExistingTargetContent
						nodeProps={nodeProps}
						closeModal={handleClose}
						onSelect={(payload) => {
							existingTarget.onSelect(payload);
						}}
						borderColor={colourCfg.yellow}
					/>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(CloseExistingTargetModalAction())
	};
};

const mapStateToProps = (state, props) => {
	const mapped = {};

	const core = state.get("core");
	if (core) {
		mapped.profile = core.get("profile");
	}

	const playground = getNestedState(state, ["canvas", "playground"]);
	if (!playground) {
		return mapped;
	}

	const target = getNestedState(playground, ["inlet", "filter", "canvas", "phase", "target"]);
	if (!target) {
		return mapped;
	}

	return {
		...mapped,
		existingTarget: target.get("existing")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ExistingTargetModal);
