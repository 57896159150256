import React from "react";
import Stub from "../../components/common/Stub/Stub";

export const Dashboard = () => {
	return <>
		<Stub/>
	</>;
};

export default Dashboard;
