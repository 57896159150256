import {API_BASE_URL, FormatData, FormatterOutput} from "./formatter"

const header = `
const response = await fetch('|API_BASE|/|ENDPOINT|', {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer |TOKEN|",
    "UP-Account-Key": "|ACCOUNT_KEY|",
  },
  body: JSON.stringify(|DATA|),`;


const ending = `);
const result = await response.json();
`;


export function format_js({payload, endpoint, accountKey, token}: FormatData): FormatterOutput {
    let code = header.replace(/\|DATA\|/, JSON.stringify(payload, null, 2));
    code = code.replace(/\|ENDPOINT\|/, endpoint);
    code = code.replace(/\|TOKEN\|/, token);
    code = code.replace(/\|API_BASE\|/, API_BASE_URL);
    code = code.replace(/\|ACCOUNT_KEY\|/, accountKey);

    const end = "}),";
    if (code.endsWith(end))
        code = code.substring(0, code.length - end.length) + "  })\n}";
    code += ending;

    return {
        code: code,
        language: "javascript"
    };
}
