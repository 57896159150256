import React from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Form, Formik } from "formik";

import cloneDeep from "lodash/cloneDeep";
import Tabs, { Tab } from "core/components/common/tabs/Tabs";
import InputField from "core/components/common/form/InputField";
import ParamFields, { getParamInitialValues, getParamYupSchema } from "../../../common/logic/param/ParamFields";
import Loading from "components/common/loading/Loading";
import InlineError from "core/components/common/message/InlineError";
import InlineInfo from "core/components/common/message/InlineInfo";
import { MessageDetails } from "core/components/common/message/MessageDetails";
import { environmentQueryToOptions } from "core/lib/constants";

const PlaygroundDebuggerExecFormSchema = Yup.object().shape({
	environment: Yup.string().required("Environment is required"),
	params: getParamYupSchema()
});

const applyModelToForm = (model, dashboard) => {
	const form = {
		environment: model.environment,
		params: []
	};

	const cloneModel = cloneDeep(model);
	const seenNames = {};

	if (dashboard && dashboard.playground && dashboard.playground.settings) {
		if (dashboard.playground.settings.variables) {
			dashboard.playground.settings.variables.forEach(v => {
				let value = v.default;
				if (cloneModel.params && cloneModel.params[v.name]) {
					value = cloneModel.params[v.name];
				}

				if (value || v.required) {
					form.params.push({
						name: v.name,
						value: value
					});

					seenNames[v.name] = true;
				}
			});
		}
	}

	if (cloneModel.params) {
		Object.keys(cloneModel.params).forEach(name => {
			if (!seenNames[name]) {
				form.params.push({
					name: name,
					value: cloneModel.params[name]
				});
			}
		});
	}

	return form;
};

const applyFormToModel = (form) => {
	const cloneForm = cloneDeep(form);
	const newModel = {
		environment: cloneForm.environment,
		params: {}
	};

	if (cloneForm.params) {
		cloneForm.params.forEach(p => {
			newModel.params[p.name] = p.value;
		});
	}

	return newModel;
};

const getInitialValues = (formData, environmentOptions) => {
	return {
		params: getParamInitialValues(formData)
	};
};

const getResultMessageDetails = (result) => {
	const details = [];

	if (result.correlation_id) {
		details.push({
			title: "Correlation Id",
			value: result.correlation_id
		});
	}
	if (result.run_id) {
		details.push({
			title: "Run Id",
			value: result.run_id
		});
	}
	if (result.status) {
		details.push({
			title: "Status",
			value: result.status
		});
	}

	return details;
};

export const PlaygroundDebuggerExecForm = ({
	model, dashboard, environments, onSubmit, reset, handleTrace, buttonBgColor, buttonFontColor
}) => {
	if (!model) {
		model = {};
	}

	const formData = applyModelToForm(model, dashboard);

	const onSubmitAdaptor = (form) => {
		return onSubmit(applyFormToModel(form));
	};

	const environmentOptions = environmentQueryToOptions(environments);

	return (
		<Formik
			onSubmit={onSubmitAdaptor}
			initialValues={getInitialValues(formData, environmentOptions)}
			validationSchema={PlaygroundDebuggerExecFormSchema}
		>
			{({ errors, touched, handleReset, ...props }) => (
				<Form className="up-form with-tabs">
					<div className="pg-debugger-tab-content">
						{model.loading
							? <Loading minHeight={180}/>
							: (
								<Tabs borderColor={buttonBgColor} errors={errors} touched={touched} {...props}>
									<Tab id="options" title="Options" fields={["environment"]} fullHeight>
										<InputField
											key="environment" name="environment" label="Environment"
											type="select" options={environmentOptions}
											errors={errors} touched={touched}
											{...props}
										/>
									</Tab>
									<Tab id="params" title="Parameters" fields={["params"]} fullHeight>
										<ParamFields errors={errors} touched={touched} {...props} />
									</Tab>
								</Tabs>
							)}
						{(model.error || model.result) && (
							<div className="up-form-inline-messages">
								{model.error && (
									<InlineError title="Error" error={model.error} showMessage/>
								)}
								{model.result && (
									<InlineInfo title="Result">
										<MessageDetails details={getResultMessageDetails(model.result)}/>

										{model.result.run_id && (
											<div className="buttons">
												<button
													type="button" className="button is-small is-info"
													onClick={() => {
														handleTrace({
															runId: model.result.run_id,
															verbose: false
														});
													}}
												>
													Trace
												</button>
												<button
													type="button" className="button is-small"
													onClick={() => {
														handleTrace({
															runId: model.result.run_id,
															verbose: true
														});
													}}
												>
													Verbose Trace
												</button>
											</div>
										)}
									</InlineInfo>
								)}
							</div>
						)}
					</div>
					<div className="up-form-inline-controls">
						<div className="buttons">
							<button
								type="submit" className="button is-small primary" style={{
								backgroundColor: buttonBgColor,
								color: buttonFontColor
							}} disabled={model.loading}
							>
								Execute
							</button>

							<button
								type="button" className="button is-small" onClick={() => {
								handleReset();
								reset();
							}} disabled={model.loading}
							>
								Reset
							</button>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};

PlaygroundDebuggerExecForm.propTypes = {
	model: PropTypes.object.isRequired,
	dashboard: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	reset: PropTypes.func.isRequired,
	handleTrace: PropTypes.func.isRequired,
	buttonBgColor: PropTypes.string.isRequired,
	buttonFontColor: PropTypes.string.isRequired
};

export default PlaygroundDebuggerExecForm;
