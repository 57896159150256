import resourcesSagas from "./resources";
import authSagas from "./auth";
import preferenceSagas from "./preferences";

export const coreSagas = [
	...resourcesSagas,
	...authSagas,
	...preferenceSagas
];

export default coreSagas;
