import {DispatchApi} from "../../core/dispatch/dispatch";
import {useToken} from "../../state/UserContextProvider";

export type NumberConfig = {
    name: string;
    voiceTemplateId: string | null;
    smsTemplateId: string | null;

    callbackFormat: string | null;
    callbackUrl: string | null;
    callbackMethod: string | null;
    callbackUsername: string | null;
    callbackPassword: string | null;

    $modified: boolean;
}

export type NumberDescriptor = NumberConfig & {
    number: string;
    country: string;
    isActive: boolean;
    isMobile: boolean;
    isSMS: boolean;
    isVoice: boolean;
    namespace: string;
    modified: number;

    numberId: string;

    callbackFormat: string | null;
    callbackUrl: string | null;
    callbackMethod: string | null;
    callbackUsername: string | null;
    callbackPassword: string | null;
}


export class NumbersDispatchApi extends DispatchApi {

    constructor(token: string | undefined | null) {
        super(token, "numbers");
    }

    async getNumbers(): Promise<NumberDescriptor[]> {
        const list = await this.dispatch<NumberDescriptor[]>("list");
        return list.sort(a => a.modified).reverse();
    }

    async updateNumber(id: string, config: NumberConfig) {
        if (!config.$modified) {
            return;
        }

        await this.dispatch<void>("update", {"numberId": id, ...config});
    }
}

export function useNumberDispatchApi() {
    return new NumbersDispatchApi(useToken());
}
