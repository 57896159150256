import * as React from "react";
import { AbstractReactFactory } from "@projectstorm/react-canvas-core";
import { DefaultNodeWidget } from "./DefaultNodeWidget";
import { SmallNodeModel } from "./SmallNodeModel";
import { isNodeBranchingOutcomeAvailable } from "../../../../config/node/util";
import { BranchingOutcomeFailure, BranchingOutcomeSuccess } from "../../../../lib/playground/constants";

export class SmallNodeFactory extends AbstractReactFactory {
	constructor () {
		super("small");
	}

	generateReactWidget (event) {
		const opts = event.model.getOptions();

		return (
			<DefaultNodeWidget
				engine={this.engine}
				node={event.model}
				right={isNodeBranchingOutcomeAvailable(opts, BranchingOutcomeSuccess)}
				bottom={isNodeBranchingOutcomeAvailable(opts, BranchingOutcomeFailure)}
				{...opts}
			/>
		);
	}

	generateModel (event) {
		return new SmallNodeModel();
	}
}
