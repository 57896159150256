import React, { PureComponent } from "react";
import Modal from "../../components/common/modal/Modal";
import connect from "react-redux/es/connect/connect";

import { ConfirmCloseModalAction } from "../../actions/info/confirm";

class ConfirmModal extends PureComponent {
	render () {
		const { confirm, closeModal } = this.props;

		if (!confirm) {
			return "";
		}

		const open = !!(confirm.open);
		const title = confirm.title || "";
		const content = confirm.content || "";
		const confirmLabel = confirm.confirmLabel || "Yes";
		const task = confirm.task;

		const closeModalWrapper = () => {
			if (task) task.resolve(false);
			closeModal();
		};

		const handleConfirm = () => {
			if (task) task.resolve(true);
			closeModalWrapper();
			if (confirm.onConfirm) {
				confirm.onConfirm();
			}
		};

		return (
			<Modal
				title={title}
				icon={confirm.icon}
				closeModal={closeModalWrapper}
				modalState={open}

				headBgColor={confirm.headBgColor}
				fontColor={confirm.fontColor}
			>
				<section className="modal-card-body">
					{content}
				</section>
				<footer className="modal-card-foot">
					<button
						type="button"
						className="button is-primary"
						onClick={handleConfirm}
						style={{
							backgroundColor: confirm.headBgColor,
							color: confirm.fontColor
						}}
					>
						{confirmLabel}
					</button>
					<button type="button" className="button" onClick={closeModalWrapper}>Cancel</button>
				</footer>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(ConfirmCloseModalAction())
	};
};

const mapStateToProps = (state, props) => {
	const core = state.get("core");
	if (!core) {
		return {};
	}

	return {
		confirm: core.get("confirm")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal);
