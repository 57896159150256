import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useVarContextApi, VarContext} from "./data";
import "./VarContext.scss";
import {Alert, Button, Chip, IconButton, InputAdornment, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {UpwireModalLoading, useUpwireModal} from "../../canvas/components/common/modal/modal";
import classNames from "classnames";
import {Chit} from "../../canvas/components/playground/chip/chit";


type Variable = {
    key: string;
    value: string;
}

type VarContextProps = {
    varContext: VarContext,
    onVarContextUpdate: (varContext: VarContext) => void
    onDelete: () => void
}

type VarContextValuesFieldsProps = {
    keyValue: string,
    value: string,
    onChangeKey: (text: string) => void,
    onChangeValue: (text: string) => void,
    onDelete: () => void

    readOnly: boolean
}

function isValidIdentifier(text: string): boolean {
    return !/^[a-zA-Z_$][a-zA-Z_$0-9]*$/.test(text);
}

function validateVariables(variables: Variable[]): string | null {
    const keys = new Set<string>();

    for (const variable of variables) {
        const {key} = variable;

        if (!key.length) {
            return "Variable key cannot be an empty string";
        }

        if (isValidIdentifier(key)) {
            return `Invalid identifier: "${key}"`;
        }

        if (keys.has(key)) {
            return `Duplicate variable key: "${key}"`;
        }

        keys.add(key);
    }

    return null;
}

function VarContextValuesField(props: VarContextValuesFieldsProps) {

    const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({});
    const readOnly = props.readOnly;
    const editable = !readOnly;


    const handleShowValuesToggle = (key: string) => {
        setVisibility(prevState => {
            const newState = {...prevState};
            if (newState[key]) {
                delete newState[key];
            } else {
                newState[key] = true;
            }
            return newState;
        });
    };

    return <div className="var-context-line">
        <TextField
            size="small"
            value={props.keyValue}
            autoComplete={"new-context-key"}
            className={"input"}
            disabled={readOnly}
            onChange={(ev) => props.onChangeKey(ev.target.value)}
            fullWidth
        />

        {editable && <TextField
			className={"input"}
			size="small"
			type={visibility[props.keyValue] ? "text" : "password"}
			autoComplete={"new-context-secret-value"}
			value={props.value}
			onChange={(ev) => props.onChangeValue(ev.target.value)}
			fullWidth
			InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton size="small" onClick={() => handleShowValuesToggle(props.keyValue)}>
                            {visibility[props.keyValue] ? <VisibilityIcon fontSize="small"/> :
                                <VisibilityOffIcon fontSize="small"/>}
                        </IconButton>
                    </InputAdornment>
                )
            }}
		/>}

        {editable && <IconButton onClick={props.onDelete} className="field-icon" size="small"><RemoveIcon
			fontSize="small"/></IconButton>}

    </div>;
}

function VarContextWidget(props: VarContextProps) {

    const [modified, setModified] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [shared, setShared] = useState(props.varContext.shared);
    const [variables, setVariables] = useState<Variable[]>(() => {
        let initialVars: Variable[] = [];
        if (props.varContext.variables) {
            initialVars = Object.entries(props.varContext.variables).map(([key, value]) =>
                ({key, value}));
        }
        return initialVars;
    });

    const readOnly = props.varContext.readOnly;
    const editable = !readOnly;

    useEffect(() => {
        setError(modified ? validateVariables(variables) : null);
    }, [variables, modified]);

    function updateSharing() {
        setShared(s => !s);
        setModified(true);
    }

    function addNewVariable() {
        setVariables([...variables, {key: "", value: ""}]);
    }

    function onChangeVariable(idx: number, key: string, value: string) {
        const item = {key, value};
        setVariables([
            ...variables.slice(0, idx),
            item,
            ...variables.slice(idx + 1)
        ]);

        setModified(true);
    }

    function onDeleteVariable(idx: number) {
        setVariables(prevArray => {
            return prevArray.filter((item, i) => i !== idx);
        });

        setModified(true);
    }

    function updateContext(): VarContext {
        setModified(false);
        const transformedVariables = Object.fromEntries(
            variables.map(({key, value}) => [key, value])
        );

        return {
            ...props.varContext,
            shared: shared,
            variables: transformedVariables
        };
    }

    return <div className="var-context-widget">

        <div className="var-context-widget-header">
            <div className="left-side">
                <Chit size="normal" text={props.varContext.name}/>
                {
                    shared &&
					<Chip size="small" label="shared" variant="outlined" className="header-chip"/>
                }

            </div>
            {editable && <div className="right-side">
				<Button onClick={updateSharing} size="small"
						className="header-button">{shared ? "Unshare" : "Share"}</Button>
				<IconButton onClick={props.onDelete} className="header-icon"><DeleteIcon fontSize="small"/></IconButton>
			</div>}
        </div>

        <div className="var-context-widget-fields">

            {variables.length > 0 ? variables.map((it, index) => (
                    <VarContextValuesField key={index} keyValue={it.key} value={it.value}
                                           readOnly={readOnly}
                                           onChangeKey={(key) => onChangeVariable(index, key, it.value)}
                                           onChangeValue={(value) => onChangeVariable(index, it.key, value)}
                                           onDelete={() => onDeleteVariable(index)}/>
                )) :
                <div>
                    <Alert severity="info">This variable context doesn't have any variables yet.</Alert>
                </div>

            }
        </div>

        {editable && <div className="var-context-widget-footer">

			<div className="footer-add-new">
				<Button size="small" onClick={addNewVariable}>Add New Variable</Button>
			</div>

            {
                modified && error ? <Alert severity="error">{error}</Alert> : modified &&
					<Alert sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}
						   severity="warning"><Button onClick={() => props.onVarContextUpdate(updateContext())}
													  size="small">Save
						Changes</Button></Alert>

            }
		</div>
        }

    </div>;
}


function useNewContextModal(config: { addContext: (name: string) => void }) {

    const [modified, setModified] = useState(false);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState<string | null>(null);

    const api = useVarContextApi();

    const {
        data: takenNames,
        isLoading: isLoadingNames,
        error: errorLoadingNames
    } = useQuery(["varContexts", "names"], async () => {
        return await api.getTakenContexts();
    });

    function updateContextName(name: string) {
        setModified(true);
        setName(name);
    }

    function addContext() {
        if (nameError)
            return;

        hide();
        config.addContext(name);
        setModified(false);
        setName("");
    }


    const modalBody = <div className="new-var-context-modal">
        <div className="name-context-field">
            <p className="expl">
                Create a new variable context. Variable contexts are used to group variables together.
            </p>
            <TextField
                size="small"
                value={name}
                label="Context Name"
                onChange={(e) => updateContextName(e.target.value)}
                onKeyDown={(e) => e.key === "Enter" && addContext()}
                fullWidth
            />
        </div>

        {
            modified && <div className="new-context-footer">
                {
                    nameError ?
                        <Alert severity="error" sx={{flex: "1 0 0"}}>{nameError}</Alert> :
                        <Button onClick={addContext}>Save</Button>
                }

			</div>
        }
    </div>;
    const showModalBody = isLoadingNames ? <Alert severity="info">Loading...</Alert> : modalBody;
    const showBody = errorLoadingNames ? <Alert severity="error">Error loading data...</Alert> : showModalBody;

    const {modal, show, hide} = useUpwireModal(showBody, {
            title: "Create New Variable Context",
            wide: false
        }
    );

    useEffect(() => {

        function validateContextName(name: string): string | null {
            if (!name.length) {
                return "Context name cannot be an empty string";
            }

            if (takenNames) {
                if (takenNames.includes(name)) {
                    return `${name} is already in use. Choose another one.`;
                }
            }

            if (!/^[a-zA-Z_$][0-9a-zA-Z_$]*$/.test(name)) {
                return `Invalid identifier: "${name}"`;
            }
            return null;
        }

        setNameError(validateContextName(name));
    }, [name, takenNames]);

    return {
        ui: modal,
        show: show
    };
}

function VarContextHost() {

    const client = useQueryClient();
    const api = useVarContextApi();

    const {
        data: varContexts,
        isLoading,
        error,
    } = useQuery(["varContexts", "contexts"], async () => {
        return await api.getVarContexts();
    });


    const mutator = useMutation(async (fn: () => Promise<any>) => {
        await fn();
        await client.invalidateQueries(["varContexts"]);
    });

    const newContextModal = useNewContextModal({
        addContext(name) {
            mutator.mutate(async () => {
                await api.createContext(name);
            });
        }
    });

    function deleteContext(id: string) {
        mutator.mutate(async () => {
            await api.deleteContext(id);
        });
    }

    function updateContext(varContext: VarContext) {
        mutator.mutate(async () => {
            await api.updateContext(varContext);
        });
    }


    const anythingLoading = isLoading || mutator.isLoading;

    if (anythingLoading) {
        return <div className="centered">
            <UpwireModalLoading message="Loading Variable Contexts..."/>
        </div>;
    }

    if (error) {
        return <div>Error: {error as string} </div>;
    }

    if (!varContexts) {
        return <Alert severity="error">Something went wrong.</Alert>;
    }

    return <div className="var-context-host">

        <div className={classNames("add-new-context-button", {"empty-state": varContexts.length === 0})}>
            <div className="info">
                A variable context is a collection of variables that can be used together in a workflow. You can share a
                variable context with other users to make it available for them to use in their workflows. Shared
                contexts are visible to all users in the namespace, but only the owner can edit them and see their
                values.
            </div>
            <div className="rjust">
                <Button onClick={() => newContextModal.show()} variant="outlined">Add New Context</Button>
            </div>
        </div>

        {newContextModal.ui}

        <div className="var-contexts">

            {
                varContexts.map(varContext => {
                    return <VarContextWidget
                        key={varContext.id}
                        varContext={varContext}
                        onVarContextUpdate={(vars) => {
                            updateContext(vars);
                        }}
                        onDelete={() => {
                            deleteContext(varContext.id);
                        }}
                    />;
                })
            }
        </div>

    </div>;
}


export function VarContextEditor() {
    return <div className="var-context-editor">
        <div className="var-context-editor-header">
            <h1>Variable Contexts</h1>
        </div>
        <VarContextHost/>
    </div>;
}
