import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import "./Dialog.scss";

class Dialog extends PureComponent {
	render () {
		let { children, title, theme } = this.props;

		if (!theme) {
			theme = "is-primary";
		}

		return (
			<div className="dialog-container">
				<article className={`message ${theme} ed-box-shadow dialog-message`}>
					<div className="message-header">
						<p>{title}</p>
					</div>
					{children}
				</article>
			</div>
		);
	}
}

Dialog.propTypes = {
	title: PropTypes.string,
	theme: PropTypes.string
};

export default Dialog;
