import {
	PlaygroundGroupDeleteAction,
	PlaygroundGroupDeleteFailedAction,
	PlaygroundGroupDeleteSuccessAction
} from "../../../actions/playground/group/delete";
import { registerLoadingActions } from "core/reducers/loading";
import { registerErrorActions } from "core/reducers/error";

registerLoadingActions(
	[PlaygroundGroupDeleteAction.getType()],
	[PlaygroundGroupDeleteSuccessAction.getType(), PlaygroundGroupDeleteFailedAction.getType()]
);

registerErrorActions([
	PlaygroundGroupDeleteFailedAction.getType()
]);

export const deleteReducer = (state = null, action) => {
	// Empty
	return state;
};
