import React, { useState } from "react";
import classNames from "classnames";

import { haveErrorInFields } from "../../../util/form/helper";

import config from "canvas/config";
import { CfgColors } from "../../../config";

import "./Tabs.scss";

export const TabLayoutDefault = "default";
export const TabLayoutDrawer = "drawer";

const colourCfg = config.get(CfgColors);

export const Tab = ({
	id, layout, title, activeTab, setActiveTab, fields, errors, touched,
	icon, iconColor, inactiveIconColor, borderColor, maxWidth, disabled
}) => {
	const isActive = (id === activeTab);
	const hasErrors = haveErrorInFields(fields, errors, touched);

	if (!iconColor) {
		iconColor = colourCfg.black;
	}

	if (!inactiveIconColor) {
		inactiveIconColor = colourCfg.greyDark2;
	}

	if (!maxWidth) {
		maxWidth = 150;
	}

	const styles = {
		"--active-icon-color": iconColor,
		"--inactive-icon-color": inactiveIconColor,
		"--tab-max-width": maxWidth + "px"
	};

	if (isActive) {
		styles.borderColor = borderColor;
	}

	const handleClick = () => {
		if (!disabled) {
			setActiveTab(id);
		}
	};

	return (
		<li
			id={"tab-" + id}
			key={"tab-" + id}
			className={classNames(
				{ "is-active": isActive },
				{ "has-error": hasErrors },
				{ disabled: disabled }
			)}
			style={styles}
			onClick={handleClick}
		>
			<div className={"tab-title " + ((icon) ? "with-icon" : "")}>
				{icon
					? <span className="icon"><i className={icon}/></span>
					: ""}
				<span>{title}</span>
			</div>
		</li>
	);
};
Tab.displayName = "Tab";

export const TabSection = ({
	id, layout, activeTab, borderColor, values, errors, touched, setFieldValue,
	isNested, fullHeight, className, children
}) => {
	const isActive = (id === activeTab);

	const sectionChildren = [];

	let sectionSeq = 0;
	if (children) {
		React.Children.toArray(children).forEach(c => {
			const key = "tab-section-child-" + sectionSeq++;

			if (typeof c === "object") {
				switch (c.type.displayName) {
					case "Tabs":
						sectionChildren.push(React.cloneElement(c, {
							key: key,
							values: values,
							errors: errors,
							touched: touched,
							setFieldValue: setFieldValue,
							borderColor: borderColor
						}));

						break;
					default:
						sectionChildren.push(React.cloneElement(c, {
							key: key,
							values: values,
							errors: errors,
							touched: touched,
							setFieldValue: setFieldValue
						}));

						break;
				}
			} else {
				sectionChildren.push(c);
			}
		});
	}

	return (
		<div
			key={"tab-section-" + id}
			className={"tab-section " + ((className) ? className + " " : "") + ((isNested) ? "is-nested " : "")}
			style={{ display: isActive ? "block" : "none" }}
		>
			{sectionChildren}
		</div>
	);
};
TabSection.displayName = "TabSection";

export const ErrorSection = ({ children }) => {
	return <>{children}</>;
};
ErrorSection.displayName = "ErrorSection";

export const Tabs = ({
	values, errors, touched, setFieldValue, layout, borderColor, minWidth,
	onTabSelect, selectedTab, children
}) => {
	if (!layout) {
		layout = TabLayoutDefault;
	}

	children = React.Children.toArray(children);

	let [activeTab, setActiveTab] = useState(null);

	// Override.
	if (selectedTab) {
		activeTab = selectedTab;
	}
	if (onTabSelect) {
		setActiveTab = onTabSelect;
	}

	const tabs = [];
	const sections = [];
	const errorSections = [];
	children.forEach(c => {
		const { id, title, fields, isNested, fullHeight, icon, children, ...rest } = c.props;

		switch (c.type.displayName) {
			case "Tab":
				if (!activeTab) {
					activeTab = id;
				}

				tabs.push(
					<Tab
						key={"tab-" + id}
						id={id}
						layout={layout}
						title={title}
						borderColor={borderColor}
						icon={icon}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						fields={fields}
						values={values}
						touched={touched}
						errors={errors}
						setFieldValue={setFieldValue}
						{...rest}
					/>
				);
				sections.push(
					<TabSection
						key={"tab-section-" + id}
						id={id}
						layout={layout}
						activeTab={activeTab}
						borderColor={borderColor}
						values={values}
						touched={touched}
						errors={errors}
						setFieldValue={setFieldValue}
						isNested={isNested}
						fullHeight={fullHeight}
						{...rest}
					>
						{children}
					</TabSection>
				);
				break;
			case "ErrorSection":
				errorSections.push(c);
				break;
			default:
		}
	});

	return (
		<div className={"tabs-container layout-" + layout}>
			<div className="tabs" style={{ minWidth: (minWidth) ? minWidth + "px" : undefined }}>
				<ul>
					{tabs}
				</ul>
			</div>
			<div className="tab-content">
				<div className="tab-errors">
					{errorSections}
				</div>
				<div className="tab-sections">
					{sections}
				</div>
			</div>
		</div>
	);
};
Tabs.displayName = "Tabs";

export default Tabs;
