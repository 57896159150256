import { walkNode, walkPhaseNodes } from "./walker";

export const GotoKeys = ["scope", "phase", "canvas", "target"];

export const extractNodeTargets = (node, nodeTargets) => {
	return walkNode(null, node, null, nodeTargets);
};

export const extractDangingNodes = (phase, playgroundProps) => {
	const danglingNodes = [];

	const allTargets = walkPhaseNodes(phase);

	if (phase && phase.nodes) {
		for (const nodeId in phase.nodes) {
			if (Object.prototype.hasOwnProperty.call(phase.nodes, nodeId)) {
				if (!allTargets.local || !allTargets.local[nodeId]) {
					danglingNodes.push({
						id: nodeId,
						...phase.nodes[nodeId]
					});
				}
			}
		}
	}

	return danglingNodes;
};

export const haveSeenTargetAndSetSeen = (goto, seenTargets) => {
	const uniqueKey = constructGotoUniqueKey(goto);

	if (seenTargets[uniqueKey]) {
		return true;
	}

	seenTargets[uniqueKey] = true;

	return false;
};

export const constructGotoUniqueKey = (goto) => {
	return GotoKeys.filter(k => {
		return !!goto[k];
	}).map(k => {
		const firstChar = k.substr(0, 1).toLowerCase();

		return firstChar + ":" + goto[k];
	}).join("/");
};
