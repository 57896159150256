import React, { Component } from "react";
import { connect } from "react-redux";

import { ContextMenuContent } from "../../components/common/context/ContextMenuContent";
import { getNestedState } from "../../lib/state";
import { HideContextMenuAction } from "../../actions/menu/actions";

class ContextMenu extends Component {
	render () {
		let { hideMenu, contextMenu } = this.props;

		if (!contextMenu) {
			contextMenu = {};
		}
		const open = !!(contextMenu && contextMenu.open);
		if (!open) {
			return null;
		}

		return (
			<>
				<ContextMenuContent
					doClose={() => {
						hideMenu();

						if (contextMenu.onClose) {
							contextMenu.onClose();
						}
					}}
					{...contextMenu}
				/>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		hideMenu: () => dispatch(HideContextMenuAction())
	};
};

const mapStateToProps = (state, props) => {
	const mapped = {};

	const context = getNestedState(state, ["core", "context"]);
	if (!context) {
		return mapped;
	}

	return {
		contextMenu: context.get("menu"),
		...mapped
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);
