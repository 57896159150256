import React, { Component } from "react";
import PropTypes from "prop-types";

import { shortVersion } from "../../../lib/version";
import config, { CfgColors } from "../../../config";

import "./VersionLabel.scss";

export class VersionLabel extends Component {
	render () {

		let { versionType, version } = this.props;

		if (!version) {
			version = {};
		}

		const colours = config.get(CfgColors);

		let darkColor = colours.upwirePurple2;
		let lightColor = colours.upwirePurple2Light;
		if (versionType === "TEST") {
			darkColor = colours.upwireYellow2;
			lightColor = colours.upwireYellow2Light;
		} else if (versionType === "PROD") {
			darkColor = colours.upwireGreen2;
			lightColor = colours.upwireGreen2Light;
		}

		// For missing
		if (!version.version_id) {
			darkColor = colours.greyDark;
			lightColor = colours.greyDarkLight;
		}

		const crc = shortVersion(version.version_id);

		const versionTypeStyle = {
			backgroundColor: darkColor,
			borderColor: darkColor
		};

		const versionCRCStyle = {
			backgroundColor: lightColor,
			borderColor: darkColor
		};

		return (
			<div className="version-label" style={{ borderColor: darkColor }}>
				<div className="ver-type" style={versionTypeStyle}>
					<span>{versionType}</span>
				</div>
				<div className="ver-crc" style={versionCRCStyle}>{crc}</div>
			</div>
		);
	}
}

VersionLabel.propTypes = {
	versionType: PropTypes.string.isRequired,
	version: PropTypes.object
};

export default VersionLabel;
