import {API_BASE_URL, FormatData, FormatterOutput} from "./formatter";

const header = `
curl --request POST \\
  --url |API_BASE|/|ENDPOINT| \\
  --header 'Authorization: Bearer |TOKEN|' \\
  --header 'Content-Type: application/json' \\
  --header 'UP-Account-Key: |ACCOUNT_KEY|' \\
  --data '|DATA|'`;


export function format_curl({payload, endpoint, accountKey, token}: FormatData): FormatterOutput {
    let code = header.replace(/\|DATA\|/, JSON.stringify(payload, null, 2));
    code = code.replace(/\|ENDPOINT\|/, endpoint);
    code = code.replace(/\|TOKEN\|/, token);
    code = code.replace(/\|API_BASE\|/, API_BASE_URL);
    code = code.replace(/\|ACCOUNT_KEY\|/, accountKey);

    const end = "}),";
    if (code.endsWith(end))
        code = code.substring(0, code.length - end.length) + "  })\n}";

    return {
        code: code,
        language: "curl"
    };
}
