import { v4 as uuid } from "uuid";

import { NodeTypeStart } from "../../../../../config/node";

import { extractUpdatableCanvas } from "../../update";
import { SetTargetModeCreate, SetTargetModeExisting, SetTargetModeTerminate } from "../../../constants";
import { ensureNodeInitialised, pruneNodeBranching, removeNodeFromTargets } from "./init";
import { extractNodeTargets } from "./targets";

export const extractNodeForUpdate = (phase, nodeId) => {
	if (!phase) {
		throw new Error("no phase given to extractNodeForUpdate");
	}
	if (!phase.nodes || !phase.nodes[nodeId]) {
		throw new Error("failed to find node id '" + nodeId + "' in phase id: '" + phase.id + "'");
	}

	return ensureNodeInitialised(phase.nodes[nodeId]);
};

export const createGotoTarget = (target) => {
	const { mode, payload } = target;
	const { definition } = payload;
	const newNodeId = uuid();

	const newNodes = [];
	let goto = {};

	switch (mode) {
		case SetTargetModeCreate:
			newNodes.push({
				id: newNodeId,
				definition: definition
			});
			goto = {
				target: newNodeId
			};

			break;
		case SetTargetModeExisting:
		case SetTargetModeTerminate:
			goto = definition;

			break;
		default:
			throw new Error("unknown set target mode: " + mode);
	}

	return [{
		goto: goto
	}, newNodes];
};

// Used to loosely add a target to a node's outcome (i.e. via the plus on the canvas).
export const addCanvasPhaseNodeTarget = (dashboard, canvasId, phaseId, nodeId, outcome, target) => {

	const model = extractUpdatableCanvas(dashboard, canvasId);
	const { canvas } = model;

	const [newGoto, newNodes] = createGotoTarget(target);

	let foundPhase = false;
	canvas.schema.phases.forEach((p, pi) => {
		const phase = canvas.schema.phases[pi];
		if (phase.id !== phaseId) {
			return;
		}

		if (nodeId === NodeTypeStart) {
			if (phase.start && phase.start.goto && phase.start.goto.target) {
				throw new Error("phase.start.goto.target already exists!");
			}

			phase.start = newGoto;
		} else {
			const node = extractNodeForUpdate(phase, nodeId);
			if (!node) {
				throw new Error("failed to find nodeId: " + nodeId + " in phaseId: " + phase.id);
			}

			// Add to first group (will be initialised above).
			node.branching[outcome].branching[0].branching.push(newGoto);

			phase.nodes[nodeId] = pruneNodeBranching(node);
		}

		if (!phase.nodes) {
			phase.nodes = {};
		}

		for (let nni = 0; nni < newNodes.length; nni++) {
			phase.nodes[newNodes[nni].id] = newNodes[nni].definition;
		}

		canvas.schema.phases[pi] = phase;
		foundPhase = true;
	});

	if (!foundPhase) {
		throw new Error("failed to find phase id '" + phaseId + "' in canvas id '" + canvasId + "'");
	}

	return model;
};

export const replaceCanvasPhaseNodeTarget = (dashboard, canvasId, phaseId, nodeId, target) => {
	const model = extractUpdatableCanvas(dashboard, canvasId);
	const { canvas } = model;

	const { mode, payload } = target;
	const { definition } = payload;

	if (mode !== SetTargetModeCreate) {
		throw new Error("unknown mode given to replaceCanvasPhaseNodeTarget: " + mode);
	}

	let foundPhase = false;
	for (let pi = 0; pi < canvas.schema.phases.length; pi++) {
		const phase = canvas.schema.phases[pi];
		if (phase.id !== phaseId) {
			continue;
		}

		const node = extractNodeForUpdate(phase, nodeId);

		phase.nodes[nodeId] = {
			...definition,
			name: node.name,
			branching: node.branching,
			settings: node.settings
		};

		canvas.schema.phases[pi] = phase;
		foundPhase = true;
	}

	if (!foundPhase) {
		throw new Error("failed to find phase id '" + phaseId + "' in canvas id '" + canvasId + "'");
	}

	return model;
};

export const trimNewNodes = (newNodes, node) => {
	const trimmedNodes = {};

	if (newNodes) {
		const nodeTargets = extractNodeTargets(node);

		for (const nodeId in newNodes) {
			if (Object.prototype.hasOwnProperty.call(newNodes, nodeId)) {
				if (nodeTargets && nodeTargets.local) {
					if (nodeTargets.local[nodeId]) {
						trimmedNodes[nodeId] = newNodes[nodeId];
					}
				}
			}
		}
	}

	return trimmedNodes;
};

export const unlinkCanvasPhaseNode = (dashboard, canvasId, phaseId, nodeId) => {
	const model = extractUpdatableCanvas(dashboard, canvasId);
	const { canvas } = model;
	let foundPhase = false;

	for (let pi = 0; pi < canvas.schema.phases.length; pi++) {
		const phase = canvas.schema.phases[pi];
		if (phase.id !== phaseId) {
			continue;
		}

		for (const currentNodeId in phase.nodes) {
			if (Object.prototype.hasOwnProperty.call(phase.nodes, currentNodeId)) {
				if (currentNodeId !== nodeId) {
					phase.nodes[currentNodeId] = removeNodeFromTargets(phase.nodes[currentNodeId], nodeId);
				}
			}
		}

		canvas.schema.phases[pi] = phase;

		foundPhase = true;
	}

	if (!foundPhase) {
		throw new Error("failed to find phase by id '" + phaseId + "' in dashboard data");
	}

	return model;
};
