import React, { Component } from "react";
import PropTypes from "prop-types";

import "./LogicGroupAction.scss";

export class LogicGroupAction extends Component {
	static propTypes = {
		onClick: PropTypes.func.isRequired,
		label: PropTypes.string,
		icon: PropTypes.string,
		showDelete: PropTypes.bool,
		doDelete: PropTypes.func
	};

	render () {
		let { onClick, label, icon, showDelete, doDelete } = this.props;

		if (!label) {
			label = "Add Group";
		}
		if (!icon) {
			icon = "fa fa-plus";
		}

		return (
			<div className="logic-group-action-container text-selection-none">
				<div
					className="logic-group-action"
					onClick={onClick}
				>
					<div className="action-icon">
            <span className="icon">
              <i className={icon}/>
            </span>
					</div>
					<div className="action-label">
						<span>{label}</span>
					</div>
				</div>
				{showDelete && doDelete
					? (
						<div className="logic-group-remove">
							<div className="action" onClick={doDelete}>
                <span className="icon">
                  <i className="fad fa-trash"/>
                </span>
							</div>
						</div>
					)
					: ""}
			</div>
		);
	}
}

export default LogicGroupAction;
