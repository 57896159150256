import React, { Component } from "react";
import PropTypes from "prop-types";

import VersionLabel from "./VersionLabel";

export class VersionLabels extends Component {
	render () {
		const { versions } = this.props;

		if (!versions) {
			return "";
		}

		return (
			<div className="version-labels">
				<VersionLabel versionType="HEAD" version={versions.HEAD}/>
				<VersionLabel versionType="TEST" version={versions.TEST}/>
				<VersionLabel versionType="PROD" version={versions.PROD}/>
			</div>
		);
	}
}

VersionLabels.propTypes = {
	versions: PropTypes.object.isRequired
};

export default VersionLabels;
