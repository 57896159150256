import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash/isEmpty";
import Loading from "../../../../components/common/loading/Loading";

class AutoCompleteField extends Component {
	state = {
		isOpen: false,
		selectedOption: 0,
		currentValue: null
	};

	componentDidMount () {
		document.addEventListener("mousedown", this.handleClick, false);
	}

	componentWillUnmount () {
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = (e) => {

		// TODO: For some reason this had two handlers

		{
			const { isOpen } = this.state;
			if (e.target.value && !isOpen) {
				this.setState({
					isOpen: true
				});
			}
		}

		{
			const { isOpen } = this.state.isOpen;
			if (!isOpen) {
				return;
			}

			if (!this.node) {
				return;
			}

			if (this.node.contains(e.target)) {
				return;
			}

			this.setState({
				isOpen: false
			});
		}
	};

	handleKeyUp = (e) => {
		const { currentValue } = this.state;
		if (e.keyCode !== 13) {
			if (currentValue !== e.target.value) {
				this.props.onChange({});
				this.props.doSearch(e.target.value);
				this.setState({
					isOpen: !!(e.target.value),
					selectedOption: 0,
					currentValue: e.target.value
				});
			}
		}
	};

	handleKeyDown = (e) => {
		const { selectedOption } = this.state;
		const { options } = this.props;

		if (e.keyCode === 13) {
			// Enter
			e.preventDefault();
			const opt = options[selectedOption];
			if (opt && opt.value) {
				this.chooseValue(opt.value);
			}

			return false;
		} else if (e.keyCode === 38) {
			// Up
			e.preventDefault();
			if (selectedOption === 0) {
				return;
			}

			this.setState({ selectedOption: selectedOption - 1 });
		} else if (e.keyCode === 40) {
			// Down
			e.preventDefault();
			if (selectedOption + 1 >= options.length) {
				return;
			}

			this.setState({ selectedOption: selectedOption + 1 });
		}
	};

	chooseValue = (value) => {
		this.setValue(value);
		this.props.onChange(value);
		this.setState({
			isOpen: false,
			selectedOption: 0
		});
	};

	renderOptions = (options) => {
		const { selectedOption } = this.state;
		let keyId = 0;
		return options.map((opt, i) => {
			const isSelected = (selectedOption === i);
			return (
				<button
					key={keyId++} type="button"
					className={"dropdown-item button-link " + (isSelected ? " is-active" : "")}
					onClick={() => this.chooseValue(opt.value)}
				>
					{opt.label}
				</button>
			);
		});
	};

	setValue = (value) => {
		const { renderValue } = this.props;
		if (this.input) {
			this.input.value = renderValue(value);
		}
	};

	setValueIfAbsent = () => {
		const { field, renderValue } = this.props;
		if (this.input && !this.input.value) {
			if (!isEmpty(field.value)) {
				const label = renderValue(field.value);
				if (label) {
					this.input.value = label;
				}
			}
		}
	};

	render () {
		const { placeholder, hasError, options, searchState } = this.props;
		const { isOpen } = this.state;
		const { loading, error } = searchState;

		// Initially set the value
		this.setValueIfAbsent();

		return (
			<div
				className={"dropdown autocomplete-search " + (isOpen ? "is-active" : "")} ref={node => {
				this.node = node;
			}}
			>
				<div className="dropdown-trigger">
					<input
						ref={input => {
							this.input = input;
						}}
						className={"input " + (hasError ? "is-danger" : "")} type="text" placeholder={placeholder}
						aria-haspopup="true"
						onKeyDown={this.handleKeyDown}
						onKeyUp={this.handleKeyUp}
						onClick={this.handleClick}
						autoComplete={window.isChrome ? "disabled" : "off"}
					/>
				</div>
				<div className="dropdown-menu" id="prova-menu" role="menu">
					<div className="dropdown-content">
						{loading
							? <Loading inlineSmall/>
							: error
								? <div className="error">Oops! Something went wrong.</div>
								: this.input && this.input.value && this.input.value.length > 2
									? (!isEmpty(options)
											? this.renderOptions(options)
											: <div className="empty">No results found.</div>
									)
									: <div className="info">Keep typing &hellip;</div>}
					</div>
				</div>
			</div>
		);
	}
}

AutoCompleteField.propTypes = {
	onChange: PropTypes.func.isRequired,
	doSearch: PropTypes.func.isRequired,
	renderValue: PropTypes.func.isRequired
};

export default AutoCompleteField;
