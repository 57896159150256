import { put, takeLatest } from "redux-saga/effects";
import { LoadPreferencesAction, SetPreferencesAction } from "../../actions/preferences";
import { getPreferences } from "../../lib/preferences";

///  Load
// Worker
function * loadPreferencesWorker (action) {
	yield put(SetPreferencesAction(getPreferences()));
}

// Saga
export function * loadPreferencesSaga () {
	yield takeLatest(LoadPreferencesAction.getType(), loadPreferencesWorker);
}

// Export

export const loadSagas = [
	loadPreferencesSaga()
];

export default loadSagas;
