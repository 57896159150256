import { parseModuleUrl } from "./url";
import cloneDeep from "lodash/cloneDeep";

export const extractModuleData = (module, modules) => {
	// Extract module.
	let moduleData = {};
	if (module && modules) {
		const moduleUrl = parseModuleUrl(module);
		if (moduleUrl) {
			if (modules[moduleUrl.proto] &&
				modules[moduleUrl.proto][moduleUrl.namespace] &&
				modules[moduleUrl.proto][moduleUrl.namespace][moduleUrl.moduleId]) {
				moduleData = cloneDeep(modules[moduleUrl.proto][moduleUrl.namespace][moduleUrl.moduleId]);
				moduleData.moduleUrl = moduleUrl;

				// Init props
				if (!moduleData.node_props) {
					moduleData.node_props = {};
				}

				// Remap name to label.
				if (moduleData.name) {
					moduleData.node_props.label = moduleData.name;
				}
			}
		}
	}

	return moduleData;
};
