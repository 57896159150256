export const sortPositionalArray = (a) => {
	return a.sort((a, b) => {
		const aPos = (a.position) ? a.position : 1000;
		const bPos = (b.position) ? b.position : 1000;

		if (aPos !== bPos) {
			if (aPos > bPos) {
				return 1;
			} else {
				return -1;
			}
		}

		return 0;
	});
};
