/// / Auth

export const ErrCodeAuthFailed = "UPAU000";

export const ErrCodeUserEmailExists = "TODO"; // TODO
export const ErrCodeUserNotFound = "TODO"; // TODO
export const ErrCodeUserResetTokenInvalid = "TODO"; // TODO
export const ErrCodeUserResetTokenMissing = "TODO"; // TODO
export const ErrCodeUserNotActivated = "TODO"; // TODO
export const ErrCodeUserEmailPending = "TODO"; // TODO
