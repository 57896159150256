import React from "react";
import { FieldArray } from "formik";
import * as Yup from "yup";
import { extractArrayErrors, extractErrorMessageFromArrayErrors } from "core/util/form/helper";
import { RawField } from "core/";

import "./ParamFields.scss";

const newEmptyParam = () => {
	return {
		name: "",
		value: ""
	};
};

export const getParamInitialValues = (model) => {
	return (model.params) ? model.params : [];
};

export const getParamYupSchema = () => {
	return Yup.array()
		.of(
			Yup.object().shape({
				name: Yup.string().required("Param name is required"),
				value: Yup.string().required("Param value is required")
			})
		);
};

const ParamField = ({ index, touched, values, errors, arrayHelpers, ...props }) => {
	const errorData = extractArrayErrors("params", index, errors, touched);
	const errorMessage = extractErrorMessageFromArrayErrors(errorData);

	return (
		<div className="param-field-container">
			<div className="param-field">
				<div className="field-name">
					<RawField
						name={`params[${index}].name`} values={values} touched={touched} errors={errors}
						hasError={!!errorData.name} type="text" placeholder="Variable name" {...props}
					/>
				</div>
				<div className="field-value">
					<RawField
						name={`params[${index}].value`} values={values} touched={touched} errors={errors}
						hasError={!!errorData.value}
						type="text" placeholder="Value" {...props}
					/>
				</div>
				<div className="field-actions">
					<button type="button" className="button is-danger" onClick={() => arrayHelpers.remove(index)}>
            <span className="icon is-small">
              <i className="fa fa-trash"/>
            </span>
					</button>
				</div>
			</div>
			{errorMessage ? <p className="help is-danger">{errorMessage}</p> : ""}
		</div>
	);
};

const ParamFields = ({ values, ...props }) => {
	return (
		<FieldArray name="params">
			{arrayHelpers => (
				<>
					<div className="param-fields">
						{values.params.map((variable, index) => (
							<ParamField
								key={index}
								index={index}
								arrayHelpers={arrayHelpers}
								values={values} {...props}
							/>
						))}
					</div>
					<div className="param-field-new">
						<div className="new-action-label">
							Add new param
						</div>
						<div className="new-action-button">
							<button type="button" className="button is-success"
									onClick={() => arrayHelpers.push(newEmptyParam())}>
                <span className="icon is-small">
                  <i className="fa fa-plus-circle"/>
                </span>
							</button>
						</div>
					</div>
				</>
			)}
		</FieldArray>
	);
};

export default ParamFields;
