import React, { Component } from "react";
import { connect } from "react-redux";

import config from "../../config";
import { CfgColors } from "core/config";

import Modal from "core/components/common/modal/Modal";
import PlaygroundSettingsForm from "../../components/playground/settings/PlaygroundSettingsForm";
import { ClosePlaygroundSettingsModalAction } from "../../actions/playground/settings";
import { PlaygroundUpdateAction } from "../../actions/playground/update";
import { updateDashboardSettings } from "../../lib/playground/update";

class PlaygroundSettingsModal extends Component {
	render () {
		let { closeModal, playgroundSettings, playgroundDashboard, playgroundList, updatePlayground } = this.props;
		if (!playgroundSettings) {
			playgroundSettings = {};
		}
		if (!playgroundDashboard) {
			playgroundDashboard = {};
		}
		if (!playgroundList) {
			playgroundList = {};
		}

		const groups = playgroundList.groups || [];

		const open = !!(playgroundSettings && playgroundSettings.open);
		if (!open) {
			return null;
		}

		const colourCfg = config.get(CfgColors);

		const onSubmit = (form) => {
			updatePlayground({
				namespace: playgroundDashboard.playground.namespace,
				playgroundId: playgroundDashboard.playground.playground_id,
				model: updateDashboardSettings(playgroundDashboard, form)
			});

			closeModal();
		};

		return (
			<>
				<Modal
					title="Playground Settings"
					closeModal={closeModal}
					icon="fas fa-cog"
					modalState={open}
					headBgColor={colourCfg.blue}
					fontColor={colourCfg.white}
				>
					<PlaygroundSettingsForm
						model={playgroundDashboard.playground}
						groups={groups}
						onSubmit={onSubmit}
						buttonBgColor={colourCfg.blue}
						buttonFontColor={colourCfg.white}
					/>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(ClosePlaygroundSettingsModalAction()),
		updatePlayground: (payload) => dispatch(PlaygroundUpdateAction(payload))
	};
};

const mapStateToProps = (state, props) => {
	const canvas = state.get("canvas");
	if (!canvas) {
		return {};
	}
	const playground = canvas.get("playground");
	if (!playground) {
		return {};
	}

	return {
		playgroundSettings: playground.get("settings"),
		playgroundDashboard: playground.get("dashboard"),
		playgroundList: playground.get("list")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaygroundSettingsModal);
