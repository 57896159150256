import {
	PrivateRouteLoginAction,
	PrivateRouteRegisterAction,
	RegisterAction,
	RegisterFailedAction,
	RegisterSuccessAction
} from "../../actions/auth";

export const registerReducer = (state = null, action) => {
	switch (action.type) {
		case RegisterAction.getType():
			return { ...state, loading: true, error: null, ...action.payload };
		case RegisterSuccessAction.getType():
			return { ...state, loading: false, error: null, success: true };
		case RegisterFailedAction.getType():
			return { ...state, loading: false, error: action.payload };
		case PrivateRouteLoginAction.getType():
			return {};
		case PrivateRouteRegisterAction.getType():
			return { ...action.payload };
		default:
			return state;
	}
};
