import { call, put, takeLatest } from "redux-saga/effects";
import { postLogin } from "../../api/auth/login";
import { LoginAction, LoginFailedAction, LoginRequestAction, LoginSuccessAction } from "../../actions/auth";

/// / Login
// Worker
function * postLoginWorker (action) {
	try {
		yield put(LoginRequestAction());
		const r = yield call(postLogin, action.payload);
		if (r.json) {
			yield put(LoginSuccessAction(r.json));
		} else {
			yield put(LoginFailedAction("Failed to login user"));
		}
	} catch (e) {
		yield put(LoginFailedAction(e));
	}
}

// Saga
export function * postLoginSaga () {
	yield takeLatest(LoginAction.getType(), postLoginWorker);
}

// Export

export const loginSagas = [
	postLoginSaga()
];

export default loginSagas;
