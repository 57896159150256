import crc16 from "../crc16";

export const shortVersion = (versionId) => {
	if (!versionId) {
		return "NONE";
	}

	let crcVersion = crc16(versionId).toString(16);

	if (crcVersion.length === 2) {
		crcVersion = "00" + crcVersion;
	} else if (crcVersion.length === 3) {
		crcVersion = "0" + crcVersion;
	}

	return crcVersion;
};
