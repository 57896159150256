import React, { Component } from "react";
import PropTypes from "prop-types";

import config, { CfgDiagram } from "../../../../config";
import Diagram from "./Diagram";
import { calculateOffsetY } from "./utils";

import "./DiagramContainer.scss";

const diagramCfg = config.get(CfgDiagram);

export class DiagramContainer extends Component {
	constructor (props) {
		super(props);

		this.selContainer = React.createRef();
	}

	updateDimensions = ({ minX, minY, maxX, maxY }) => {
		const { selContainer } = this;

		const { onChangeDimensions } = this.props;

		const minFromEdge = (diagramCfg.gridSize * diagramCfg.minFromEdgeMultiplier);
		const minFromCenter = (diagramCfg.gridSize * diagramCfg.minFromCenterMultiplier);
		const minTrashSpacing = (diagramCfg.gridSize * diagramCfg.minTrashSpacingMultiplier);

		// Offset (will include top padding)
		const offsetY = calculateOffsetY(minY);

		// Bottom Padding
		let paddingBottom = minFromCenter;
		if (maxY > 0) {
			const offset = (maxY + minFromEdge);
			if (offset > minFromCenter) {
				paddingBottom = minFromEdge;
			}
		}

		// Height
		let calcHeight = offsetY + (maxY + (paddingBottom));

		if (selContainer.current) {
			const { current } = selContainer;

			if ((maxX + minFromCenter) >= current.offsetWidth) {
				// Add room for the trash bin.
				calcHeight += minTrashSpacing;
			}

			current.style.height = calcHeight + "px";
		}

		if (onChangeDimensions) {
			onChangeDimensions({
				offsetY: offsetY,
				minX: minX,
				minY: minY,
				maxX: maxX,
				maxY: maxY
			});
		}

		return false;
	};

	render () {
		const { children, minHeight } = this.props;

		let childKeySeq = -1;
		const finalChildren = React.Children.toArray(children).map(c => {
			const { data, playgroundProps, ...rest } = c.props;

			childKeySeq++;

			switch (c.type.displayName) {
				case "Diagram":
					return (
						<Diagram
							key={"dc-" + childKeySeq}
							data={data}
							playgroundProps={playgroundProps}
							updateDimensions={this.updateDimensions}
							{...rest}
						/>
					);
				default:
					// Handle other types.
					return React.cloneElement(c, {
						key: "dc-" + childKeySeq
					});
			}
		});

		return (
			<div
				ref={this.selContainer}
				className="diagram-container with-grid"
				style={{ height: minHeight + "px" }}
			>
				{finalChildren}
			</div>
		);
	}
}

DiagramContainer.propTypes = {
	onChangeDimensions: PropTypes.func
};

export default DiagramContainer;
