import {DispatchApi} from "../../core/dispatch/dispatch";
import {useToken} from "../../state/UserContextProvider";
import {useQuery} from "@tanstack/react-query";

export type Variables = { [key: string]: string };


export type VarContext = {
    id: string;
    name: string;
    shared: boolean;
    accountKey: string;
    variables?: Variables;

    readOnly: boolean;
}


export class VarContextApi extends DispatchApi {

    constructor(token: string) {
        super(token, "varContexts", false);
    }

    async getVarContexts(): Promise<VarContext[]> {
        return this.dispatch("getVarContexts");
    }

    async getTakenContexts(): Promise<string[]> {
        return this.dispatch("getTakenContexts");
    }

    async createContext(name: string) {
        return this.dispatch("createVarContext", {name});
    }

    async deleteContext(varContextId: string) {
        return this.dispatch("deleteVarContext", {id: varContextId});
    }

    async updateContext(varContext: VarContext) {
        return this.dispatch("updateVarContext", varContext);
    }

}

export function useVarContextApi(): VarContextApi {
    return new VarContextApi(useToken());
}

export function useVarContexts() {
    const api = useVarContextApi();
    const {
        data: varContexts,
        isLoading: varContextsLoading,
        error: varContextsError,
    } = useQuery(["varContexts", "contexts"], async () => {
        return await api.getVarContexts();
    });

    const errorString = "" && varContextsError && varContextsError.toString();

    return {
        varContexts: varContexts,
        loading: varContextsLoading,
        error: errorString,
    };
}
