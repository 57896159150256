import { PortModelAlignment } from "@projectstorm/react-diagrams";
import { SuccessPortModel } from "../port/SuccessPortModel";
import { BaseNodeModel } from "./BaseNodeModel";
import { BranchingOutcomeSuccess } from "../../../../lib/playground/constants";
import { FailurePortModel } from "../port/FailurePortModel";
import { OptIconHoverPadPercent, OptIconPadPercent } from "core/config";

export class TargetNodeModel extends BaseNodeModel {
	constructor (opts) {
		if (!opts) {
			opts = {};
		}
		const { linkData } = opts;
		const { type, firstNode } = linkData;

		if (!opts.size) {
			if (firstNode) {
				opts.size = 74;
			} else {
				opts.size = 64;
			}
		}
		if (!opts[OptIconPadPercent]) {
			opts[OptIconPadPercent] = 14;
		}
		if (!opts[OptIconHoverPadPercent]) {
			opts[OptIconHoverPadPercent] = 14;
		}

		super({
			type: "target",
			...opts
		});
		if (type === BranchingOutcomeSuccess) {
			this.addPort(new SuccessPortModel(PortModelAlignment.LEFT, true));
		} else {
			this.addPort(new FailurePortModel(PortModelAlignment.TOP, true));
		}
	}
}
