import React, { Component } from "react";
import PropTypes from "prop-types";

import InlineMessage from "./InlineMessage";
import { ErrorMessageDetails } from "./MessageDetails";

class InlineError extends Component {
	render () {
		const { title, error, showMessage, children } = this.props;

		return (
			<InlineMessage title={title} colourClass="is-danger">
				{children}

				{error && (
					<ErrorMessageDetails error={error} showMessage={showMessage} showGeneric={!children}/>
				)}
			</InlineMessage>
		);
	}
}

InlineError.propTypes = {
	title: PropTypes.string,
	error: PropTypes.object,
	showMessage: PropTypes.bool
};

export default InlineError;
