import React, { Component } from "react";

class ActivateSuccess extends Component {
	render () {
		const { closeModal, forVerify, isDialog } = this.props;

		return (
			<>
				<section className="modal-card-body">
					<article className="media">
						<div className="media-left">
              <span className="icon is-medium has-text-success">
                <i className="fas fa-2x fa-check-circle"/>
              </span>
						</div>
						<div className="media-content">
							{forVerify
								? (
									<>
										<p className="title is-size-4">Email Verified</p>
										<p className="msg">Awesome, your new email has been verified and you'll now
											be able to use it to login.
										</p>
									</>
								)
								: (
									<>
										<p className="title is-size-4">Account Activated</p>
										<p className="msg">Awesome, welcome aboard! Your account is now active and
											you're ready to rock!
											We've also taken the liberty to log you in, so you're all set!
										</p>
									</>
								)}
						</div>
					</article>
				</section>
				<footer className="modal-card-foot">
					{!isDialog
						? <button type="button" className="button is-primary" onClick={closeModal}>Ok</button>
						: ""}
				</footer>
			</>
		);
	}
}

export default ActivateSuccess;
