import {
	CloseNewPhaseModalAction,
	OpenNewPhaseModalAction
} from "../../../../../../actions/playground/inlet/filter/canvas/phase/new";
import {
	CanvasNewAction,
	CanvasNewFailedAction,
	CanvasNewSuccessAction
} from "../../../../../../actions/playground/inlet/filter/canvas/new";

export const newReducer = (state = null, action) => {
	switch (action.type) {
		case OpenNewPhaseModalAction.getType():
			return { open: true, ...action.payload };
		case CloseNewPhaseModalAction.getType():
			return { open: false };
		case CanvasNewAction.getType():
			return { ...state, loading: true, error: null, ...action.payload };
		case CanvasNewSuccessAction.getType():
			return {}; // Close dialog.
		case CanvasNewFailedAction.getType():
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};
