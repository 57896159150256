import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import config from "../../../../config";
import { CfgTitleBgColors, getTitleBgColor } from "core/config";

import GroupActions from "./GroupActions";

import PlaygroundCards from "./card/PlaygroundCards";
import NewPlaygroundCard from "./card/NewPlaygroundCard";

import "./Group.scss";

export class Group extends Component {
	render () {
		const {
			group, index, openNewPlaygroundModal, openEditPlaygroundGroupModal, deletePlaygroundGroup,
			preferences, setPreference
		} = this.props;

		const { name } = group;

		const preferenceKey = `playground.list.group.${group.id}.open`;

		let open = preferences[preferenceKey];
		if (!group.id && open === undefined) {
			open = true;
		}

		const handleSetOpen = (open) => {
			setPreference({
				[preferenceKey]: open
			});
		};

		const titleBgColors = config.get(CfgTitleBgColors);

		let color = null;
		if (group.settings && group.settings.color) {
			color = group.settings.color;
		}

		if (!color) {
			color = getTitleBgColor(titleBgColors, index);
		}

		return (
			<div className={classNames(
				"pg-group",
				{ closed: !open }
			)}
			>
				<div className="pg-group-title-bar text-selection-none">
					<div
						className="start" onClick={() => { handleSetOpen(!open); }}
					>
						<div className="pg-group-name text-selection-none" style={{ backgroundColor: color }}>
							<span>{name}</span>
						</div>
						<div className="pg-group-slope"
							 style={{ borderColor: color + " transparent transparent transparent" }}/>
						<div className="pg-group-slope-accent"/>
					</div>
					<div className="end">
						<GroupActions
							group={group}
							openNewPlaygroundModal={openNewPlaygroundModal}
							openEditPlaygroundGroupModal={openEditPlaygroundGroupModal}
							deletePlaygroundGroup={deletePlaygroundGroup}
							open={open}
							setOpen={handleSetOpen}
						/>
					</div>
				</div>
				<div className="pg-group-content">
					<PlaygroundCards group={group}/>
					<NewPlaygroundCard
						group={group}
						openNewPlaygroundModal={openNewPlaygroundModal}
					/>
				</div>
			</div>
		);
	}
}

Group.propTypes = {
	index: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	group: PropTypes.object.isRequired,
	openNewPlaygroundModal: PropTypes.func.isRequired,
	openEditPlaygroundGroupModal: PropTypes.func.isRequired,
	deletePlaygroundGroup: PropTypes.func.isRequired,
	preferences: PropTypes.object.isRequired,
	setPreference: PropTypes.func.isRequired
};

export default Group;
