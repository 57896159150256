import { combineReducers } from "redux-immutable";
import { createResponsiveStateReducer } from "redux-responsive";

import coreReducers from "core/reducers";
import canvasReducers from "canvas/reducers";

export default combineReducers({
	browser: createResponsiveStateReducer({
		xsmall: 480,
		small: 799,
		medium: 992,
		desktop: 1088,
		large: 1200,
		xlarge: 1350
	}),
	core: coreReducers,
	canvas: canvasReducers
});
