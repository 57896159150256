import React, {useState} from "react";

import "./external-template-editor.scss";

import {connect} from "react-redux";
import {ITemplateDescriptor, TemplateDispatchApi} from "./api";
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import Loading from "../../components/common/loading/Loading";
import {Alert, Button, CircularProgress, IconButton, List, ListItem, ListItemButton, ListItemIcon} from "@mui/material";
import {Redirect} from "react-router-dom";
import classNames from "classnames";
import {fromNow} from "../../util/datetime";
import {attachUpwireEditorFrame, IFramedEditor, Scaffold} from "./framer";
import {TemplateKind} from "./types";
import {ScreenContext} from "../../state/ScreenContextProvider";
import {useConfirmGuard} from "../../core/actions/info/confirm";
import {AuthTokenChanged, getToken} from "../../core/lib/auth/token";
import {Browser, BrowserSize, Profile} from "../../store";


function getTemplateIcon(templateKind: TemplateKind) {
    return classNames({
        "fas": true,
        "fa-sms": templateKind === "sms",
        "fa-envelope": templateKind === "email",
        "fa-phone-square-alt": templateKind === "voice"
    });
}

interface IExternalEditorProps {
    id?: string;
    kind: TemplateKind;
    token?: string | undefined | null;
    browser?: { is: BrowserSize };
}

class FrameHostedEditor extends React.PureComponent<IExternalEditorProps> {

    static contextType = ScreenContext;
    context!: React.ContextType<typeof ScreenContext>;
    private fullHost = React.createRef<HTMLDivElement>();
    private frameHost = React.createRef<HTMLDivElement>();
    private controller: IFramedEditor | null = null;
    private tokenUpdater: any;

    componentDidMount() {

        if (this.controller)
            return;

        const {id: templateId, token, kind} = this.props;

        if (!templateId)
            return;

        if (!token)
            return;

        const element = this.frameHost.current;
        if (!element)
            return;

        const fullHostElement = this.fullHost.current;
        if (!fullHostElement)
            return;

        fullHostElement.classList.add("loading");

        const init: Scaffold = {
            templateId: templateId,
            editor: kind,
            token: token,
        };

        const controller = this.controller = attachUpwireEditorFrame(element, init);
        this.context.dispatch({editorMode: true});

        this.controller.ready().then(() => {
            fullHostElement.classList.remove("loading");
        });

        this.tokenUpdater = () => {
            const token = getToken();
            if (token) {
                controller.update(token);
            }
        };

        window.addEventListener(AuthTokenChanged, this.tokenUpdater);
        setTimeout(this.tokenUpdater, 1000);
    }

    componentWillUnmount() {

        if (this.controller) {
            this.controller.detach();
            this.context.dispatch({editorMode: false});
            this.controller = null;
        }

        window.removeEventListener(AuthTokenChanged, this.tokenUpdater);
    }

    render() {
        return <div className="full-frame" ref={this.fullHost}>
            <div className="loading-overlay">
                <div className="loading-message">
                    <CircularProgress/>
                    <div className="message">
                        Warming up the editor...
                    </div>
                </div>
            </div>
            <div className="full-frame" ref={this.frameHost}/>
        </div>;
    }
}

interface IListingItemProps {
    template: ITemplateDescriptor;
    selected: boolean;
    onSelect: (templateId: string) => void;
    onDelete: (templateId: string) => void;
    onClone: (templateId: string) => void;
    kind: TemplateKind;
}


function getPublicationStatus(descriptor: ITemplateDescriptor): "draft" | "latest" | "modified" {

    if (!descriptor || !descriptor.published)
        return "draft";

    if (descriptor.publishedLatest)
        return "latest";

    return "modified";
}

function getPublicationPill(status: string) {
    if (status === "draft")
        return <div className="draft pill"><span>Draft</span></div>;
    else if (status === "latest")
        return <div className="published pill"><span>Published</span></div>;
    else if (status === "modified")
        return <div className="modified pill"><span>Modified</span></div>;
}


const TemplateListItem = ({
                              template,
                              selected,
                              onSelect,
                              onDelete,
                              onClone,
                              kind
                          }: IListingItemProps) => {

    const deleteConfirm = useConfirmGuard({
        title: "Delete template?",
        content: "Are you sure you want to delete this template?",
        style: "danger",
        action: () => onDelete(template.templateId)
    });

    function selectTemplate(event: React.MouseEvent<HTMLLIElement, MouseEvent>) {
        event.stopPropagation();
        onSelect(template.templateId);
    }

    const status = getPublicationStatus(template);
    const publicationPill = getPublicationPill(status);

    return <ListItem
        className="big-list-item"
        selected={selected}
        onClick={selectTemplate}>

        <ListItemButton>

            <ListItemIcon>
                <i className={getTemplateIcon(kind)}/>
            </ListItemIcon>

            <div className="template-publication-status">
                {publicationPill}
            </div>

            <div className="big-item-info">
                <div className="big-item-name">{template.name || "Untitled template"}</div>
                <div className="big-item-subtitle">modified {fromNow(template.modified)}</div>
            </div>

            <div className="spacer"/>

            <div className="big-item-actions-container">
                <div className="big-item-actions">

                    <IconButton onClickCapture={() => onClone(template.templateId)}>
                        <i className="fas fa-copy"/>
                    </IconButton>

                    <IconButton onClickCapture={deleteConfirm} className="delete">
                        <i className="fas fa-trash"/>
                    </IconButton>

                </div>
            </div>

        </ListItemButton>

    </ListItem>;
};

const listingQueryKey = "templates-all";

function getErrorExit(compositeError: unknown) {
    if (!compositeError)
        return null;

    const errorMessage = "Unable to load templates for your account";
    if (compositeError instanceof Error)
        return <Alert severity="error">{errorMessage}: {compositeError.message}</Alert>;

    return <Alert severity="error">{errorMessage}: {compositeError.toString()}</Alert>;
}

const ExternalTemplateListerImpl = ({
                                        token,
                                        kind,
                                        browser
                                    }: { token?: string, browser?: Browser, kind: TemplateKind }) => {

    const [currentTemplateId, selectTemplateId] = useState<string | null>(null);
    const client = useQueryClient();

    const {isFetching, error, data} = useQuery([listingQueryKey], () => {
        const dispatcher = new TemplateDispatchApi(token);
        return dispatcher.getAllTemplates();
    });

    const mutator = useMutation(async (fn: (api: TemplateDispatchApi) => Promise<any>) => {
        await fn(new TemplateDispatchApi(token));
        await refreshTemplates();
    });

    const compositeError = getErrorExit(error ?? mutator.error);
    if (compositeError)
        return compositeError;

    if (isFetching || mutator.isLoading)
        return (<Loading contained/>);

    if (!data) {
        return <Alert severity="error">Unable to get template data...</Alert>;
    }

    if (currentTemplateId)
        return <Redirect to={`/templates/${kind}/${currentTemplateId}`}/>;

    const refreshTemplates = async () => {
        await client.invalidateQueries([listingQueryKey]);
    };

    const deleteTemplate = (templateId: string) => {
        mutator.mutate(async (api: TemplateDispatchApi) => {
            await api.deleteTemplate(templateId);
        });
    };

    const createTemplate = () => {
        mutator.mutate(async (api: TemplateDispatchApi) => {
            const template = await api.createTemplate(kind);
            selectTemplateId(template.templateId);
        });
    };

    const duplicateTemplate = (templateId: string) => {
        mutator.mutate(async (api: TemplateDispatchApi) => {
            await api.duplicateTemplate(templateId);
        });
    };

    const templates = data[kind];

    let listing;
    if (templates.length === 0) {
        listing = (
            <div className="empty-list">
                <Alert severity="info">
                    You have no {kind} templates.
                    <div className="actions">
                        <Button variant="contained" color="primary" onClick={createTemplate}>
                            Create new template
                        </Button>
                    </div>
                </Alert>
            </div>
        );
    } else {
        listing = <List className="big-item-list">
            {templates.map((template: ITemplateDescriptor) =>
                <TemplateListItem template={template}
                                  key={template.templateId}
                                  selected={currentTemplateId === template.templateId}
                                  onSelect={selectTemplateId}
                                  onDelete={() => deleteTemplate(template.templateId)}
                                  onClone={() => duplicateTemplate(template.templateId)}
                                  kind={kind}/>
            )}
        </List>;
    }

    const small = !!(browser?.is?.small);
    const titleOf = {"sms": "SMS", "email": "Email", "voice": "Voice"}[kind];
    const title = `${titleOf} Templates`;

    return (
        <div className={classNames({"big-selector": true, "small": small})}>

            <div className="big-selector-header">
                <div className="spacer"/>
                <h1>{title}</h1>
                <div className="actions spacer">
                    <IconButton onClick={refreshTemplates}><i className="fas fa-sync"/></IconButton>
                    <IconButton onClick={createTemplate}><i className="fas fa-plus"/></IconButton>
                </div>
            </div>

            <div className="big-selector-content">
                {listing}
            </div>

        </div>
    );

};

function mapStateToProps(state: Map<string, any>) {

    let mapping: any = {
        token: null,
        browser: state.get("browser"),
    };

    const core = state.get("core");
    if (!core) return mapping;

    const profile = core.get("profile") as Profile;
    if (!profile) return mapping;

    mapping.token = profile.auth.token;

    return mapping;
}

export const ExternalTemplateEditor = connect(mapStateToProps)(FrameHostedEditor);
export const ExternalTemplateLister = connect(mapStateToProps)(ExternalTemplateListerImpl);

