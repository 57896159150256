import React from "react";
import {ExternalTemplateEditor, ExternalTemplateLister} from "./external-template-editor";
import {Route} from "react-router-dom";
import {isTemplateKind} from "./types";
import {Alert} from "@mui/material";

export const EditorTemplateRoutes = () => [

    <Route key="external-template-editor-route"
           path="/templates/:kind/:templateId?"
           render={(props) => {
               const {kind, templateId} = props.match.params;


               if (!isTemplateKind(kind)) {
                   return <Alert severity="error">
                       Invalid template kind: <em>{kind}</em>
                   </Alert>;
               }

               if (templateId) {
                   return <ExternalTemplateEditor kind={kind} id={templateId}/>;
               }

               return <ExternalTemplateLister kind={kind}/>;
           }}/>,
];

export default EditorTemplateRoutes;
