import { call, put, takeLatest } from "redux-saga/effects";
import {
	ResendVerificationAction,
	ResendVerificationFailedAction,
	ResendVerificationRequestAction,
	ResendVerificationSuccessAction,
	VerifyAction,
	VerifyFailedAction,
	VerifyRequestAction,
	VerifySuccessAction
} from "../../actions/auth";
import { getVerify, postVerificationResend } from "../../api/auth/verify";

/// / Verify
// Worker
function * getVerifyWorker (action) {
	try {
		yield put(VerifyRequestAction());
		const r = yield call(getVerify, action.payload);
		if (r.json) {
			yield put(VerifySuccessAction(r.json));
		} else {
			yield put(VerifyFailedAction("Failed to activate account"));
		}
	} catch (e) {
		yield put(VerifyFailedAction(e));
	}
}

// Saga
export function * getVerifySaga () {
	yield takeLatest(VerifyAction.getType(), getVerifyWorker);
}

/// / Resend Verification
// Worker
function * getResendVerificationWorker (action) {
	try {
		yield put(ResendVerificationRequestAction());
		const r = yield call(postVerificationResend, action.payload);
		if (r.json) {
			yield put(ResendVerificationSuccessAction(r.json));
		} else {
			yield put(ResendVerificationFailedAction("Failed to resend verifcation link"));
		}
	} catch (e) {
		yield put(ResendVerificationFailedAction(e));
	}
}

// Saga
export function * getResendVerificationSaga () {
	yield takeLatest(ResendVerificationAction.getType(), getResendVerificationWorker);
}

// Export

export const verifySagas = [
	getVerifySaga(),
	getResendVerificationSaga()
];

export default verifySagas;
