import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { getIn } from "formik";
import { Draggable } from "react-beautiful-dnd";

import { extractErrorMessageFromArrayErrors, extractErrors } from "core/util/form/helper";
import { RawField } from "core/components/common/form";
import { OperatorTypeComparisonOptions } from "core/lib/logic/operator";
import { BranchingTypeEnum } from "../../../../../../../../../lib/playground/constants";
import LogicConditionSummary from "core/components/common/logic/condition/LogicConditionSummary";

import BranchingTargetGoto from "./BranchingTargetGoto";
import { ItemTypeCatchAll } from "./BranchingGroupItems";

import "./BranchingTarget.scss";

class BranchingTargetElement extends PureComponent {
	static propTypes = {
		provided: PropTypes.object,
		snapshot: PropTypes.object,
		target: PropTypes.object.isRequired,
		group: PropTypes.object.isRequired,
		groupType: PropTypes.string.isRequired,
		outcome: PropTypes.string.isRequired,
		namePath: PropTypes.string.isRequired,
		disableEdit: PropTypes.bool,
		nodeProps: PropTypes.object.isRequired,
		playgroundProps: PropTypes.object.isRequired,
		nodeInfoCbRef: PropTypes.object.isRequired,
		doDelete: PropTypes.func.isRequired,
		setModalHidden: PropTypes.func.isRequired,
		setFieldValue: PropTypes.func.isRequired,
		values: PropTypes.object,
		touched: PropTypes.object,
		errors: PropTypes.object
	};

	state = {
		isDragHover: false
	};

	setIsDragHover = (isDragHover) => {
		this.setState(() => ({
			isDragHover: isDragHover
		}));

		return false;
	};

	render () {
		const {
			provided, snapshot, target, group, groupType, outcome, namePath, disableEdit,
			nodeProps, playgroundProps, nodeInfoCbRef, setModalHidden, doDelete,
			values, touched, errors, setFieldValue
		} = this.props;
		const { openConditionBuilder } = playgroundProps;

		const { isDragHover } = this.state;

		const errorData = extractErrors(namePath, errors, touched);
		const errorMessage = extractErrorMessageFromArrayErrors(errorData);

		const operatorOptions = [
			{ value: "" },
			...OperatorTypeComparisonOptions
		];

		let operatorEnabled = true;
		if (group.branching_type === BranchingTypeEnum) {
			operatorEnabled = false;
		}

		const operatorValue = getIn(values, namePath + ".operator");

		let valueEnabled = false;
		if (group.branching_type === BranchingTypeEnum || operatorValue) {
			valueEnabled = true;
		}

		// When branches are used in conjunction with goto's we hide them
		// (this can only happen when using the API directly).
		const hiddenGroupCount = (target.branching) ? target.branching.length : 0;

		let typeClassName = "standard-target";
		if (groupType === ItemTypeCatchAll) {
			typeClassName = "catch-all-target";
		}

		let dragInnerRef;
		let isDragging = false;
		let draggableProps = {};
		let dragHandleProps = {};
		if (!disableEdit && snapshot) {
			isDragging = snapshot.isDragging;
		}
		if (!disableEdit && provided) {
			dragInnerRef = provided.innerRef;
			draggableProps = provided.draggableProps;
			dragHandleProps = provided.dragHandleProps;
		}

		const onEditCondition = () => {
			setModalHidden(true);

			openConditionBuilder({
				model: target.condition,
				onClose: () => {
					setModalHidden(false);
				},
				onSave: (condition) => {
					setFieldValue(namePath + ".condition", condition);
				}
			});
		};

		return (
			<div
				className={classNames(
					"branching-target-container",
					typeClassName,
					{ "is-dragging": isDragging }
				)}
				ref={dragInnerRef}
				{...draggableProps}
			>
				<div
					className={classNames(
						"branching-target",
						{ "is-drag-hover": (isDragHover || isDragging) }
					)}
				>
					{!disableEdit
						? (
							<div
								className="target-drag"
								onMouseEnter={() => this.setIsDragHover(true)}
								onMouseLeave={() => this.setIsDragHover(false)}
								onMouseUp={() => this.setIsDragHover(false)}
								{...dragHandleProps}
							>
                <span className="icon">
                  <i className="fal fa-ellipsis-v"/>
                </span>
							</div>
						)
						: ""}
					<div className="target-content">
						<div className="target-inner-content">
							<div className="target-values">
								<div className="operator">
									<RawField
										name={`${namePath}.operator`} values={values} touched={touched} errors={errors}
										hasError={!!errorData.operator} type="select" placeholder="Operator"
										options={operatorOptions}
										setFieldValue={setFieldValue} isSmall disabled={!operatorEnabled}
									/>
								</div>
								<div className="value">
									<RawField
										name={`${namePath}.value`} values={values} touched={touched} errors={errors}
										hasError={!!errorData.value} type="text" placeholder="Value"
										setFieldValue={setFieldValue} isSmall isFullWidth disabled={!valueEnabled}
									/>
								</div>
							</div>
							<div className="target-conditions">
								<LogicConditionSummary
									condition={target.condition}
									subText={(hiddenGroupCount > 0)
										? "(" + hiddenGroupCount + " Groups Hidden)"
										: ""}
									onEdit={onEditCondition}
								/>
							</div>
							<div className="target-goto">
								<BranchingTargetGoto
									target={target}
									group={group}
									outcome={outcome}
									namePath={`${namePath}.goto`}
									disableEdit={disableEdit}
									nodeProps={nodeProps}
									playgroundProps={playgroundProps}
									nodeInfoCbRef={nodeInfoCbRef}
									doDelete={doDelete}
									setModalHidden={setModalHidden}
									hasError={!!errorData.goto}
									values={values}
									touched={touched}
									errors={errors}
									setFieldValue={setFieldValue}
								/>
							</div>
						</div>
						{errorMessage
							? (
								<div className="target-errors">
									<p className="help is-danger">{errorMessage}</p>
								</div>
							)
							: ""}
					</div>
				</div>
			</div>
		);
	}
}

export class BranchingTarget extends PureComponent {
	static propTypes = {
		target: PropTypes.object.isRequired,
		group: PropTypes.object.isRequired,
		groupType: PropTypes.string.isRequired,
		outcome: PropTypes.string.isRequired,
		namePath: PropTypes.string.isRequired,
		disableEdit: PropTypes.bool,
		nodeProps: PropTypes.object.isRequired,
		playgroundProps: PropTypes.object.isRequired,
		nodeInfoCbRef: PropTypes.object.isRequired,
		doDelete: PropTypes.func.isRequired,
		setModalHidden: PropTypes.func.isRequired,
		setFieldValue: PropTypes.func.isRequired,
		values: PropTypes.object,
		touched: PropTypes.object,
		errors: PropTypes.object
	};

	render () {
		const { target, index, disableEdit, ...rest } = this.props;

		if (disableEdit) {
			return (
				<BranchingTargetElement
					disableEdit={disableEdit}
					target={target}
					index={index}
					{...rest}
				/>
			);
		}

		return (
			<Draggable
				key={target._id}
				draggableId={target._id}
				type="target"
				index={index}
			>
				{(provided, snapshot) => (
					<BranchingTargetElement
						provided={provided}
						snapshot={snapshot}
						target={target}
						index={index}
						{...rest}
					/>
				)}
			</Draggable>
		);
	}
}

export default BranchingTarget;
