import React, {forwardRef} from "react";
import {IconButton, Modal} from "@mui/material";


import "./modal.scss";
import classNames from "classnames";
import {ArrowBackRounded, ErrorRounded, Warning} from "@mui/icons-material";

import Loading from "../../../../components/common/loading/Loading";

type ModalProps = {
    title: React.ReactNode,
    children: React.ReactNode,

    wide?: boolean,
    icon?: React.ReactNode,
    headBackgroundColor?: string,
    headFontColor?: string,
    onClose?: () => void
}

const UpwireModal = forwardRef((props: ModalProps, ref: React.Ref<HTMLDivElement>) => {
    const {icon, title, headBackgroundColor, headFontColor, children, onClose, wide} = props;
    return <div ref={ref} tabIndex={-1} className={classNames({
        "upwire-common-modal": true,
        "modal": true,
        "is-active": true,
        "wide": wide
    })}>
        <div className="modal-card">
            <header className="modal-card-head" style={{backgroundColor: headBackgroundColor}}>
                {icon}
                <p className="modal-card-title" style={{color: headFontColor}}>{title}</p>
                <button className="delete" aria-label="close" onClick={onClose}/>
            </header>
            <div className="body">
                {children}
            </div>
        </div>
    </div>;
});

export function useUpwireModal(children: React.ReactNode, config: Omit<ModalProps, "children">) {
    const [showModal, setShowModal] = React.useState(false);

    function hide() {
        const wasShown = showModal;
        setShowModal(false);
        if (wasShown && config.onClose) {
            config.onClose();
        }
    }

    function show() {
        setShowModal(true);
    }

    const modal = <Modal
        open={showModal}
        disableEnforceFocus={true}
        sx={{"zIndex": 1280}}
        onClose={hide}>
        <UpwireModal {...config} onClose={hide}>
            {children}
        </UpwireModal>
    </Modal>;

    return {
        show,
        hide,
        modal
    };
}

type ModalSubheaderProps = {
    onBack?: () => void
    text: string
}

export function UpwireModalSubheader(props: ModalSubheaderProps) {
    return <div className="modal-subheader">
        {props.onBack &&
            <IconButton sx={{"color": "white"}} onClick={() => {
                props?.onBack?.call(null);
            }}>
                <ArrowBackRounded></ArrowBackRounded>
            </IconButton>
        }
        <h2>
            {props.text}
        </h2>
    </div>;
}

export function UpwireModalCentered(props: { children: React.ReactNode }) {
    return <div className="modal-centered">
        {props.children}
    </div>;
}

export function UpwireModalLoading({message}: { message?: string }) {
    return <UpwireModalCentered>
        <div className="modal-loading">
            <Loading/>
            {message && <p>{message}</p>}
        </div>
    </UpwireModalCentered>;
}

const iconSize = 70

export function UpwireModalError({message}: { message: string }) {
    return <UpwireModalCentered>
        <div className="modal-error">

            <div className="error-icon">
                <ErrorRounded sx={{fontSize: iconSize}}/>
            </div>
            <p>{message}</p>
        </div>
    </UpwireModalCentered>;
}

export function UpwireModalWarning({message}: { message: string }) {
    return <UpwireModalCentered>
        <div className="modal-warning">

            <div className="warning-icon">
                <Warning sx={{fontSize: iconSize}}/>
            </div>
            <p>{message}</p>
        </div>
    </UpwireModalCentered>;
}
