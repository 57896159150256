import React, { Component } from "react";
import PropTypes from "prop-types";

import { DiagramModel, PortModelAlignment } from "@projectstorm/react-diagrams";
import { DefaultPortFactory } from "../port/DefaultPortFactory";
import { SuccessPortModel } from "../port/SuccessPortModel";
import { FailurePortModel } from "../port/FailurePortModel";
import { StartNodeFactory } from "../node/StartNodeFactory";
import { TargetNodeFactory } from "../node/TargetNodeFactory";
import { DefaultNodeFactory } from "../node/DefaultNodeFactory";
import { SmallNodeFactory } from "../node/SmallNodeFactory";
import { CanvasInitFactory } from "../link/CanvasInitFactory";
import { SuccessArrowLinkFactory } from "../link/SuccessArrowLinkFactory";
import { FailureArrowLinkFactory } from "../link/FailureArrowLinkFactory";
import config, { CfgDiagram } from "../../../../config";
import { CanvasInitModel } from "../link/CanvasInitModel";
import { CanvasWidget } from "@projectstorm/react-canvas-core";
import { generateModel } from "./modelGenerator";
import { DefaultDiagramState } from "../states/DefaultDiagramState";
import createEngine from "../engine/DiagramEngine";

export class Diagram extends Component {
	static displayName = "Diagram";

	static propTypes = {
		data: PropTypes.object.isRequired,
		playgroundProps: PropTypes.object.isRequired,
		updateDimensions: PropTypes.func
	};

	state = {
		engine: undefined
	};

	componentDidMount () {
		// Init engine (only once per canvas).
		this.setState({
			engine: this.initEngine()
		});
	}

	/// / Engine

	initEngine () {
		const engine = createEngine({
			registerDefaultZoomCanvasAction: false,
			registerDefaultDeleteItemsAction: false
		});

		this.initStateMachine(engine);
		this.initNodeFactories(engine);
		this.initPortFactories(engine);
		this.initLinkFactories(engine);

		return engine;
	}

	initStateMachine (engine) {
		const state = engine.getStateMachine().getCurrentState();
		if (state instanceof DefaultDiagramState) {
			state.dragNewLink.config.allowLooseLinks = false;
		}
	}

	initNodeFactories (engine) {
		const nodeFactories = engine.getNodeFactories();

		nodeFactories.registerFactory(new StartNodeFactory());
		nodeFactories.registerFactory(new DefaultNodeFactory());
		nodeFactories.registerFactory(new SmallNodeFactory());
		nodeFactories.registerFactory(new TargetNodeFactory());
	}

	initPortFactories (engine) {
		const portFactories = engine.getPortFactories();

		portFactories.registerFactory(new DefaultPortFactory("success",
			config => new SuccessPortModel(PortModelAlignment.LEFT)));

		portFactories.registerFactory(new DefaultPortFactory("failure",
			config => new FailurePortModel(PortModelAlignment.TOP)));
	}

	initLinkFactories (engine) {
		const linkFactories = engine.getLinkFactories();

		linkFactories.registerFactory(new CanvasInitFactory());
		linkFactories.registerFactory(new SuccessArrowLinkFactory());
		linkFactories.registerFactory(new FailureArrowLinkFactory());
	}

	/// / Model

	initModel () {
		const diagramCfg = config.get(CfgDiagram);

		const model = new DiagramModel();
		model.setGridSize(diagramCfg.gridSize);

		const linkLayerModel = model.getLinkLayers()[0];
		linkLayerModel.addModel(new CanvasInitModel());

		return model;
	}

	populateModel (model) {
		const { playgroundProps, data, updateDimensions } = this.props;

		const state = generateModel(model, data, {
			...playgroundProps,

			// Override to enable local re-render.
			onNodePositionChanged: this.onNodePositionChanged.bind(this)
		});
		if (state) {
			if (updateDimensions) {
				updateDimensions({
					...state
				});
			}
		}

		return model;
	}

	/// Updates

	onNodePositionChanged (e, node) {
		const { playgroundProps } = this.props;

		// TODO: Reposition child node elements.
		//
		// DJR: It's not an easy thing to do:
		//      - Re-rendering everything interrupts the drag/drop
		//      - Calling node.setCenterPosition on children is risky due to the
		//        'onNodePositionChanged' event being called for each update.
		//

		if (playgroundProps.onNodePositionChanged) {
			playgroundProps.onNodePositionChanged(e, node);
		}
	}

	/// Render

	render () {
		const { data } = this.props;
		const { engine } = this.state;
		if (!engine) {
			return "";
		}

		engine.setModel(this.populateModel(
			this.initModel()
		));

		setTimeout(() => {
			// Defer the init to give the canvas time to load.
			data.init = true;
		}, 200);

		return (
			<>
				<CanvasWidget engine={engine}/>
			</>
		);
	}
}

export default Diagram;
