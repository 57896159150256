import React from "react";

import PrivateRoute from "core/components/layout/route/PrivateRoute";

import PlaygroundList from "./containers/playground/PlaygroundList.container";
import PlaygroundDashboard from "./containers/playground/PlaygroundDashboard.container";

export const CanvasRoutes = ({ profile, setFullscreen, isFullscreen }) => [
	<PrivateRoute
		key="playgrounds" exact path="/playgrounds" component={PlaygroundList}
		profile={profile} setFullscreen={setFullscreen} isFullscreen={isFullscreen}
	/>,
	<PrivateRoute
		key="playground" exact path="/playground/:namespace/:playgroundId" component={PlaygroundDashboard}
		profile={profile} setFullscreen={setFullscreen} isFullscreen={isFullscreen}
	/>
];

export default CanvasRoutes;
