// eslint-disable-next-line no-redeclare

import { call, put, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { TokenExpiredAction, TokenRefreshAction } from "../actions/auth";
import { getTokenStatus } from "../lib/auth/token";

function tokenRefreshInitChannel () {

	return eventChannel(emitter => {

		function checkTokenStatus () {
			switch (getTokenStatus()) {
				case "refresh":
					return emitter(TokenRefreshAction());
				case "expired":
					console.warn("Token has expired");
					return emitter(TokenExpiredAction());
				default:
					return;
			}
		}

		checkTokenStatus();
		const iv = setInterval(() => {
			checkTokenStatus();
		}, 1000 * 60);

		return () => {
			clearInterval(iv);
		};
	});
}

// Saga
function * tokenRefreshSaga () {
	const channel = yield call(tokenRefreshInitChannel);
	while (true) {
		const action = yield take(channel);
		switch (action.type) {
			case TokenRefreshAction.getType():
			case TokenExpiredAction.getType():
				yield put(action);
				break;
			default:
				console.error("Ignoring token action", action);
		}
	}
}

export default tokenRefreshSaga;
