import React, { Component } from "react";
import PropTypes from "prop-types";

import { InletBar } from "./InletBar";
import InletContent from "./InletContent";

export class Inlet extends Component {
	render () {
		return (
			<>
				<div className="pg-inlet">
					<InletBar {...this.props} />
					<InletContent {...this.props} />
				</div>
			</>
		);
	}
}

Inlet.propTypes = {
	playgroundProps: PropTypes.object.isRequired,
	index: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	inlet: PropTypes.object.isRequired
};

export default Inlet;
