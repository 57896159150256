import { getIn } from "formik";
import { isEmpty } from "underscore";

export const extractNestedProp = (data, strKey, prefix, defaultsTo) => {
	if (!data || !strKey) {
		return null;
	}

	let fullPath = strKey;
	if (prefix) {
		fullPath = prefix + "." + strKey;
	}

	let val = getIn(data, fullPath);
	if (!val) {
		val = defaultsTo;
	}

	return val;
};

export const haveErrorInFields = (fields, errors, touched) => {
	let haveError = false;

	if (fields && errors && touched) {
		fields.forEach(f => {
			let prefix;
			if (f && Array.isArray(f)) {
				[prefix, f] = f;
			} else if (f === "") {
				if (!isEmpty(errors) && !isEmpty(touched)) {
					haveError = true;
					return;
				}
			}

			const e = extractNestedProp(errors, f, prefix);
			const t = extractNestedProp(touched, f, prefix);

			if (e && t) {
				if (Array.isArray(e)) {
					e.forEach((val, index) => {
						if (t[index]) {
							if (typeof val === "object") {
								Object.keys(val).forEach(vk => {
									if (t[index][vk]) {
										haveError = true;
									}
								});
							} else {
								haveError = true;
							}
						}
					});
				} else {
					haveError = true;
				}
			}
		});
	}

	return haveError;
};

export const extractArrayErrors = (key, index, errors, touched) => {
	return extractErrors(key + "[" + index + "]", errors, touched);
};

export const extractErrors = (key, errors, touched) => {
	const keyError = getIn(errors, key);
	const keyTouched = getIn(touched, key);

	if (keyTouched && keyError) {
		return keyError;
	}

	return {};
};

export const extractErrorMessageFromArrayErrors = (errorData) => {
	let errorMessage = "";
	Object.keys(errorData).forEach(n => {
		if (errorData[n]) {
			if (errorMessage) {
				errorMessage += ", ";
			}
			errorMessage += errorData[n];
		}
	});

	if (errorMessage) {
		errorMessage += ".";
	}

	return errorMessage;
};

export const handleFormOnDrag = (values, setFieldValue) => {
	return ({ source, destination }) => {
		if (!source || !destination) {
			return;
		}

		let sourceList = getIn(values, source.droppableId);
		if (source.droppableId === destination.droppableId) {
			// Swap in same group

			if (source.index !== destination.index) {
				[sourceList[source.index], sourceList[destination.index]] =
					[sourceList[destination.index], sourceList[source.index]];

				setFieldValue(source.droppableId, sourceList);
			}
		} else {
			// Move between groups

			let destinationList = getIn(values, destination.droppableId);
			if (!destinationList) {
				destinationList = [];
			}

			// Add to destination

			destinationList.splice(destination.index, 0, sourceList[source.index]);

			// Remove from source

			sourceList = sourceList.filter((it, index) => {
				return index !== source.index;
			});

			// Update
			setFieldValue(destination.droppableId, destinationList);
			setFieldValue(source.droppableId, sourceList);
		}
	};
};

export const formListMove = (namePath, values, setFieldValue, from, to) => {
	const list = getIn(values, namePath);
	if (list) {
		[list[from], list[to]] = [list[to], list[from]];

		setFieldValue(namePath, list);
	}
};
