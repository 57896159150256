import React, { PureComponent } from "react";
import Modal from "../../components/common/modal/Modal";
import connect from "react-redux/es/connect/connect";
import { InfoCloseModalAction } from "../../actions/info/info";

class InfoModal extends PureComponent {
	render () {
		const { info, closeModal } = this.props;
		const open = !!(info && info.open);
		const title = (info && info.title) ? info.title : "";
		const content = (info && info.content) ? info.content : "";
		if (!open) {
			return null;
		}

		return (
			<Modal
				title={title}
				closeModal={closeModal}
				modalState={open}
			>
				<section className="modal-card-body">
					{content}
				</section>
				<footer className="modal-card-foot">
					<button type="button" className="button" onClick={closeModal}>Close</button>
				</footer>
			</Modal>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(InfoCloseModalAction())
	};
};

const mapStateToProps = (state, props) => {
	const core = state.get("core");
	if (!core) {
		return {};
	}

	return {
		info: core.get("info")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoModal);
