import React, { Component } from "react";

import PropTypes from "prop-types";

export class DropShadowSvgFilter extends Component {
	render () {
		const { id } = this.props;

		return (
			<filter id={id} x="-40%" y="-40%" height="200%" width="200%" colorInterpolationFilters="sRGB">
				<feOffset result="offOut" in="SourceAlpha" dx="2" dy="2"/>

				<feColorMatrix
					result="colorOut" in="offOut"
					values="1 0 0 0 0
                                   0 1 0 0 0
                                   0 0 1 0 0
                                   0 0 0 0.2 0"
				/>

				<feGaussianBlur result="blurOut" in="colorOut" stdDeviation="2"/>

				<feBlend in="SourceGraphic" in2="blurOut" mode="normal"/>

			</filter>
		);
	}
}

DropShadowSvgFilter.propTypes = {
	id: PropTypes.string.isRequired
};

export default DropShadowSvgFilter;
