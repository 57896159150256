import {
	PlaygroundListAction,
	PlaygroundListFailedAction,
	PlaygroundListSuccessAction
} from "../../actions/playground/list";
import { registerLoadingActions } from "core/reducers/loading";

registerLoadingActions(
	[PlaygroundListAction.getType()],
	[PlaygroundListSuccessAction.getType(), PlaygroundListFailedAction.getType()]
);

export const listReducer = (state = null, action) => {
	switch (action.type) {
		case PlaygroundListAction.getType():
			return { ...state, loading: true, error: null, ...action.payload };
		case PlaygroundListSuccessAction.getType():
			return { open: false, ...action.payload };
		case PlaygroundListFailedAction.getType():
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};
