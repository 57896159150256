import {
	CloseNodeNewModalAction,
	OpenNodeNewModalAction
} from "../../../../../../../actions/playground/inlet/filter/canvas/phase/node/new";

export const newReducer = (state = null, action) => {
	switch (action.type) {
		case OpenNodeNewModalAction.getType():
			return { open: true, ...action.payload };
		case CloseNodeNewModalAction.getType():
			return { open: false };
		default:
			return state;
	}
};
