import {environment} from "../../../../../generated/env";

export type FormatData = {
    token: string;
    payload: any;
    endpoint: string;
    accountKey: string;
}

export type FormatterOutput = {
    code: string;
    language: string;
}

export const API_BASE_URL = environment.api.replace(/\/api$/, "");
