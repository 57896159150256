import {
	PlaygroundKillAction,
	PlaygroundKillFailedAction,
	PlaygroundKillSuccessAction
} from "../../actions/playground/kill";
import { registerLoadingActions } from "core/reducers/loading";
import { registerErrorActions } from "core/reducers/error";

registerLoadingActions(
	[PlaygroundKillAction.getType()],
	[PlaygroundKillSuccessAction.getType(), PlaygroundKillFailedAction.getType()]
);

registerErrorActions([
	PlaygroundKillFailedAction.getType()
]);

export const killReducer = (state = null, action) => {
	switch (action.type) {
		case PlaygroundKillAction.getType():
			return { ...action.payload };
		case PlaygroundKillSuccessAction.getType():
			return {};
		case PlaygroundKillFailedAction.getType():
			return {};
		default:
			return state;
	}
};
