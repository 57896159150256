import React from "react";
import Checkboard from "react-color/lib/components/common/Checkboard";
import "./Swatch.scss";

const ENTER = 13;

export const Swatch = ({
	color,
	onClick = () => {},
	onHover,
	title = color,
	children,
	selected
}) => {
	const transparent = color === "transparent";

	const handleClick = e => onClick(color, e);
	const handleKeyDown = e => e.keyCode === ENTER && onClick(color, e);
	const handleHover = e => onHover(color, e);

	const optionalEvents = {};
	if (onHover) {
		optionalEvents.onMouseOver = handleHover;
	}

	const isLight = (color) => {
		const bright = color.substr(1).match(/.{2}/g).reduce((acc, cur) => {
			return acc + parseInt(cur, 16);
		}, 0) / 3;
		return bright > 165;
	};

	return (
		<div
			style={{ background: color }}
			className="swatch"
			onClick={handleClick}
			title={title}
			tabIndex={0}
			onKeyDown={handleKeyDown}
			{...optionalEvents}
		>
			{selected &&
				<span className={"icon" + (isLight(color) ? " icon-dark" : "")}><i className="fas fa-check"/></span>}
			{children}
			{transparent && (
				<Checkboard
					boxShadow="inset 0 0 0 1px rgba(0,0,0,0.1)"
				/>
			)}
		</div>
	);
};

export default Swatch;
