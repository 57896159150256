import { call, put, takeLatest } from "redux-saga/effects";

import {
	PlaygroundKillAction,
	PlaygroundKillFailedAction,
	PlaygroundKillRequestAction,
	PlaygroundKillSuccessAction
} from "../../actions/playground/kill";
import { getPlaygroundKill } from "../../api/playground/kill";

/// / Playground Kill
// Worker
function * postPlaygroundKillWorker (action) {
	try {
		yield put(PlaygroundKillRequestAction());

		const r = yield call(getPlaygroundKill, action.payload);
		if (r.json) {
			yield put(PlaygroundKillSuccessAction(r.json));

			if (action.payload.onSuccess) {
				yield call(action.payload.onSuccess, r.json);
			}
		} else {
			yield put(PlaygroundKillFailedAction("Failed to post playground kill"));
		}
	} catch (e) {
		yield put(PlaygroundKillFailedAction(e));
	}
}

// Saga
export function * postPlaygroundKillSaga () {
	yield takeLatest(PlaygroundKillAction.getType(), postPlaygroundKillWorker);
}

// Export

export const debuggerSagas = [
	postPlaygroundKillSaga()
];

export default debuggerSagas;
