import { combineReducers } from "redux-immutable";

import { listReducer } from "./list";
import { newReducer } from "./new";
import { settingsReducer } from "./settings";
import { debuggerReducer } from "./debugger";
import { dashboardReducer } from "./dashboard";

import inletReducers from "./inlet";
import groupReducers from "./group";
import { killReducer } from "./kill";

export default combineReducers({
	group: groupReducers,
	list: listReducer,
	new: newReducer,
	dashboard: dashboardReducer,
	settings: settingsReducer,
	debugger: debuggerReducer,
	kill: killReducer,
	inlet: inletReducers
});
