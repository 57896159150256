import React, { Component } from "react";
import PropTypes from "prop-types";

import "./InletContent.scss";
import Filters from "./filter/Filters";

export class InletContent extends Component {
	render () {
		const { playgroundProps, inlet } = this.props;

		return (
			<div className="pg-inlet-content">
				<Filters playgroundProps={playgroundProps} inlet={inlet}/>
			</div>
		);
	}
}

InletContent.propTypes = {
	playgroundProps: PropTypes.object.isRequired,
	inlet: PropTypes.object.isRequired
};

export default InletContent;
