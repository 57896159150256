import { apiFetch } from "core/api/base";

export function * getPlaygroundKill ({
	namespace, playgroundId, runId, force
}) {
	return yield apiFetch({
		endpoint: `/v1/playground/${namespace}/${playgroundId}/kill/${runId}`,
		method: "GET",
		authenticated: true,
		params: {
			force: force || ""
		}
	});
}
