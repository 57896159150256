import React, { Component } from "react";
import PropTypes from "prop-types";
import PhaseTabs from "./phase/PhaseTabs";
import "./CanvasBar.scss";

export class CanvasBar extends Component {
	render () {
		const { playgroundProps, canvas, phase, setPhaseId } = this.props;

		return (
			<div className="pg-canvas-bar">
				<div className="start">
					<PhaseTabs
						playgroundProps={playgroundProps}
						canvas={canvas}
						phase={phase} setPhaseId={setPhaseId}
					/>
				</div>
			</div>
		);
	}
}

CanvasBar.propTypes = {
	playgroundProps: PropTypes.object.isRequired,
	canvas: PropTypes.object.isRequired,
	phase: PropTypes.object,
	setPhaseId: PropTypes.func.isRequired
};

export default CanvasBar;
