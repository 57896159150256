import {
	ActivateAction,
	ActivateCloseModalAction,
	ActivateFailedAction,
	ActivateModeVerification,
	ActivateOpenModalAction,
	ActivateSuccessAction,
	PrivateRouteAction,
	ResendActivateAction,
	ResendActivateFailedAction,
	ResendActivateSuccessAction,
	ResendVerificationAction,
	ResendVerificationFailedAction,
	ResendVerificationSuccessAction,
	VerifyAction,
	VerifyFailedAction,
	VerifySuccessAction
} from "../../actions/auth";

export const activateReducer = (state = null, action) => {
	switch (action.type) {
		case ActivateOpenModalAction.getType():
			return { open: true, ...action.payload };
		case ActivateCloseModalAction.getType():
			return { open: false };
		case ActivateModeVerification.getType():
			return { ...state, forVerify: true };
		case ActivateAction.getType():
			return { ...state, loading: true, error: null, ...action.payload };
		case ActivateSuccessAction.getType():
			return { ...state, open: true, loading: false, error: null, success: true };
		case ActivateFailedAction.getType():
			return { ...state, open: true, loading: false, error: action.payload };
		case ResendActivateAction.getType():
			return { ...state, loading: true, resendError: null, ...action.payload };
		case ResendActivateSuccessAction.getType():
			return { ...state, loading: false, resendError: null, resendSuccess: true };
		case ResendActivateFailedAction.getType():
			return { ...state, loading: false, resendError: action.payload };
		case VerifyAction.getType():
			return { ...state, forVerify: true, loading: true, error: null, ...action.payload };
		case VerifySuccessAction.getType():
			return { ...state, open: true, loading: false, error: null, success: true };
		case VerifyFailedAction.getType():
			return { ...state, open: true, loading: false, error: action.payload };
		case ResendVerificationAction.getType():
			return { ...state, open: true, forVerify: true, loading: true, resendError: null, ...action.payload };
		case ResendVerificationSuccessAction.getType():
			return { ...state, loading: false, resendError: null, resendSuccess: true };
		case ResendVerificationFailedAction.getType():
			return { ...state, loading: false, resendError: action.payload };
		case PrivateRouteAction.getType():
			return {};
		default:
			return state;
	}
};
