import React, { Component } from "react";
import PropTypes from "prop-types";

import { CategoryAll } from "../../../../../../../../config/node/category";
import ShapeButtonSvgElem from "../../../../../../../common/svg/elem/ShapeButtonSvgElem";
import { initNodeProps } from "../../../../../../../../lib/common/diagram/node";
import { initStyleProps } from "../../../../../../../../lib/common/diagram/style";

import "./NodeCards.scss";

export class NodeCards extends Component {
	shouldComponentUpdate (nextProps, nextState, nextContext) {
		const { nodes, category, searchTerm } = this.props;
		if (category !== nextProps.category) {
			return true;
		}

		if (nodes && nextProps.nodes && nodes.length !== nextProps.nodes.length) {
			return true;
		}

		if (searchTerm !== nextProps.searchTerm) {
			return true;
		}

		return false;
	}

	render () {
		let { nodes, category, handleSelect, handleHover } = this.props;

		nodes = nodes.filter(n => {
			if (category !== CategoryAll) {
				if (!n.categories.includes(category)) {
					return false;
				}
			}

			return true;
		});

		if (nodes.length < 1) {
			return <p className="empty">No nodes found.</p>;
		}

		return (
			<div className="node-cards">
				{nodes.map(node => {

					let key = node.type;
					if (node.definition.module)
						key += node.definition.module;

					return (
						<NodeCard
							key={key}
							node={node}
							handleSelect={handleSelect}
							handleHover={handleHover}
						/>
					);
				})}
			</div>
		);
	}
}

NodeCards.propTypes = {
	nodes: PropTypes.array.isRequired,
	searchTerm: PropTypes.string,
	category: PropTypes.string.isRequired,
	handleSelect: PropTypes.func.isRequired,
	handleHover: PropTypes.func.isRequired
};

export const NodeCardIcon = ({ node, ...rest }) => {
	const shapeProps = initNodeProps(
		initStyleProps({
			shapeSize: 75,
			shapePad: 8,
			...rest,
			...node.nodeProps
		})
	);

	return (
		<div className="node-icon">
			<svg className="svg-icon" width={shapeProps.shapeSize} height={shapeProps.shapeSize}>
				<ShapeButtonSvgElem
					style={shapeProps.style}
					size={shapeProps.shapeSize}
					padding={shapeProps.shapePad}
					fill={shapeProps.fill}
					iconFill={shapeProps.iconFill}
					iconPad={shapeProps.iconPad}
					iconHoverPad={shapeProps.iconHoverPad}
					iconUrl={shapeProps.iconUrl}
					iconSvg={shapeProps.iconSvg}
					iconSvgSprite={shapeProps.iconSvgSprite}
					disableHover
					disableDropShadow={shapeProps.disableDropShadow}
				/>
			</svg>
		</div>
	);
};

export const NodeCard = ({ node, handleSelect, handleHover }) => {
	const handleClick = () => {
		handleSelect(node);
	};

	return (
		<div className="node-card-container">
			<div
				className="node-card" onClick={handleClick}
				onMouseOver={() => handleHover(node)}
				onMouseLeave={() => handleHover()}
			>
				<NodeCardIcon node={node}/>
				<div className="node-label">
					<div>{node.nodeProps.label}</div>
				</div>
			</div>
		</div>
	);
};

NodeCard.propTypes = {
	node: PropTypes.object.isRequired,
	handleSelect: PropTypes.func.isRequired,
	handleHover: PropTypes.func.isRequired
};
