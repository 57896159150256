import {
	CloseEditPlaygroundGroupModalAction,
	OpenEditPlaygroundGroupModalAction,
	PlaygroundGroupUpdateAction,
	PlaygroundGroupUpdateFailedAction,
	PlaygroundGroupUpdateSuccessAction
} from "../../../actions/playground/group/update";
import { registerLoadingActions } from "core/reducers/loading";
import { registerErrorActions } from "core/reducers/error";

registerLoadingActions(
	[PlaygroundGroupUpdateAction.getType()],
	[PlaygroundGroupUpdateSuccessAction.getType(), PlaygroundGroupUpdateFailedAction.getType()]
);

registerErrorActions([
	PlaygroundGroupUpdateFailedAction.getType()
]);

export const editReducer = (state = null, action) => {
	switch (action.type) {
		case OpenEditPlaygroundGroupModalAction.getType():
			return { open: true, model: action.payload };
		case CloseEditPlaygroundGroupModalAction.getType():
			return { open: false };
		case PlaygroundGroupUpdateAction.getType():
			return { ...state, loading: true, error: null, model: action.payload };
		case PlaygroundGroupUpdateSuccessAction.getType():
			return { open: false };
		case PlaygroundGroupUpdateFailedAction.getType():
			return { ...state, loading: false, error: action.payload };
		default:
			return state;
	}
};
