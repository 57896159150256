import React, { Component } from "react";
import PropTypes from "prop-types";
import { clone } from "underscore";

import WindowTaskBar, { TaskBarAction } from "components/common/window/WindowTaskBar";
import Groups from "./group/Groups";

const extractGroupsFromPlaygroundList = (playgroundList) => {
	const playgroundToGroupMap = {};
	if (playgroundList.playgrounds) {
		playgroundList.playgrounds.forEach(p => {
			let groupId = p.group_id;
			if (!groupId) {
				groupId = "";
			}

			if (!playgroundToGroupMap[groupId]) {
				playgroundToGroupMap[groupId] = [];
			}

			playgroundToGroupMap[groupId].push(p);
		});
	}

	const groups = playgroundList.groups || [];

	return [{ id: "", name: "Default" }, ...groups].map(g => {
		const cloneG = clone(g);

		if (playgroundToGroupMap[cloneG.id]) {
			cloneG.playgrounds = playgroundToGroupMap[cloneG.id];
		} else {
			cloneG.playgrounds = [];
		}

		return cloneG;
	});
};

export class PlaygroundListContent extends Component {
	render () {
		const {
			playgroundList, reloadPlaygrounds,
			openNewPlaygroundModal, openNewPlaygroundGroupModal, openEditPlaygroundGroupModal, deletePlaygroundGroup,
			preferences, setPreference
		} = this.props;

		const groups = extractGroupsFromPlaygroundList(playgroundList);

		const actions = [
			<TaskBarAction key="reload" icon="fas fa-sync-alt" onClick={reloadPlaygrounds}/>,
			<TaskBarAction key="clipboard" icon="fas fa-folder-plus" onClick={openNewPlaygroundGroupModal}/>
		];

		return (
			<>
				<WindowTaskBar
					title="Playgrounds"
					actions={actions}
				/>
				<Groups
					groups={groups}
					openNewPlaygroundModal={openNewPlaygroundModal}
					openEditPlaygroundGroupModal={openEditPlaygroundGroupModal}
					deletePlaygroundGroup={deletePlaygroundGroup}
					preferences={preferences}
					setPreference={setPreference}
				/>
			</>
		);
	}
}

PlaygroundListContent.propTypes = {
	playgroundList: PropTypes.object.isRequired,
	reloadPlaygrounds: PropTypes.func.isRequired,
	openNewPlaygroundModal: PropTypes.func.isRequired,
	openNewPlaygroundGroupModal: PropTypes.func.isRequired,
	openEditPlaygroundGroupModal: PropTypes.func.isRequired,
	deletePlaygroundGroup: PropTypes.func.isRequired,
	preferences: PropTypes.object.isRequired,
	setPreference: PropTypes.func.isRequired
};

export default PlaygroundListContent;
