import React, { Component } from "react";
import { Form, Formik } from "formik";
import InputField from "../../common/form/InputField";
import * as Yup from "yup";
import InlineError from "../../common/message/InlineError";
import InlineInfo from "../../common/message/InlineInfo";
import ProcessResetSuccess from "./ProcessResetSuccess";
import Loading from "../../../../components/common/loading/Loading";

const ProcessResetFormSchema = Yup.object().shape({
	password: Yup.string()
		.required("Password is required.")
		.min(8, "Password is too short - should be 8 chars minimum.")
});

const ProcessResetMessage = ({ error }) => {
	if (error) {
		return (
			<InlineError error={error} title="Internal Error">
				<p>Well this is embarrassing, it looks like we encountered an error while trying to
					change your password. Please try again later and contact support if the
					issue persists.
				</p>
			</InlineError>
		);
	}

	return (
		<InlineInfo title="Change Password">
			<p>Ok cool now that we've identified you, please enter a new password below.</p>
		</InlineInfo>
	);
};

class ProcessResetForm extends Component {
	render () {
		let { reset, closeModal, processReset, isDialog } = this.props;
		if (!reset) {
			reset = {};
		}

		if (reset.success) {
			return (
				<ProcessResetSuccess
					closeModal={closeModal}
					isDialog={isDialog}
				/>
			);
		}

		const onSubmit = (form) => {
			form.token = reset.token;
			processReset(form);
		};

		return (
			<Formik
				onSubmit={onSubmit}
				initialValues={{
					password: ""
				}}
				validationSchema={ProcessResetFormSchema}
				render={({ setFieldValue, ...props }) => (
					<Form className="ed-form">
						<section className="modal-card-body">
							{!reset.loading
								? (
									<>
										<ProcessResetMessage error={reset.error}/>

										<InputField
											name="password" label="Password"
											type="password" placeholder="Password" {...props}
										/>
									</>
								)
								: <Loading/>}
						</section>
						<footer className="modal-card-foot">
							<button type="submit" className="button is-primary" disabled={reset.loading}>Change
								Password
							</button>
							{!isDialog
								? <button type="button" className="button" onClick={closeModal}>Close</button>
								: ""}
						</footer>
					</Form>
				)}
			/>
		);
	}
}

export default ProcessResetForm;
