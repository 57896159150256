import * as React from "react";

export class ArrowLinkSegmentWidget extends React.Component {
	render () {
		const Bottom = React.cloneElement(
			this.props.factory.generateLinkSegment(
				this.props.link,
				this.props.selected || this.props.link.isSelected(),
				this.props.path
			),
			{
				ref: this.props.forwardRef
			}
		);

		return (
			<g>
				{Bottom}
			</g>
		);
	}
}
