import { v4 as uuid } from "uuid";
import { LogicTypeParam } from "../type";

export const defaultConditionRule = () => {
	return {
		_id: uuid(),
		enabled: true,
		type: LogicTypeParam,
		key: "",
		operator: "",
		value: ""
	};
};
