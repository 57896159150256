import React from "react";

import { PortModelAlignment } from "@projectstorm/react-diagrams";

import DropShadowSvgFilter from "../../../svg/defs/DropShadowSvgFilter";
import { PortSetTargetFacet } from "./PortSetTargetFacet";
import { PortSimpleFacet } from "./PortSimpleFacet";
import { PortTargetsFacet } from "./PortTargetsFacet";
import { initNodeProps } from "../../../../../lib/common/diagram/node";
import { initStyleProps } from "../../../../../lib/common/diagram/style";
import { getNodeTypeOptions, NodeTypeTarget } from "../../../../../config/node";
import { PortTeleportInletFacet } from "./PortTeleportInletFacet";

export class DefaultPortFacet extends React.Component {
	state = {
		isActive: false
	};

	setIsActive = (isActive) => {
		this.setState(() => ({
			isActive: isActive
		}));

		return false;
	};

	render () {
		const {
			node, type, teleports, targets, portWidth, portHeight, portSize, portPadding,
			position, withSetTarget, handleSetTarget, withTeleportInlet
		} = this.props;
		const { isActive } = this.state;

		const shapeSize = portSize;
		let shapeOffsetX = 0;
		let shapeOffsetY = 0;
		let inTeleportOffsetX = 0;
		let inTeleportOffsetY = 0;

		switch (position) {
			case PortModelAlignment.LEFT:
				shapeOffsetX = portWidth - shapeSize - portPadding;
				shapeOffsetY = (portHeight / 2) - shapeSize / 2;

				inTeleportOffsetX = shapeOffsetX - (shapeSize * -0.40);
				inTeleportOffsetY = shapeOffsetY - (shapeSize * 0.80);
				break;
			case PortModelAlignment.TOP:
				shapeOffsetY = portHeight - shapeSize - portPadding;
				shapeOffsetX = (portWidth / 2) - shapeSize / 2;
				break;
			case PortModelAlignment.RIGHT:
				shapeOffsetX = portPadding;
				shapeOffsetY = (portHeight / 2) - shapeSize / 2;
				break;
			case PortModelAlignment.BOTTOM:
				shapeOffsetY = portPadding;
				shapeOffsetX = (portWidth / 2) - shapeSize / 2;
				break;
			default:
		}

		const setTargetNodeProps = initNodeProps(
			initStyleProps({
				shapePad: 0,
				...getNodeTypeOptions(NodeTypeTarget, {}, type)
			})
		);

		return (
			<svg width={portWidth} height={portHeight}>
				<defs>
					<DropShadowSvgFilter id="drop-shadow"/>
				</defs>
				<g id="Layer_1"/>
				<g id="Layer_2">
					<rect
						width={portWidth} height={portHeight}
						fill="transparent"
						onMouseOver={() => this.setIsActive(true)}
						onMouseLeave={() => this.setIsActive(false)}
					/>

					{withSetTarget
						? (
							<PortSetTargetFacet
								nodeProps={setTargetNodeProps}
								size={shapeSize}
								offsetX={shapeOffsetX}
								offsetY={shapeOffsetY}
								onClick={handleSetTarget}
								isActive={isActive}
								setIsActive={this.setIsActive}
							/>
						)
						: (
							<PortSimpleFacet
								setTargetNodeProps={setTargetNodeProps}
								position={position}
								portSize={portSize}
								portWidth={portWidth}
								portHeight={portHeight}
								isActive={isActive}
								setIsActive={this.setIsActive}
							/>
						)}

					<PortTargetsFacet
						type={type}
						teleports={teleports}
						targets={targets}
						setTargetNodeProps={setTargetNodeProps}
						size={shapeSize}
						offsetX={shapeOffsetX}
						offsetY={shapeOffsetY}
						onClick={handleSetTarget}
						isActive={isActive}
						setIsActive={this.setIsActive}
					/>

					{withTeleportInlet && (
						<PortTeleportInletFacet
							node={node}
							teleports={teleports}
							size={shapeSize}
							offsetX={inTeleportOffsetX}
							offsetY={inTeleportOffsetY}
							onClick={handleSetTarget}
							isActive={isActive}
							setIsActive={this.setIsActive}
						/>
					)}
				</g>
			</svg>
		);
	}
}
