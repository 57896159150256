import {useVarContexts, VarContext} from "./data";
import {Alert, Button, CircularProgress, MenuItem, Select, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {Chit} from "../../canvas/components/playground/chip/chit";


type VarContextsListProps = {
    onRemoveVarContext(key: string): void;
    selectedContexts: { [key: string]: VarContext };
}

export type VarContextsManagerProps = VarContextsListProps & {
    onAddVarContext(varContext: VarContext, key: string): void;
}


function SelectedVarContextsList(props: VarContextsListProps) {
    const {selectedContexts, onRemoveVarContext} = props;
    const contextKeys = Object.keys(selectedContexts);

    if (!contextKeys.length) {
        return <Alert severity="info">No contexts added</Alert>;
    }

    return <div>
        {contextKeys.map((contextKey) => (
            <div key={contextKey} className="picked-context-key-value">
                <div className="key">context.{contextKey}</div>
                <div className="value">
                    <Chit size="normal" text={selectedContexts[contextKey].name}/>
                </div>
                <Button onClick={() => onRemoveVarContext(contextKey)}>
                    <DeleteIcon/>
                    Remove
                </Button>
            </div>
        ))}
    </div>;
}

export function VarContextsManager(props: VarContextsManagerProps) {

    const {onAddVarContext, onRemoveVarContext, selectedContexts} = props;
    const {varContexts, loading, error} = useVarContexts();
    const [pickerPhase, setPickerPhase] = useState<"closed" | "selecting" | "naming">("closed");

    const [selectedContext, setSelectedContext] = useState<VarContext | null>(null);
    const [newContextKey, setNewContextKey] = useState<string>("");

    useEffect(() => {
        if (selectedContext) {
            setNewContextKey(selectedContext.name);
        }
    }, [selectedContext]);

    useEffect(() => {
        if (varContexts && varContexts.length > 0) {
            setSelectedContext(varContexts[0]);
        }
    }, [varContexts]);

    function addContext() {
        if (selectedContext) {
            onAddVarContext(selectedContext, newContextKey);
        }

        const resetContext = varContexts && varContexts.length > 0 ? varContexts[0] : null;
        if (resetContext) {
            setNewContextKey(resetContext.name);
        }
        setSelectedContext(resetContext);
        setPickerPhase("closed");
    }


    if (loading) return <CircularProgress/>;
    if (error) return <Alert severity="error">Unable to get context data!</Alert>;
    if (!varContexts) return <Alert severity="warning">No contexts found!</Alert>;

    function makePicker(contexts: VarContext[]) {

        if (pickerPhase === "closed") {
            if (contexts.length === 0)
                return <Alert severity="info">No contexts found. You can add variable contexts in your settings.</Alert>;

            return <div className="picker-field">
                <Button onClick={() => setPickerPhase("selecting")}>
                    <AddIcon/> Add Context
                </Button>
            </div>;
        }

        if (pickerPhase === "selecting") {
            return <div className="picker-field">
                <Select size={"small"} fullWidth={true}
                        value={selectedContext ? selectedContext.id : ""} onChange={
                    (e) => {
                        const contextId = e.target.value as string;
                        const context = contexts.find((context) => context.id === contextId);
                        setSelectedContext(context || null);
                    }
                }>
                    {contexts.map((context) => (
                        <MenuItem key={context.id} value={context.id}>{context.name}</MenuItem>
                    ))}
                </Select>

                {
                    selectedContext && <Button onClick={() => {
                        setPickerPhase("naming");
                    }}>Next</Button>
                }
            </div>;
        }

        if (pickerPhase === "naming") {
            return <div className="picker-field">
                <TextField fullWidth={true}
                           size={"small"}
                           label="Context Key" value={newContextKey} onChange={(e) => {
                    setNewContextKey(e.target.value);
                }}/>
                <Button onClick={addContext}>Add</Button>
            </div>;
        }
    }

    return <div className="var-contexts-manager">
        <h3>Manage Variable Contexts</h3>
        {varContexts.length > 0 && <SelectedVarContextsList
			selectedContexts={selectedContexts}
			onRemoveVarContext={onRemoveVarContext}
		/>}
        {makePicker(varContexts)}
    </div>;
}
