import { capitalizeFirstLetter } from "../../variable/keys";

export const ConditionOr = "OR";
export const ConditionAnd = "AND";
export const ConditionNoneOf = "NONE_OF";

export const conditionOptions = [
	{ value: ConditionAnd, label: capitalizeFirstLetter(ConditionAnd) },
	{ value: ConditionOr, label: capitalizeFirstLetter(ConditionOr) },
	{ value: ConditionNoneOf, label: "None Of" }
];
