import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Color from "color";

import config, { CfgColors } from "../../../../config";

import "./LogicGroup.scss";

const LogicGroupActions = ({ index, count, moveUp, moveDown, doDelete, disableDelete }) => {
	if ((!moveUp || !(index > 0)) &&
		(!moveDown || !(index !== count - 1)) &&
		(disableDelete || !doDelete)) {
		return "";
	}

	const doMoveUp = () => {
		if (moveUp && index > 0) {
			moveUp();
		}

		return false;
	};

	const doMoveDown = () => {
		if (moveDown && index !== count - 1) {
			moveDown();
		}

		return false;
	};

	return (
		<div className="control-actions-container">
			<div className="control-actions">
				<div
					className={classNames(
						"action",
						"up",
						{ "is-enabled": (index > 0) }
					)} onClick={doMoveUp}
				>
          <span className="icon">
            <i className="fas fa-arrow-up"/>
          </span>
				</div>
				<div
					className={classNames(
						"action",
						"down",
						{ "is-enabled": (index !== count - 1) }
					)} onClick={doMoveDown}
				>
          <span className="icon">
            <i className="fas fa-arrow-down"/>
          </span>
				</div>
				{!disableDelete && doDelete
					? (
						<div className="action trash is-enabled" onClick={doDelete}>
              <span className="icon">
                <i className="fas fa-trash"/>
              </span>
						</div>
					)
					: ""}
			</div>
		</div>
	);
};

const LogicGroupOption = ({ option, onSelect }) => {
	return (
		<div
			key={"opt-" + option.value}
			className="group-option"
			onClick={() => onSelect(option)}
		>{option.label}
		</div>
	);
};

const LogicGroupOptions = ({ value, options, width, onSelect }) => {
	return (
		<div
			className="group-options"
			style={{ minWidth: width + "px" }}
		>
			{options.filter(o => {
				return (o.value !== value);
			}).map(o => (
				<LogicGroupOption
					key={"opt-" + o.value}
					option={o}
					onSelect={onSelect}
				/>
			))}
		</div>
	);
};

class LogicGroupControl extends Component {
	// eslint-disable-next-line
	static propTypes = {
		value: PropTypes.string,
		options: PropTypes.array,
		label: PropTypes.string,
		setFieldValue: PropTypes.func,
		fieldName: PropTypes.string,
		onChange: PropTypes.func,
		withSettings: PropTypes.bool,
		onClickSettings: PropTypes.func,
		width: PropTypes.number,
		className: PropTypes.string
	};

	state = {
		isActive: false,
		isSelectorHover: false
	};

	constructor (props) {
		super(props);
		this.containerSel = React.createRef();
	}

	componentDidMount () {
		document.addEventListener("mousedown", this.handleSelectorClick, false);
	}

	componentWillUnmount () {
		document.removeEventListener("mousedown", this.handleSelectorClick, false);
	}

	setActive = (isActive) => {
		this.setState(() => ({
			isActive: isActive
		}));

		return false;
	};

	setSelectorHover = (isSelectorHover) => {
		this.setState(() => ({
			isSelectorHover: isSelectorHover
		}));

		return false;
	};

	handleSelectorClick = (e) => {
		const { isActive } = this.state;
		if (!isActive) {
			return;
		}

		if (!this.containerSel) {
			return;
		}

		if (!this.containerSel.current) {
			return;
		}

		if (this.containerSel.current.contains(e.target)) {
			return;
		}

		this.setActive(false);
	};

	render () {
		let {
			value, options, label, onChange,
			setFieldValue, fieldName, withSettings, width
		} = this.props;
		const { isActive } = this.state;

		if (!width) {
			width = 100;
		}

		let haveOptions = false;
		let onSelect;
		let selected;

		if (options && options.length > 0) {
			options.forEach(o => {
				if (!selected) {
					selected = o;
				} else if (value && o.value === value) {
					selected = o;
				}
			});

			onSelect = (option) => {
				if (setFieldValue && fieldName) {
					setFieldValue(fieldName, option.value, true);
				}

				if (onChange) {
					onChange(option);
				}

				this.setActive(false);
			};

			haveOptions = true;
		} else {
			selected = {
				label: label
			};
		}

		return (
			<div className="logic-group-control-container" ref={this.containerSel}>
				<div
					className={classNames(
						"logic-group-control",
						"text-selection-none",
						{ "is-active": isActive }
					)}
					style={{ minWidth: width + "px" }}
				>
					{withSettings
						? (
							<div
								className={classNames(
									"control-settings",
									{ "is-active": isActive })}
							>
                <span className="icon">
                  <i className="fas fa-cog"/>
                </span>
							</div>
						)
						: ""}
					{(haveOptions)
						? (
							<div
								className={classNames(
									"control-selector",
									{ "without-settings": !withSettings },
									{ "have-options": haveOptions },
									{ "is-active": isActive }
								)}
								onClick={() => this.setActive(!isActive)}
							>
								<div className="selector-label">
									{selected.label}
								</div>
								<div className="selector-icon" key={((isActive) ? "open" : "closed")}>
                  <span className="icon">
                    {isActive
						? <i className="fad fa-chevron-down"/>
						: <i className="fad fa-chevron-right"/>}
                  </span>
								</div>
							</div>
						)
						: (
							<div
								className={classNames(
									"control-selector",
									{ "without-settings": !withSettings },
									{ "is-active": isActive }
								)}
							>
								<div className="selector-label">
									{selected.label}
								</div>
								<div className="selector-icon"/>
							</div>
						)}
					{(haveOptions && isActive)
						? <LogicGroupOptions
							value={value}
							options={options}
							width={width}
							onSelect={onSelect}
						/>
						: ""}
				</div>
			</div>
		);
	}
}

class LogicGroupContent extends Component {
	static propTypes = {
		value: PropTypes.string,
		options: PropTypes.array,
		label: PropTypes.string,
		isEmpty: PropTypes.bool,
		color: PropTypes.string,
		fontColor: PropTypes.string,
		setFieldValue: PropTypes.func,
		fieldName: PropTypes.string,
		onChange: PropTypes.func,
		withSettings: PropTypes.bool,
		onClickSettings: PropTypes.func,
		width: PropTypes.number,
		className: PropTypes.string,
		isDraggable: PropTypes.bool,
		draggableId: PropTypes.string,
		moveUp: PropTypes.func,
		moveDown: PropTypes.func,
		doDelete: PropTypes.func,
		disableDelete: PropTypes.bool,
		isDraggingOver: PropTypes.bool
	};

	render () {
		let { isEmpty, color, fontColor, className, isDraggingOver, children, ...rest } = this.props;
		if (isEmpty === undefined) {
			isEmpty = true;
		}
		if (!className) {
			className = "";
		}

		const colourCfg = config.get(CfgColors);
		if (!color) {
			color = colourCfg.greyDark;
		}
		if (!fontColor) {
			fontColor = colourCfg.white;
		}

		const inactiveColor = Color(color).lighten("0.15").hsl().string();
		const inactiveFontColor = Color(fontColor).darken("0.05").hsl().string();

		let background = "inherit";
		if (isDraggingOver) {
			const backgroundColor = Color(color).alpha(0.03).hsl().string();

			background = `repeating-linear-gradient(
        45deg,
        #FFF,
        #FFF 10px,
        ${backgroundColor} 10px,
        ${backgroundColor} 20px
      )`;
		}

		return (
			<div
				className={"logic-group " + className +
					((isEmpty) ? " is-empty " : "") +
					((isDraggingOver) ? " is-dragging-over " : "")}
				style={{
					"--active-color": color,
					"--active-font-color": fontColor,
					"--inactive-color": inactiveColor,
					"--inactive-font-color": inactiveFontColor,
					background: background
				}}
			>

				<LogicGroupActions color={color} {...rest} />

				<LogicGroupControl color={color} {...rest} />

				{children}
			</div>
		);
	}
}

export class LogicGroup extends Component {
	static propTypes = {
		value: PropTypes.string,
		options: PropTypes.array,
		label: PropTypes.string,
		index: PropTypes.number,
		count: PropTypes.number,
		isEmpty: PropTypes.bool,
		color: PropTypes.string,
		fontColor: PropTypes.string,
		setFieldValue: PropTypes.func,
		fieldName: PropTypes.string,
		onChange: PropTypes.func,
		withSettings: PropTypes.bool,
		onClickSettings: PropTypes.func,
		width: PropTypes.number,
		className: PropTypes.string,
		moveUp: PropTypes.func,
		moveDown: PropTypes.func,
		doDelete: PropTypes.func,
		disableDelete: PropTypes.bool,
		isDraggingOver: PropTypes.bool
	};

	render () {
		return <LogicGroupContent {...this.props} />;
	}
}

export default LogicGroup;
