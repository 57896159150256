import { LoadingCloseModalAction, LoadingOpenModalAction } from "../../actions/loading/loading";

const loadingRegister = {};

export const registerLoadingActions = (onActions, offActions) => {
	if (onActions) {
		for (const action of onActions) {
			loadingRegister[action] = {
				open: true
			};
		}
	}
	if (offActions) {
		for (const action of offActions) {
			loadingRegister[action] = {
				open: false
			};
		}
	}
};

const calculateLoadingState = (state, payload) => {
	let openCount = 0;
	if (state.openCount) {
		openCount = state.openCount;
	}

	if (payload.open) {
		openCount++;
	} else {
		openCount--;
	}

	if (openCount < 1) {
		openCount = 0;
	}

	return {
		open: (openCount > 0),
		openCount: openCount
	};
};

export const loadingReducer = (state = {}, action) => {

	if (loadingRegister[action.type]) {
		return calculateLoadingState(state, loadingRegister[action.type]);
	}

	switch (action.type) {
		case LoadingOpenModalAction.getType():
			return calculateLoadingState(state, { open: true, ...action.payload });
		case LoadingCloseModalAction.getType():
			return calculateLoadingState(state, { open: false });
		default:
			return state;
	}
};
