export const nearestToNumber = (n, number) => {
	let o = Math.round(n / number);

	o *= number;

	return o;
};

export const floorToPlaces = (num, decimals) => {
	const multiplier = Math.pow(10, decimals);

	return Math.floor(num * multiplier) / multiplier;
};
