import React, { useState } from "react";
import PropTypes from "prop-types";
import reactCSS from "reactcss";
import merge from "lodash/merge";

import { Checkboard, ColorWrap, Hue, Saturation } from "react-color/lib/components/common";
import SketchFields from "react-color/lib/components/sketch/SketchFields";
import SketchPresetColors from "./PresetColours";

import "./ColorInput.scss";

export const Sketch = ({
	rgb, hex, hsv, hsl, onChange, onSwatchHover, onClear, color,
	disableAlpha, presetColors, styles: passedStyles = {}, className = ""
}) => {
	const [isOpen, setOpen] = useState(color && !presetColors.includes(color.toUpperCase()));
	const styles = reactCSS(merge({
		default: {
			saturation: {
				radius: "3px",
				shadow: "inset 0 0 0 1px rgba(0,0,0,.15), inset 0 0 4px rgba(0,0,0,.25)"
			},
			activeColor: {
				absolute: "0px 0px 0px 0px",
				borderRadius: "2px",
				background: `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`,
				boxShadow: "inset 0 0 0 1px rgba(0,0,0,.15), inset 0 0 4px rgba(0,0,0,.25)"
			},
			Hue: {
				radius: "2px",
				shadow: "inset 0 0 0 1px rgba(0,0,0,.15), inset 0 0 4px rgba(0,0,0,.25)"
			},
			...passedStyles
		}
	}, passedStyles));

	return (
		<div className={`sketch-picker ${className}`}>
			<SketchPresetColors
				colors={presetColors}
				onClick={onChange}
				onClear={() => {
					onClear();
					setOpen(false);
				}}
				onToggle={() => setOpen(!isOpen)}
				onSwatchHover={onSwatchHover}
				isOpen={isOpen}
				selected={color}
			/>
			{isOpen && (
				<>
					<div className="saturation">
						<Saturation
							style={styles.saturation}
							hsl={hsl}
							hsv={hsv}
							onChange={onChange}
						/>
					</div>
					<div className="controls">
						<div className="sliders">
							<div className="hue">
								<Hue
									style={styles.Hue}
									hsl={hsl}
									onChange={onChange}
								/>
							</div>
						</div>
						<div className="color">
							<Checkboard/>
							<div style={styles.activeColor}/>
						</div>
					</div>

					<SketchFields
						rgb={rgb}
						hsl={hsl}
						hex={hex}
						onChange={onChange}
						disableAlpha={disableAlpha}
					/>
				</>
			)}
		</div>
	);
};

Sketch.propTypes = {
	disableAlpha: PropTypes.bool,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	styles: PropTypes.object
};

Sketch.defaultProps = {
	disableAlpha: false,
	width: 200,
	styles: {},
	presetColors: ["#D0021B", "#F5A623", "#F8E71C", "#8B572A", "#7ED321", "#417505",
		"#BD10E0", "#9013FE", "#4A90E2", "#50E3C2", "#B8E986", "#000000",
		"#4A4A4A", "#9B9B9B"]
};

export const ColorPicker = ColorWrap(Sketch);
