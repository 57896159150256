import React, { Component } from "react";
import { connect } from "react-redux";

import config from "../../../../../../../config";
import { CfgColors } from "core/config";
import Modal from "core/components/common/modal/Modal";
import { getNestedState } from "core/lib/state";
import {
	CloseTerminateTargetModalAction
} from "../../../../../../../actions/playground/inlet/filter/canvas/phase/target/terminate";
import TerminateTargetContent
	from "../../../../../../../components/playground/inlet/filter/canvas/phase/target/terminate/TerminateTargetContent";

class TerminateTargetModal extends Component {
	render () {
		let { closeModal, terminateTarget } = this.props;
		if (!terminateTarget) {
			terminateTarget = {};
		}

		const open = !!(terminateTarget && terminateTarget.open);
		if (!open) {
			return null;
		}

		if (terminateTarget.loading) {
			closeModal = () => {
				// Disabled.
			};
		}

		const handleClose = () => {
			closeModal();

			if (terminateTarget.onClose) {
				terminateTarget.onClose();
			}
		};

		const colourCfg = config.get(CfgColors);

		return (
			<>
				<Modal
					title="Terminate"
					closeModal={handleClose}
					icon="fas fa-traffic-light-stop"
					modalState={open}
					headBgColor={colourCfg.red}
					fontColor={colourCfg.white}
				>
					<TerminateTargetContent
						closeModal={handleClose}
						onSelect={(payload) => {
							terminateTarget.onSelect(payload);
						}}
					/>
				</Modal>
			</>
		);
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		closeModal: () => dispatch(CloseTerminateTargetModalAction())
	};
};

const mapStateToProps = (state, props) => {
	const mapped = {};

	const core = state.get("core");
	if (core) {
		mapped.profile = core.get("profile");
	}

	const playground = getNestedState(state, ["canvas", "playground"]);
	if (!playground) {
		return mapped;
	}

	const target = getNestedState(playground, ["inlet", "filter", "canvas", "phase", "target"]);
	if (!target) {
		return mapped;
	}

	return {
		...mapped,
		terminateTarget: target.get("terminate")
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminateTargetModal);
