import React from "react";
import PropTypes from "prop-types";

import { NodeCardIcon } from "./NodeCards";

import "./NodeInfoCard.scss";

const NodeTag = ({ title, label }) => {
	return (
		<div className="node-tag">
			<div className="tag-title">
				{title}
			</div>
			<div className="tag-label">
				{label}
			</div>
		</div>
	);
};

const getNodeTags = (node) => {
	const { nodeProps } = node;
	const tags = [];

	if (nodeProps.canvas) {
		tags.push(
			<NodeTag
				key="canvas"
				title="Canvas"
				label={nodeProps.canvas.name}
			/>
		);
	}
	if (nodeProps.phase) {
		tags.push(
			<NodeTag
				key="phase"
				title="Phase"
				label={nodeProps.phase.name}
			/>
		);
	}

	return tags;
};

export class NodeInfoCard extends React.Component {
	static propTypes = {
		cbRef: PropTypes.object.isRequired
	};

	state = {
		node: undefined
	};

	setNode = (node) => {
		this.setState((prevState) => {
			return {
				node: node
			};
		});

		return false;
	};

	render () {
		const { cbRef } = this.props;
		const { node } = this.state;

		if (cbRef) {
			// Not the best, but unsure how else to do this.
			cbRef.setNode = this.setNode;
		}

		if (!node) {
			return "";
		}

		const tags = getNodeTags(node);

		return (
			<div className="node-info-card">
				<div className="node-info-card-content">
					<NodeCardIcon node={node} shapeSize={50} shapePad={5}/>

					<div className="node-details">
						{tags.length < 1 && (
							<div className="node-title">
								{node.nodeProps.label}
							</div>
						)}
						{tags.length < 2 && (
							<div className="node-description">
								{node.nodeProps.description}
							</div>
						)}
						{tags.length > 0 && (
							<div className="node-tags">
								{tags}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
