import React, { Component } from "react";
import { Form, Formik } from "formik";
import InputField from "../../common/form/InputField";
import * as Yup from "yup";
import InlineError from "../../common/message/InlineError";
import { ErrCodeUserNotFound } from "../../../errs";
import InlineInfo from "../../common/message/InlineInfo";
import Loading from "../../../../components/common/loading/Loading";

const ResendFormSchema = Yup.object().shape({
	email: Yup.string()
		.required("User name is required.")
});

const ActivationMessage = ({ error, fromLogin }) => {
	if (error) {
		if (ErrCodeUserNotFound === error.error_code) {
			return (
				<InlineError error={error} title="Authentication Error">
					<p>The email address you entered doesn't appear to be linked to any account.
						Please verify it and try again.
					</p>
				</InlineError>
			);
		}

		return (
			<InlineError error={error} title="Internal Error">
				<p>Well this is embarrassing, it looks like we encountered an error while trying to
					resend your activation link. Please try again later and contact support if the
					issue persists.
				</p>
			</InlineError>
		);
	}

	if (fromLogin) {
		return (
			<InlineInfo title="Activation Required">
				<p>Whoa, hold up! Before you can login you need to activate your account. If you can't find the
					email we sent you, have a new one sent below.
				</p>
			</InlineInfo>
		);
	}

	return (
		<InlineError title="Activation Failed :(" error={error}>
			<p>Something went wrong or the link you followed may be incorrect.
				To have another one sent to you please enter your email and click the action below.
			</p>
		</InlineError>
	);
};

const VerificationMessage = ({ error, fromLogin }) => {
	if (error) {
		if (ErrCodeUserNotFound === error.error_code) {
			return (
				<InlineError error={error} title="Authentication Error">
					<p>The email address you entered doesn't appear to be linked to any account.
						Please verify it and try again.
					</p>
				</InlineError>
			);
		}

		return (
			<InlineError error={error} title="Internal Error">
				<p>Well this is embarrassing, it looks like we encountered an error while trying to
					resend your verification link. Please try again later and contact support if the
					issue persists.
				</p>
			</InlineError>
		);
	}

	if (fromLogin) {
		return (
			<InlineInfo title="Verification Required">
				<p>Before you can login with your new email we need to verify it. If you
					can't find the email we sent you, have a new one sent below. Alternatively,
					you'll still be able to login using your old email.
				</p>
			</InlineInfo>
		);
	}

	return (
		<InlineError title="Verification Failed :(" error={error}>
			<p>Something went wrong or the link you followed may be incorrect.
				To have another one sent to you please enter your email and click the action below.
			</p>
		</InlineError>
	);
};

class ActivateErrorAndResend extends Component {
	render () {
		const { closeModal, resendLink, loading, error, fromLogin, email, forVerify, isDialog } = this.props;

		const onSubmit = (form) => {
			resendLink(form);
		};

		return (
			<Formik
				onSubmit={onSubmit}
				initialValues={{
					email: email || ""
				}}
				validationSchema={ResendFormSchema}
				render={({ setFieldValue, ...props }) => (
					<Form className="ed-form">
						<section className="modal-card-body">
							{!loading
								? (
									<>
										{forVerify
											? <VerificationMessage error={error} fromLogin={fromLogin}/>
											: <ActivationMessage error={error} fromLogin={fromLogin}/>}

										<InputField
											name="email" label="Email"
											type="email" placeholder="Your email" {...props}
										/>
									</>
								)
								: <Loading/>}
						</section>
						<footer className="modal-card-foot">
							<button type="submit" className="button is-primary" disabled={loading}>Resend Link</button>
							{!isDialog
								? <button type="button" className="button" onClick={closeModal}>Close</button>
								: ""}
						</footer>
					</Form>
				)}
			/>
		);
	}
}

export default ActivateErrorAndResend;
