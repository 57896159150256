import React from "react";

import PropTypes from "prop-types";

import config from "../../../../config";

import { getSvgSpriteStyles } from "core/config";

import "./IconSvgElem.scss";

const SvgSprite = ({ fill, x, y, width, sprite }) => {
	const iconStyles = getSvgSpriteStyles(config, sprite);

	if (iconStyles) {
		if (iconStyles.padPercent && iconStyles.padPercent !== 0) {
			const padding = width * (iconStyles.padPercent / 100);

			// Adjust
			width -= padding;
			x += padding / 2;
			y += padding / 2;
		}

		if (iconStyles.offsetTopPercent && iconStyles.offsetTopPercent !== 0) {
			const offsetTop = width * (iconStyles.offsetTopPercent / 100);

			// Adjust
			y += offsetTop;
		}

		if (iconStyles.offsetLeftPercent && iconStyles.offsetLeftPercent !== 0) {
			const offsetLeft = width * (iconStyles.offsetLeftPercent / 100);

			// Adjust
			x += offsetLeft;
		}
	}

	return (
		<svg className="svg-icon" style={{ fill: fill }}>
			<use x={x} y={y} width={width + "px"} height={width + "px"} href={iconStyles.sprite}/>
		</svg>
	);
};

export class IconSvgElem extends React.Component {
	render () {
		const { fill, x, y, width, svg, svgSprite, url } = this.props;

		if (url) {
			return (
				<g>
					<image x={x} y={y} width={width} height={width} xlinkHref={url}/>
				</g>
			);
		}

		if (svg) {
			return (
				<g>
					<image x={x} y={y} width={width} height={width} xlinkHref={svg}/>
				</g>
			);
		}

		return (
			<g>
				<SvgSprite fill={fill} x={x} y={y} width={width} sprite={svgSprite}/>
			</g>
		);
	}
}

IconSvgElem.propTypes = {
	x: PropTypes.number.isRequired,
	y: PropTypes.number.isRequired,
	width: PropTypes.number.isRequired,
	fill: PropTypes.string,
	svg: PropTypes.string,
	svgSprite: PropTypes.string,
	url: PropTypes.string
};

export default IconSvgElem;
