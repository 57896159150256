import React from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import ReactJson from "react-json-view";
import * as Yup from "yup";

import Loading from "components/common/loading/Loading";
import Tabs, { Tab } from "core/components/common/tabs/Tabs";
import InputField from "core/components/common/form/InputField";
import InlineError from "core/components/common/message/InlineError";
import { isPlaygroundRunStatusRunning } from "../../../../lib/playground/constants/status";

export const TraceTabIdQuery = "query";
export const TraceTabIdResult = "result";

const PlaygroundDebuggerTraceFormSchema = Yup.object().shape({
	run_id: Yup.string().test("RunIdRequired", "Run Id or Correlation Id is required", function () {
		return (this.parent.run_id || this.parent.correlation_id);
	})
});

const applyModelToForm = (model, dashboard) => {
	return {
		run_id: model.runId || "",
		correlation_id: model.correlationId || "",
		verbose: model.verbose || ""
	};
};

const applyFormToModel = (form) => {
	return {
		runId: form.run_id,
		correlationId: form.correlation_id,
		verbose: form.verbose
	};
};

const getInitialValues = (formData) => {
	return {
		run_id: formData.run_id || "",
		correlation_id: formData.correlation_id || "",
		verbose: formData.verbose || false
	};
};

export const PlaygroundDebuggerTraceForm = ({
	model, dashboard, onSubmit, reset, handleKill, buttonBgColor, buttonFontColor, activeTab, setActiveTab
}) => {
	if (!model) {
		model = {};
	}

	const resultsEnabled = (model.result && !model.error);

	if (!resultsEnabled) {
		if (activeTab === TraceTabIdResult) {
			// Default to first tab when no results.
			activeTab = TraceTabIdQuery;
		}
	}

	let killRunId = null;
	if (resultsEnabled) {
		if (model.result.length === 1) {
			const firstResult = model.result[0];
			if (isPlaygroundRunStatusRunning(firstResult.status)) {
				killRunId = firstResult.id;
			}
		}
	}

	const formData = applyModelToForm(model, dashboard);

	const onSubmitAdaptor = (form) => {
		return onSubmit(applyFormToModel(form));
	};

	return (
		<Formik
			onSubmit={onSubmitAdaptor}
			initialValues={getInitialValues(formData)}
			enableReinitialize
			validationSchema={PlaygroundDebuggerTraceFormSchema}
		>
			{({ errors, touched, handleReset, ...props }) => (
				<Form className="up-form with-tabs">
					<div className="pg-debugger-tab-content">
						{model.loading
							? <Loading minHeight={180}/>
							: (
								<Tabs
									borderColor={buttonBgColor}
									errors={errors}
									touched={touched}
									onTabSelect={setActiveTab}
									selectedTab={activeTab}
									{...props}
								>
									<Tab id={TraceTabIdQuery} title="Query"
										 fields={["run_id", "correlation_id", "verbose"]} fullHeight>
										<InputField
											key="run_id" name="run_id" label="Run Id"
											type="text"
											errors={errors} touched={touched}
											{...props}
										/>
										<InputField
											key="correlation_id" name="correlation_id" label="Correlation Id"
											type="text"
											errors={errors} touched={touched}
											{...props}
										/>
										<InputField
											key="verbose" name="verbose" label="Verbose"
											type="bool"
											errors={errors} touched={touched}
											{...props}
										/>
									</Tab>
									<Tab id={TraceTabIdResult} title="Result" disabled={!resultsEnabled} fullHeight>
										<ReactJson
											src={model.result}
											displayDataTypes={false}
											style={{
												fontSize: "0.65rem"
											}}
											name={null}
											theme="rjv-default"
											indentWidth={2}
										/>
									</Tab>
								</Tabs>
							)}
						{model.error && (
							<div className="up-form-inline-messages">
								{model.error && (
									<InlineError title="Error" error={model.error} showMessage/>
								)}
							</div>
						)}
					</div>
					<div className="up-form-inline-controls">
						<div className="buttons">
							<button
								type="submit" className="button is-small primary" style={{
								backgroundColor: buttonBgColor,
								color: buttonFontColor
							}} disabled={model.loading}
							>
								{(activeTab === TraceTabIdResult)
									? "Reload"
									: "Trace"}
							</button>

							<button
								type="button" className="button is-small" onClick={() => {
								handleReset();
								reset();
							}} disabled={model.loading}
							>
								Reset
							</button>
						</div>

						{killRunId && (
							<div className="buttons">
								<button
									type="button"
									className="button is-small is-danger"
									onClick={() => {
										handleKill({
											runId: killRunId
										}, () => {
											props.handleSubmit();
										});
									}}
									disabled={model.loading}
								>
									Kill
								</button>
							</div>
						)}
					</div>
				</Form>
			)}
		</Formik>
	);
};

PlaygroundDebuggerTraceForm.propTypes = {
	model: PropTypes.object.isRequired,
	dashboard: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	handleKill: PropTypes.func.isRequired,
	buttonBgColor: PropTypes.string.isRequired,
	buttonFontColor: PropTypes.string.isRequired,
	activeTab: PropTypes.string,
	setActiveTab: PropTypes.func.isRequired
};

export default PlaygroundDebuggerTraceForm;
