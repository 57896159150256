import { BranchingOutcomes } from "../../lib/playground/constants";

export const isNodeBranchingOutcomeAvailable = (nodeProps, outcome) => {
	if (nodeProps && nodeProps[outcome]) {
		if (nodeProps[outcome].disabled) {
			return false;
		}
	}

	return true;
};

export const getNodeBranchingOutcomes = (nodeProps) => {
	return BranchingOutcomes.filter(o => {
		return isNodeBranchingOutcomeAvailable(nodeProps, o);
	});
};
