import React, { Component } from "react";
import PropTypes from "prop-types";

import "./Card.scss";

export class Card extends Component {
	render () {
		const { accentColor, children } = this.props;

		return (
			<div className="pg-group-card">
				<div className="pg-card-body">
					<div className="pg-card-accent" style={{ backgroundColor: accentColor }}/>
					<div className="pg-card-content">
						{children}
					</div>
				</div>
			</div>
		);
	}
}

Card.propTypes = {
	accentColor: PropTypes.string.isRequired
};

export default Card;
