import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as Yup from "yup";
import { FieldArray } from "formik";
import { DragDropContext } from "react-beautiful-dnd";

import { formListMove, handleFormOnDrag } from "core/util/form/helper";

import {
	BranchingTypeAll,
	BranchingTypeEnum,
	BranchingTypeFirst
} from "../../../../../../../../../lib/playground/constants";
import { defaultNodeBranching } from "../../../../../../../../../lib/playground/canvas/phase/node/init";
import { capitalizeFirstLetter } from "core/lib/variable/keys";
import LogicGroup from "core/components/common/logic/group/LogicGroup";
import LogicGroupAction from "core/components/common/logic/group/LogicGroupAction";

import BranchingGroup from "./BranchingGroup";
import { BranchingOutcomeCatchAll } from "./BranchingOutcomeCatchAll";

import "./BranchingOutcome.scss";

const outcomeBranchingTypeOptions = [
	{ value: BranchingTypeAll, label: capitalizeFirstLetter(BranchingTypeAll) },
	{ value: BranchingTypeFirst, label: capitalizeFirstLetter(BranchingTypeFirst) }
];

export const getOutcomeYupSchema = () => {
	return Yup.object().shape({
		branching: Yup.array()
			.of(
				Yup.object().shape({
					branching: Yup.array()
						.of(
							Yup.object().shape({
								value: Yup.string().test("ValueRequired", "Value is required", function () {
									if (!this.from[1] || !this.from[1].value) {
										return true;
									}

									const groupValue = this.from[1].value;
									if (groupValue.branching_type === BranchingTypeEnum) {
										if (!this.parent.value && this.parent.value !== 0) {
											return false;
										}
									} else {
										if (this.parent.operator) {
											if (!this.parent.value && this.parent.value !== 0) {
												return false;
											}
										}
									}

									return true;
								}),
								goto: Yup.string().test("GotoRequired", "Target is required", function () {
									if (!this.from[0] || !this.from[0].value) {
										return true;
									}

									const rawParent = this.from[0].value;

									return (rawParent.goto && (rawParent.goto.target || rawParent.goto.canvas || rawParent.goto.phase));
								})
							})
						)
				})
			),
		default: Yup.array()
			.of(
				Yup.object().shape({
					goto: Yup.string().test("GotoRequired", "Target is required", function () {
						if (!this.from[0] || !this.from[0].value) {
							return true;
						}

						const rawParent = this.from[0].value;

						return (rawParent.goto && (rawParent.goto.target || rawParent.goto.canvas || rawParent.goto.phase));
					})
				})
			)
	});
};

export class BranchingOutcome extends PureComponent {
	static propTypes = {
		outcome: PropTypes.string.isRequired,
		nodeProps: PropTypes.object.isRequired,
		playgroundProps: PropTypes.object.isRequired,
		nodeInfoCbRef: PropTypes.object.isRequired,
		setModalHidden: PropTypes.func.isRequired,
		values: PropTypes.object,
		touched: PropTypes.object,
		errors: PropTypes.object,
		setFieldValue: PropTypes.func
	};

	state = {
		isDragging: false
	};

	setIsDragging = (isDragging) => {
		this.setState(() => ({
			isDragging: isDragging
		}));

		return false;
	};

	render () {
		const {
			outcome, values, touched, errors, setFieldValue, nodeProps, playgroundProps,
			setModalHidden, nodeInfoCbRef
		} = this.props;
		const outcomeBranching = values.branching[outcome];
		const namePath = "branching[" + outcome + "]";

		const { isDragging } = this.state;

		const groupLabelWidth = 105;

		const branching = (outcomeBranching.branching)
			? outcomeBranching.branching
			: [];

		const doMoveGroup = (from, to) => {
			// arrayHelpers.move is glitchy.
			formListMove(namePath + ".branching", values, setFieldValue, from, to);
		};

		return (
			<div
				className={classNames(
					"branching-outcome",
					{ "is-dragging": isDragging }
				)}
			>
				<DragDropContext
					onBeforeCapture={() => { this.setIsDragging(true); }}
					onDragEnd={(source, destination) => {
						handleFormOnDrag(values, setFieldValue)(source, destination);
						this.setIsDragging(false);
					}}
				>
					<LogicGroup
						value={outcomeBranching.branching_type}
						options={outcomeBranchingTypeOptions}
						isEmpty={branching.length <= 0}
						width={groupLabelWidth}
						className={(!branching || branching.length < 2) ? "minimal" : ""}
						setFieldValue={setFieldValue}
						fieldName={namePath + ".branching_type"}
					>
						<FieldArray name={namePath + ".branching"}>
							{arrayHelpers => (
								<>
									{branching.map((branch, index) => {
										return (
											<BranchingGroup
												key={"branch-" + index}
												group={branch}
												index={index}
												count={branching.length}
												outcome={outcome}
												namePath={namePath + ".branching[" + index + "]"}
												labelWidth={groupLabelWidth}
												nodeProps={nodeProps}
												playgroundProps={playgroundProps}
												nodeInfoCbRef={nodeInfoCbRef}
												setModalHidden={setModalHidden}
												moveUp={() => {
													doMoveGroup(index, index - 1);
												}}
												moveDown={() => {
													doMoveGroup(index, index + 1);
												}}
												doDelete={() => arrayHelpers.remove(index)}
												disableDelete={branching.length <= 1}
												setFieldValue={setFieldValue}
												values={values}
												touched={touched}
												errors={errors}
											/>
										);
									})}

									<LogicGroupAction onClick={() => arrayHelpers.push(defaultNodeBranching())}/>
								</>
							)}
						</FieldArray>
					</LogicGroup>

					<BranchingOutcomeCatchAll
						namePath={namePath}
						outcome={outcome}
						outcomeBranching={outcomeBranching}
						nodeProps={nodeProps}
						playgroundProps={playgroundProps}
						nodeInfoCbRef={nodeInfoCbRef}
						setModalHidden={setModalHidden}
						values={values}
						touched={touched}
						errors={errors}
						setFieldValue={setFieldValue}
					/>
				</DragDropContext>
			</div>
		);
	}
}

export default BranchingOutcome;
