import * as React from "react";

import { PortModelAlignment, PortWidget } from "@projectstorm/react-diagrams";
import DropShadowSvgFilter from "../../svg/defs/DropShadowSvgFilter";

import "./StartNodeWidget.scss";

export const StartNodeWidget = ({ ...props }) => {
	let shapePad = 12;
	if (props.shapePad) {
		shapePad = props.shapePad;
	}

	const width = props.size;
	const height = props.size;
	const leftPos = 0;

	return (
		<div
			className="start-node"
			style={{
				position: "relative",
				left: leftPos,
				width: width,
				height: height
			}}
		>
			<svg width={width} height={height}>
				<defs>
					<DropShadowSvgFilter id="drop-shadow"/>
				</defs>
				<g id="Layer_1"/>
				<g id="Layer_2">
					<circle
						r={(props.size / 2) - (shapePad)}
						cx={props.size / 2}
						cy={props.size / 2}
						fill={props.fill}
						className="circle"
					/>
				</g>
			</svg>

			<PortWidget
				style={{
					left: (props.size - 8) / 2,
					top: props.size / 2 - 8,
					position: "absolute",
					zIndex: 9999
				}}
				port={props.node.getPort(PortModelAlignment.RIGHT)}
				engine={props.engine}
			>
				<div className="port success"/>
			</PortWidget>
		</div>
	);
};
