// eslint-disable-next-line no-redeclare
/* global localStorage */

const preferencesKey = "preferences";

export const setPreference = (values) => {
	localStorage.setItem(preferencesKey, JSON.stringify({
		...getPreferences(),
		...values
	}));
};

export const getPreferences = () => {
	const currentStr = localStorage.getItem(preferencesKey);
	let current = {};

	if (currentStr) {
		current = JSON.parse(currentStr);
	}

	return current;
};
