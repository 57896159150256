import React from "react";
import PropTypes from "prop-types";

import { Resources } from "./Resources";
import SplashContainer from "./splash/SplashContainer";

const Site = ({ resources, profile, children }) => {
	if (resources && resources.loaded && profile && !profile.loading) {
		return (
			<div className="site">
				<Resources resources={resources}/>
				{children}
			</div>
		);
	}

	return <SplashContainer/>;
};

Site.propTypes = {
	resources: PropTypes.object,
	profile: PropTypes.object,
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default Site;
