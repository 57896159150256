import { apiFetch } from "../base";

export function * postLogin (payload) {
	const { ...model } = payload;

	const opts = {
		endpoint: "/auth/v1/auth/login",
		method: "POST",
		authenticated: false,
		body: JSON.stringify(model)
	};

	return yield apiFetch(opts);
}
