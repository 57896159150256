import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class Home extends Component {
	componentDidMount () {
		this.props.setFullscreen(false);
	}

	render () {
		// Default to change password
		return <Redirect to="/account/contexts"/>;
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {};
};

const mapStateToProps = (state, props) => {
	return {};
};

Home.propTypes = {
	setFullscreen: PropTypes.func.isRequired,
	isFullscreen: PropTypes.bool
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
