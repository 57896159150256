import React from "react";

import BranchingTarget from "./BranchingTarget";

import "./BranchingGroupItems.scss";

export const ItemTypeBranching = "BRANCHING";
export const ItemTypeCatchAll = "CATCH_ALL";

export const BranchingGroupItems = ({
	items, itemType, provided, namePath, subPath, group, outcome, disableEdit,
	nodeProps, playgroundProps, nodeInfoCbRef, setModalHidden, setFieldValue,
	arrayHelpers, values, touched, errors
}) => {
	if (!subPath) {
		subPath = "branching";
	}

	return (
		<div
			className="branching-group-items"
			ref={provided.innerRef}
			{...provided.droppableProps}
		>

			{items.map((branch, index) => {
				const curNamePath = `${namePath}.${subPath}[${index}]`;
				const childElements = [];

				if (branch) {
					if (branch.goto) {
						childElements.push(
							<BranchingTarget
								key={"target-" + index}
								target={branch}
								index={index}
								group={group}
								groupType={itemType}
								outcome={outcome}
								namePath={curNamePath}
								disableEdit={disableEdit}
								nodeProps={nodeProps}
								playgroundProps={playgroundProps}
								nodeInfoCbRef={nodeInfoCbRef}
								doDelete={() => arrayHelpers.remove(index)}
								setModalHidden={setModalHidden}
								setFieldValue={setFieldValue}
								values={values}
								touched={touched}
								errors={errors}
							/>
						);
					} else if (branch.branching && branch.branching.length > 0) {
						/**
						 * DJR: Disabled for now (maybe forever? maybe just display "groups hidden").
						 *
           childElements.push(<BranchingGroup key={"branch-" + index}
           group={branch}
           outcome={outcome}
           namePath={curNamePath}
           labelWidth={labelWidth}
           playgroundProps={playgroundProps}
           showNodeInfo={showNodeInfo}
           doDelete={() => arrayHelpers.remove(index)}
           setModalHidden={setModalHidden}
           setFieldValue={setFieldValue}
           values={values}
           touched={touched}
           errors={errors}/>);
						 **/
					}
				}

				return childElements;
			})}

			{provided.placeholder}
		</div>
	);
};

export default BranchingGroupItems;
