import { call, put, takeLatest } from "redux-saga/effects";

import {
	PlaygroundListAction,
	PlaygroundListFailedAction,
	PlaygroundListRequestAction,
	PlaygroundListSuccessAction
} from "../../actions/playground/list";
import { getPlaygroundList } from "../../api/playground/list";

/// / Playground List
// Worker
function * getPlaygroundListWorker (action) {
	try {
		yield put(PlaygroundListRequestAction());
		const r = yield call(getPlaygroundList, action.payload);
		if (r.json) {
			yield put(PlaygroundListSuccessAction(r.json));
		} else {
			yield put(PlaygroundListFailedAction("Failed to fetch playground list"));
		}
	} catch (e) {
		yield put(PlaygroundListFailedAction(e));
	}
}

// Saga
export function * getPlaygroundListSaga () {
	yield takeLatest(PlaygroundListAction.getType(), getPlaygroundListWorker);
}

// Export

export const listSagas = [
	getPlaygroundListSaga()
];

export default listSagas;
