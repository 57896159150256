import React from "react";
import {Helmet} from "react-helmet";

import config, {CfgColors} from "../../core/config";

import Site from "./Site";

import Router from "./Router";
import Modals from "./Modals";
import NavBar from "./NavBar/NavBar";
import MainSideMenu from "./menu/MainSideMenu";

import {ScreenContext} from "../../state/ScreenContextProvider";
import "./PlaygroundScreen.scss";
import classNames from "classnames";

interface IPlaygroundScreenProps {
    resources: any;
    profile: any;
}

class PlaygroundScreen extends React.Component<IPlaygroundScreenProps> {

    static contextType = ScreenContext;
    context!: React.ContextType<typeof ScreenContext>;

    setFullscreen = (val: boolean) => {
        this.context.dispatch({
            fullScreenMode: val
        });
        return this.context.state.fullScreenMode;
    };

    componentDidMount() {
        const {profile} = this.props;
        const loggedIn = (profile && profile.loggedIn);

        if (!loggedIn) {
            this.setFullscreen(false);
        }
    }

    render() {

        const {resources, profile} = this.props;
        const {fullScreenMode, editorMode} = this.context.state;
        const colourCfg = config.get(CfgColors);

        return (
            <>

                <Helmet
                    title="Upwire Playground"
                    meta={[
                        {name: "description", content: "Upwire Playground"},
                        {name: "keywords", content: "Upwire Playground"},
                        {name: "theme-color", content: colourCfg.blue}
                    ]}
                />

                <Site resources={resources} profile={profile}>

                    <div className={classNames({
                        "ui-window": true,
                        "is-fullscreen": fullScreenMode,
                        "is-editor": editorMode
                    })}>

                        <NavBar/>
                        <MainSideMenu/>
                        <div className="ui-content-area">
                            <Router
                                profile={profile as any}
                                setFullscreen={this.setFullscreen}
                                isFullscreen={fullScreenMode}
                            />
                        </div>

                    </div>

                </Site>

                <Modals/>
            </>
        );

    }
}

export default PlaygroundScreen;
