import { call, put, takeLatest } from "redux-saga/effects";

import {
	PlaygroundDebuggerExecAction,
	PlaygroundDebuggerExecFailedAction,
	PlaygroundDebuggerExecRequestAction,
	PlaygroundDebuggerExecSuccessAction,
	PlaygroundDebuggerQueryAction,
	PlaygroundDebuggerQueryFailedAction,
	PlaygroundDebuggerQueryRequestAction,
	PlaygroundDebuggerQuerySuccessAction,
	PlaygroundDebuggerTraceAction,
	PlaygroundDebuggerTraceFailedAction,
	PlaygroundDebuggerTraceRequestAction,
	PlaygroundDebuggerTraceSuccessAction
} from "../../actions/playground/debugger";
import {
	getPlaygroundExecByCorrelationId,
	getPlaygroundExecByRunId,
	getPlaygroundExecQuery,
	postPlaygroundExec
} from "../../api/playground/exec";

/// / Playground Debugger Exec
// Worker
function * postPlaygroundDebuggerExecWorker (action) {
	try {
		yield put(PlaygroundDebuggerExecRequestAction());

		const r = yield call(postPlaygroundExec, action.payload);
		if (r.json) {
			yield put(PlaygroundDebuggerExecSuccessAction(r.json));
		} else {
			yield put(PlaygroundDebuggerExecFailedAction("Failed to post playground debugger exec"));
		}
	} catch (e) {
		yield put(PlaygroundDebuggerExecFailedAction(e));
	}
}

// Saga
export function * postPlaygroundDebuggerExecSaga () {
	yield takeLatest(PlaygroundDebuggerExecAction.getType(), postPlaygroundDebuggerExecWorker);
}

/// / Playground Debugger Trace
// Worker
function * getPlaygroundDebuggerTraceWorker (action) {
	try {
		yield put(PlaygroundDebuggerTraceRequestAction());

		const apiFn = (action.payload.runId)
			? getPlaygroundExecByRunId
			: getPlaygroundExecByCorrelationId;

		const r = yield call(apiFn, action.payload);
		if (r.json) {
			yield put(PlaygroundDebuggerTraceSuccessAction(r.json));
		} else {
			yield put(PlaygroundDebuggerTraceFailedAction({
				message: "Not Found",
				details: "No results found"
			}));
		}
	} catch (e) {
		yield put(PlaygroundDebuggerTraceFailedAction(e));
	}
}

// Saga
export function * getPlaygroundDebuggerTraceSaga () {
	yield takeLatest(PlaygroundDebuggerTraceAction.getType(), getPlaygroundDebuggerTraceWorker);
}

/// / Playground Debugger Query
// Worker
function * getPlaygroundDebuggerQueryWorker (action) {
	try {
		yield put(PlaygroundDebuggerQueryRequestAction());

		const r = yield call(getPlaygroundExecQuery, action.payload);
		if (r.json) {
			yield put(PlaygroundDebuggerQuerySuccessAction(r.json));
		} else {
			yield put(PlaygroundDebuggerQueryFailedAction({
				message: "Not Found",
				details: "No results found"
			}));
		}
	} catch (e) {
		yield put(PlaygroundDebuggerQueryFailedAction(e));
	}
}

// Saga
export function * getPlaygroundDebuggerQuerySaga () {
	yield takeLatest(PlaygroundDebuggerQueryAction.getType(), getPlaygroundDebuggerQueryWorker);
}

// Export

export const debuggerSagas = [
	postPlaygroundDebuggerExecSaga(),
	getPlaygroundDebuggerTraceSaga(),
	getPlaygroundDebuggerQuerySaga()
];

export default debuggerSagas;
