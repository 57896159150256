import React, { Component } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";

import { CfgColors } from "core/config";
import config from "../../../../../../../../config";
import { getNodeTypeEditSchema, mergeNodeTypeEditSchema } from "../../../../../../../../config/node/edit";

import { DynamicForm } from "core/components/common/form";
import {
	ensureNodeInitialised,
	modifyNodeBranchingTempIds,
	pruneNodeBranching
} from "../../../../../../../../lib/playground/canvas/phase/node/init";
import { FooterSection } from "core/components/common/form/dynamic/DynamicForm";
import { NodeInfoCard } from "../common/NodeInfoCard";
import { trimNewNodes } from "../../../../../../../../lib/playground/canvas/phase/node/update";
import { getBranchingInitialValues, getBranchingTabs, getBranchingYupSchema } from "./branching/BranchingFields";

const topLevelVariables = [
	// "templateId", // TODO: Find out why 'templateId' was protected in this way.
	"name",
	"branching"
];

const prunedVariables = [
	"newNodes"
];

const validateDataForForm = (data, fnName) => {
	if (!data || !data.nodes)
		throw new Error(`Invalid data provided to ${fnName}`);
};

const applyDataToForm = (data, nodeProps) => {
	validateDataForForm(data, "applyDataToForm");

	const { id } = nodeProps;
	if (!id) {
		throw new Error("No 'id' found in nodeProps in call to applyDataToForm");
	}

	const nodeData = modifyNodeBranchingTempIds(ensureNodeInitialised(cloneDeep(data.nodes[id])));
	const form = {};

	for (let variableName of topLevelVariables) {
		const value = nodeData[variableName];
		if (value !== undefined && value !== null)
			form[variableName] = value;
	}

	// Copy settings
	if (nodeData.settings) {
		Object.keys(nodeData.settings).forEach(fk => {
			form[fk] = nodeData.settings[fk];
		});
	}

	return form;
};

// TODO: This is messy as heck. Untangle this mess, and make it more palatable.

const applyFormToData = (form, data, nodeProps) => {
	validateDataForForm(data, "applyFormToData");

	const cloneForm = cloneDeep(form);
	const newData = cloneDeep(data);

	const { id } = nodeProps;
	if (!id)
		throw new Error("No 'id' found in nodeProps in call to applyFormToData");

	if (!data.nodes[id]) {
		throw new Error(`Node id '${id}' not found during call to applyFormToData`);
	}

	const nodeData = cloneDeep(data.nodes[id]);

	// Extract newNodes
	const newNodes = cloneForm.newNodes;
	if (cloneForm.newNodes) {
		delete (cloneForm.newNodes);
	}

	// Copy top level
	topLevelVariables.forEach(v => {
		if (cloneForm[v] !== undefined && cloneForm[v] !== null) {
			nodeData[v] = cloneForm[v];
		}
		delete (cloneForm[v]);
	});

	// Copy settings
	if (!nodeData.settings) {
		nodeData.settings = {};
	}

	// Prune mis-saved shiz.
	prunedVariables.forEach(k => {
		if (nodeData.settings[k]) {
			delete (nodeData.settings[k]);
		}
	});

	Object.keys(cloneForm).forEach(fk => {
		nodeData.settings[fk] = cloneForm[fk];
	});

	const updatedNode = pruneNodeBranching(
		modifyNodeBranchingTempIds(
			ensureNodeInitialised(nodeData), true)
	);

	newData.nodes = {
		...newData.nodes,
		...trimNewNodes(newNodes, updatedNode),
		[id]: updatedNode
	};

	return newData;
};

const prepareInitialValues = (nodeProps) => {
	return (initialValues, formValues) => {
		return {
			...initialValues,
			branching: getBranchingInitialValues(formValues, nodeProps)
		};
	};
};

const prepareValidationSchema = (nodeProps) => {
	return (schema) => {
		return {
			...schema,
			branching: getBranchingYupSchema(nodeProps)
		};
	};
};

class NodeEditForm extends Component {
	state = {
		nodeInfoCbRef: {}
	};

	componentDidMount () {
		this.setState({
			nodeInfoCbRef: {}
		});
	}

	render () {
		let { edit, onSubmit, playgroundProps, setModalHidden } = this.props;
		const { nodeInfoCbRef } = this.state;

		if (!edit) {
			edit = {};
		}

		let { data, nodeProps, nodeData, moduleData } = edit;

		if (!data) {
			data = {};
		}
		if (!nodeProps) {
			nodeProps = {};
		}

		if (!nodeData) {
			nodeData = {};
		}

		const colourCfg = config.get(CfgColors);

		let buttonBgColor = colourCfg.blue;
		if (nodeProps.fill) {
			buttonBgColor = nodeProps.fill;
		}

		let buttonFontColor = colourCfg.white;
		if (nodeProps.iconFill) {
			buttonFontColor = nodeProps.iconFill;
		}

		const onSubmitAdaptor = (form) => {
			return onSubmit(applyFormToData(form, data, nodeProps));
		};

		const schema = mergeNodeTypeEditSchema(moduleData.edit_schema, nodeData.schema);
		const editSchema = getNodeTypeEditSchema(nodeData.type, schema);

		return (
			<DynamicForm
				formState={edit}
				formValues={applyDataToForm(data, nodeProps)}
				formSchema={editSchema.form}
				onSubmit={onSubmitAdaptor}
				prepareInitialValues={prepareInitialValues(nodeProps)}
				prepareValidationSchema={prepareValidationSchema(nodeProps)}
				buttonLabel="Save"
				buttonBgColor={buttonBgColor}
				buttonFontColor={buttonFontColor}
				modalClassName="no-scroll"
			>

				{getBranchingTabs(playgroundProps, nodeProps, nodeInfoCbRef, setModalHidden)}

				<FooterSection>
					<NodeInfoCard cbRef={nodeInfoCbRef}/>
				</FooterSection>
			</DynamicForm>
		);
	}
}

NodeEditForm.propTypes = {
	edit: PropTypes.object.isRequired,
	onSubmit: PropTypes.func.isRequired,
	playgroundProps: PropTypes.object.isRequired,
	setModalHidden: PropTypes.func.isRequired
};

export default NodeEditForm;
