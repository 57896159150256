import { Action, InputType, SelectingState, State } from "@projectstorm/react-canvas-core";
import { DragDiagramItemsState, DragNewLinkState, PortModel } from "@projectstorm/react-diagrams";

export class DefaultDiagramState extends State {
	constructor () {
		super({
			name: "default-diagrams"
		});
		this.childStates = [new SelectingState()];
		// this.dragCanvas = new DragCanvasState();
		this.dragNewLink = new DragNewLinkState();
		this.dragItems = new DragDiagramItemsState();

		// determine what was clicked on
		this.registerAction(new Action({
			type: InputType.MOUSE_DOWN,
			fire: (event) => {
				const element = this.engine.getActionEventBus().getModelForEvent(event);
				// the canvas was clicked on, transition to the dragging canvas state
				if (!element) {
					// this.transitionWithEvent(this.dragCanvas, event);
				} else if (element instanceof PortModel) {
					// initiate dragging a new link
					this.transitionWithEvent(this.dragNewLink, event);
				} else {
					// move the items (and potentially link points)
					this.transitionWithEvent(this.dragItems, event);
				}
			}
		}));
	}
}
