import React from "react";
import PropTypes from "prop-types";
import "./PresetColours.scss";

import { Swatch } from "./Swatch";

export const SketchPresetColors = ({
	colors,
	selected,
	isOpen,
	onClick = () => {},
	onToggle = () => {},
	onClear = () => {},
	onSwatchHover
}) => {
	const handleClick = (hex, e) => {
		onClick({
			hex,
			source: "hex"
		}, e);
	};

	return (
		<div className="flexbox-fix preset-colours">
			{colors.map((colorObjOrString) => {
				const c = typeof colorObjOrString === "string"
					? { color: colorObjOrString }
					: colorObjOrString;
				const key = `${c.color}${c.title || ""}`;

				let className = "swatch-wrap";
				let isSelected = false;
				if (selected && selected.toLowerCase() === c.color.toLowerCase()) {
					className += " swatch-selected";
					isSelected = true;
				}
				return (
					<div key={key} className={className}>
						<Swatch
							{...c}
							className="swatch"
							onClick={handleClick}
							onHover={onSwatchHover}
							selected={isSelected}
						/>
					</div>
				);
			})}

			<div className={"swatch-wrap" + (!selected || selected === "" ? " swatch-selected" : "")}>
				<Swatch
					className="swatch"
					onClick={onClear}
				>
					<span className="clear"/>
				</Swatch>
			</div>

			<div className="swatch-wrap swatch-selected">
				<Swatch
					className="swatch toggle"
					onClick={onToggle}
				>
          <span className={"icon toggle-wrap" + (isOpen ? " toggle-wrap-open" : "")}>
            <i className="fas fa-chevron-up"/>
          </span>
				</Swatch>
			</div>
		</div>
	);
};

SketchPresetColors.propTypes = {
	colors: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.shape({
			color: PropTypes.string,
			title: PropTypes.string
		})]
	)).isRequired
};

export default SketchPresetColors;
