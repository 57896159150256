import { ErrorCloseModalAction, ErrorOpenModalAction } from "../../actions/error/error";

const errorRegister = {};

export const registerErrorActions = (actions) => {
	if (actions) {
		actions.forEach(action => {
			errorRegister[action] = {
				open: true
			};
		});
	}
};

export const errorReducer = (state = null, action) => {
	if (errorRegister[action.type]) {
		return {
			open: errorRegister[action.type],
			...action.payload
		};
	}

	switch (action.type) {
		case ErrorOpenModalAction.getType():
			return { open: true, ...action.payload };
		case ErrorCloseModalAction.getType():
			return { open: false };
		default:
			return state;
	}
};
