import {
	ResourcesLoadAction,
	ResourcesLoadFailedAction,
	ResourcesLoadSuccessAction
} from "../../actions/resources/resources";
import { registerLoadingActions } from "../loading";
import { registerErrorActions } from "../error";
import { setSprites } from "../../config";

registerLoadingActions(
	[ResourcesLoadAction.getType()],
	[ResourcesLoadSuccessAction.getType(), ResourcesLoadFailedAction.getType()]
);

registerErrorActions([
	ResourcesLoadFailedAction.getType()
]);

export const resourcesReducer = (state = null, action) => {
	switch (action.type) {
		case ResourcesLoadAction.getType():
			return { loaded: false, loading: true };
		case ResourcesLoadSuccessAction.getType():
			if (action.payload.sprites) {
				setSprites(action.payload.sprites);
			}

			return { loaded: true, loading: false, ...action.payload };
		case ResourcesLoadFailedAction.getType():
			return { loaded: false, loading: false };
		default:
			return state;
	}
};
