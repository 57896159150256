import React from "react";
import cloneDeep from "lodash/cloneDeep";

import { sortPositionalArray } from "../../../../lib/array/sort";
import { haveErrorInFields } from "../../../../util/form/helper";
import { nameToLowerDashedSlugKey } from "../../../../lib/variable/keys";

import "./DynamicFormTabs.scss";

export const getTabId = (s) => {
	if (s.id) {
		return s.id;
	}

	if (s.key) {
		return nameToLowerDashedSlugKey(s.key);
	}

	if (s.label) {
		return nameToLowerDashedSlugKey(s.label);
	}

	throw new Error("failed to getTabId (no id, key or label)");
};

const tabHasErrors = (section, errors, touched) => {
	let hasErrors = false;
	if (errors && touched) {
		const fields = [];
		section.panels && section.panels.forEach(p => {
			p.fields && p.fields.forEach(f => {
				fields.push([f.prefixName, f.name]);
			});
		});

		hasErrors = haveErrorInFields(fields, errors, touched);
	}

	return hasErrors;
};

const DynamicFormTab = ({ section, isActive, setActiveTab, errors, touched, borderColor }) => {
	const ourId = getTabId(section);
	const styles = {};

	if (isActive) {
		styles.borderColor = borderColor;
	}

	return (
		<li
			id={"df-tab-" + ourId}
			className={((isActive) ? "is-active" : "") + (tabHasErrors(section, errors, touched) ? " has-error" : "")}
			style={styles}
			onClick={() => setActiveTab(ourId)}
		>

			<div className={"tab-title " + ((section.icon) ? "with-icon" : "")}>
				{section.icon
					? (
						<span className="icon">
              <i className={section.icon}/>
            </span>
					)
					: ""}
				<span>{section.label}</span>
			</div>
		</li>
	);
};

export const DynamicFormTabs = ({ formSchema, staticTabs, activeTab, setActiveTab, errors, touched, borderColor }) => {
	let sections = [];
	if (formSchema && formSchema.sections) {
		sections = cloneDeep(formSchema.sections);
	}
	if (staticTabs) {
		staticTabs.forEach(s => {
			sections.push({
				...s.props,
				component: s
			});
		});
	}

	const tabs = [];
	sortPositionalArray(sections).forEach(s => {
		const ourId = getTabId(s);
		if (!activeTab) {
			activeTab = ourId;
		}

		const isActive = (activeTab === ourId);

		if (s.component) {
			tabs.push(React.cloneElement(s.component, {
				errors: errors,
				touched: touched
			}));
		} else {
			tabs.push(<DynamicFormTab
				key={s.label}
				section={s}
				isActive={isActive}
				setActiveTab={setActiveTab}
				errors={errors}
				touched={touched}
				borderColor={borderColor}
			/>);
		}
	});

	return (
		<div className="tabs">
			<ul>
				{tabs}
			</ul>
		</div>
	);
};

export default DynamicFormTabs;
