import {PlayArrow} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {Alert, Button, IconButton, TextField, Tooltip} from "@mui/material";
import classNames from "classnames";
import React, {ReactNode, useCallback, useEffect, useState} from "react";
import "./test-case-wizard-styles.scss";
import {Playground} from "../types";
import {useMutation, useQuery} from "@tanstack/react-query";
import {UpwireModalLoading, UpwireModalSubheader} from "../../common/modal/modal";

import {getTestCaseError, SavedTestCase, TestCase, useTestRunnerDispatchApi, Variable} from "../../common/testCase";
import {VarContextsManager} from "../../../../components/VarContext/VarContextPicker";
import {useVarContexts, VarContext} from "../../../../components/VarContext/data";

function TestCaseView(props: {
    testCase: SavedTestCase,
    validationError: ReactNode | null,
    onRun(): void
    onEdit(): void
    onDelete(): void
}) {
    const isValid = props.validationError === null;
    return <div className={classNames("test-case", {"valid": isValid})} key={props.testCase.id}>
        <div className="inner-text">
            <div className="action-row">
                <div className="text"><ArrowForwardIcon color="disabled" fontSize="small"/>
                    <p>{props.testCase.name}</p>
                </div>
                {isValid &&
					<div className="run-button">
						<Button
							size="small"
							variant="contained"
							color="success"
							onClick={props.onRun}
						>
							<PlayArrow/>Run test Case
						</Button>
					</div>
                }

            </div>
            {!isValid ? <div className="validation-error">{props.validationError}</div> : <></>}
        </div>

        <div className="actions">
            <Tooltip className="displayed" title="Edit">
                <IconButton onClick={props.onEdit} size="small">
                    <EditIcon/>
                </IconButton>
            </Tooltip>
            <Tooltip className="displayed" title="Delete">
                <IconButton onClick={props.onDelete} size="small">
                    <DeleteIcon/>
                </IconButton>
            </Tooltip>
        </div>
    </div>;
}

function TestCaseManager(props: {
    testCases: SavedTestCase[],
    variables: Variable[],
    varContexts: VarContext[],

    onCreate: (testCase: TestCase) => void,
    onUpdate: (testCase: SavedTestCase) => void,
    onDelete: (testCase: SavedTestCase) => void,
    onRun: (testCase: SavedTestCase) => void
}) {

    const [modal, setModal] = useState<SavedTestCase | "new" | false>(false);

    const createNewTestCase = () => setModal("new");
    const editTestCase = (testCase: SavedTestCase) => setModal(testCase);
    const closeEditor = () => setModal(false);

    function onSave(testCase: TestCase) {
        if (modal === "new") props.onCreate(testCase);
        else if (modal) props.onUpdate({...modal, ...testCase});
        closeEditor();
    }

    if (modal) {

        const modalProps = {
            onSave: onSave,
            onCancel: closeEditor,
            variables: props.variables,
            varContexts: props.varContexts,
        };

        if (modal === "new") return <TestCaseEditor {...modalProps}/>;
        else return <TestCaseEditor testCase={modal} {...modalProps}/>;
    }

    const actions = <div className="control-actions">
        <Button variant="contained"
                onClick={createNewTestCase}
                startIcon={<AddIcon/>}>Add New Test Case</Button>
    </div>;

    if (props.testCases.length === 0) return <div className="test-case-manager">
        <UpwireModalSubheader text={"Start by creating a new test case"}/>
        {actions}
    </div>;


    return <div className="test-case-manager">
        <UpwireModalSubheader text="Pick a test case or create a new one"/>

        {
            props.testCases.map(it => <TestCaseView
                key={it.id} testCase={it}
                validationError={getTestCaseError(it, props.variables)}
                onRun={() => props.onRun(it)}
                onDelete={() => props.onDelete(it)}
                onEdit={() => editTestCase(it)}/>)
        }

        {actions}
    </div>;

}

function TestCaseValueEditor(props: {
    testCase: TestCase,
    modified: boolean,
    variable: Variable,
    onChange: (text: string) => void
    onTrySave: () => void
}) {

    const value = props.testCase.values[props.variable.name];
    return <div className={classNames("variable-value", {"modified": props.modified})}>
        <div className="name">
            {props.variable.name} {props.variable.required ? <span className="required">*</span> : <></>}
        </div>
        <TextField size="small"
                   name={props.variable.name}
                   className="value"
                   value={value ?? ""}
                   required={props.variable.required}
                   variant="outlined"
                   onKeyDown={e => {
                       if (e.key === "Enter") {
                           e.preventDefault();
                           props.onTrySave();
                       }
                   }}
                   onChange={ev => props.onChange(ev.target.value)}/>
    </div>;
}

function TestCaseEditor(props: {
    testCase?: TestCase,
    variables: Variable[],
    varContexts: VarContext[],

    onSave: (testCase: TestCase) => void,
    onCancel: () => void,
}) {

    const [modified, setModified] = useState<{ [key: string]: boolean }>({});
    const [modifiedVarContexts, setModifiedVarContexts] = useState<number>(0);
    const [modifiedName, setModifiedName] = useState(false);
    const [testCase, setTestCase] = useState<TestCase>(props.testCase ?? {
        name: "New test case",
        values: {},
        varContexts: {}
    });

    const setValue = useCallback((variable: Variable, value: string) => {
        if (testCase.values[variable.name] === value)
            return;

        setTestCase(tc => {
            return {...tc, values: {...tc.values, [variable.name]: value}};
        });

        setModified(m => ({...m, [variable.name]: true}));
    }, [testCase, setTestCase, setModified]);

    useEffect(() => {
        for (const variable of props.variables) {
            if (!testCase.values[variable.name])
                if (variable.default)
                    setValue(variable, variable.default);
        }
    }, [props.variables, testCase, setValue]);

    function setTestCaseName(name: string) {
        setTestCase(tc => {
            return {...tc, values: {...tc.values}, name: name};
        });
        setModifiedName(true);
    }

    const isNew = props.testCase === undefined;

    let modifiedCount = modifiedName ? 1 : 0;
    for (const key in modified) {
        if (modified[key])
            modifiedCount++;
    }
    modifiedCount += modifiedVarContexts;

    let haveAllRequiredFields = true;
    for (const variable of props.variables) {
        if (variable.required && !testCase.values[variable.name])
            haveAllRequiredFields = false;
    }

    const isValid = haveAllRequiredFields && ((modifiedCount > 0) || isNew);

    function trySave() {
        if (isValid) props.onSave(testCase);
    }

    function addVarContext(varContext: VarContext, key: string) {
        setModifiedVarContexts(it => it + 1);
        setTestCase(tc => {
            return {...tc, varContexts: {...tc.varContexts, [key]: varContext.id}};
        });
    }

    function removeVarContext(key: string) {
        setModifiedVarContexts(it => it + 1);
        setTestCase(tc => {
            const varContexts = {...tc.varContexts};
            delete varContexts[key];
            return {...tc, varContexts};
        });
    }

    const selectedContexts: { [key: string]: VarContext } = {};
    for (let key in testCase.varContexts) {
        const context = props.varContexts.find(it => it.id === testCase.varContexts[key]);
        if (context) selectedContexts[key] = context;
    }

    return <div className="test-case-editor">
        <h3>{isNew ? "Create a new" : "Edit"} test case</h3>

        <div className="blocks">
            <div className="block">
                <TextField label="Test case name" size="small" defaultValue={testCase.name}
                           onChange={ev => setTestCaseName(ev.target.value)}></TextField>

                {
                    props.variables.map(it => <TestCaseValueEditor
                        key={it.name} variable={it}
                        modified={modified[it.name] && !isNew}
                        testCase={testCase}
                        onTrySave={trySave}
                        onChange={(value) => setValue(it, value)}
                    />)
                }
            </div>
            <div className="block">
                <VarContextsManager
                    onAddVarContext={addVarContext}
                    onRemoveVarContext={removeVarContext}
                    selectedContexts={selectedContexts}/>
            </div>
        </div>

        <div className="actions">
            <Button onClick={props.onCancel}>Cancel</Button>
            {isValid ? <Button variant="contained" onClick={() => props.onSave(testCase)}>
                {isNew ? "Create" : `Save ${modifiedCount} Change${modifiedCount > 1 ? "s" : ""}`}
            </Button> : <></>}

        </div>
    </div>;
}


export default function TestCaseWizard({playground, variables, onRun}: {
    playground: Playground,
    variables: Variable[]
    onRun(testCase: TestCase): void
}) {

    const playgroundId = playground.playground_id;
    const testRunnerApi = useTestRunnerDispatchApi();
    const {
        isLoading: loading,
        data: testCases,
        error: testCaseLoadError,
        refetch: refetchTestCases
    } = useQuery(["playground", playgroundId, "test-cases"], async () => {
        return await testRunnerApi.getTestCases(playgroundId);
    });

    const {varContexts, loading: varContextsLoading, error: varContextLoadingError} = useVarContexts();

    const {
        isLoading: isDeleting,
        mutate: deleteTestCase
    } = useMutation(["playground", playgroundId, "test-cases", "delete"], async (testCase: SavedTestCase) => {
        await testRunnerApi.deleteTestCase(testCase.id, playgroundId);
        await refetchTestCases();
    });

    const {
        isLoading: isUpserting,
        mutate: upsertTestCase
    } = useMutation(["playground", playgroundId, "test-cases-upsert"], async (testCase: SavedTestCase | TestCase) => {
        if ("id" in testCase) {
            await testRunnerApi.upsertTestCase(testCase, playgroundId, testCase.id);
        } else {
            await testRunnerApi.upsertTestCase(testCase, playgroundId);
        }
        await refetchTestCases();
    });


    if (isDeleting || isUpserting || loading || varContextsLoading)
        return <UpwireModalLoading/>;

    if (!testCases || !varContexts) {
        if (testCaseLoadError) {
            if (testCaseLoadError instanceof Error)
                return <Alert severity="error">Failed to load test cases: {testCaseLoadError.message}</Alert>;
        }

        if (varContextLoadingError)
            return <Alert severity="error">Failed to load variable contexts: {varContextLoadingError}</Alert>;

        return <Alert severity="error">Failed to load test cases</Alert>;
    }

    return (
        <div className="playground-run">
            <TestCaseManager testCases={testCases}
                             varContexts={varContexts}
                             onDelete={deleteTestCase}
                             onRun={onRun}
                             onCreate={upsertTestCase}
                             onUpdate={upsertTestCase}
                             variables={variables}/>
        </div>
    );
}
