import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import config, { CfgNodeCategories } from "../../../../../../../../config";

import Tabs, { ErrorSection, Tab, TabLayoutDrawer } from "core/components/common/tabs/Tabs";
import InlineError from "core/components/common/message/InlineError";

import { sortPositionalArray } from "core/lib/array/sort";
import { extractCreatableNodeList } from "../../../../../../../../lib/playground/canvas/phase/node/new";
import { defaultNodeList } from "../../../../../../../../config/node";

import { NodeCards } from "../common/NodeCards";
import { NodeInfoCard } from "../common/NodeInfoCard";

import "./NodeNewContent.scss";

const NodeNewMessages = ({ error }) => {
	if (error) {
		return (
			<InlineError>
				<p>There was an error processing your request, please try again.</p>
			</InlineError>
		);
	}

	return "";
};

const NodalNewSearchBar = ({ searchTerm, setSearchTerm }) => {
	if (!searchTerm) {
		searchTerm = "";
	}

	return (
		<div className="search-bar">
			<div className="field">
				<p className="control has-icons-left has-icons-right">
					<input
						className="input is-small" type="text" placeholder="Search"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
					<span className="icon is-small is-left">
            <i className="fas fa-search"/>
          </span>
					{searchTerm
						? (
							<span className="icon is-small is-right clear-term" onClick={() => setSearchTerm("")}>
                <i className="fas fa-times"/>
              </span>
						)
						: ""}
				</p>
			</div>

		</div>
	);
};

class NodeNewContent extends PureComponent {
	state = {
		searchTerm: undefined
	};

	setSearchTerm = (searchTerm) => {
		this.setState(() => ({
			searchTerm: searchTerm
		}));

		return false;
	};

	render () {
		let { model, playgroundDashboard, onSelect, borderColor, closeButtonLabel, closeModal } = this.props;
		const { searchTerm } = this.state;

		if (!model) {
			model = {};
		}

		if (!closeButtonLabel) {
			closeButtonLabel = "Cancel";
		}
		const error = model.error;

		const nodeCategories = sortPositionalArray(config.get(CfgNodeCategories));
		const creatableNodes = extractCreatableNodeList(playgroundDashboard, defaultNodeList, {
			term: searchTerm
		});

		// Init external ref to avoid top level re-render.
		const nodeInfoCbRef = {};

		return (
			<div className="up-form with-tabs">
				<section className="modal-card-body node-new-content">
					<NodalNewSearchBar searchTerm={searchTerm} setSearchTerm={this.setSearchTerm}/>
					<Tabs layout={TabLayoutDrawer} borderColor={borderColor} minWidth={120}>
						<ErrorSection>
							<NodeNewMessages error={error}/>
						</ErrorSection>
						{nodeCategories.map(c => {
							return (
								<Tab key={c.id} id={c.id} title={c.name} icon={c.icon}>
									<NodeCards
										nodes={creatableNodes}
										searchTerm={searchTerm}
										category={c.id}
										handleSelect={(payload) => {
											closeModal();
											onSelect(payload);
										}}
										handleHover={(node) => {
											if (nodeInfoCbRef && nodeInfoCbRef.setNode) {
												nodeInfoCbRef.setNode(node);
											}
										}}
									/>
								</Tab>
							);
						})}
					</Tabs>
				</section>
				<footer className="modal-card-foot space-between">
					<button type="button" className="button" onClick={closeModal}>{closeButtonLabel}</button>
					<NodeInfoCard cbRef={nodeInfoCbRef}/>
				</footer>
			</div>
		);
	}
}

NodeNewContent.propTypes = {
	model: PropTypes.object.isRequired,
	playgroundDashboard: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
	closeButtonLabel: PropTypes.func,
	borderColor: PropTypes.string.isRequired
};

export default NodeNewContent;
