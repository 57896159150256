import { registerLoadingActions } from "../loading";
import {
	LoginAction,
	LoginFailedAction,
	LoginSuccessAction,
	PrivateRouteLoginAction,
	PrivateRouteRegisterAction
} from "../../actions/auth";

registerLoadingActions(
	[LoginAction.getType()],
	[LoginSuccessAction.getType(), LoginFailedAction.getType()]
);

export const loginReducer = (state = null, action) => {
	switch (action.type) {
		case LoginAction.getType():
			return { ...state, loading: true, error: null, ...action.payload };
		case LoginSuccessAction.getType():
			return { open: false };
		case LoginFailedAction.getType():
			return { ...state, loading: false, error: action.payload };
		case PrivateRouteLoginAction.getType():
			return { ...action.payload };
		case PrivateRouteRegisterAction.getType():
			return {};
		default:
			return state;
	}
};
