import { SetPreferenceAction, SetPreferencesAction } from "../../actions/preferences";

export const preferencesReducer = (state = null, action) => {
	switch (action.type) {
		case SetPreferencesAction.getType():
			return { ...action.payload };
		case SetPreferenceAction.getType():
			return { ...state, ...action.payload };
		default:
			return state;
	}
};
